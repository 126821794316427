import {
  useState,
  useContext,
  useEffect,
  useCallback,
} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import SimpleAlert from "components/SimpleAlert";
import Select from "components/Select";
import NoDataMessage from "components/NoDataMessage";
import SkeletonLoader from "components/SkeletonLoader";
import starImg from "assets/images/performance/star.svg";
import {
  SKELETONS_NUMBER,
  INFO,
  OBJECT_KEYS,
  COMPANY_SLUGS,
  VARIANT,
} from "common/constants";
import {
  isEqual,
  isNull,
  isEmpty,
  isUndefined,
} from "common/helpers";
import {
  getPercent,
  getEmployeeCompanyId,
  getEmployeeCompanySlug,
} from "common/utils";
import { getEvaluationType } from "common/validators";
import { getList as getIndividualSurveyResult } from "redux/actions/performance/surveyResultsActions";
import { getList as getPerformanceProcess } from "redux/actions/performance/performanceActions";
import { SessionContext } from "modules/session/context";
import {
  getAlert,
  isActiveDataResult,
  getTotalEvaluationTable,
  getStarRating,
} from "views_refactor/Profile/functions/performance";
import AccordionRate from "views_refactor/Profile/components/ProfileInfo/components/Modals/components/PerformanceModal/components/AccordionRate";
import { useStyles } from "views/Account/components/Performance/styles";
import {
  StyledCard,
  StyledCardContent,
  StyledResult,
} from "../../styles";

/* FIXME: Remove everything when you have the new design */
const FollowUpResults = (props) => {
  const { collaboratorId } = props;
  const { t } = useTranslation(["performance", "surveys"]);
  const classes = useStyles();

  const {
    state: { user },
  } = useContext(SessionContext);

  const {
    list: performanceProcessList,
    loadingList: isLoadingPerformanceProcess,
  } = useSelector(({ performanceReducer }) => performanceReducer);

  const {
    list: surveyResults,
    isLoadingList: isLoadingSurveyResults,
  } = useSelector(({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer);

  const dispatch = useDispatch();

  const [values, setValues] = useState({
    evaluation: "",
  });
  const [performanceData, setData] = useState(null);
  const companySlug = getEmployeeCompanySlug(user);

  const setDataEmpty = useCallback(() => {
    setData([]);
  }, [setData]);

  const getDataByProfile = useCallback(() => {
    let result = [];
    if (!isEmpty(surveyResults)) {
      result = surveyResults.find((item) => (
        item.survey_process
          && isEqual(item.survey_process.id, values.evaluation)
      ));
    }
    if (!isUndefined(result)) {
      setData(result);
    } else {
      setDataEmpty();
    }
  }, [surveyResults, setDataEmpty, values.evaluation]);

  useEffect(() => {
    if (!isLoadingSurveyResults && !isNull(surveyResults)) {
      getDataByProfile();
    }
  }, [isLoadingSurveyResults, getDataByProfile, surveyResults]);

  useEffect(() => {
    dispatch(
      getPerformanceProcess(
        getEmployeeCompanyId(user),
      ),
    );
    const query = {
      q: {
        score_not_null: 1,
        survey_process_follow_up_process_id_not_null: true,
        s: "end_date desc",
      },
    };
    dispatch(getIndividualSurveyResult(collaboratorId || user?.employee?.id, query));
  }, [dispatch, user, collaboratorId]);

  // Get evaluations with results for the collaborator visualized
  const getEvaluations = useCallback(
    () => {
      const evaluations = [];
      if (!isLoadingSurveyResults && !isEmpty(surveyResults)) {
        surveyResults.forEach((item) => {
          const isPerformanceProcess = performanceProcessList?.find(
            (process) => process.id === item.survey_process?.id,
          );
          if (isPerformanceProcess) {
            evaluations.push({
              value: item.survey_process?.id,
              label: item.survey_process?.name,
            });
          }
        });
      }
      return evaluations;
    },
    [
      isLoadingSurveyResults,
      surveyResults,
      performanceProcessList,
    ],
  );

  useEffect(() => {
    if (!isLoadingPerformanceProcess && !isNull(surveyResults)) {
      setData(surveyResults);
    }
  }, [
    surveyResults,
    isLoadingPerformanceProcess,
  ]);

  useEffect(() => {
    if (!isLoadingSurveyResults && !isNull(surveyResults)) {
      const evaluationList = getEvaluations();
      const evaluationId = evaluationList[0]?.value;
      if (!isEmpty(evaluationList) && !isEmpty(performanceProcessList)) {
        setValues({ evaluation: evaluationId });
      }
    }
  }, [isLoadingSurveyResults, surveyResults, performanceProcessList, getEvaluations]);

  useEffect(() => {
    if (!isEqual(values.survey_process?.id, values.evaluation) && !isNull(surveyResults)) {
      getDataByProfile();
    }
  }, [values.evaluation, values.survey_process, getDataByProfile, surveyResults]);

  const handleChange = (prop, event) => {
    setValues({ ...values, [prop]: event.target.value });
  };

  const scaleIndices = performanceData?.result_scale_indices;
  const scaleMaxIndex = scaleIndices?.length;

  return (
    <StyledCard data-testid={ "follow-up-results-view" }>
      <StyledCardContent>
        {isActiveDataResult(
          performanceData,
          isLoadingPerformanceProcess,
          isLoadingSurveyResults,
        )
          ? (
            <>
              <Grid container className={ classes.header }>
                <Grid item md={ 9 } xs={ 12 }>
                  <Box className={ classes.classification }>
                    <Box pr={ 1 }>
                      <img src={ starImg } alt={ t("evaluation") } />
                    </Box>
                    <Box>
                      <Typography
                        variant={ "h4" }
                        className={ classes.evaluationTitle }
                      >
                        {t("evaluation")}
                      </Typography>
                    </Box>
                  </Box>
                </Grid>
                <Grid item md={ 3 } xs={ 12 }>
                  <Select
                    id={ OBJECT_KEYS.evaluation }
                    label={ t("dashboard.filter_evaluation") }
                    menuItems={ getEvaluations() }
                    value={ values.evaluation }
                    onChange={ handleChange }
                    disabled={ isEmpty(performanceProcessList) }
                  />
                </Grid>
              </Grid>
              {isEmpty(performanceData) ? (
                <>
                  <AccordionRate
                    title={ t("performanceEvaluationNote") }
                  />
                  <NoDataMessage />
                </>
              ) : (
                <>
                  <AccordionRate
                    title={ t("performanceEvaluationNote") }
                    text={ !isNull(performanceData.comment)
                      ? performanceData.comment
                      : "" }
                    label={ performanceData.result }
                    scaleMaxIndex={ scaleMaxIndex }
                    scaleIndices={ scaleIndices }
                    score={ performanceData?.score }
                  />
                  <Box pt={ 3 }>
                    {!isEqual(companySlug, COMPANY_SLUGS.onelink)
                    && performanceData?.results_by_evaluation_type
                    && (
                      <SimpleAlert
                        type={ INFO }
                        message={ getAlert(
                          performanceData.results_by_evaluation_type,
                          t,
                        ) }
                      />
                    )}
                    <Box pb={ 2 } display={ "flex" }>
                      {performanceData?.results_by_evaluation_type?.map((result) => (
                        <StyledResult key={ result.id }>
                          <h4>{getEvaluationType(result.evaluation_type, t)}</h4>
                          {getStarRating(result, scaleMaxIndex, scaleIndices)}
                        </StyledResult>
                      ))}
                    </Box>
                  </Box>
                  <Grid container>
                    <Grid item xs={ 12 }>
                      {!isEmpty(performanceData)
                        && performanceData.complete_results_by_section?.map(
                          (evaluation) => (
                            <Box key={ evaluation.name } pb={ 3 }>
                              <AccordionRate
                                title={ evaluation.name }
                                text={ evaluation.description }
                                label={ evaluation.result }
                                children={ getTotalEvaluationTable(
                                  evaluation,
                                  evaluation.evaluation_results,
                                  performanceData,
                                  t,
                                  evaluation?.open,
                                  scaleMaxIndex,
                                  scaleIndices,
                                ) }
                                isOpen={ evaluation?.open }
                                scaleMaxIndex={ scaleMaxIndex }
                                scaleIndices={ scaleIndices }
                                score={ evaluation?.score }
                              />
                            </Box>
                          ),
                        )}
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ) : (
            <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
          )}
      </StyledCardContent>
    </StyledCard>
  );
};

FollowUpResults.propTypes = {
  collaboratorId: PropTypes.number,
};

FollowUpResults.defaultProps = {
  collaboratorId: null,
};

export default FollowUpResults;
