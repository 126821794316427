import TooltipIcon from "components/TooltipIcon";
import { WIDTH, DEFAULT_MIN_DECIMAL, ROUND } from "common/constants";
import NumberFormat from "react-number-format";
import StaticProgressBar from "components/StaticProgressBar";
import { getPercent } from "common/utils";
import palette from "theme/palette";
import StarRating from "components/StarRating";
import { getRuleName } from "views/Goals/functions";
import { StyledResult, StyledChip } from "./styles";

export const getGoalDetailsHeader = (t) => [
  {
    id: "title",
    label: t("tables:headers.title"),
    width: WIDTH.text,
    tooltip: (
      <TooltipIcon isHelpIcon>
        {t("tables:helpers.description")}
      </TooltipIcon>
    ),
    customRender: (rowData) => rowData.title,
  },
  {
    id: "goalRule",
    label: t("tables:headers.rule"),
    width: WIDTH.select,
    tooltip: (
      <TooltipIcon isHelpIcon>
        {t("tables:helpers.rule")}
      </TooltipIcon>
    ),
    customRender: (rowData) => getRuleName(rowData.goal_rule_name, t) || "-",
  },
  {
    id: "metric",
    label: t("tables:headers.metrics"),
    tooltip: (
      <TooltipIcon isHelpIcon>
        {t("tables:helpers.metrics")}
      </TooltipIcon>
    ),
    customRender: (rowData) => (
      <StyledResult>
        <NumberFormat
          value={ rowData.metric }
          displayType={ "text" }
          thousandSeparator
          decimalScale={ DEFAULT_MIN_DECIMAL }
        />
      </StyledResult>
    ),
    width: WIDTH.number,
  },
  {
    id: "weight",
    label: t("tables:headers.weight"),
    tooltip: (
      <TooltipIcon isHelpIcon>
        {t("tables:helpers.weight")}
      </TooltipIcon>
    ),
    customRender: (rowData) => (
      <StyledResult>{getPercent(rowData.weight, true)}</StyledResult>
    ),
    width: WIDTH.number,
  },
  {
    id: "completedValue",
    label: t("tables:headers.status"),
    tooltip: (
      <TooltipIcon isHelpIcon>
        {t("tables:helpers.status")}
      </TooltipIcon>
    ),
    customRender: (rowData) => (
      <StyledResult>
        {rowData.completed_value === null ? (
          "-"
        ) : (
          <NumberFormat
            value={ rowData.completed_value }
            displayType={ "text" }
            thousandSeparator
            decimalScale={ DEFAULT_MIN_DECIMAL }
          />
        )}
      </StyledResult>
    ),
    width: WIDTH.number,
  },
  {
    id: "period",
    label: t("tables:headers.measurement_period"),
    customRender: (rowData) => {
      if (rowData.period?.id) {
        let periodLabel = t(`periods:${rowData.period.slug}.name`);
        if (rowData.subperiod?.id) {
          periodLabel = t(
              `periods:${rowData.period.slug}.subperiods.${rowData.subperiod.value.toString()}`,
          );
        }
        return (
          <StyledChip
            label={ periodLabel }
            minWidth={ "70px" }
            blacktextcolor={ 1 }
          />
        );
      }
      return "-";
    },
    width: WIDTH.progressBar,
  },
  {
    id: "completionPercentage",
    label: t("tables:headers.compliance"),
    customRender: (rowData) => (
      <StaticProgressBar
        percentage={ Number(getPercent(rowData.completion_percentage, false, ROUND.max)) }
        isNotShowLabel={ rowData.completion_percentage === null }
        color={ palette.linearProgress.complete }
        isPrimaryColor={ false }
      />
    ),
    width: WIDTH.progressBar,
  },
];

export const performanceOption = (row, index, resultScaleLength) => (
  <StarRating
    name={ `star-rating-${row.answer}` }
    value={ resultScaleLength - index }
    isReadOnly
    maxRating={ resultScaleLength }
    max={ resultScaleLength }
    precision={ 1 }
    hasNoFormattedValue
    detail={ row.answer }
  />
);
