import { useMutation } from "react-query";
import { updateGoalEvaluationAnswer } from "redux/actions/goalEvaluationAnswersActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

const handleQueryError = (error, t) => {
  let errorMessage = t("common:common.api_responses.error.title");

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  } else {
    errorMessage = error.toString();
  }

  toast(MESSAGE_TYPES.error, { message: errorMessage });
};

export const useUpdateGoalEvaluationAnswer = (onSuccessCallback, t) => useMutation(
  ({ id, data }) => updateGoalEvaluationAnswer(id, data),
  {
    onSuccess: (data) => {
      if (onSuccessCallback) {
        onSuccessCallback(data);
      }
    },
    onError: (queryError) => handleQueryError(queryError, t),

  },
);
