import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import Box from "@mui/material/Box";
import Button from "components/Button";
import NoDataMessage from "components/NoDataMessage";
import ProfileImageDetail from "components/ProfileImageDetail";
import SimpleProgressBar from "components_refactor/SimpleProgressBar";
import palette from "theme/palette";
import { useGoal } from "hooks/useGoal";
import { ReactComponent as CommentsIcon } from "assets/images/forms/comments.svg";
import TextField from "@mui/material/TextField";
import GoalDetails from "../GoalDetails";
import { StyledGoalHeader, StyledGoalList, StyledStepButtons } from "../styles";

const GoalList = (props) => {
  const {
    t, currentMember,
    isMobile, scaleIndices,
    handleToCoverOrHomeProcess,
    updateGoalEvaluations,
  } = props;

  const evaluation = currentMember?.evaluationResult || currentMember;
  const evaluated = evaluation?.evaluation_details?.evaluated;

  const goalDetailsRef = useRef(null);
  const previousMemberRef = useRef(currentMember);

  const [currentGoalIndex, setCurrentGoalIndex] = useState(0);
  const [goalEvaluations, setGoalEvaluations] = useState([]);
  const [isCommentOpen, setIsCommentOpen] = useState(false);
  const [comment, setComment] = useState("");
  const [isSaving, setIsSaving] = useState(false);

  useEffect(() => {
    const sortedGoalEvaluations = (evaluation?.goal_evaluations || []).sort((a, b) => a.id - b.id);
    setGoalEvaluations(sortedGoalEvaluations);

    if (previousMemberRef.current.id !== currentMember.id) {
      setCurrentGoalIndex(0);
      previousMemberRef.current = currentMember;
    }

    setComment("");
    setIsCommentOpen(false);
  }, [evaluation, currentMember]);

  const currentGoal = goalEvaluations[currentGoalIndex];
  const goalId = currentGoal?.goal_id;

  const { goal, isLoading: isGoalLoading, isError } = useGoal(goalId);

  const updateGoalEvaluationAnswerInState = (updatedGoalEvaluationAnswer) => {
    const updatedEvaluations = goalEvaluations.map(
      (goalEval) => (goalEval.id === updatedGoalEvaluationAnswer.id
        ? updatedGoalEvaluationAnswer
        : goalEval),
    );

    setGoalEvaluations(updatedEvaluations);
    updateGoalEvaluations(updatedEvaluations);
  };

  const goToNextGoal = async () => {
    if (goalDetailsRef.current) {
      setIsSaving(true);
      await goalDetailsRef.current.saveObservation();
      setIsSaving(false);
    }
    setCurrentGoalIndex((prevIndex) => Math.min(prevIndex + 1, goalEvaluations.length - 1));
  };

  const goToPreviousGoal = async () => {
    if (goalDetailsRef.current) {
      setIsSaving(true);
      await goalDetailsRef.current.saveObservation();
      setIsSaving(false);
    }
    setCurrentGoalIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleCancel = () => {
    handleToCoverOrHomeProcess();
  };

  const toggleComment = () => {
    setIsCommentOpen((prev) => !prev);
  };

  return (
    <>
      <StyledGoalHeader isMobile={ isMobile }>
        <Grid container alignItems={ "center" } justifyContent={ "space-between" }>
          <Grid item width={ "200px" }>
            <ProfileImageDetail
              collaborator={ evaluated }
              isShortVersion
              canViewProfile={ false }
              width={ 32 }
              height={ 32 }
            />
          </Grid>
          <Grid item xs>
            <Box display={ "flex" } alignItems={ "center" } gap={ 2 }>
              <SimpleProgressBar
                title={ `Meta ${goalEvaluations.length > 0 ? currentGoalIndex + 1 : 0} de ${goalEvaluations.length}` }
                percentage={ ((currentGoalIndex + 1) / goalEvaluations.length) * 100 }
                color={ palette.linearProgress.complete }
              />
            </Box>
          </Grid>
          <Grid item>
            <Box display={ "flex" } alignItems={ "center" } gap={ 1 }>
              {!isMobile && (
                <Typography variant={ "body2" } color={ "textPrimary" }>
                  {t("surveys:goals.add_general_comment")}
                </Typography>
              )}
              <Tooltip title={ t("surveys:goals.add_comment") } arrow>
                <Button
                  onClick={ toggleComment }
                  sx={ { minWidth: "auto", padding: 0, opacity: goalEvaluations.length > 0 ? "none" : 0.5 } }
                  isDisabled={ goalEvaluations.length === 0 || isSaving }
                >
                  <CommentsIcon
                    width={ 40 }
                    height={ 40 }
                  />
                </Button>
              </Tooltip>
            </Box>
          </Grid>
        </Grid>
      </StyledGoalHeader>

      {isCommentOpen && (
        <Box mt={ 2 }>
          <TextField
            fullWidth
            multiline
            minRows={ 3 }
            variant={ "outlined" }
            placeholder={ t("surveys:goals.add_general_comment") }
            value={ comment }
            onChange={ (e) => setComment(e.target.value) }
          />
        </Box>
      )}

      <StyledGoalList>
        {!isGoalLoading && goal && !isError && currentGoal && (
          <Box mt={ 2 }>
            <GoalDetails
              key={ currentGoal.id }
              goal={ goal }
              t={ t }
              goalEvaluationAnswer={ currentGoal }
              scaleIndices={ scaleIndices }
              onUpdate={ updateGoalEvaluationAnswerInState }
              ref={ goalDetailsRef }
              isMobile={ isMobile }
            />
          </Box>
        )}
        {goalEvaluations.length === 0 && <NoDataMessage />}
        <StyledStepButtons>
          <Button
            variant={ "outlined" }
            type={ "text" }
            onClick={ handleCancel }
            typeStyle={ "outlined" }
            isDisabled={ isSaving }
          >
            {isMobile ? t("common:common.cancel") : t("surveys:continueLater")}
          </Button>
          <Button
            color={ "primary" }
            variant={ "outlined" }
            onClick={ goToPreviousGoal }
            isDisabled={ currentGoalIndex === 0 || isSaving }
            typeStyle={ "outlined" }
          >
            {t("common:common.previous")}
          </Button>
          <Button
            isDisabled={ currentGoalIndex === goalEvaluations.length - 1
              || goalEvaluations.length === 0 || isSaving }
            variant={ "contained" }
            color={ "primary" }
            typeStyle={ "submit" }
            onClick={ goToNextGoal }
          >
            {t("common:common.next")}
          </Button>
        </StyledStepButtons>
      </StyledGoalList>
    </>
  );
};

GoalList.propTypes = {
  t: PropTypes.func.isRequired,
  currentMember: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleToCoverOrHomeProcess: PropTypes.func.isRequired,
  updateGoalEvaluations: PropTypes.func.isRequired,
  scaleIndices: PropTypes.array,
};

GoalList.defaultProps = {
  scaleIndices: null,
};

export default GoalList;
