import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import makeStyles from "@mui/styles/makeStyles";
import palette, { BODY, TABLE, STATE_COLORS } from "theme/palette";

const styledCell = `
  font-size: 13px;
  color: ${palette.text.subtitle};`;

export const StyledTableCell = styled(TableCell)`
  padding: 0 15px;
  border-bottom: ${palette.border.simpleThin};
  .MuiSvgIcon-root {
    font-size: 1rem;
  }
  .MuiIconButton-root .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
  .MuiButton-text .MuiSvgIcon-root {
    font-size: 1.5rem;
  }
  ${styledCell}
`;

export const StyledTableCellCollapse = styled(TableCell)`
  padding: 0 !important;
  border: 0;
  ${styledCell}
`;

export const StyledTableCellDragable = styled(TableCell)`
  padding: 0 15px;
  border-right: 1px solid ${TABLE.DIVIDER};
  ${styledCell}
  text-align: center;
  &:hover {
    cursor: pointer;
  }
`;

export const StyledContainer = styled.div`
  width: 100%;
  .MuiOutlinedInput-adornedEnd {
    min-width: 160px;
  }
  .MuiOutlinedInput-inputMultiline {
    min-width: 150px;
  }
`;

export const StyledSearchContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
  padding: 25px 10px;
`;

export const StyledCellHeader = styled(TableCell)`
  background: ${BODY.BG_MAIN};
  padding: 5px 15px;
  border-bottom: 1px solid ${ TABLE.DIVIDER};
  font-size: 13px;
  color: ${palette.black};
`;

export const useStyles = makeStyles((theme) => ({
  active: {
    background: theme.palette.backgroundTab,
  },
  open: {
    border: `2px solid ${theme.palette.text.link}`,
    borderLeft: `10px solid ${theme.palette.text.link}`,
    borderRight: `5px solid ${theme.palette.text.link}`,
  },
  openNested: {
    border: `0px solid ${theme.palette.text.link}`,
    borderLeft: `5px solid ${theme.palette.text.link}`,
    borderRight: `2px solid ${theme.palette.text.link}`,
    borderBottom: `2px solid ${theme.palette.text.link}`,
  },
  simpleTable: {
    padding: theme.spacing(0),
  },
  collapseData: {
    display: "flex",
    alignItems: "center",
  },
  collapseComponent: {
    width: "100%",
  },
  emptyContainer: {
    textAlign: "center",
  },
  emptyInfoButton: {
    paddingBottom: 0,
  },
  iconNewElement: {
    width: "0.7em",
    marginRight: theme.spacing(0.5),
    marginLeft: "5px",
    "&:hover": {
      cursor: "pointer",
    },
  },
  addNew: {
    color: theme.palette.text.link,
  },
  cancelNew: {
    color: STATE_COLORS.ERROR,
  },
  iconDisabled: {
    color: theme.palette.text.disabled,
    "&:hover": {
      cursor: "default",
    },
  },
  fadeIn: {
    animation: "fadeIn ease 2s",
  },
  clickableRow: {
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "white",
    },
  },
  cellContent: {
    display: "flex",
    alignItems: "center",
  },
}));

export const StyledTableRowCollapse = styled(TableRow)`
  cursor: pointer;
  padding: 0;
  background: ${palette.background.lightGrey};
  transition: background-color 0.3s ease;
  button {
    padding: 0;
  }
  :hover {
    background: ${palette.white};
  }
`;

export const StyledCustomTableCell = styled(TableCell)`
  padding: 0px;
  border-bottom: ${palette.border.simpleThin};
  .MuiIconButton-root .MuiSvgIcon-root {
    font-size: 1rem;
  }
  ${styledCell}
`;
