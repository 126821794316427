import configAxios from "../configAxios";

const URL = {
  main: "/goal_evaluation_answers",
  attachments: "attachments",
};

export const updateGoalEvaluationAnswer = async (id, data) => {
  const response = await configAxios.put(`${URL.main}/${id}`, data, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
  return response.data.goal_evaluation_answer;
};

export const deleteAttachment = async (goalEvaluationAnswerId, attachmentId) => {
  const response = await configAxios.delete(`${URL.main}/${goalEvaluationAnswerId}/${URL.attachments}/${attachmentId}`);
  return response.data;
};
