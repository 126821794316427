import { useMutation } from "react-query";
import { deleteAttachment } from "redux/actions/goalEvaluationAnswersActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

export const useDeleteAttachment = (onSuccessCallback) => {
  const mutation = useMutation(
    ({ goalEvaluationAnswerId, attachmentId }) => deleteAttachment(
      goalEvaluationAnswerId,
      attachmentId,
    ),
    {
      onSuccess: (data, variables) => {
        if (onSuccessCallback) {
          onSuccessCallback(variables.attachmentId);
        }
      },
      onError: (error) => {
        toast(
          MESSAGE_TYPES.error,
          {
            message: error.message || error.toString(),
          },
        );
      },
    },
  );

  return mutation;
};
