import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";

export const StyledInputContainer = styled(Grid)`
  margin-bottom: 16px;
`;

export const StyledLabel = styled(Typography)`
  margin-bottom: 8px;
`;

export const StyledInputNumberContainer = styled.div`
  width: 200px;
`;
