import Button from "components/Button";
import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Checkbox from "@mui/material/Checkbox";
import palette from "theme/palette";

export const StyledButton = styled(Button)`
  &.MuiButton-root {
    background: ${palette.background.newButton};
    .MuiSvgIcon-root {
      width: 0.7em;
      margin-right: 5px;
    }
  }
`;

export const StyledReminder = styled.div`
  svg {
    margin-bottom: 8px;
  }
  .MuiGrid-root {
    margin-top: 20px;
  }
`;

export const StyledCustomButton = styled(Button)`
  &.MuiButton-root {
    margin-left: 20px;
    border-color: ${palette.background.newButton};
    color: ${palette.background.newButton};
    background: ${palette.white};
    &:hover {
      background: rgba(41,48,255,0.15);
    }
    .MuiSvgIcon-root {
      width: 0.7em;
      margin-right: 5px;
      color: ${palette.background.newButton};
    }
  }
`;

export const StyledStakeholderContainer = styled(Grid)`
  margin-top: 20px;
`;

export const StyledEditTitle = styled.div`
  background: ${palette.background.lightGray};
  border-radius: 4px;
  padding: 10px;
`;

export const StyledPaper = styled(Paper)`
  margin-bottom: 20px;
  padding: 20px;
  h4 {
    margin-bottom: 10px;
  }
  ol {
    margin-left: 13px;
  }
  button {
    margin-top: 20px;
  }
`;

export const StyledRestoreButton = styled(Button)`
  margin-right: 10px;
  &.MuiButton-root {
    .MuiSvgIcon-root {
      width: 0.7em;
      margin-left: 5px;
    }
  }
`;

export const StyledLink = styled(Button)`
  cursor: pointer;
  text-decoration: underline;
  padding: 0px;
  font-size: 13px;
  margin-bottom: 2px;
  font-weight: 500;
  color: ${palette.background.purpleBox};
  :hover {
    background: none;
    color: ${palette.primaryApp};
  }
`;

export const StyledCheckbox = styled(Checkbox)`
  color: ${palette.background.purple};
  &.Mui-checked {
    color: ${palette.background.purple};
  };
`;

export const StyledCalibration = styled.div`
  span {
    font-size: 16px;
    font-weight: 500;
  }
  .MuiPaper-root {
    background: ${palette.background.primaryInfo};
    color: ${palette.text.primaryInfo};
  }
`;

export const StyledGoalsContainer = styled(Grid)`
  margin-top: 20px;
`;
