import styled from "styled-components";
import { Box, Typography, IconButton, Button, TextField, Checkbox, Grid } from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import theme from "theme";

export const StyledDrawer = styled(Box)`
  width: 800px;
  padding: ${theme.spacing(2)};
  box-sizing: border-box;
`;

export const StyledHeader = styled(Box)`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 25px;
`;

export const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 20px;
  color: ${theme.palette.text.primary};
`;

export const StyledIconButton = styled(IconButton)`
  color: ${theme.palette.text.secondary};
`;

export const StyledForm = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: ${theme.spacing(2)};
`;

export const StyledTextField = styled(TextField)`
  width: 100%;
`;

export const StyledCheckboxLabel = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledButtonContainer = styled(Box)`
  display: flex;
  justify-content: center;
  margin-top: ${theme.spacing(2)};
`;

export const StyledSendButton = styled(Button)`
  width: 100px;
  margin: 10px;
  background-color: ${theme.palette.primaryApp};
`;

export const StyledCancelButton = styled(Button)`
  width: 100px;
  margin: 10px;
`;

export const StyledCheckbox = styled(Checkbox)`
  &.Mui-checked {
    color: ${theme.palette.background.purple};
  };
`;

export const StyledInputContainer = styled(Grid)`
  margin-bottom: 16px;
`;

export const StyledMailIcon = styled(EmailIcon)`
  color: white;
  font-size: 50px;
  margin-right: 8px;
  background-color: ${theme.palette.primaryApp};
  border-radius: 50%;
  padding:10px;
`;

export const StyledTypography = styled(Typography)`
  font-weight: 600;
  margin-top: 20px;
  color: ${theme.palette.text.primary};
`;

export const StyledSubtitle = styled.h3`
  font-size: 14px;
  color: ${theme.palette.text.primary};
  display: flex;
  align-items: center;
  padding-bottom: 15px;
  font-weight: 500;

`;

export const StyledPreviewBox = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  border: 1px solid #e0e0e0;
  cursor: pointer;

  &:hover {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.15);
  }

  .icon {
    margin-right: 8px;
    color: #616161;
  }

  .label {
    font-weight: 500;
    color: #333;
  }

  .arrow {
    color: #757575;
  }
`;

export const StyledPreviewContent = styled(Box)`
  margin-top: 10px;
  padding: 16px;
  border: 1px solid #e0e0e0;
  border-radius: 8px;
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
`;

export const StyledPreview = styled(Typography)`
  font-weight: 600;
  color: ${theme.palette.text.primary};
  margin-left: 15px;
`;
