import { useQuery, useMutation, useQueryClient } from "react-query";
import { getNineBoxTypes, updateNineBoxType } from "redux/actions/talentScore/talentScoreActions";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";

const handleQueryError = (error, t) => {
  let errorMessage = t("common:common.api_responses.error.default_error");

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  } else {
    errorMessage = error.toString();
  }

  toast(MESSAGE_TYPES.error, { message: errorMessage });
};

export const useNineBoxTypes = (t) => {
  const {
    data: nineBoxTypeList,
    isLoading,
    isError,
    error,
  } = useQuery("nineBoxTypeList", getNineBoxTypes, {
    staleTime: Infinity,
    onError: (queryError) => handleQueryError(queryError, t),
  });

  return {
    nineBoxTypeList,
    isLoading,
    isError,
    error,
  };
};

export const useUpdateNineBoxType = (t) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    ({ id, data }) => updateNineBoxType({ id, data }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("nineBoxTypeList");
        toast(MESSAGE_TYPES.success, { message: t("common:nine_box_type.alerts.update") });
      },
      onError: (mutationError) => handleQueryError(mutationError, t),
    },
  );

  return mutation;
};
