import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import palette, { iconColor } from "theme/palette";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

export const StyledContainer = styled(Paper)`
  padding: 32px;
  margin-top: 20px;
`;

export const StyledContent = styled.div`
  margin-bottom: 16px;
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledFilterGridContainer = styled(Grid)`
  padding-bottom: 16px;
  h4 {
    font-size: 18px;
    font-weight: 700;
  }
`;

export const StyledGrid = styled(Grid)`
  margin-top: 20px;
`;

export const StyledSkill = styled.div`
  display: flex;
  align-items: center;
  color: ${ (props) => props.color };
  img {
    margin-right: 2px;
  }
`;

export const StyledOptionsGrid = styled(Grid)`
  align-items: center;
  font-weight: 400;
`;

export const StyledLegend = styled.div`
  display: flex;
  align-items: center;
  font-weight: 400;
`;

export const StyledItem = styled.div`
  margin: 0px 8px;
  font-size: 14px;
  @media (max-width: 960px) {
    margin: 20px 8px;
  }
  @media (max-width: 600px) {
    font-size: 12px;
  }
  @media (max-width: 400px) {
    margin: 10px 4px;
    font-size: 10px;
  }
`;

export const StyledLegendTitle = styled.div`
  font-size: 14px;
`;

export const StyledInfoOutlinedIcon = styled(InfoOutlinedIcon)`
  color: ${iconColor.defaultMenu};
  margin-left: 5px;
  cursor: pointer;
`;

export const StyledGridCards = styled(Grid)`
  margin-top: 5px;
`;

export const StyledGridButton = styled(Grid)`
  margin-top: 5px;
  margin-right: ${(props) => (props.isModal && "15px")};
  float: ${(props) => (props.isModal && "right")};
  margin-top: ${(props) => (props.isModal ? "20px" : "5px")};
  svg {
    display: ${(props) => (props.isModal && "none")};
  }
  .MuiButtonBase-root{
    background: ${(props) => (props.isModal && palette.primaryApp)};
    color: ${(props) => (props.isModal && palette.white)};
  }
  .MuiButton-outlinedSizeSmall{
    padding: ${(props) => (props.isModal && "5px 10px !important")};
  }
  .buttonIconContainer .left {
    margin-right: ${(props) => (props.isModal && "5px")};
  }
`;

export const StyledGridParticipation = styled(Grid)`
  width: 97%;
`;
export const StyledCalibrationDetail = styled(Typography)`
  margin-top: 5px;
  display: flex;
  align-items: center;
  color: ${palette.iconRating.mediumGrey};
  font-weight: 700;
`;

export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
`;

export const StyledManageSearchIcon = styled(ContentPasteSearchOutlinedIcon)`
  cursor: pointer;
`;

export const StyledReplayIcon = styled(ReplayIcon)`
  cursor: pointer;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: black;
`;
