import { useEffect } from "react";
import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { StyledOption } from "./styles";

const OptionInput = ({
  id, onDelete, percentage, formLanguage, register, setValue, watch,
}) => {
  const languageCodes = ["es", "en", "pt"];

  useEffect(() => {
    languageCodes.forEach((langCode) => {
      register(`${id}_option_${langCode}`);
    });
    register(`${id}_percentage`);
    setValue(`${id}_percentage`, percentage);
  }, [register, id, languageCodes, percentage, setValue]);

  const isEmpty = (value) => value === undefined || (value && value.trim()) === "";

  return (
    <StyledOption>
      <Box width={ "20%" } marginRight={ 2 }>
        <TextField fullWidth value={ `${percentage}%` } disabled />
      </Box>
      <Box width={ "80%" }>
        {languageCodes.map((langCode) => (
          <div
            style={ { display: formLanguage === langCode ? "block" : "none" } }
            key={ langCode }
          >
            <TextField
              name={ `${id}_option_${langCode}` }
              fullWidth
              variant={ "outlined" }
              onChange={ (e) => setValue(`${id}_option_${langCode}`, e.target.value) }
              className={ isEmpty(watch(`${id}_option_${langCode}`)) ? "error" : "" }
            />
          </div>
        ))}
      </Box>
      {onDelete
      && (
        <IconButton onClick={ () => onDelete(id) } disabled={ id <= 3 }>
          <DeleteIcon />
        </IconButton>
      ) }
    </StyledOption>
  );
};

OptionInput.propTypes = {
  id: PropTypes.number.isRequired,
  percentage: PropTypes.number.isRequired,
  formLanguage: PropTypes.string.isRequired,
  register: PropTypes.func.isRequired,
  setValue: PropTypes.func.isRequired,
  watch: PropTypes.func.isRequired,
  onDelete: PropTypes.func,
};

OptionInput.defaultProps = {
  onDelete: null,
};

export default OptionInput;
