import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";
import Box from "@mui/material/Box";
import palette from "theme/palette";

const SimpleProgressBar = ({ title, percentage, color }) => (
  <Box data-testid={ "simple-progress-bar-component" }>
    <Typography variant={ "caption" } gutterBottom>
      {title}
    </Typography>
    <LinearProgress
      variant={ "determinate" }
      value={ percentage }
      sx={ {
        width: 116,
        height: 7,
        borderRadius: 5,
        backgroundColor: "#DBDBDB",
        "& .MuiLinearProgress-bar": {
          borderRadius: 5,
          backgroundColor: color,
        },
      } }
    />
  </Box>
);

SimpleProgressBar.propTypes = {
  title: PropTypes.string.isRequired,
  percentage: PropTypes.number.isRequired,
  color: PropTypes.string,
};

SimpleProgressBar.defaultProps = {
  color: palette.linearProgress.complete,
};

export default SimpleProgressBar;
