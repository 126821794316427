import { Redirect, Switch, useLocation } from "react-router-dom";
import AuthenticationLayout from "layouts/Authentication";
import { getMainRoute, getValidationRoutes } from "common/utils";

// main routes
// refactored
import CoverController from "views/Cover/Cover.controller";
import HomeController from "views/Home/Home.controller";
import LeadershipController from "views/Leadership/Leadership.controller";
import NalaAnalyticsController from "views/NalaAnalytics/NalaAnalytics.controller";
import PlanningController from "views/Planning/Planning.controller";
import MatrixNineBoxController from "views/MatrixNineBox/MatrixNineBox.controller";
// end main routes

import { PrivateRouteWithLayout, RouteWithLayout } from "./components";
import { Main as MainLayout, Minimal as MinimalLayout } from "./layouts";

import {
  // main routes
  Engagement as EngagementView,
  HrAnalyticsBeta as HrAnalyticsBetaView,
  OrganizationChart as OrganizationChartView,
  Account as AccountView,
  Preboarding as PreboardingView,
  Candidates as CandidatesView,
  Performance as PerformanceView,
  Potential as PotentialView,
  BulkUpload as BulkUploadView,
  HCPlanning as HCPlanningView,
  Goals as GoalsView,
  DevelopmentPlan as DevelopmentPlanView,
  GeneralAdministrator as GeneralAdministratorView,
  HrAnalytics as HrAnalyticsView,
  TalentDrain as TalentDrainView,
  TimeOff as TimeOffView,
  OnboardingFollowUp as OnboardingFollowUpView,
  CollaboratorsFollowUp as CollaboratorsFollowUpView,
  // end main routes
  NotFound as NotFoundView,
  SignIn as SignInView,
  SignUp as SignUpView,
  CollaboratorForms as CollaboratorFormsView,
  Onboarding as OnboardingView,
  OnboardingBulkUpload as OnboardingBulkUploadView,
  Templates as TemplatesView,
  TemplateForms as TemplateFormsView,
  RecoverPassword as RecoverPasswordView,
  ExpiredToken as ExpiredTokenView,
  EvaluationForm as EvaluationFormView,
  SurveyEvaluationsPreview as SurveyEvaluationsPreviewView,
} from "./views";
import MainView from "./views/GeneralAdministrator/modules/Main";
// import MainCustomView from "./views/GeneralAdministrator/modules/MainCustom";
import UncontrolledView from "./views/GeneralAdministrator/modules/Uncontrolled";
// refactor in progress
import Profile from "./views_refactor/Profile/Profile.controller";
import HomeControllerTest from "./views_refactor/Home/Home.controller";
import localRoutes from "./views_refactor/Profile/functions/routes";
// end refactor
import SurveyTemplateController from "./views/SurveyTemplate/SurveyTemplate.controller";
import CollaboratorsController from "./views/Collaborators/Collaborators.controller";
import PositionsController from "./views/Positions/Positions.controller";
import NotificationsSettingsController from "./views/NotificationsSettings/NotificationsSettings.controller";

const Routes = () => {
  const location = useLocation();
  const mainRoute = getMainRoute();
  const isAllowedRoute = getValidationRoutes(location?.pathname);

  return (
    <Switch>
      <Redirect exact from={ "/" } to={ mainRoute } />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? AccountView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/onboarding_profile" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? CollaboratorsController : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/collaborators" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? GoalsView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/goals" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? DevelopmentPlanView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/development-plan" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? CollaboratorFormsView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/collaborators/new" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? EngagementView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/engagement" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? CandidatesView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/onboarding" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? HrAnalyticsBetaView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/hrAnalyticsBeta" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? HrAnalyticsView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/hrAnalytics" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? PerformanceView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/performance-review" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? PotentialView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/potential" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? PreboardingView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/preboarding" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? OnboardingView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/onboarding/new" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? OnboardingBulkUploadView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/onboarding/massive" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? TemplatesView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/templates" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? TemplateFormsView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/templates/new" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? TemplateFormsView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/templates/edit" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? OrganizationChartView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/organization-chart" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? HCPlanningView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/hc-planning" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? BulkUploadView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/bulk-upload" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? GeneralAdministratorView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? TalentDrainView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/attrition-risk" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? MainView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/units" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? MainView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/surveys" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? UncontrolledView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/surveys/new" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? UncontrolledView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/surveys/edit" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? UncontrolledView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/surveys/preview" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? MainView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/contract-types" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? MainView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/cities" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? PositionsController : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/positions" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? MainView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/additional-fields" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? MainView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/integrations" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? NotificationsSettingsController : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/administrator/notifications" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? TimeOffView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/time-off" }
      />
      <PrivateRouteWithLayout
        component={ OnboardingFollowUpView }
        exact
        layout={ MainLayout }
        path={ "/profile/onboarding-follow-up" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? CollaboratorsFollowUpView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/collaborators-follow-up" }
      />
      <PrivateRouteWithLayout
        component={ EvaluationFormView }
        layout={ MainLayout }
        path={ "/evaluation-form/:id" }
      />
      <RouteWithLayout
        component={ SignUpView }
        exact
        path={ "/sign-up" }
        layout={ AuthenticationLayout }
      />
      <RouteWithLayout
        component={ ExpiredTokenView }
        exact
        path={ "/expired-token" }
        layout={ MinimalLayout }
      />
      <RouteWithLayout
        component={ SignInView }
        exact
        path={ "/sign-in" }
        layout={ AuthenticationLayout }
      />
      <RouteWithLayout
        component={ RecoverPasswordView }
        exact
        path={ "/recover-password" }
        layout={ AuthenticationLayout }
      />
      <RouteWithLayout
        component={ NotFoundView }
        exact
        layout={ MinimalLayout }
        path={ "/not-found" }
      />

      <PrivateRouteWithLayout
        component={ isAllowedRoute ? NalaAnalyticsController : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/nala_analytics" }
      />

      {/* Refactored */}
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? PlanningController : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/planning" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? SurveyEvaluationsPreviewView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/planning/preview" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? SurveyEvaluationsPreviewView : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/planning/survey_process" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? SurveyTemplateController : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/planning/templates" }
      />
      <PrivateRouteWithLayout
        component={ CoverController }
        exact
        layout={ MainLayout }
        path={ "/cover" }
      />

      <PrivateRouteWithLayout
        component={ isAllowedRoute ? MatrixNineBoxController : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/ninebox" }
      />

      {/* New */}
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? HomeController : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/home" }
      />
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? LeadershipController : NotFoundView }
        exact
        layout={ MainLayout }
        // TODO: change by leadership
        path={ "/dashboard-good-leader" }
      />

      {/* Refactor in progress */}
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.root }
      />
      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for personal info)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.personalInfo }
      />
      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for performance)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.performance }
      />
      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for performance)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.skills }
      />
      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for documents)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.documents }
      />
      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for goodLeader)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.goodLeader }
      />

      {/* refactor container manager */}
      <PrivateRouteWithLayout
        component={ isAllowedRoute ? HomeControllerTest : NotFoundView }
        exact
        layout={ MainLayout }
        path={ "/container_manager_test" }
      />
      {/* end container manager */}
      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for employeeInfo)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.employeeInfo }
      />

      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for employeeInfo)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.feedback }
      />

      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for employeeInfo)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.addFeedback }
      />

      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for employeeInfo)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.addAcknowledgment }
      />

      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for employeeInfo)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.salaryInfo }
      />

      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for employeeInfo)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.addMovement }
      />

      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for employeeInfo)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.lossRisk }
      />

      <PrivateRouteWithLayout
        // Same "background" different validation (modal on TRUE for performance)
        component={ isAllowedRoute ? Profile : NotFoundView }
        exact
        layout={ MainLayout }
        path={ localRoutes.ninebox }
      />

      {/* End refactor */}

      <Redirect to={ "/not-found" } />
    </Switch>
  );
};

export default Routes;
