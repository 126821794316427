import styled from "styled-components";
import Paper from "@mui/material/Paper";
import theme from "theme/palette";
import Button from "components/Button";

export const StyledTable = styled.div`
  width: 100%;
  color: ${theme.text.secondaryTitle};
  font-size: 14px;
  font-weight: 500;
`;

export const StyledTableHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  height: 70px;
  border-top-right-radius: 8px;
  border-top-left-radius: 8px;
  &.content{
    background: ${theme.table.header};
    text-align: left;
    > div{
      width: 100%;
      padding: 10px 25px;
      line-height: 16px;
      &:first-child{
        width: 200%;
      }
      &:last-child{
        width: ${(props) => (props.withoutActions ? "100%" : "15%") };
      }
    }
  }
  &.header{
    height: 48px;
    color: ${theme.white};
  }
  .skeleton{
    margin: 0 15px;
  }
`;

export const StyledTableBody = styled.div`
  width: 100%;
  border: ${theme.border.simpleGray};
  background-color: ${theme.white};
  &.content{
    .noData{
      min-height: 200px;
    }
    .rowContent{
      min-height: ${(props) => (props.isDense ? "35px" : "70px") };
      display: flex;
      align-items: center;
      width: 100%;
      text-align: left;
      border-bottom: ${theme.border.simpleGray};
      &.odd{
        background-color: ${theme.table.oddRow} ;
      }
      > div {
        width: 100%;
        padding: 10px 25px;
        &:first-child{
          width: 200%;
        }
        &:last-child{
          width: ${(props) => (props.withoutActions ? "100%" : "15%") };
        }
      }
    }
  }
`;

export const StyledTitleWithSubtitle = styled.div`
  display: inline;
  text-align: ${(props) => (props.isMobile ? "center" : "left") };
  > div{
    padding-left: 5px;
    &.subtitle{
      font-weight: 400;
      font-size: 12px;
      color: ${theme.text.content};
      padding-top: 5px;
      display: flex;
      align-items: center;
      svg{
        margin-right: 5px;
        width: 14px;
      }
    }
  }
  .cardMobile{
    font-size: 14px;
  }
  svg {
    width: 18px;
    height: 12px;
    padding-right: 2px;
    cursor: pointer;
  }
`;

export const StyledActionItem = styled.div`
  &.error{
    color: ${theme.table.red}
  }
`;

export const StyledPaginationContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  color: ${theme.table.paginationIcon};
  font-size: 12px;

`;

export const StyledButtonPaginator = styled(Button)`
  background-color: ${theme.white};
  margin: 5px 2px;
  svg{
    color: ${theme.table.paginationIcon};
  }
  &.Mui-disabled{
    svg{
      color: ${theme.table.disabled};
    }
  }
`;

export const StyledSearchContainer = styled.div`
  display: ${(props) => (props.isMobile ? "inline-block" : "flex")};
  justify-content: space-between;
  align-items: center;
  width: 100%;
  margin-bottom: 15px;
  .searchInput{
    input{
      width: 250px;
      @media (max-width: 600px) {
        width: 100%
      };
    }
  }
`;

export const StyledDownLoad = styled.div`
  margin-bottom: 10px;
`;

export const StyledTableCard = styled(Paper)`
  padding:25px;
  margin-top: 15px;
  box-shadow: ${theme.boxShadow.card};
`;

export const StyledButtonActionsContainer = styled.div`
  margin-top: 25px;
  text-align: center;
`;
export const StyledLink = styled.div`
  color: ${theme.text.link};
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  &:hover {
    cursor: pointer;
  }
  @media (max-width: 600px) {
    display: inline;
  };
`;

export const StyledRow = styled.div`
  cursor: ${(props) => (props.isClickeable ? "pointer" : "auto") };
  background-color: ${(props) => (props.isSelected ? theme.table.hover : "none") };
  :hover {
    background-color: ${(props) => (props.isClickeable ? theme.table.hover : "none") };
  }
`;
