import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  closestCenter,
  DndContext,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy,
} from "@dnd-kit/sortable";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { generateRandomId } from "common/utils";
import Section from "../Section";
import { NewSectionContainer, AddSectionButton } from "./styles";

const SectionList = (props) => {
  const {
    formValues, setFormValues, t, formLanguage, templateStates,
    setWatchedFields, hasInternalClient,
  } = props;
  const { sections, movedItems = [] } = formValues || [];

  const [showItemId, setShowItemId] = useState("");
  const [items, setItems] = useState([]);
  const [expandedSection, setExpandedSection] = useState(null);

  const handleShowItem = (id) => {
    setShowItemId(id);
  };

  const handleAccordionChange = (id) => {
    setExpandedSection((prev) => (prev === id ? null : id));
  };

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const handleDragEnd = (event) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const newItems = [...items];
      const oldIndex = newItems.indexOf(active.id);
      const newIndex = newItems.indexOf(over.id);

      newItems.splice(oldIndex, 1);
      newItems.splice(newIndex, 0, active.id);
      setItems(newItems);

      const newSections = newItems.map((item) => sections?.find((section) => section.id === item));
      setFormValues({
        ...formValues,
        sections: newSections,
        movedItems: [
          ...new Set([
            ...movedItems,
            { id: active.id, newPosition: newIndex + 1 },
          ]),
        ],
      });
    }
  };

  const handleAddSection = (siblingSection = null, openSection = false) => {
    const indexSiblingSection = siblingSection !== null ? sections.findIndex(
      (section) => section.id === siblingSection,
    ) : -1;

    const newSection = {
      id: generateRandomId(14),
      name_es: "",
      name_en: "",
      name_pt: "",
      evaluations: [],
      questions: [],
      open: openSection,
      isNew: true,
    };

    const updatedSections = indexSiblingSection !== -1
      ? [
        ...sections.slice(0, indexSiblingSection),
        newSection,
        ...sections.slice(indexSiblingSection),
      ]
      : [...sections, newSection];

    setFormValues({
      ...formValues,
      sections: updatedSections,
      [`section_${formLanguage}_${newSection.id}`]: newSection[`name_${formLanguage}`],
    });

    setWatchedFields((prev) => ({
      ...prev,
      sections: [...prev.sections, `section_${formLanguage}_${newSection.id}`],
    }));

    setExpandedSection(newSection.id);
  };

  const handleDuplicateSection = (sectionId) => {
    const sectionToDuplicate = sections.find(
      (section) => section.id === sectionId
    );
    const actualIndex = sections.findIndex(
      (section) => section.id === sectionId
    );

    const newSection = {
      ...sectionToDuplicate,
      id: generateRandomId(10),
      name_es: `${sectionToDuplicate.name_es} copia`,
      name_en: `${sectionToDuplicate.name_en} copy`,
      name_pt: `${sectionToDuplicate.name_pt} cópia`,
      isNew: true,
      questions: sectionToDuplicate.questions.map((question) => ({
        ...question,
        id: generateRandomId(10),
        isNew: true,
      })),
      evaluations: sectionToDuplicate.evaluations.map((evaluation) => ({
        ...evaluation,
        id: generateRandomId(10),
        isNew: true,
      })),
    };

    const { evaluations } = newSection;
    const defaultStakeholders = { ...templateStates.stakeholders };
    let newData = {};
    let newCheckboxFields = [];
    let newWeighingFields = [];

    Object.keys(defaultStakeholders).forEach((keyStakeholder) => {
      const { type } = defaultStakeholders[keyStakeholder];
      const actualEvaluation = evaluations.find(
        (evaluation) => evaluation.type === type
      );
      const weighing = actualEvaluation?.weighing || 0;
      newData = {
        ...newData,
        [`weighing_${type}_${newSection.id}`]: weighing,
        [`checkbox_${type}_${newSection.id}`]: weighing !== 0 || newSection.open === true,
      };
      newCheckboxFields = [...newCheckboxFields, `checkbox_${type}_${newSection.id}`];
      newWeighingFields = [...newWeighingFields, `weighing_${type}_${newSection.id}`];
    });

    const newQuestionsFields = [];
    const newScalesFields = [];
    newSection.questions.forEach((question) => {
      newQuestionsFields.push(`question_${formLanguage}_${question.id}`);
      newScalesFields.push(`scale_${question.id}`);
    });

    const newSections = [...sections];
    setFormValues({
      ...formValues,
      sections: [
        ...newSections.slice(0, actualIndex + 1),
        newSection,
        ...newSections.slice(actualIndex + 1),
      ],
      ...newData,
      [`section_${formLanguage}_${newSection.id}`]: newSection[`name_${formLanguage}`],
    });

    setWatchedFields((prev) => ({
      ...prev,
      sections: [...prev.sections, `section_${formLanguage}_${newSection.id}`],
      stakeholders: [...prev.stakeholders, ...newCheckboxFields],
      weighings: [...prev.weighings, ...newWeighingFields],
      questions: [...prev.questions, ...newQuestionsFields],
      scales: [...prev.scales, ...newScalesFields],
    }));

    setExpandedSection(newSection.id);
  };

  const handleDeleteSection = (sectionIdToDelete) => {
    let newSections = [...sections];
    const sectionToDelete = sections.find(
      (section) => section.id === sectionIdToDelete
    );
    if (!sectionToDelete.isNew) {
      newSections = newSections.map((section) => {
        if (section.id === sectionIdToDelete) {
          return { ...section, isDeleted: true };
        }
        return section;
      });
    } else {
      newSections = newSections.filter(
        (section) => section.id !== sectionIdToDelete
      );
    }
    setFormValues({ ...formValues, sections: newSections });

    setWatchedFields((prev) => ({
      ...prev,
      sections: prev.sections.filter(
        (field) => field !== `section_${formLanguage}_${sectionIdToDelete}`
      ),
    }));
  };

  useEffect(() => {
    if (!sections) return;
    const newItems = sections.map((section) => section.id);
    setItems(newItems);
    // eslint-disable-next-line
  }, [sections]);

  return (
    <DndContext
      sensors={ sensors }
      collisionDetection={ closestCenter }
      onDragEnd={ handleDragEnd }
    >
      <SortableContext
        items={ items }
        strategy={ verticalListSortingStrategy }
      >
        {items.map((item) => {
          const sectionInfo = sections.find((section) => section.id === item);
          if (sectionInfo?.isDeleted || !sectionInfo) return null;

          return (
            <Section
              key={ item }
              id={ item }
              data={ sectionInfo }
              setShowItem={ handleShowItem }
              showItemId={ showItemId }
              handleAccordionChange={ handleAccordionChange }
              expandedSection={ expandedSection }
              handleAddSection={ () => handleAddSection(item) }
              handleDelete={ () => handleDeleteSection(item) }
              handleDuplicate={ () => handleDuplicateSection(item) }
              { ...props }
              hasInternalClient={ hasInternalClient }
              handle
            />
          );
        })}
      </SortableContext>
      <NewSectionContainer>
        <AddSectionButton
          variant={ "outlined" }
          color={ "primary" }
          onClick={ () => handleAddSection(null, true) }
        >
          <AddCircleIcon />
          {t("planning:templates.sections.new_open")}
        </AddSectionButton>
        <AddSectionButton
          variant={ "outlined" }
          color={ "primary" }
          onClick={ handleAddSection }
        >
          <AddCircleIcon />
          {t("planning:templates.sections.new_closed")}
        </AddSectionButton>
      </NewSectionContainer>
    </DndContext>
  );
};

SectionList.propTypes = {
  formValues: PropTypes.shape({
    name_en: PropTypes.string,
    name_es: PropTypes.string,
    name_pt: PropTypes.string,
    sections: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
      ]).isRequired,
      name_en: PropTypes.string,
      name_es: PropTypes.string,
      name_pt: PropTypes.string,
      evaluations: PropTypes.any,
      questions: PropTypes.any,
      open: PropTypes.bool,
      isNew: PropTypes.bool,
    })),
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  formLanguage: PropTypes.string.isRequired,
  templateStates: PropTypes.shape({
    stakeholders: PropTypes.object.isRequired,
  }).isRequired,
  setWatchedFields: PropTypes.func.isRequired,
  hasInternalClient: PropTypes.bool.isRequired,
};

export default SectionList;
