import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import Grid from "@mui/material/Grid";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import { LOCATION_PROPS } from "common/constants";
import paths from "common/paths";
import { getPropertyByLocation } from "common/utils";
import { StyledSimpleDashboardContainer } from "styledComponents/View";
import { StyledTabsContainer } from "styledComponents/Tabs";
import OverView from "./components/OverView";
import FollowUpResults from "./components/FollowUpResults";

const OnboardingFollowUp = () => {
  const { t } = useTranslation(["onboardingFollowUp"]);

  const [tabHandler, setTabHandler] = useState(0);
  // search collaborator id by url
  const location = useLocation();
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const collaboratorParam = locationSearch?.split(paths.collaborator)[1];
  const collaboratorId = collaboratorParam
    ? locationSearch?.split("=")[1]
    : null;

  const allTabs = [{ label: t("tabs.overview") }, { label: t("tabs.results") }];

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  return (
    <StyledSimpleDashboardContainer>
      <ViewHeaderTitle title={ t("title") } />
      <Grid container spacing={ 4 }>
        <StyledTabsContainer item xs={ 12 }>
          <Tab
            tabs={ allTabs }
            onChange={ handleValueChanged }
            tabValue={ tabHandler }
          />
          <TabPanel value={ tabHandler } index={ 0 }>
            <OverView collaboratorId={ collaboratorId } />
          </TabPanel>
          <TabPanel value={ tabHandler } index={ 1 }>
            <FollowUpResults collaboratorId={ collaboratorId } />
          </TabPanel>
        </StyledTabsContainer>
      </Grid>
    </StyledSimpleDashboardContainer>
  );
};

export default OnboardingFollowUp;
