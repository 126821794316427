import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import CardContent from "@mui/material/CardContent";
import Avatar from "@mui/material/Avatar";
import {
  VARIANT, ALIGN_ITEMS, JUSTIFY,
} from "common/constants";
import { getCurrentLanguage, getAvatar } from "common/utils";
import AcknowledgmentIcon from "assets/images/feedback/acknowledgment.svg";
import {
  StyledContainer, StyledNumber, StyledCard, StyledTotal, StyledCategory,
} from "./styles";

const AcknowledgmentCard = (props) => {
  const {
    data, index, isMobile,
  } = props;

  return (
    <StyledContainer>
      <StyledNumber>{ index }</StyledNumber>
      <StyledCard>
        <CardContent >
          <Box display={ ALIGN_ITEMS.flex } justifyContent={ JUSTIFY.spaceBetween }>
            <Box display={ ALIGN_ITEMS.flex } alignItems={ ALIGN_ITEMS.center }>
              <Box pr={ 1 }>
                <Avatar alt={ data?.full_name } src={ getAvatar(data) } />
              </Box>
              <Box flexGrow={ 1 }>
                <Typography variant={ VARIANT.h6 }>
                  { data?.full_name }
                </Typography>
                <Typography variant={ VARIANT.bodyTwo }>
                  { [data?.position_name, data?.division_name, data?.country_name].filter(Boolean).join(" / ") }
                </Typography>
              </Box>
            </Box>
            <Box>
              <StyledTotal>{ data?.acknowledgements_count }</StyledTotal>
            </Box>
          </Box>
          <Box mt={ 1 } ml={ 7 }>
            {!isMobile && data?.acknowledgement_types?.map((item) => (
              <StyledCategory key={ item }>
                <img alt={ item.title } src={ AcknowledgmentIcon } />
                { item[`title_${getCurrentLanguage()}`] }
              </StyledCategory>
            ))}
          </Box>
        </CardContent>
      </StyledCard>
    </StyledContainer>
  );
};

AcknowledgmentCard.propTypes = {
  data: PropTypes.object.isRequired,
  index: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
};

AcknowledgmentCard.defaultProps = {
  isMobile: false,
};

export default AcknowledgmentCard;
