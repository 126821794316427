import {
  useState, useEffect, useRef, useImperativeHandle, forwardRef,
} from "react";
import PropTypes from "prop-types";
import { useUpdateGoalEvaluationAnswer } from "hooks/useGoalEvaluationAnswer";
import { useDeleteAttachment } from "hooks/useDeleteAttachment";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import Grid from "@mui/material/Grid";
import InputAdornment from "@mui/material/InputAdornment";
import Table from "components/Table";
import TooltipIcon from "components/TooltipIcon";
import { StyledChipTag } from "styledComponents/Chip";
import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";
import AttachFileIcon from "@mui/icons-material/AttachFile";
import { getParseValue } from "common/utils";
import { MAX_FILE_WEIGHT } from "common/constants";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { getGoalDetailsHeader, performanceOption } from "./goalDetailsUtils";
import {
  StyledContainer,
  StyledCollapseData,
  StyledDescription,
  StyledLabel,
  FileInputLabel,
  HiddenFileInput,
  StyledAttachment,
  StyledDeleteIcon,
  StyledTitle,
  StyledGridForm,
  StyledGridMobile,
} from "./styles";

const GoalDetails = forwardRef((props, ref) => {
  const {
    goal,
    t,
    goalEvaluationAnswer,
    scaleIndices,
    onUpdate,
    isMobile,
  } = props;

  const [selectedValue, setSelectedValue] = useState(
    goalEvaluationAnswer?.score !== undefined && goalEvaluationAnswer?.score !== null
      ? goalEvaluationAnswer.score * 100
      : "",
  );
  const [observation, setObservation] = useState(goalEvaluationAnswer?.observation || "");
  const [existingAttachments, setExistingAttachments] = useState(
    goalEvaluationAnswer.attachments || [],
  );
  const [debounceTimer, setDebounceTimer] = useState(null);

  const observationRef = useRef(observation);
  const initialObservationRef = useRef(goalEvaluationAnswer?.observation || "");

  useEffect(() => {
    setSelectedValue(
      goalEvaluationAnswer?.score !== undefined && goalEvaluationAnswer?.score !== null
        ? goalEvaluationAnswer.score * 100
        : "",
    );
    setObservation(goalEvaluationAnswer?.observation || "");
    observationRef.current = goalEvaluationAnswer?.observation || "";
    setExistingAttachments(goalEvaluationAnswer.attachments || []);
    initialObservationRef.current = goalEvaluationAnswer?.observation || "";
  }, [goalEvaluationAnswer?.id]);

  const {
    mutateAsync: updateGoalEvaluationAnswer,
  } = useUpdateGoalEvaluationAnswer(onUpdate, t);

  const { mutate: deleteAttachmentMutate } = useDeleteAttachment((attachmentId) => {
    setExistingAttachments(
      (prevAttachments) => prevAttachments.filter(
        (attachment) => attachment.id !== attachmentId,
      ),
    );
    if (onUpdate) {
      goalEvaluationAnswer.attachments = goalEvaluationAnswer.attachments.filter(
        (attachment) => attachment.id !== attachmentId,
      );
      onUpdate(goalEvaluationAnswer);
    }
  });

  const handleDeleteAttachment = (attachmentId) => {
    deleteAttachmentMutate({
      goalEvaluationAnswerId: goalEvaluationAnswer.id,
      attachmentId,
    });
  };

  const sendData = async (updatedFields = {}) => {
    const formData = new FormData();

    const dataToSend = {
      score: updatedFields.score !== undefined ? updatedFields.score / 100 : selectedValue / 100,
      observation: updatedFields.observation !== undefined
        ? updatedFields.observation
        : observationRef.current,
    };

    if (updatedFields.attachments) {
      updatedFields.attachments.forEach((file) => {
        formData.append("goal_evaluation_answer[attachments][]", file);
      });
    }

    Object.entries(dataToSend).forEach(([key, value]) => {
      formData.append(`goal_evaluation_answer[${key}]`, value);
    });

    const updatedData = await updateGoalEvaluationAnswer({
      id: goalEvaluationAnswer.id,
      data: formData,
    });

    setExistingAttachments(updatedData.attachments || []);
    if (updatedFields.observation !== undefined) {
      initialObservationRef.current = updatedData.observation || "";
      setObservation(updatedData.observation || "");
      observationRef.current = updatedData.observation || "";
    }
    if (updatedFields.score !== undefined) {
      setSelectedValue(updatedData.score * 100 || "");
    }
    if (onUpdate) {
      onUpdate(updatedData);
    }
  };

  const handleRatingChange = (event) => {
    const newValue = event.target.value;
    setSelectedValue(newValue);
    sendData({ score: newValue });
  };

  const handleObservationChange = (event) => {
    const newObservation = event.target.value;
    setObservation(newObservation);
    observationRef.current = newObservation;

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      if (newObservation !== initialObservationRef.current) {
        sendData({ observation: newObservation });
      }
    }, 1000);

    setDebounceTimer(newTimer);
  };

  useImperativeHandle(ref, () => ({
    saveObservation: async () => {
      if (debounceTimer) {
        clearTimeout(debounceTimer);
      }
      const currentObservation = observationRef.current;
      if (currentObservation !== initialObservationRef.current) {
        await sendData({ observation: currentObservation });
      }
    },
  }));

  const handleAttachmentsChange = (event) => {
    const { files } = event.target;
    const validExtensions = /(.jpg|.jpeg|.png|.pdf)$/i;

    const filteredFiles = Array.from(files).filter((file) => validExtensions.test(file.name));

    if (filteredFiles.length !== files.length) {
      toast(MESSAGE_TYPES.error, {
        title: t("common:common.files.wrongGeneralFormat"),
      });
      return;
    }

    const oversizedFiles = filteredFiles.filter((file) => file.size > MAX_FILE_WEIGHT);

    if (oversizedFiles.length > 0) {
      toast(MESSAGE_TYPES.error, {
        title: t("common:common.files.exceedSizeLimit"),
      });
      return;
    }

    sendData({ attachments: filteredFiles });
  };

  const handleNumericInputChange = (event) => {
    const newValue = event.target.value;

    if (newValue === "") {
      setSelectedValue("");
      return;
    }

    const floatValue = parseFloat(newValue);

    if (Number.isNaN(floatValue) || floatValue < 0 || floatValue > 120) {
      return;
    }

    setSelectedValue(newValue);

    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const newTimer = setTimeout(() => {
      sendData({ score: floatValue });
    }, 1000);

    setDebounceTimer(newTimer);
  };

  const handleNumericInputBlur = () => {
    if (debounceTimer) {
      clearTimeout(debounceTimer);
    }

    const floatValue = parseFloat(selectedValue);

    if (!Number.isNaN(floatValue) && floatValue >= 0 && floatValue <= 120) {
      sendData({ score: floatValue });
    }
  };

  const headers = getGoalDetailsHeader(t);
  const goalData = [goal];
  const resultScaleLength = scaleIndices?.length || 0;

  const getCollapseData = () => (
    <Grid container spacing={ 2 }>
      <Grid item xs={ 12 } md={ 7 }>
        <StyledCollapseData>
          <h4>
            {t("tables:headers.description")}
            <TooltipIcon isHelpIcon>
              {t("tables:helpers.description")}
            </TooltipIcon>
          </h4>
          <StyledDescription>{getParseValue(goal.description)}</StyledDescription>
          {goal.tags && goal.tags.length > 0 && (
            <>
              <StyledTitle>
                {t("goals:tags.title")}
                <TooltipIcon isHelpIcon>
                  {t("goals:tags.description")}
                </TooltipIcon>
              </StyledTitle>
              {goal.tags.map((tag) => (
                <StyledChipTag
                  key={ tag.name }
                  variant={ "outlined" }
                  label={ tag.name }
                  size={ "small" }
                />
              ))}
            </>
          )}
        </StyledCollapseData>
      </Grid>
      <StyledGridForm item xs={ 12 } md={ 5 }>
        <form>
          <StyledLabel>{t("surveys:goals.form.result")}</StyledLabel>
          {scaleIndices ? (
            <Select
              value={ selectedValue }
              onChange={ handleRatingChange }
              displayEmpty
              fullWidth
            >
              {scaleIndices.map((row, index) => (
                <MenuItem key={ row.id } value={ row.value }>
                  <div style={ { display: "flex", alignItems: "center" } }>
                    {performanceOption(row, index, resultScaleLength)}
                  </div>
                </MenuItem>
              ))}
            </Select>
          ) : (
            <TextField
              type={ "number" }
              value={ selectedValue }
              onChange={ handleNumericInputChange }
              onBlur={ handleNumericInputBlur }
              fullWidth
              InputProps={ {
                endAdornment: (
                  <InputAdornment
                    position={ "end" }
                  >
                    {"% - 120%"}
                  </InputAdornment>
                ),
                style: {
                  fontSize: "28px",
                  fontWeight: 700,
                  color: "blue",
                  padding: "0 10px",
                },
              } }
            />
          )}
          <TextField
            placeholder={ t("surveys:goals.form.observation") }
            value={ observation }
            onChange={ handleObservationChange }
            multiline
            rows={ 4 }
            fullWidth
            variant={ "standard" }
            InputProps={ {
              disableUnderline: true,
            } }
          />
          <FileInputLabel htmlFor={ "file-upload" }>
            <AttachFileIcon />
            {t("surveys:goals.form.files")}
          </FileInputLabel>
          <HiddenFileInput
            id={ "file-upload" }
            type={ "file" }
            multiple
            onChange={ handleAttachmentsChange }
            accept={ ".jpg,.jpeg,.png,.pdf" }
          />
          <div>
            {existingAttachments.length > 0 && (
              <div style={ {
                display: "flex", flexDirection: "row", gap: "8px", flexWrap: "wrap",
              } }
              >
                {existingAttachments.map((attachment) => (
                  <div key={ attachment.url } style={ { display: "flex", alignItems: "center" } }>
                    <StyledAttachment
                      href={ attachment.url }
                      target={ "_blank" }
                      rel={ "noopener noreferrer" }
                    >
                      {attachment.filename}
                    </StyledAttachment>
                    <IconButton onClick={ () => handleDeleteAttachment(attachment.id) }>
                      <TooltipIcon
                        title={ t("tables:actions.delete") }
                        element={ <StyledDeleteIcon /> }
                      />
                    </IconButton>
                  </div>
                ))}
              </div>
            )}
          </div>
        </form>
      </StyledGridForm>
    </Grid>
  );

  const renderMobileView = () => (
    goalData.map((goalItem) => (
      <div key={ goalItem.id }>
        <StyledGridMobile container>
          {getGoalDetailsHeader(t).map((header) => (
            <Grid
              item
              xs={ header.id === "title" ? 12 : 6 }
              key={ header.id }
            >
              <h4>
                {header.label}
              </h4>
              <p>
                {header.customRender
                  ? header.customRender(goalItem)
                  : goalItem[header.id] || "-"}
              </p>
            </Grid>
          ))}
          {getCollapseData()}
        </StyledGridMobile>
      </div>
    ))
  );

  return (
    <StyledContainer>
      {isMobile ? renderMobileView()
        : (
          <Table
            header={ headers }
            list={ goalData }
            isSimpleTable
            isNotPagination
            getCollapseData={ getCollapseData }
            showCollapseData
            hasCustomBody
            isActive
          />
        )}
    </StyledContainer>
  );
});

GoalDetails.propTypes = {
  goal: PropTypes.object.isRequired,
  goalEvaluationAnswer: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  onUpdate: PropTypes.func.isRequired,
  scaleIndices: PropTypes.array,
  isMobile: PropTypes.bool,
};

GoalDetails.defaultProps = {
  scaleIndices: null,
};

export default GoalDetails;
