import { useQuery } from "react-query";
import {
  getNps,
  getEngagementAndPerformance,
  getFollowUpResults,
} from "redux/actions/followUpActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

// total_nps
export const useFollowUpResult = (query, pageFilter, tableSort) => useQuery(
  ["followUpResult", query, pageFilter, tableSort],
  () => getFollowUpResults(query, pageFilter, tableSort),
  {
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: false,
    select: (data) => {
      const { data: employees, total } = data;
      return { employees, total };
    },
    onError: (queryError) => toast(MESSAGE_TYPES.error, {
      message: queryError.message || queryError.toString(),
    }),
  },
);

// total_nps
export const useNps = (query) => useQuery(
  ["nps", query],
  () => getNps(query),
  {
    onError: (queryError) => toast(MESSAGE_TYPES.error, {
      message: queryError.message || queryError.toString(),
    }),

    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: false,
  },
);

// total_engagement_and_performance
export const useEngagementAndPerformance = (query) => useQuery(
  ["total_engagement_and_performance", query],
  () => getEngagementAndPerformance(query),
  {
    onError: (queryError) => toast(MESSAGE_TYPES.error, {
      message: queryError.message || queryError.toString(),
    }),
    staleTime: 5 * 60 * 1000,
    cacheTime: 10 * 60 * 1000,
    retry: false,
  },
);
