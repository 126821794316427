import PropTypes from "prop-types";
import Alert from "@mui/material/Alert";
import noDataImage from "assets/images/alerts/no-data.svg";
import Grid from "@mui/material/Grid";
import AlertTitle from "@mui/material/AlertTitle";
import Typography from "@mui/material/Typography";
import useStyles from "./styles";

const AlertNoData = (props) => {
  const classes = useStyles();
  const { title, message } = props;

  return (
    <Alert
      className={ classes.alertNoData }
      icon={ false }
      data-testid={ "alertNoData" }
    >
      <Grid container spacing={ 1 }>
        <Grid item xs={ 12 } sm={ 3 } md={ 1 } className={ classes.imageGrid }>
          <img src={ noDataImage } className={ classes.alertImage } alt={ "No Data" } />
        </Grid>
        <Grid
          item
          xs={ 12 }
          sm={ 9 }
          md={ 11 }
          className={ classes.alertMessageContainer }
        >
          <AlertTitle className={ classes.alertTitle }>{title}</AlertTitle>
          <Typography className={ classes.alertMessage }>{message}</Typography>
        </Grid>
      </Grid>
    </Alert>
  );
};

AlertNoData.propTypes = {
  title: PropTypes.string,
  message: PropTypes.string,
};

export default AlertNoData;
