import _ from "lodash";
import { NINEBOX, CATEGORY_PROCESS } from "common/constants";
import { NINEBOX_COLORS, STATE_COLORS } from "theme/palette";
import { getCurrentLanguage } from "common/utils";

const defaultBoxRange = {
  xBox: {
    low: {
      min: 0,
      max: 54,
    },
    medium: {
      min: 55,
      max: 84,
    },
    high: {
      min: 85,
      max: 100,
    },
  },
  yBox: {
    low: {
      min: 0,
      max: 75,
    },
    medium: {
      min: 75.1,
      max: 87.5,
    },
    high: {
      min: 87.51,
      max: 100,
    },
  },
};

const createNineBoxResultToResultMap = (yScale, language) => {
  const map = {};
  yScale.forEach((item) => {
    map[item.nine_box_result] = item[`result_${language}`];
  });
  return map;
};

export const getDefaultFooterBox = (t) => [
  [NINEBOX.footerBox.xa, t("ninebox:subtitle-box.xa")],
  [NINEBOX.footerBox.xb, t("ninebox:subtitle-box.xb")],
  [NINEBOX.footerBox.xc, t("ninebox:subtitle-box.xc")],
];

export const getFooterBox = (yScale, language) => {
  const nineBoxResultToResultMap = createNineBoxResultToResultMap(yScale, language);

  return [
    [NINEBOX.footerBox.xa, nineBoxResultToResultMap[CATEGORY_PROCESS.low]],
    [NINEBOX.footerBox.xb, nineBoxResultToResultMap[CATEGORY_PROCESS.medium]],
    [NINEBOX.footerBox.xc, nineBoxResultToResultMap[CATEGORY_PROCESS.high]],
  ];
};

const getQuadrantData = (quadrants, quadrantNumber) => {
  const currentLanguage = getCurrentLanguage();
  const quadrant = quadrants?.[quadrantNumber - 1];
  return {
    name: quadrant?.name ? `${quadrantNumber}. ${quadrant[`name_${currentLanguage}`]}` : quadrantNumber,
    description: quadrant?.description ? quadrant[`description_${currentLanguage}`] : "",
  };
};

export const getDataByBox = (quadrants = {}, boxRangeByScales = defaultBoxRange) => [
  [
    NINEBOX.zero,
    NINEBOX.zero,
    NINEBOX.maxBox,
    STATE_COLORS.SUCCESS,
    getQuadrantData(quadrants, 7)?.name,
    NINEBOX.box.a.x,
    NINEBOX.box.a.y,
    getQuadrantData(quadrants, 7)?.description,
    boxRangeByScales.xBox.low.min,
    boxRangeByScales.xBox.low.max,
    boxRangeByScales.yBox.high.min,
    boxRangeByScales.yBox.high.max,
    NINEBOX.maxBox,
    NINEBOX.total,
    NINEBOX.quadrantIndex.zero,
    boxRangeByScales,
    7,
  ],
  [
    NINEBOX.maxBox,
    NINEBOX.zero,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lightBlue,
    getQuadrantData(quadrants, 8)?.name,
    NINEBOX.box.b.x,
    NINEBOX.box.a.y,
    getQuadrantData(quadrants, 8)?.description,
    boxRangeByScales.xBox.medium.min,
    boxRangeByScales.xBox.medium.max,
    boxRangeByScales.yBox.high.min,
    boxRangeByScales.yBox.high.max,
    NINEBOX.xMaxBox,
    NINEBOX.total,
    NINEBOX.quadrantIndex.first,
    boxRangeByScales,
    8,
  ],
  [
    NINEBOX.xMaxBox,
    NINEBOX.zero,
    NINEBOX.maxBox,
    STATE_COLORS.INFO,
    getQuadrantData(quadrants, 9)?.name,
    NINEBOX.box.c.x,
    NINEBOX.box.a.y,
    getQuadrantData(quadrants, 9)?.description,
    boxRangeByScales.xBox.high.min,
    boxRangeByScales.xBox.high.max,
    boxRangeByScales.yBox.high.min,
    boxRangeByScales.yBox.high.max,
    NINEBOX.total,
    NINEBOX.total,
    NINEBOX.quadrantIndex.second,
    boxRangeByScales,
    9,
  ],
  [
    NINEBOX.zero,
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.orange,
    getQuadrantData(quadrants, 4)?.name,
    NINEBOX.box.a.x,
    NINEBOX.box.d.y,
    getQuadrantData(quadrants, 4)?.description,
    boxRangeByScales.xBox.low.min,
    boxRangeByScales.xBox.low.max,
    boxRangeByScales.yBox.medium.min,
    boxRangeByScales.yBox.medium.max,
    NINEBOX.maxBox,
    NINEBOX.xMaxBox,
    NINEBOX.quadrantIndex.third,
    boxRangeByScales,
    4,
  ],
  [
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lightGreen,
    getQuadrantData(quadrants, 5)?.name,
    NINEBOX.box.b.x,
    NINEBOX.box.d.y,
    getQuadrantData(quadrants, 5)?.description,
    boxRangeByScales.xBox.medium.min,
    boxRangeByScales.xBox.medium.max,
    boxRangeByScales.yBox.medium.min,
    boxRangeByScales.yBox.medium.max,
    NINEBOX.xMaxBox,
    NINEBOX.xMaxBox,
    NINEBOX.quadrantIndex.fourth,
    boxRangeByScales,
    5,
  ],
  [
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lighterBlue,
    getQuadrantData(quadrants, 6)?.name,
    NINEBOX.box.c.x,
    NINEBOX.box.d.y,
    getQuadrantData(quadrants, 6)?.description,
    boxRangeByScales.xBox.high.min,
    boxRangeByScales.xBox.high.max,
    boxRangeByScales.yBox.medium.min,
    boxRangeByScales.yBox.medium.max,
    NINEBOX.total,
    NINEBOX.xMaxBox,
    NINEBOX.quadrantIndex.fifth,
    boxRangeByScales,
    6,
  ],
  [
    NINEBOX.zero,
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    STATE_COLORS.ERROR,
    getQuadrantData(quadrants, 1)?.name,
    NINEBOX.box.a.x,
    NINEBOX.box.g.y,
    getQuadrantData(quadrants, 1)?.description,
    boxRangeByScales.xBox.low.min,
    boxRangeByScales.xBox.low.max,
    boxRangeByScales.yBox.low.min,
    boxRangeByScales.yBox.low.max,
    NINEBOX.maxBox,
    NINEBOX.maxBox,
    NINEBOX.quadrantIndex.sixth,
    boxRangeByScales,
    1,
  ],
  [
    NINEBOX.maxBox,
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lightRed,
    getQuadrantData(quadrants, 2)?.name,
    NINEBOX.box.b.x,
    NINEBOX.box.g.y,
    getQuadrantData(quadrants, 2)?.description,
    boxRangeByScales.xBox.medium.min,
    boxRangeByScales.xBox.medium.max,
    boxRangeByScales.yBox.low.min,
    boxRangeByScales.yBox.low.max,
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    NINEBOX.quadrantIndex.seventh,
    boxRangeByScales,
    2,
  ],
  [
    NINEBOX.xMaxBox,
    NINEBOX.xMaxBox,
    NINEBOX.maxBox,
    NINEBOX_COLORS.lighterGreen,
    getQuadrantData(quadrants, 3)?.name,
    NINEBOX.box.c.x,
    NINEBOX.box.g.y,
    getQuadrantData(quadrants, 3)?.description,
    boxRangeByScales.xBox.high.min,
    boxRangeByScales.xBox.high.max,
    boxRangeByScales.yBox.low.min,
    boxRangeByScales.yBox.low.max,
    NINEBOX.total,
    NINEBOX.maxBox,
    NINEBOX.quadrantIndex.eighth,
    boxRangeByScales,
    3,
  ],
];
