import { useEffect } from "react";
import useComplexState from "hooks/utils/useComplexState";
import { isEqual, isNull } from "common/helpers";

export const usePlanningDataHook = (externalHooks, internalHooks) => {
  const { dispatch, t } = externalHooks;
  const { formStates, setFormStates } = internalHooks;

  const allPeople = {
    id: null,
    value: t("planning:processDetail.audience.all"),
    label: t("planning:processDetail.audience.all"),
  };

  const [dataStates, setDataStates] = useComplexState({
    inputTagsSelected: [allPeople],
    isOpen: false,
  });

  useEffect(() => {
    if (isNull(formStates.resetAudience)) {
      setDataStates({
        inputTagsSelected: [{
          id: null,
          value: t("planning:processDetail.audience.all"),
          label: t("planning:processDetail.audience.all"),
        }],
      });
      setFormStates({
        audienceSelected: undefined,
      });
    } else {
      setDataStates({
        inputTagsSelected: [formStates.resetAudience],
      });
      setFormStates({
        audienceSelected: formStates.resetAudience,
      });
    }
  // eslint-disable-next-line
  }, [formStates.resetAudience, dispatch, t]);

  const handleTags = (tags) => {
    if (isEqual(tags.length, 0)) {
      setDataStates({
        inputTagsSelected: [allPeople],
      });
      setFormStates({
        audienceSelected: null,
      });
    } else {
      const lastTag = [...tags].pop();
      setDataStates({
        inputTagsSelected: [lastTag],
      });
      setFormStates({
        audienceSelected: lastTag,
      });
    }
  };

  const handleCloseModal = () => {
    setDataStates({
      isOpen: !dataStates.isOpen,
    });
  };

  return {
    setDataStates,
    dataStates,
    handleTags,
    handleCloseModal,
  };
};
