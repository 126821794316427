import { useMutation } from "react-query";
import {
  getOverallParticipationStatistics,
  getOverallNpsStatistics,
  getParticipation,
  getDepartments,
  getTopics,
  getSatisfactionForDepartment,
  getSatisfactionForTopic,
  getCommentsForDepartment,
} from "redux/actions/engagementActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

// overall_satisfaction_statistics
export const useParticipationStatistics = () => {
  const mutation = useMutation(
    ({ id, query }) => getOverallParticipationStatistics(id, query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};

// overall_nps_statistics
export const useNpsStatistics = () => {
  const mutation = useMutation(
    ({ id, query }) => getOverallNpsStatistics(id, query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};

// participation
export const useParticipation = () => {
  const mutation = useMutation(
    ({ id, query }) => getParticipation(id, query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};

// sections by process
export const useTopics = () => {
  const mutation = useMutation(
    ({ id, query }) => getTopics(id, query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};

// departments by section
export const useDepartments = () => {
  const mutation = useMutation(
    ({ id, query }) => getDepartments(id, query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};

// satisfaction_for_department
export const useSatisfactionForDepartment = () => {
  const mutation = useMutation(
    ({ id, departmentId, query }) => getSatisfactionForDepartment(id, departmentId, query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};

// comments_for_department
export const useCommentsForDepartment = () => {
  const mutation = useMutation(
    ({ id, departmentId, query }) => getCommentsForDepartment(id, departmentId, query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};

// satisfaction_for_topic
export const useSatisfactionForTopic = () => {
  const mutation = useMutation(
    ({ id, topicId, query }) => getSatisfactionForTopic(id, topicId, query),
    {
      onError: (queryError) => toast(MESSAGE_TYPES.error, {
        message: queryError.message || queryError.toString(),
      }),
    },
  );

  return mutation;
};
