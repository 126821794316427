import InsertEmoticonOutlinedIcon from "@mui/icons-material/InsertEmoticonOutlined";
import HomeIcon from "@mui/icons-material/Home";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PersonIcon from "@mui/icons-material/Person";
import AddBoxIcon from "@mui/icons-material/AddBox";
import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import EmojiFlagsIcon from "@mui/icons-material/EmojiFlags";
import BusinessIcon from "@mui/icons-material/Business";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import SupervisedUserCircleIcon from "@mui/icons-material/SupervisedUserCircle";
import PeopleIcon from "@mui/icons-material/People";
import PollIcon from "@mui/icons-material/Poll";
import ContactsIcon from "@mui/icons-material/Contacts";
import ShowChartRoundedIcon from "@mui/icons-material/ShowChartRounded";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import PublishIcon from "@mui/icons-material/Publish";
import SettingsIcon from "@mui/icons-material/Settings";
import LocalLibraryIcon from "@mui/icons-material/LocalLibrary";
import LinearScaleIcon from "@mui/icons-material/LinearScale";
import BarChartIcon from "@mui/icons-material/BarChart";
import { ReactComponent as TimeOff } from "assets/images/time-off/time-off.svg";
import { ReactComponent as NalaAnalytics } from "assets/images/nala_analytics/nala_analytics.svg";
import { ReactComponent as TalentAnalytics } from "assets/images/icons/TalentAnalytics.svg";
import { ReactComponent as NineBox } from "assets/images/icons/9box.svg";
import { ReactComponent as AttritionRisk } from "assets/images/icons/attrition.svg";
import { ReactComponent as Potential } from "assets/images/icons/potential.svg";
import {
  isEmpty,
  isEqual,
  isUndefined,
  isNull,
} from "common/helpers";
import {
  COMPANY_STATES, EMAIL_LIST, ROLES, COMPANY_SLUGS,
} from "common/constants";

const ALIANCE_ACCESS = {
  general: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
    ROLES.VACATION_ADMIN,
    ROLES.ONBOARDING_ADMIN,
    ROLES.ADMIN_COLOMBIA,
  ],
  profile: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
    ROLES.VACATION_ADMIN,
    ROLES.ONBOARDING_ADMIN,
    ROLES.ADMIN_COLOMBIA,
  ],
  analytics: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
  ],
  talentManagementWithNewHires: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
    ROLES.ONBOARDING_ADMIN,
    ROLES.ADMIN_COLOMBIA,
  ],
  talentManagement: [
    ROLES.ADMIN,
    ROLES.ADMIN_NALA,
    ROLES.TALENT_MANAGER,
    ROLES.ADMIN_COLOMBIA,
  ],
};

export const MENU_ICONS = {
  insertEmoticonOutlinedIcon: <InsertEmoticonOutlinedIcon />,
  homeIcon: <HomeIcon />,
  accountCircleIcon: <AccountCircleIcon />,
  personIcon: <PersonIcon />,
  addBoxIcon: <AddBoxIcon />,
  checkCircleOutline: <CheckCircleOutline />,
  emojiFlagsIcon: <EmojiFlagsIcon />,
  businessIcon: <BusinessIcon />,
  calendarTodayIcon: <CalendarTodayIcon />,
  supervisedUserCircleIcon: <SupervisedUserCircleIcon />,
  peopleIcon: <PeopleIcon />,
  timeOff: <TimeOff />,
  pollIcon: <PollIcon />,
  contactsIcon: <ContactsIcon />,
  showChartRoundedIcon: <ShowChartRoundedIcon />,
  potentialIcon: <Potential />,
  emojiPeopleIcon: <EmojiPeopleIcon />,
  attritionIcon: <AttritionRisk />,
  publishIcon: <PublishIcon />,
  settingsIcon: <SettingsIcon />,
  localLibraryIcon: <LocalLibraryIcon />,
  linearScaleIcon: <LinearScaleIcon />,
  barChartIcon: <BarChartIcon />,
  nalaAnalytics: <NalaAnalytics />,
  talentAnalytics: <TalentAnalytics />,
  nineBox: <NineBox />,
};

export const MENU_STRUCTURE = [
  {
    slug: "preboarding",
    href: "/preboarding",
    icon: "insertEmoticonOutlinedIcon",
    roles: ["candidate"],
  },
  {
    slug: "home",
    href: "/home",
    icon: "homeIcon",
    roles: [
      ROLES.ADMIN,
      ROLES.ADMIN_NALA,
      ROLES.COLLABORATOR,
      ROLES.MANAGER,
      ROLES.TALENT_MANAGER,
      ROLES.VACATION_ADMIN,
      ROLES.ONBOARDING_ADMIN,
      ROLES.ADMIN_COLOMBIA,
    ],
    items: {
      acknowledgments: {
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.general,
          },
        ],
      },
    },
  },
  {
    slug: "about-me",
    icon: "accountCircleIcon",
    subMenu: [
      {
        slug: "my-profile",
        href: "/profile",
        icon: "personIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.COLLABORATOR,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.VACATION_ADMIN,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.profile,
          },
        ],
      },
      {
        slug: "my-first-90-days",
        href: "/profile/onboarding-follow-up",
        icon: "addBoxIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.COLLABORATOR,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.VACATION_ADMIN,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.profile,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.profile,
          },
        ],
      },
      {
        slug: "development-plan",
        href: "/development-plan",
        icon: "checkCircleOutline",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.COLLABORATOR,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.VACATION_ADMIN,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.general,
          },
        ],
      },
      {
        slug: "goals",
        href: "/goals",
        icon: "emojiFlagsIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.COLLABORATOR,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.VACATION_ADMIN,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.general,
          },
        ],
      },
    ],
  },
  {
    slug: "business",
    icon: "businessIcon",
    subMenu: [
      {
        slug: "planning",
        href: "/planning",
        icon: "calendarTodayIcon",
        roles: [ROLES.ADMIN, ROLES.ADMIN_NALA, ROLES.TALENT_MANAGER, ROLES.ADMIN_COLOMBIA],
      },
      {
        slug: "onboarding",
        href: "/onboarding",
        icon: "addBoxIcon",
        roles: [ROLES.ADMIN, ROLES.ADMIN_NALA, ROLES.ONBOARDING_ADMIN, ROLES.ADMIN_COLOMBIA],
      },
      {
        slug: "org-chart",
        href: "/organization-chart",
        icon: "supervisedUserCircleIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.COLLABORATOR,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.VACATION_ADMIN,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.stech,
            roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
          },
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.btf,
            roles: [
              ROLES.ADMIN,
              ROLES.ADMIN_NALA,
              ROLES.TALENT_MANAGER,
            ],
          },
        ],
      },
      {
        slug: "employees",
        href: "/collaborators",
        icon: "peopleIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.COLLABORATOR,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.VACATION_ADMIN,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.stech,
            roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
          },
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.general,
          },
        ],
      },
      {
        slug: "time-off",
        href: "/time-off",
        icon: "timeOff",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.COLLABORATOR,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.VACATION_ADMIN,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.general,
          },
          {
            company: COMPANY_SLUGS.btf,
            roles: [
              ROLES.ADMIN,
              ROLES.ADMIN_NALA,
              ROLES.TALENT_MANAGER,
            ],
          },
        ],
      },
      {
        slug: "hc-planning",
        href: "/hc-planning",
        icon: "localLibraryIcon",
        roles: [ROLES.ADMIN, ROLES.ADMIN_NALA, ROLES.MANAGER, ROLES.TALENT_MANAGER],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.analytics,
          },
        ],
      },
      {
        slug: "hr-analytics",
        href: "/hrAnalytics",
        icon: "linearScaleIcon",
        emails: EMAIL_LIST,
        roles: [ROLES.ADMIN, ROLES.ADMIN_NALA, ROLES.MANAGER, ROLES.TALENT_MANAGER],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.aprende,
            roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
          },
          {
            company: "nala-demo",
            roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
          },
          // qa-nala just for testing purposes
          {
            company: "qa-nala",
            roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
          },
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.analytics,
          },
        ],
      },
      {
        slug: "hr-analytics-beta",
        href: "/hrAnalyticsBeta",
        icon: "barChartIcon",
        emails: EMAIL_LIST,
        roles: [ROLES.ADMIN, ROLES.ADMIN_NALA, ROLES.MANAGER, ROLES.TALENT_MANAGER],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.aprende,
            roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
          },
          {
            company: "nala-demo",
            roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
          },
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.analytics,
          },
          // qa-nala just for testing purposes
          {
            company: "qa-nala",
            roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
          },
        ],
      },
    ],
  },
  {
    slug: "talent-management",
    icon: "pollIcon",
    subMenu: [
      {
        slug: "new-hires-experience",
        href: "/collaborators-follow-up",
        icon: "contactsIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
        ],
      },
      {
        slug: "engagement",
        href: "/engagement",
        icon: "insertEmoticonOutlinedIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.talentManagementWithNewHires,
          },
          {
            company: COMPANY_SLUGS.btf,
            roles: [
              ROLES.ADMIN,
              ROLES.ADMIN_NALA,
              ROLES.TALENT_MANAGER,
              ROLES.ONBOARDING_ADMIN,
            ],
          },
        ],
      },
      {
        slug: "performance",
        href: "/performance-review",
        icon: "showChartRoundedIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.ONBOARDING_ADMIN,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.talentManagement,
          },
        ],
      },
      {
        slug: "potential",
        href: "/potential",
        icon: "potentialIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.talentManagement,
          },
        ],
      },
      {
        slug: "leadership",
        href: "/dashboard-good-leader", // TODO: change by leadership
        icon: "emojiPeopleIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.btf,
            roles: [
              ROLES.ADMIN,
              ROLES.ADMIN_NALA,
              ROLES.TALENT_MANAGER,
            ],
          },
        ],
      },
    ],
  },
  {
    slug: "talent-analytics",
    icon: "talentAnalytics",
    subMenu: [
      {
        slug: "nala-analytics",
        href: "/nala_analytics",
        icon: "nalaAnalytics",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.TALENT_MANAGER,
          ROLES.MANAGER,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.analytics,
          },
          {
            company: COMPANY_SLUGS.btf,
            roles: [
              ROLES.ADMIN,
              ROLES.ADMIN_NALA,
              ROLES.TALENT_MANAGER,
            ],
          },
        ],
      },
      {
        slug: "attrition-risk",
        href: "/attrition-risk",
        icon: "attritionIcon",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.btf,
            roles: [
              ROLES.ADMIN,
              ROLES.ADMIN_NALA,
              ROLES.TALENT_MANAGER,
            ],
          },
        ],
      },
      {
        slug: "ninebox",
        href: "/ninebox",
        icon: "nineBox",
        roles: [
          ROLES.ADMIN,
          ROLES.ADMIN_NALA,
          ROLES.MANAGER,
          ROLES.TALENT_MANAGER,
          ROLES.ADMIN_COLOMBIA,
        ],
        companiesValidation: [
          {
            company: COMPANY_SLUGS.mt_ad,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_lp,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_mc,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.add_cdo,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.idl,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.em_acmas,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.mt_hf,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.unab,
            roles: ALIANCE_ACCESS.talentManagement,
          },
          {
            company: COMPANY_SLUGS.bvi,
            roles: ALIANCE_ACCESS.talentManagement,
          },
        ],
      },
    ],
  },
  {
    slug: "bulk-upload",
    href: "/bulk-upload",
    icon: "publishIcon",
    roles: [ROLES.ADMIN, ROLES.ADMIN_NALA, ROLES.ADMIN_COLOMBIA],
    divider: true,
  },
  {
    slug: "general-settings",
    href: "/administrator",
    icon: "settingsIcon",
    roles: [ROLES.ADMIN, ROLES.ADMIN_NALA],
  },
];

export const rolesIntersection = (menu, roles) => {
  const filteredArray = menu?.roles?.filter((value) => roles.includes(value));
  return !isEmpty(filteredArray);
};

export const swapItems = (arr, indexA, indexB, indexC = null) => {
  [arr[indexA], arr[indexB]] = [arr[indexB], arr[indexA]];
  if (!isNull(indexC)) {
    [arr[indexC], arr[indexB]] = [arr[indexB], arr[indexC]];
  }
  return arr;
};

export const getPages = (
  userMenuOptions,
  followUpProcesses,
  userEmail,
  userCompanySlug,
  roles,
  menuStructure,
) => {
  const updatedMenu = [];
  const allowedRoutes = [];
  menuStructure.forEach((menu) => {
    const temporalArr = [];
    if (!isUndefined(userMenuOptions)) {
      const find = userMenuOptions.find((item) => isEqual(menu.slug, item.slug));
      if (find) {
        if (rolesIntersection(menu, roles)) {
          updatedMenu.push({
            ...menu,
            state: find?.state,
          });
          if (isEqual(find?.state, COMPANY_STATES.active)) {
            allowedRoutes.push(menu.href);
          }
        }
      }
      if (menu?.subMenu) {
        menu.subMenu.forEach((subMenu) => {
          const findMenu = userMenuOptions.find((item) => isEqual(subMenu.slug, item.slug));
          const companiesValidation = subMenu?.companiesValidation?.find(
            (item) => isEqual(item.company, userCompanySlug),
          );
          if (findMenu) {
            if (subMenu?.emails?.includes(userEmail)) {
              temporalArr.push({
                ...subMenu,
                state: findMenu?.state,
              });
              if (isEqual(findMenu?.state, COMPANY_STATES.active)) {
                allowedRoutes.push(subMenu.href);
              }
            } else if (rolesIntersection(companiesValidation || subMenu, roles)) {
              // my-first-90-days validation
              if (
                (followUpProcesses
                  && !isEmpty(followUpProcesses)
                  && isEqual(subMenu.slug, "my-first-90-days"))
                || !isEqual(subMenu.slug, "my-first-90-days")
              ) {
                temporalArr.push({
                  ...subMenu,
                  state: findMenu?.state,
                });
                if (isEqual(findMenu?.state, COMPANY_STATES.active)) {
                  allowedRoutes.push(subMenu.href);
                }
              }
            }
          }
        });
        menu.subMenu = temporalArr;
        if (!isEmpty(menu.subMenu)) {
          updatedMenu.push(menu);
        }
      }
    }
  });
  const invalidMenuOption = updatedMenu.find(
    (item) => !["about-me", "business", "talent-management"].includes(item.slug),
  );

  if (isUndefined(invalidMenuOption)) {
    return [];
  }

  return { updatedMenu, allowedRoutes };
};
