import styled from "styled-components";
import Grid from "@mui/material/Grid";

export const StyledGridContainer = styled(Grid)`
  padding: 10px 10px 0;
  min-height: 290px;
  max-width: 500px;
  .MuiTypography-h4, .MuiTypography-h5, .MuiTypography-h6 {
    color: ${(props) => props.color};
    font-weight: 700;
  }
  .MuiTypography-h4 {
    margin-bottom: 10px;
  }
  .MuiTypography-h6 {
    font-size: 12px;
    margin-bottom: 5px;
  }
`;

export const StyledGrid = styled(Grid)`
  text-align: center;
  border-right: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : "none") };
  min-height: 182px;
`;
