import styled from "styled-components";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import palette from "theme/palette";

export const StyledMember = styled.div`
  display: flex;
  align-items: center;
  .MuiTypography-body1 {
    font-size: 16px;
    margin-left: 12px;
  }
  .MuiAvatar-root, .MuiSvgIcon-root.MuiAvatar-fallback {
    width: 36px;
    height: 36px;
  }
`;

export const StyledContainer = styled(Box)`
  padding: 16px;
  border-radius: 8px;
  background-color: #f9f9f9;
`;

export const StyledMemberSection = styled(Box)`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  border-right: ${palette.border.simpleThin};
  padding-right: 20px;
`;

export const StyledTeamSection = styled(Box)`
  display: flex;
  flex-direction: column;
  gap: 8px;
`;

const getBorderColor = ({ isCurrent, active }) => {
  if (isCurrent) {
    return "transparent";
  } if (active) {
    return palette.primaryApp;
  }
  return palette.background.divider;
};

export const StyledAvatar = styled(Avatar)`
  width: 40px;
  height: 40px;
  border: 2.5px solid ${({ isCurrent, active }) => getBorderColor({ isCurrent, active })};
  cursor: pointer;
  transition: border 0.3s ease;
  opacity: ${({ isCurrent }) => (isCurrent ? 0.5 : 1)};
`;

export const StyledGoalHeader = styled(Box)`
  margin-top: 20px;
  padding: ${({ isMobile }) => (isMobile ? "16px" : "16px 40px")};
  border-radius: 8px;
  background-color: ${palette.background.lightInfoCard};
`;

export const StyledGoalList = styled.div`
  margin-top: 20px;
`;

export const StyledStepButtons = styled.div`
  margin-top: 20px;
  display: flex;
  button {
    margin-left: 10px;
  }
  button:first-child {
    margin-left: 0;
    margin-right: auto;
  }
  @media (max-width: 960px) {
    padding: 0px;
    font-family: "Lato";
    .MuiTypography-root {
      font-family: "Lato";
    }
    .MuiButton-root {
      font-family: "Lato";
    }
  }
`;
