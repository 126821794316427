import styled from "styled-components";
import palette from "theme/palette";

export const StyledButtonOuterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  width: 100%;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 10px;
  padding: 4px;
  border: ${palette.border.simple};
  border-radius: 20px;
  position: relative;
`;

export const StyledButton = styled.div`
  padding: 8px;
  border-radius: 50%;
  width: 35px;
  height: 35px;
  display: flex;
  justify-content: center;
  align-items: center;
  svg {
    width: 29px;
    height: 29px;
    opacity: ${(props) => (props.isDisabledIcon ? 0.3 : 1) };
    &:hover {
      transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
      opacity: 1;
    }
  }
  &:hover {
    cursor: pointer;
    transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
    background: ${palette.background.lightGray};
  }
  &:first-child::after {
    content: '';
    position: absolute;
    right: 43px;
    top: 50%;
    transform: translateY(-50%);
    height: 29px;
    width: 1px;
    background-color: ${palette.divider};
  }
`;
