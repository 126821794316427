import SendIcon from "@mui/icons-material/Send";
import EditIcon from "@mui/icons-material/Edit";
import AlarmIcon from "@mui/icons-material/Alarm";
import VisibilityIcon from "@mui/icons-material/Visibility";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import ToggleOnIcon from "@mui/icons-material/ToggleOn";
import MenuPopup from "components/MenuPopup";
import {
  SIZE, ACTIONS, STATE,
} from "common/constants";
import { getProcessTypes } from "views/SurveyProcess/functions";

const handleSendClick = (
  functions,
  t,
  thisModule,
  rowData,
  isReminder = false,
) => functions.viewModal(
  t("common:common.modal_messages.sure_question"),
  "",
  t(`common:common.modal_messages.${isReminder ? "send_text_reminder" : "send_text"}`),
  t("common:common.modal_messages.yes_confirm"),
  t("common:common.modal_messages.no_cancel"),
  () => {
    functions.handleSendEmail(thisModule, rowData.id, isReminder);
  },
);
const handleLoadClick = (functions, t, thisModule, rowData) => functions.viewModal(
  t("common:common.modal_messages.sure_question"),
  "",
  t("common:common.modal_messages.load_data"),
  t("common:common.modal_messages.yes_confirm"),
  t("common:common.modal_messages.no_cancel"),
  () => {
    functions.handleLoadData(thisModule, rowData.id);
  },
);

const handleEnableClick = (functions, t, thisModule, id, processType) => functions.viewModal(
  t("common:common.modal_messages.sure_question"),
  "",
  t("common:common.modal_messages.sure_enable_text"),
  t("common:common.modal_messages.yes_confirm"),
  t("common:common.modal_messages.no_cancel"),
  () => {
    functions.handleEnableData(thisModule, id, processType);
  },
);

export const getSurveyActions = (t, thisModule, functions) => (rowData) => {
  const {
    type, id, follow_up_process_id: followUpProcessId, state,
    survey_results_count: surveyResultsCount,
  } = rowData;
  const processType = getProcessTypes(t).find((process) => type === process.key);
  const ACTIONS_MENU = [
    {
      title: t("table.actions.edit"),
      icon: <EditIcon fontSize={ SIZE.small } />,
      onClick: () => {
        functions.handlePathByModule(thisModule, id, processType.value, ACTIONS.edit);
      },
    },
  ];
  if (!followUpProcessId > 0) {
    ACTIONS_MENU.push(
      {
        title: t("table.actions.send"),
        icon: <SendIcon fontSize={ SIZE.small } />,
        onClick: () => {
          handleSendClick(functions, t, thisModule, rowData);
        },
      },
      {
        title: t("table.actions.send_reminder"),
        icon: <AlarmIcon fontSize={ SIZE.small } />,
        onClick: () => {
          handleSendClick(functions, t, thisModule, rowData, true);
        },
      },
    );
  }
  // evaluations
  ACTIONS_MENU.push(
    {
      title: t("table.actions.preview"),
      icon: <VisibilityIcon fontSize={ SIZE.small } />,
      onClick: () => {
        functions.handlePathByModule(thisModule, id, processType.value, ACTIONS.preview);
      },
    },
  );
  if (surveyResultsCount > 0) {
    ACTIONS_MENU.push(
      {
        title: t("tables:actions.download"),
        icon: <CloudDownloadIcon fontSize={ SIZE.small } />,
        onClick: () => { functions.handleDownloadExcel(thisModule, id, surveyResultsCount); },
      },
    );
  }

  ACTIONS_MENU.push(
    {
      title: t("table.actions.load_answers"),
      icon: <CloudUploadIcon fontSize={ SIZE.small } />,
      onClick: () => {
        handleLoadClick(functions, t, thisModule, rowData);
      },
    },
  );
  if (followUpProcessId > 0 && state === STATE.programmed) {
    ACTIONS_MENU.push(
      {
        title: t("table.actions.enable"),
        icon: <ToggleOnIcon fontSize={ SIZE.small } />,
        onClick: () => {
          handleEnableClick(functions, t, thisModule, id, processType);
        },
      },
    );
  }
  return <MenuPopup items={ ACTIONS_MENU } />;
};
