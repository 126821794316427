import PropTypes from "prop-types";
import AlertTitle from "@mui/material/AlertTitle";
import InfoIcon from "@mui/icons-material/Info";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import WarningIcon from "@mui/icons-material/Warning";
import ErrorIcon from "@mui/icons-material/Error";
import { INFO } from "common/constants";
import { StyledAlert } from "./styles";

const Alert = (props) => {
  const {
    type, title, message, hasToAction, hasIcon, children,
  } = props;

  const alertIcon = {
    info: <InfoIcon />,
    success: <CheckCircleIcon />,
    warning: <WarningIcon />,
    error: <ErrorIcon />,
  };

  return (
    <StyledAlert
      icon={ hasIcon && alertIcon[type] }
      severity={ type }
      action={ hasToAction }
    >
      {title && <AlertTitle>{title}</AlertTitle>}
      {message && <p>{message}</p>}
      {children}
    </StyledAlert>
  );
};

Alert.propTypes = {
  message: PropTypes.string,
  type: PropTypes.string,
  title: PropTypes.string,
  hasToAction: PropTypes.bool,
  hasIcon: PropTypes.bool,
  children: PropTypes.node,
};

Alert.defaultProps = {
  message: "",
  type: INFO,
  title: null,
  hasToAction: false,
  hasIcon: true,
  children: "",
};

export default Alert;
