import { forwardRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { NavLink as RouterLink } from "react-router-dom";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import Typography from "@mui/material/Typography";
import Button from "components/Button";
import Modal from "components/Modal";
import { ALIGN_ITEMS, BUTTON_STYLE_TYPES, VARIANT } from "common/constants";
import {
  StyledRadio,
  StyledGrid,
  StyledTitle,
  StyledFooter,
} from "./styles";

const CustomRouterLink = forwardRef((props, ref) => (
  <div ref={ref}>
    <RouterLink {...props} />
  </div>
));

const OptionsModal = (props) => {
  const {
    isOpen,
    onClose,
  } = props;
  const { t } = useTranslation("candidates");
  const [selected, setSelected] = useState("/onboarding/new");
  const activeStyle = (item) => (selected === item.link ? "active" : "");
  const options = [
    {
      title: t("optionsModal.one.title"),
      subtitle: t("optionsModal.one.subtitle"),
      link: "/onboarding/new",
    },
    {
      title: t("optionsModal.massive.title"),
      subtitle: t("optionsModal.massive.subtitle"),
      link: "/onboarding/massive",
    },
  ];

  return (
    <div data-testid={ "modal-candidates-toolbar" }>
      <Modal
        isOpen={ isOpen }
        onClose={ onClose }
        title={ t("createOnboarding") }
      >
        <Typography>{ t("induction.select_subtitle") }</Typography>
        <StyledGrid container spacing={ 2 }>
          {options?.map((item) => (
            <Grid item xs={ 12 } md={ 6 } key={ item.id }>
              <StyledRadio
                className={ activeStyle(item) }
                onClick={ () => setSelected(item.link) }
              >
                <FormControlLabel
                  value={ item.link }
                  control={ (
                    <Radio
                      checked={ item.link === selected }
                    />
                  ) }
                  label={ (
                    <>
                      <StyledTitle>{item.title}</StyledTitle>
                      <Typography variant={ VARIANT.bodyTwo }>{item.subtitle}</Typography>
                    </>
                  ) }
                  labelPlacement={ ALIGN_ITEMS.right }
                />
              </StyledRadio>
            </Grid>
          ))}
        </StyledGrid>
        <StyledFooter>
          <Button
            component={ CustomRouterLink }
            href={ selected }
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          >
            { t("actions.continue") }
          </Button>
        </StyledFooter>
      </Modal>
    </div>
  );
};

OptionsModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default OptionsModal;
