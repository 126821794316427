import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import Autocomplete, { createFilterOptions } from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import { StyledChipTag } from "styledComponents/Chip";
import CircularProgress from "@mui/material/CircularProgress";
import { useTags } from "hooks/useTags";

const filter = createFilterOptions();

const InputTagCreator = ({
  onTagsChange, defaultTags,
}) => {
  const {
    tags = [],
    isLoading: isTagsLoading,
  } = useTags();

  const [inputValue, setInputValue] = useState("");
  const [selectedTags, setSelectedTags] = useState(defaultTags || []);

  useEffect(() => {
    setSelectedTags(defaultTags || []);
  }, [defaultTags]);

  const handleChange = (event, newValue) => {
    setSelectedTags(newValue);
    if (onTagsChange) {
      onTagsChange(newValue.map((tag) => (typeof tag === "string" ? tag : tag.name)));
    }
  };

  const handleInputChange = (event, newInputValue) => {
    setInputValue(newInputValue);
  };

  return (
    <Autocomplete
      multiple
      freeSolo
      size={ "small" }
      options={ Array.isArray(tags) && tags.length > 0 ? tags : [] }
      getOptionLabel={ (option) => (typeof option === "string" ? option : option.name) }
      value={ selectedTags }
      onChange={ handleChange }
      inputValue={ inputValue }
      onInputChange={ handleInputChange }
      filterOptions={ (options, params) => {
        const filtered = filter(options, params);
        if (params.inputValue !== "" && (!Array.isArray(tags) || !tags.some((tag) => tag.name === params.inputValue))) {
          filtered.push({ name: params.inputValue, isNew: true });
        }
        return filtered;
      } }
      renderInput={ (params) => (
        <TextField
          { ...params }
          variant={ "outlined" }
          onClick={ (event) => event.stopPropagation() }
          InputProps={ {
            ...params.InputProps,
            endAdornment: (
              <>
                {isTagsLoading ? <CircularProgress size={ 20 } /> : null}
                {params.InputProps.endAdornment}
              </>
            ),
          } }
        />
      ) }
      renderTags={ (tagValue, getTagProps) => tagValue.map((option, index) => (
        <StyledChipTag
          key={ typeof option === "string" ? option : option.name }
          variant={ "outlined" }
          label={ typeof option === "string" ? option : option.name }
          { ...getTagProps({ index }) }
          size={ "small" }
        />
      )) }
    />
  );
};

InputTagCreator.propTypes = {
  onTagsChange: PropTypes.func,
  defaultTags: PropTypes.array,
};

InputTagCreator.defaultProps = {
  defaultTags: [],
};

export default InputTagCreator;
