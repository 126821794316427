import CheckCircleOutline from "@mui/icons-material/CheckCircleOutline";
import RemoveCircleOutline from "@mui/icons-material/RemoveCircleOutline";
import ErrorOutlineOutlined from "@mui/icons-material/ErrorOutlineOutlined";
import { EVALUATION_TYPE } from "common/constants/surveyProcess";
import { isEqual } from "common/helpers";
import { EVENT_STATE_KEYS } from "common/constants";
import theme from "theme/palette";
import { StyledIcon } from "../styles";

export const STATE_TYPES = {
  incomplete: {
    label: EVENT_STATE_KEYS.incomplete,
    icon: <StyledIcon color={ theme.table.red }> <ErrorOutlineOutlined/> </StyledIcon>,
  },
  does_not_apply: {
    label: EVENT_STATE_KEYS.doesNotApply,
    icon: <StyledIcon color={ theme.table.red }> <RemoveCircleOutline/> </StyledIcon>,
  },
  complete: {
    label: EVENT_STATE_KEYS.complete,
    icon: <StyledIcon color={ theme.table.green }> <CheckCircleOutline/> </StyledIcon>,
  },
};

// FIXME?
export const getDetails = (t, data) => {
  const updatedData = { ...data };
  if (!isEqual(data?.evaluation_type, EVALUATION_TYPE.COLLABORATORS)
    && !isEqual(data?.evaluation_type, EVALUATION_TYPE.MANAGER)) {
    updatedData.action = t((`common:common.evaluations.${EVALUATION_TYPE.AUTOEVALUATION}.action`));
  }
  if (isEqual(data?.evaluation_type, EVALUATION_TYPE.PAIRS)) {
    updatedData.subtitle = t((`common:common.evaluations.${EVALUATION_TYPE.PAIRS}.subtitle`));
  }
  return updatedData;
};
