import styled from "styled-components";
import palette, { EVALUATION_COLOR } from "theme/palette";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Typography from "@mui/material/Typography";
import LinearProgress from "@mui/material/LinearProgress";

export const StyledTabs = styled(Tabs)`
  button {
    padding: 0;
    border: 0;
  }
  .MuiTabs-indicator {
    height: 0;
  }
`;

export const StyledContainer = styled.div`
  padding: 10px 20px;
`;

export const StyledText = styled(Typography)`
  font-weight: 700;
  color: ${(props) => EVALUATION_COLOR[props.status]?.color || "#000000"};
`;

export const StyledProgressText = styled(Typography)`
  font-weight: 500;
  color: ${(props) => EVALUATION_COLOR[props.status]?.color || "#000000"};
`;

export const StyledTab = styled(Tab)`
  min-width: auto;
  text-transform: capitalize;
  &.Mui-selected {
    background: ${(props) => EVALUATION_COLOR[props.isCompleted ? "completed" : "in_progress"]?.background};
  }
`;

export const StyledLinearProgress = styled(LinearProgress)`
  background-color: #dadbf1;
  .MuiLinearProgress-barColorPrimary {
    background-color: ${(props) => (props.isCompleted
  ? EVALUATION_COLOR.completed.background
  : palette.background.cyan)};
  }
`;
