import i18next from "i18next";
import split from "lodash/split";
import includes from "lodash/includes";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import startCase from "lodash/startCase";
import isNull from "lodash/isNull";
import forEach from "lodash/forEach";
import {
  ROLES,
  PROFILE_INDEX_TABS,
  SUCCESS,
  ERROR,
  WARNING,
  COLOR_SCALE,
  OBJECT_KEYS,
} from "common/constants";
import {
  isCandidate, isValidRole, getUserRoles, isAdminOrManager,
} from "common/utils";
import { isNullOrUndefined } from "common/validators";
import { SECTIONS } from "common/constants/goodLeader";
import { orderByDesc } from "common/helpers";
import {
  getOne as getCollaborator,
  resetStateProcess as resetStateCollaboratorProcess,
  resetStateOne as resetStateCollaborator,
} from "redux/actions/collaboratorActions";
import {
  getOne as getCandidate,
  resetStateProcess as resetStateCandidateProcess,
  resetStateOne as resetStateCandidate,
} from "redux/actions/candidateActions";

export const TABS = (t) => {
  const defaultTabs = [
    { label: t("common.profile"), value: PROFILE_INDEX_TABS.profile },
    { label: t("common.personalData"), value: PROFILE_INDEX_TABS.personalData },
  ];
  return defaultTabs;
};

export const userTopInformationPath = (searchBy) => (includes(searchBy, ROLES.CANDIDATE)
  ? "/onboarding"
  : "/collaborators");

export const getTabID = (props, dataUser) => {
  let tabId;
  if (props?.location?.state?.tabId) {
    tabId = props.location.state.tabId;
  } else if (includes(getUserRoles(dataUser?.userCookies), ROLES.CANDIDATE)) {
    tabId = PROFILE_INDEX_TABS.induction;
  } else {
    tabId = PROFILE_INDEX_TABS.profile;
  }
  return tabId;
};

export const getTabs = (user, externalUser, searchBy, candidateReducer, reducerToUse, tabHandler, t) => {
  const allTabs = TABS(t);
  // Disable salary tab for talent manager role
  allTabs.push({
    label: t("common.salary"),
    value: PROFILE_INDEX_TABS.salary,
    isDisabled: isValidRole(externalUser?.userCookies, ROLES.TALENT_MANAGER) && searchBy,
  });
  allTabs.push({ label: t("common.performance"), value: PROFILE_INDEX_TABS.performance });

  if (externalUser) {
    if (reducerToUse?.one?.roles?.some((rol) => isEqual(rol.name, ROLES.MANAGER))
      || isEqual(tabHandler, PROFILE_INDEX_TABS.leadership)) {
      allTabs.push({ label: t("common.leadership"), value: PROFILE_INDEX_TABS.leadership });
    }

    if (
      includes(getUserRoles(user?.userCookies), ROLES.CANDIDATE)
      || includes(searchBy, ROLES.CANDIDATE)
    ) {
      allTabs.push({
        label: t("common.induction"),
        isDisabled:
          !includes(getUserRoles(user?.userCookies), ROLES.CANDIDATE)
          && isEmpty(candidateReducer?.one?.requested_documents)
          && isEmpty(candidateReducer?.one?.requested_infos),
        value: PROFILE_INDEX_TABS.induction,
      });
    } else {
      allTabs.push({ label: t("common.potential"), value: PROFILE_INDEX_TABS.potential });
    }
    allTabs.push({ label: t("common.documents"), value: PROFILE_INDEX_TABS.documents });

    if (!isProfileView(searchBy) && !isCandidate(user.userCookies) && searchBy !== ROLES.CANDIDATE) {
      allTabs.push({ label: t("common.feedback"), value: PROFILE_INDEX_TABS.feedback });
    }

    if (
      isProfileView(searchBy)
      && !isCandidate(user.userCookies)
      && searchBy !== ROLES.CANDIDATE
      && isAdminOrManager(externalUser?.userCookies)
    ) {
      allTabs.push({ label: t("common.feedback"), value: PROFILE_INDEX_TABS.feedback });
    }

    if (isProfileView(searchBy) && !isCandidate(user.userCookies) && searchBy !== ROLES.CANDIDATE) {
      allTabs.push({ label: t("common.lossRisk"), value: PROFILE_INDEX_TABS.lossRisk });
    }
  }

  return allTabs;
};

export const getEmployeeFullName = (
  searchBy,
  candidateReducer,
  collaboratorReducer,
) => {
  return includes(searchBy, ROLES.CANDIDATE) ? candidateReducer?.one?.person : collaboratorReducer?.one;
};

export const getLastLabelBreadcrumb = (translate, value) => (isEqual(i18next.language, "en")
  ? value + translate
  : translate + value);

export const getUserTopInformation = (
  t,
  searchBy,
  candidateReducer,
  collaboratorReducer,
) => {
  const isCandidate = includes(searchBy, ROLES.CANDIDATE);
  const employee = isCandidate
    ? candidateReducer.one
    : collaboratorReducer.one;
  return {
    fullName: isCandidate ? employee?.person?.full_name : employee?.full_name,
    positionName: employee?.job_position && employee?.job_position?.position_name,
    profileImage: employee?.profile_img_url,
    path: userTopInformationPath(searchBy),
  };
};

export const getEmployeeID = (locationSearch, externalUser, selectedId) => (isEmpty(locationSearch)
  ? !isNull(externalUser.employee) && externalUser.employee.id
  : selectedId);

export const dispatchValidation = (role, searchBy, externalUser) => (isEqual(role, ROLES.CANDIDATE)
  ? includes(searchBy, ROLES.CANDIDATE)
        || (!isNullOrUndefined(externalUser.employee)
          && isEqual(externalUser.employee.type, startCase(ROLES.CANDIDATE))
          && !includes(getUserRoles(externalUser?.userCookies), ROLES.ADMIN_NALA))
  : (isEqual(role, ROLES.COLLABORATOR)
        && includes(searchBy, ROLES.COLLABORATOR)) || (!isNullOrUndefined(externalUser.employee)
          && isEqual(externalUser.employee.type, startCase(ROLES.COLLABORATOR))));

export const dispatchToUse = (
  dispatch,
  locationSearch,
  searchBy,
  externalUser,
  selectedId,
) => {
  const searchType = (locationSearch.includes(ROLES.COLLABORATOR) || locationSearch.includes(ROLES.CANDIDATE))
    ? locationSearch
    : "";
  const employeeId = getEmployeeID(searchType, externalUser, selectedId);
  if (dispatchValidation(ROLES.CANDIDATE, searchBy, externalUser)) {
    dispatch(getCandidate(employeeId));
  } else if (dispatchValidation(ROLES.COLLABORATOR, searchBy, externalUser)) {
    dispatch(getCollaborator(employeeId));
  }
};

export const isProfileView = (toValidateEmpty) => isCandidateSearchView(toValidateEmpty) || isCollaboratorSearchView(toValidateEmpty);

export const isCandidateSearchView = (toValidateEmpty) => {
  const param = toValidateEmpty?.toLowerCase();
  return param === ROLES.CANDIDATE;
};

export const isCollaboratorSearchView = (toValidateEmpty) => {
  const param = toValidateEmpty?.toLowerCase();
  return param === ROLES.COLLABORATOR;
};

export const validateRoleCondition = (
  toValidateEmpty,
  user,
  onRoles,
  offRoles,
) => (
  isEmpty(toValidateEmpty)
    && includes(getUserRoles(user?.userCookies), onRoles)
    && !includes(getUserRoles(user?.userCookies), offRoles)
);

export const handleAlert = (type, errorStatus, message) => {
  switch (type) {
  case SUCCESS:
    return {
      open: true,
      title: "common.api_responses.success.title",
      message: "common.api_responses.success.save",
      type: SUCCESS,
    };
  case ERROR:
    return {
      open: true,
      title: "common.api_responses.error.title",
      message: `${
          message || `common.api_responses.error.${errorStatus}`
        } `,
      type: ERROR,
    };
  case WARNING:
    return {
      open: true,
      title: "common.api_responses.warning.title",
      message,
      type: WARNING,
    };
  default:
    break;
  }
};

export const getErrorMessage = (RESPONSE_ERRORS) => (Object.values(RESPONSE_ERRORS).length > 0
  ? Object.values(RESPONSE_ERRORS).map(
    (value, index) => `${split(Object.keys(RESPONSE_ERRORS)[index], ".")[1]} - ${value} `,
  )
  : "");

export const getTypeOfContract = (typesOfContract) => {
  const typeOfContract = [];
  typesOfContract
    && forEach(typesOfContract, (contract) => {
      typeOfContract.push({
        value: contract.id,
        label: contract.name,
      });
    });
  return typeOfContract;
};

export const feedbackQueryParam = "feedback";

export const resetProfileData = (
  dispatch,
  from,
  searchBy,
  user,
  selectedId,
) => {
  dispatch(resetStateCollaboratorProcess());
  dispatch(resetStateCandidateProcess());
  dispatch(resetStateCollaborator());
  dispatch(resetStateCandidate());
  dispatchToUse(
    dispatch,
    from,
    searchBy,
    user,
    selectedId,
  );
};

export const resetCandidate = (dispatch, employeeId) => {
  dispatch(resetStateCandidateProcess());
  dispatch(resetStateCandidate());
  dispatch(getCandidate(employeeId));
};

export const getScaleWithColors = (scale) => orderByDesc(scale, OBJECT_KEYS.top)
  .map((item, index) => ({
    ...item,
    color: COLOR_SCALE[index],
  }));

export const getDescriptionBySection = (t) => ({
  [SECTIONS.beAGoodCoach]: t("goodLeader:sectionDescription.beAGoodCoach"),
  [SECTIONS.empowerTeam]: t("goodLeader:sectionDescription.empowerTeam"),
  [SECTIONS.expressInterest]: t("goodLeader:sectionDescription.expressInterest"),
  [SECTIONS.beProductive]: t("goodLeader:sectionDescription.beProductive"),
  [SECTIONS.beAGoodCommunicator]: t("goodLeader:sectionDescription.beAGoodCommunicator"),
  [SECTIONS.helpTeam]: t("goodLeader:sectionDescription.helpTeam"),
  [SECTIONS.visionOfArea]: t("goodLeader:sectionDescription.visionOfArea"),
  [SECTIONS.technicalSkills]: t("goodLeader:sectionDescription.technicalSkills"),
  [SECTIONS.teamEnvironment]: t("goodLeader:sectionDescription.teamEnvironment"),
  [SECTIONS.collaborateUsCompany]: t("goodLeader:sectionDescription.collaborateUsCompany"),
  [SECTIONS.strongDecision]: t("goodLeader:sectionDescription.strongDecision"),
});
