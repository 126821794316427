import { useEffect, useState, useCallback } from "react";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import NoDataMessage from "components/NoDataMessage";
import NineBox from "components/NineBox";
import SkeletonLoader from "components/SkeletonLoader";
import VerticalStepperList from "components/VerticalStepperList";
import SimpleAccordion from "components/SimpleAccordion";
import { isEmpty, isNull } from "common/helpers";
import { SKELETONS_NUMBER, VARIANT, NINEBOX } from "common/constants";
import { getEmployeeImage } from "common/utils";
import { getCircleByProfile, getDataNineBoxResult } from "views/MatrixNineBox/functions";
import { getListByEmployee as getNineboxResultByEmployee } from "redux/actions/talentScore/talentScoreActions";
import HistoricalResult from "views/MatrixNineBox/components/HistoricalResult";
import HistoricalHeader from "views/MatrixNineBox/components/HistoricalHeader";
import Tips from "assets/images/potential/skills/tips.svg";
import { getKeyByQuadrant } from "views_refactor/Profile/functions/skills";
import { usePatternImageNineBox } from "hooks/usePatternImageNineBox";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import {
  Container,
  Content,
  AvatarProfile,
  Title,
  NoDataContainer,
  EmployeeData,
  StyledGridHistorical,
  StyleList,
} from "./styles";

const NineBoxProfile = (props) => {
  const {
    t,
    collaborator,
    resultScales,
  } = props;

  const { resultScaleList, isLoadingScaleList } = resultScales;

  const [nineBoxResultEmployee, setNineBoxResultEmployee] = useState();

  const employeeId = collaborator?.id;
  const { data: historical, isLoading } = useQuery(
    ["historical", employeeId],
    () => getNineboxResultByEmployee(employeeId),
    { staleTime: Infinity },
    [employeeId],
  );

  const { nineBoxTypeList, isLoading: isLoadingType } = useNineBoxTypes(t);

  const handleHistoricalNinebox = useCallback(async () => {
    if (!isEmpty(historical)) {
      const results = await getDataNineBoxResult(historical, resultScaleList, t, nineBoxTypeList);
      setNineBoxResultEmployee(results);
    } else {
      setNineBoxResultEmployee([]);
    }
  }, [historical, resultScaleList, t]);

  const historicalEmployee = nineBoxResultEmployee && nineBoxResultEmployee.map((result) => {
    result.title = `${t("ninebox:quadrant")}: ${result?.quadrantData[NINEBOX.quadrantData.name]}`;
    const historicalResult = <HistoricalResult data={ result } { ...props } />;
    return {
      title: result.title,
      customRender: historicalResult,
    };
  });

  useEffect(() => {
    if (!isLoading && !isLoadingScaleList && !isLoadingType) {
      handleHistoricalNinebox(historical);
    }
    // eslint-disable-next-line
  }, [historical]);

  const loadingData = isNull(nineBoxResultEmployee) ? (
    <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.FOUR } isInline={ false } />
  ) : (
    <NoDataContainer>
      <NoDataMessage
        message={ t("ninebox:historical_empty") }
      />
    </NoDataContainer>
  );

  const verticalStepper = (
    <StyledGridHistorical item xs={ 12 }>
      <Title variant={ VARIANT.h6 }>
        {t("ninebox:historical")}
      </Title>
      <VerticalStepperList data={ historicalEmployee?.slice(1) } />
    </StyledGridHistorical>
  );

  const tipsByQuadrant = nineBoxResultEmployee && (
    <SimpleAccordion
      title={ t("developmentPlan:tabsOverview.tipsForDevelopment") }
      src={ Tips }
      isTitleImage
    >
      { nineBoxResultEmployee.length > 0
        && nineBoxResultEmployee[0]?.quadrantData[NINEBOX.quadrantData.quadrant] >= 0 ? (
          <StyleList>
            {t(
`developmentPlan:tipsByQuadrant.${
            getKeyByQuadrant(nineBoxResultEmployee[0]?.quadrantData[NINEBOX.quadrantData.quadrant])}`,
{ returnObjects: true },
            )?.map((item) => (
              <li key={ item }>{item}</li>
            ))}
          </StyleList>
        ) : (
          <NoDataMessage
            message={ t("developmentPlan:noTips") }
            isHappyFace
          />
        )}
    </SimpleAccordion>
  );

  const historicalData = nineBoxResultEmployee && (
    <>
      <HistoricalResult
        data={ nineBoxResultEmployee[0] }
        t={ t }
      />
      { tipsByQuadrant }
      <Typography variant={ VARIANT.bodyOne }>
        {nineBoxResultEmployee[0]?.quadrantData[NINEBOX.quadrantData.description]}
      </Typography>
      {nineBoxResultEmployee?.length > 1 && verticalStepper}
    </>
  );

  const patternImage = usePatternImageNineBox(nineBoxResultEmployee);

  return (
    <Container>
      {(isLoading || !nineBoxResultEmployee)
        ? <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.NINE } />
        : (
          <Grid container spacing={ 2 }>
            <Grid item xs={ 12 } md={ 7 }>
              <NineBox>
                {nineBoxResultEmployee && (
                  <>
                    {patternImage !== "" && patternImage}
                    {getCircleByProfile(
                      nineBoxResultEmployee,
                      t,
                    )}
                  </>
                )}
              </NineBox>
            </Grid>
            <Grid item xs={ 12 } md={ 5 }>
              <Content>
                <HistoricalHeader t={ t } nineBoxResult={ nineBoxResultEmployee } />
                <EmployeeData>
                  <AvatarProfile
                    alt={ collaborator.full_name }
                    src={ getEmployeeImage(collaborator?.profile_img_url) }
                  />
                  <Title variant={ VARIANT.h6 }>
                    {collaborator.full_name}
                    {nineBoxResultEmployee && (
                      <p>
                        {`${t("ninebox:quadrant")}:
                    ${nineBoxResultEmployee[0]?.quadrantData[NINEBOX.quadrantData.name]}`}
                      </p>
                    )}
                  </Title>
                </EmployeeData>
                {/* {!isNull(nineBoxResultEmployee) && historicalData} */}
                {!isEmpty(nineBoxResultEmployee) ? historicalData : loadingData}
              </Content>
            </Grid>
          </Grid>
        )}
    </Container>
  );
};

NineBoxProfile.propTypes = {
  t: PropTypes.func.isRequired,
  collaborator: PropTypes.object.isRequired,
  resultScales: PropTypes.object.isRequired,
};

export default NineBoxProfile;
