import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import FormHelperText from "components/FormHelperText";
import LoaderInput from "components/LoaderInput";
import { LOCATION_PROPS, SIZE } from "common/constants";
import { isEqual } from "common/helpers";
import { StyledMultilineAdornment } from "./styles";

const InputText = (props) => {
  const {
    type,
    label,
    required,
    disabled,
    control,
    name,
    multiline,
    rules,
    margin,
    customStyles,
    helperText,
    isError,
    defaultValue,
    showHelperText,
    isControlledRequired,
    placeholder,
    isLoading,
    id,
    variant,
    onFocus,
    size,
    inputProps,
    endAdornment,
  } = props;

  return (
    <div>
      <Controller
        data-testid={ "input-text-controller" }
        as={ (
          <TextField
            id={ id }
            type={ type }
            label={ label }
            InputLabelProps={ { required: false } }
            required={ required }
            disabled={ disabled }
            helperText={ helperText }
            error={ isError }
            variant={ variant }
            margin={ margin }
            placeholder={ placeholder }
            fullWidth
            multiline={ Boolean(multiline) }
            rows={ multiline }
            InputProps={ {
              autoComplete: "new-input",
              inputProps: { ...inputProps, maxLength: inputProps?.maxLength || null },
              endAdornment: !multiline && (isLoading ? <LoaderInput /> : endAdornment),
              startAdornment: isEqual(name, LOCATION_PROPS.search) && (
                <InputAdornment position={ "start" }>
                  <SearchIcon />
                </InputAdornment>
              ),
            } }
            className={ customStyles }
            onFocus={ onFocus }
            size={ size }
          />
        ) }
        rules={ rules }
        control={ control }
        name={ name }
        defaultValue={ defaultValue }
      />
      {multiline && endAdornment && (
        <StyledMultilineAdornment
          variant={ "caption" }
          color={ "textSecondary" }
        >
          {endAdornment}
        </StyledMultilineAdornment>
      )}

      {((!disabled && required && showHelperText) || (isControlledRequired && !disabled)) && (
        <FormHelperText />
      )}
    </div>
  );
};

InputText.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  disabled: PropTypes.bool,
  margin: PropTypes.string,
  control: PropTypes.object,
  name: PropTypes.string,
  multiline: PropTypes.number,
  rules: PropTypes.object,
  customStyles: PropTypes.string,
  helperText: PropTypes.string,
  isError: PropTypes.bool,
  showHelperText: PropTypes.bool,
  defaultValue: PropTypes.string,
  placeholder: PropTypes.string,
  variant: PropTypes.string,
  id: PropTypes.string,
  isControlledRequired: PropTypes.bool,
  isLoading: PropTypes.bool,
  onFocus: PropTypes.func,
  size: PropTypes.string,
  inputProps: PropTypes.object,
  endAdornment: PropTypes.node,
};

InputText.defaultProps = {
  margin: "dense",
  defaultValue: "",
  showHelperText: true,
  id: "",
  variant: "outlined",
  onFocus: () => {},
  size: SIZE.medium,
  inputProps: {},
};
export default InputText;
