import { useEffect, useState, useCallback } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import NoDataMessage from "components/NoDataMessage";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import {
  getNpsAndSatisfactionChart,
  getNpsAndSatisfactionResult,
  getDataBarNps,
  getDataSatisfactionBar,
} from "../../functions";
import SatisfactionByTopic from "../../components/SatisfactionByTopic";
import { StyledSubtitle } from "../../styledComponents";
import { StyledCardContent } from "../GeneralContent/styles";
import { StyledCard } from "./styles";

const SatisfactionByTopicAndDepartment = ({
  satisfactionResults,
  topicSatisfaction,
  t,
  engagementParticipation,
  minFilled,
}) => {
  const defaultData = {
    satisfactionByDepartment: null,
    hasInvalidResult: false,
    npsAndSatisfaction: null,
  };
  const [values, setValues] = useState(defaultData);

  const getData = useCallback(() => {
    const invalidResult = satisfactionResults?.some(
      (item) => item.forms_filled > 0 && item.forms_filled < minFilled,
    );

    const validResults = satisfactionResults?.filter(
      (item) => item.forms_filled >= minFilled,
    ) || [];

    const npsAndSatisfactionOptions = validResults
      ? getNpsAndSatisfactionChart(
        t,
        getDataBarNps(validResults),
        getDataSatisfactionBar(validResults, t),
        getNpsAndSatisfactionResult(validResults, t),
      )
      : [];

    setValues((prevValues) => ({
      ...prevValues,
      satisfactionByDepartment: validResults,
      npsAndSatisfaction: npsAndSatisfactionOptions,
      hasInvalidResult: invalidResult,
    }));
  }, [satisfactionResults, t]);

  useEffect(() => {
    if (satisfactionResults?.length > 0) {
      getData();
    }
  }, [satisfactionResults, getData]);

  return (
    <Grid item xs={ 12 }>
      {/* nps and satisfaction by division - chart and table */}
      <StyledCard elevation={ 0 }>
        <StyledCardContent>
          {values.npsAndSatisfaction?.length > 0 ? (
            <SwitchSelectorContent
              options={ values.npsAndSatisfaction }
              customStyles={ { switch: { height: "36px", margin: "0 0 10px 0" } } }
            />
          )
            : (
              <NoDataMessage />
            )}
        </StyledCardContent>
      </StyledCard>
      {/* satisfaction by department */}
      <StyledCard elevation={ 0 }>
        <StyledCardContent>
          <StyledSubtitle variant={ "h4" }>{t("satisfactionByTopic")}</StyledSubtitle>
          {values.satisfactionByDepartment?.length > 0 ? (
            <SatisfactionByTopic
              topics={ topicSatisfaction }
              formsFilled={ engagementParticipation?.surveys_answered }
              divisions={ values.satisfactionByDepartment }
            />
          ) : (
            <NoDataMessage />
          )}
        </StyledCardContent>
      </StyledCard>
    </Grid>
  );
};

SatisfactionByTopicAndDepartment.propTypes = {
  satisfactionResults: PropTypes.arrayOf(
    PropTypes.shape({
      forms_filled: PropTypes.number.isRequired,
    }),
  ).isRequired,
  topicSatisfaction: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
    }),
  ).isRequired,
  t: PropTypes.func.isRequired,
  engagementParticipation: PropTypes.arrayOf(
    PropTypes.shape({
      surveys_answered: PropTypes.number.isRequired,
    }),
  ).isRequired,
  minFilled: PropTypes.number.isRequired,
};

export default SatisfactionByTopicAndDepartment;
