import Grid from "@mui/material/Grid";
import GeneralContent from "./components_refactor/GeneralContent";
import { useStyles } from "./styles";

const Engagement = () => {
  const classes = useStyles();

  return (
    <div className={ classes.root } data-testid={ "engagement-view-component" }>
      <Grid container>
        <GeneralContent />
      </Grid>
    </div>
  );
};

export default Engagement;
