import { useHistory } from "react-router-dom";
import PropTypes from "prop-types";
import Avatar from "@mui/material/Avatar";
import { getAvatar, historyPush } from "common/utils";
import { PATHS } from "common/constants";
import {
  StyledImageContainer, StyledTitle,
  StyledSubtitle, StyledLink,
} from "../../styles";

const CollaboratorProfile = ({ collaborator }) => {
  const history = useHistory();

  return (
    <StyledImageContainer>
      <Avatar src={ getAvatar(collaborator) } />
      <StyledLink
        onClick={ () => {
          historyPush(
            history,
            PATHS.profile,
            `${PATHS.search.collaborators}${collaborator?.id}`,
          );
        } }
      >
        <StyledTitle>
          {collaborator.full_name}
        </StyledTitle>
      </StyledLink>
      <StyledSubtitle>
        {collaborator?.job_position?.position_name || collaborator?.position_name}
      </StyledSubtitle>
    </StyledImageContainer>
  );
};

CollaboratorProfile.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default CollaboratorProfile;
