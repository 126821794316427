import { useState, useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import find from "lodash/find";
import orderBy from "lodash/orderBy";
import { ASC, OBJECT_KEYS, ROUND } from "common/constants";
import { getPercent, formatDate, formatDateStart } from "common/utils";
import { create as createGoal } from "redux/actions/goalsActions";
import { groupGoalsByPeriod } from "../../functions/collapseGoalsTable";
import { getSelectedPeriod, transformedPeriods, totalCell } from "../../functions";

const useCollapse = (data, t, reset) => {
  const [values, setValues] = useState();
  const [newValues, setNewValues] = useState();
  const [newData, setNewData] = useState(false);
  const dispatch = useDispatch();

  const {
    list: periodList,
  } = useSelector(({ periodsReducer }) => periodsReducer);

  const periodListTransformed = transformedPeriods(periodList, t);

  const getGoalsGroupedByPeriods = useCallback(
    (goals) => groupGoalsByPeriod(goals, periodListTransformed),
    [periodListTransformed]
  );

  const handleDuplicate = (goalToDuplicate) => {
    const goalData = {
      goal: {
        description: goalToDuplicate.description,
        employee_id: data.id,
        metric: goalToDuplicate.metric,
        weight: goalToDuplicate.weight,
        completed_value: "",
        starting_date: formatDate(moment()),
        end_date: formatDate(moment()),
        archieved_at: "",
        goal_rule_id: goalToDuplicate.goal_rule_id,
        period_id: goalToDuplicate.period.id,
        subperiod_id: goalToDuplicate.subperiod?.id,
        tags: goalToDuplicate.tags,
      },
    };
    dispatch(createGoal(goalData));
  };

  const resetForm = useCallback(
    (goals) => {
      reset({
        "goals.employee": data.id,
        "goals.id": goals.id,
        "goals.description": goals.description,
        "goals.metric": goals.metric,
        "goals.weight": getPercent(goals.weight, false, ROUND.max),
        "goals.completed_value": goals.completed_value,
        "goals.starting_date": goals.starting_date
          ? formatDateStart(goals.starting_date)
          : moment(),
        "goals.end_date": goals.end_date
          ? formatDateStart(goals.end_date)
          : moment(),
        "goals.goal_rule_id": goals.goal_rule_id,
        "goals.selected_period": getSelectedPeriod(periodListTransformed, goals.period?.id, goals.subperiod?.id),
        "goals.tags": goals.tags,
      });
    },
    [reset, data, periodListTransformed],
  );

  const closeForm = useCallback(
    (goalId) => {
      const resetValues = values;
      if (goalId === 0) {
        resetValues.pop();
      } else {
        const actualGoal = find(resetValues, [OBJECT_KEYS.id, goalId]);
        actualGoal.isEdit = false;
        resetForm(actualGoal);
      }
      resetValues.pop();
      resetValues.push(totalCell);
      setNewData(false);
      setValues(resetValues);
    },
    [resetForm, values],
  );

  const editGoal = useCallback(
    (goalId) => {
      const resetValues = values;
      resetValues.forEach((item) => {
        const isItemEdit = item.id === goalId;
        item.isEdit = isItemEdit;
        isItemEdit && resetForm(item);
      });
      setValues(resetValues);
    },
    [resetForm, values],
  );

  useEffect(() => {
    const goals = orderBy(data.goals, [OBJECT_KEYS.id], [ASC]);
    !find(goals, [OBJECT_KEYS.id, OBJECT_KEYS.total]) && goals.push(totalCell);
    setValues(goals);
  }, [data]);

  useEffect(() => {
    if ((values === null) && newValues) {
      setValues(newValues);
      setNewValues();
    }
  }, [values, newValues]);

  return {
    values,
    setValues,
    newData,
    setNewData,
    setNewValues,
    getGoalsGroupedByPeriods,
    periodListTransformed,
    handleDuplicate,
    editGoal,
    resetForm,
    closeForm,
  };
};

export default useCollapse;
