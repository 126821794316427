import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Modal from "components/Modal";
import TabComponent from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import { StyledTabsContainer } from "styledComponents/Tabs";
import { SKELETONS_NUMBER, VARIANT, COLORS_NAME } from "common/constants";
import { isNull } from "common/helpers";
import {
  getDefinitionResult,
  isActiveDataResult,
  getTabs,
  INDEX_TABS_SKILLS,
} from "views_refactor/Profile/functions/skills";
import { StyledScrollBar } from "styledComponents/ScrollBar";
import PersonalitySpiderChart from "./components/PersonalitySpiderChart";
import EvaluationDetail from "./components/EvaluationDetail";
import DefinitionResults from "./components/DefinitionResults";
import { getFormattedObject } from "./components/PersonalitySpiderChart/functions";
import { StyledCardContainer, StyledTitle } from "./styles";
import BadgeRecommendation from "./components/BadgeRecommendation";

const SkillsModal = (props) => {
  const {
    t,
    isOwnProfile,
    profileStates,
    skillStates,
    handleTabChange,
    personality,
    performanceSurvey,
    hasPersonalitySection,
    onCloseModals,
  } = props;

  const { isModalOpen } = profileStates;
  const { tabValue } = skillStates;
  const { isLoadingPerformanceSurveyResults } = performanceSurvey;
  const { personalityData } = personality;

  return (
    <Modal
      isOpen={ isModalOpen.skills }
      title={ t("profile:modals.skills.skills.title") }
      onClose={ () => onCloseModals() }
    >
      <StyledScrollBar maxHeight={ "500px" } padding={ "10px" } minWidth={ "900px" }>
        <StyledTabsContainer
          spacing={ 3 }
          fontsize={ 14 }
          mt={ 0 }
          textcolor={ COLORS_NAME.black }
          normaltext={ "true" }
          uppercase={ "true" }
          withbackground={ "true" }
        >
          <TabComponent
            tabs={ getTabs(t, hasPersonalitySection) }
            onChange={ handleTabChange }
            tabValue={ tabValue }
          />
        </StyledTabsContainer>
        <TabPanel value={ tabValue } index={ INDEX_TABS_SKILLS.skills }>
          <StyledCardContainer>
            <Grid container spacing={ 3 }>
              <Grid item xs={ 12 }>
                {isNull(skillStates.userSurveyResult) || isLoadingPerformanceSurveyResults ? (
                  <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.SIX } />
                ) : <BadgeRecommendation { ...props } />}
              </Grid>
            </Grid>
          </StyledCardContainer>
        </TabPanel>
        {hasPersonalitySection && (
          <TabPanel value={ tabValue } index={ INDEX_TABS_SKILLS.personality }>
            <StyledCardContainer>
              <Grid item xs={ 12 }>
                <Typography variant={ VARIANT.h4 }>
                  {t("profile:modals.skills.skills.chart.personality_test_title")}
                </Typography>
                <StyledTitle variant={ VARIANT.bodyOne }>
                  {t(
                      `profile:modals.skills.skills.chart.personality_test_description${isOwnProfile ? "" : "_for_manager"}`,
                  )}
                </StyledTitle>
              </Grid>
              <Grid container spacing={ 3 }>
                {isActiveDataResult(personality) ? (
                  <>
                    <Grid item xs={ 12 } md={ 7 }>
                      <PersonalitySpiderChart
                        t={ t }
                        dataFormatted={ getFormattedObject(personalityData) }
                      />
                    </Grid>
                    <Grid item xs={ 12 } md={ 5 }>
                      <EvaluationDetail t={ t } data={ personalityData } />
                    </Grid>
                    <Grid item xs={ 12 } md={ 7 }>
                      <DefinitionResults
                        t={ t }
                        data={ getDefinitionResult(personalityData) }
                      />
                    </Grid>
                  </>
                ) : (
                  <NoDataMessage />
                )}
              </Grid>
            </StyledCardContainer>
          </TabPanel>
        )}
      </StyledScrollBar>
    </Modal>
  );
};

SkillsModal.propTypes = {
  t: PropTypes.func.isRequired,
  onCloseModals: PropTypes.func.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  profileStates: PropTypes.object.isRequired,
  skillStates: PropTypes.object.isRequired,
  handleTabChange: PropTypes.func.isRequired,
  personality: PropTypes.object.isRequired,
  performanceSurvey: PropTypes.object.isRequired,
  potencialProcesses: PropTypes.object.isRequired,
  hasPersonalitySection: PropTypes.bool.isRequired,
};

export default SkillsModal;
