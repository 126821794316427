import {
  SCALE_COUNT,
} from "common/constants";
import { isEqual } from "common/helpers";

export const getLabelAnswer = (id, label, scale, firstScaleId, lastScaleId, isMobile = false) => {
  let answerLabel = label;
  const numericChar = isMobile ? "" : "_";
  if (scale?.indices?.length > SCALE_COUNT.min) {
    answerLabel = isEqual(id, firstScaleId) || isEqual(id, lastScaleId) ? label : numericChar;
  }
  return answerLabel;
};

export const getValue = (id, teamAnswers, marks, memberId) => (teamAnswers[memberId] && id in teamAnswers[memberId]
  ? marks.find((item) => isEqual(item.firstLabel, teamAnswers[memberId][id]))?.value
  : marks[0]?.value);

export const validateShowLabel = (index, indices) => isEqual(index, 0)
  || isEqual(index, indices.length - 1)
  || isEqual(index, Math.floor((indices.length - 1) / 2));

export const getLabel = (id, teamAnswers, marks, memberId) => (teamAnswers[memberId] && id in teamAnswers[memberId]
  ? marks.find((item) => isEqual(item.firstLabel, teamAnswers[memberId][id]))?.firstLabel
  : marks[0]?.firstLabel);
