import { useEffect } from "react";
import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TitleIcon from "components/TitleIcon";
import InputNumberController from "components/InputNumberController";
import StakeholdersIcon from "assets/images/planning/team_group_thin.svg";
import { VARIANT } from "common/constants";
import { getPercent } from "common/utils";
import { EVALUATION_TYPE, ensureTemplateSuffix, getEvaluationType } from "../../functions";
import {
  StyledTitle, StyledTableCell,
  StyledTotalTableCell,
  StyledTableContainer,
  StyledHeader,
  StyledTotalTitle,
} from "./styles";

const Stakeholders = ({
  t, control, watch, evaluations, setValue, i18n, register,
  setTemplateStates, editMode, templateStates, formLanguage,
  hasInternalClient,
}) => {
  const { stakeholders } = templateStates;
  const types = {
    [EVALUATION_TYPE.AUTOEVALUATION]: "auto",
    [EVALUATION_TYPE.MANAGER]: "manager",
    [EVALUATION_TYPE.PAIRS]: "peers",
    [EVALUATION_TYPE.COLLABORATORS]: "team",
    ...(hasInternalClient && {
      [EVALUATION_TYPE.INTERNAL_CLIENT]: "internal_client",
    }),
  };

  useEffect(() => {
    if (evaluations) {
      const updatedStakeholders = { ...stakeholders };
      if (evaluations.length > 0) {
        evaluations.forEach((evaluation) => {
          const typeWithTemplate = ensureTemplateSuffix(evaluation.type);
          const stakeholderKey = types[typeWithTemplate];

          if (stakeholderKey && evaluation.weighing !== 0) {
            updatedStakeholders[stakeholderKey] = {
              ...updatedStakeholders[stakeholderKey],
              checked: true,
              value: getPercent(evaluation.weighing),
            };
            setValue(`checkbox_${stakeholderKey}`, true);
          }
        });
      }
      setTemplateStates({ stakeholders: updatedStakeholders });
    }
    // eslint-disable-next-line
  }, []);

  const handleSwitchChange = (name) => (event) => {
    setTemplateStates({
      stakeholders: {
        ...stakeholders,
        [name]: {
          ...stakeholders[name],
          checked: event.target.checked,
          value: 0,
          total: 0,
        },
      },
    });
    setValue(`checkbox_${name}`, !!event.target.checked);
    setValue(name, 0);
  };

  const total = (parseFloat(watch("auto")) || 0)
  + (parseFloat(watch("manager")) || 0)
  + (parseFloat(watch("team")) || 0)
  + (parseFloat(watch("peers")) || 0)
  + (hasInternalClient ? (parseFloat(watch("internal_client")) || 0) : 0);

  const totalValue = Object.values(stakeholders)
    .reduce((sum, stakeholder) => sum + stakeholder.value, 0);

  useEffect(() => {
    setTemplateStates({ isValid: total === 100 });
  }, [total]);

  return (
    <>
      <StyledHeader>
        <TitleIcon icon={ { src: StakeholdersIcon } } title={ t("planning:templates.stakeholders.title") } />
        <Typography>{t("planning:templates.stakeholders.description")}</Typography>
      </StyledHeader>
      <StyledTableContainer component={ Paper }>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell />
              {Object.keys(stakeholders).map((key) => (
                <StyledTableCell key={ key }>
                  <Stack direction={ "row" } justifyContent={ "center" } alignItems={ "center" } spacing={ 1 }>
                    <StyledTitle>
                      {getEvaluationType(stakeholders[key].type, i18n)[formLanguage]}
                    </StyledTitle>
                    {editMode && (
                      <Switch
                        { ...register(`checkbox_${key}`) }
                        checked={ stakeholders[key].checked }
                        onChange={ handleSwitchChange(key) }
                        data-testid={ `checkbox_${key}` }
                      />
                    )}
                  </Stack>
                </StyledTableCell>
              ))}
              <StyledTotalTableCell>
                <StyledTitle>{t("planning:total")}</StyledTitle>
              </StyledTotalTableCell>
            </TableRow>
            <TableRow>
              <StyledTotalTableCell>
                <StyledTitle>{t("surveys:form.fields.weighing")}</StyledTitle>
              </StyledTotalTableCell>
              {Object.keys(stakeholders).map((key) => (
                <TableCell key={ key }>
                  <InputNumberController
                    isDisabled={ !editMode || !stakeholders[key].checked }
                    control={ control }
                    name={ stakeholders[key].key }
                    variant={ VARIANT.outlined }
                    isInteger
                    maxLength={ 3 }
                  />
                </TableCell>
              ))}
              <TableCell><StyledTotalTitle isInvalid={ editMode ? total !== 100 : false }>{`${editMode ? total : totalValue}%`}</StyledTotalTitle></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

Stakeholders.propTypes = {
  t: PropTypes.func.isRequired,
  control: PropTypes.object.isRequired,
  watch: PropTypes.func,
  evaluations: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(Object.values(EVALUATION_TYPE)),
    weighing: PropTypes.number,
  })),
  setValue: PropTypes.func,
  i18n: PropTypes.any,
  editMode: PropTypes.bool,
  templateStates: PropTypes.shape({
    stakeholders: PropTypes.object,
  }),
  setTemplateStates: PropTypes.func,
  formLanguage: PropTypes.string.isRequired,
  register: PropTypes.func,
  hasInternalClient: PropTypes.bool,
};

Stakeholders.defaultProps = {
  evaluations: [],
  editMode: true,
  watch: () => {},
  setValue: () => {},
  i18n: null,
  templateStates: {
    stakeholders: {},
  },
  setTemplateStates: () => {},
  register: () => {},
  hasInternalClient: false,
};

export default Stakeholders;
