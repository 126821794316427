import { useState, useEffect, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import moment from "moment";
import isString from "lodash/isString";
import Grid from "@mui/material/Grid";
import { SessionContext } from "modules/session/context";
import AlertModal from "components/AlertModal";
import Breadcrumbs from "components/Breadcrumbs";
import {
  toast, MESSAGE_TYPES,
} from "components/Toast/functions";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  MIN_VALUE,
  ONBOARDING_KEYS,
  OBJECT_KEYS,
} from "common/constants";
import { formatDateToSubmit, getValueByProp, preventEnter } from "common/utils";
import { isEqual } from "common/helpers";
import candidateIcon from "assets/images/onboarding/candidate.svg";
import salaryIcon from "assets/images/onboarding/salary.svg";
import templateIcon from "assets/images/onboarding/template.svg";
import { create, resetState, resetStateProcess } from "redux/actions/candidateActions";
import Templates from "views/Templates";
import CandidateInformation from "./components/CandidateInformation";
import Salary from "./components/Salary";
import CardSection from "./components/CardSection";
import { getDocumentFormat, getDataBreadcrumbs, getSectionItemFormat } from "./functions";
import { StyledTitle, StyledButton, StyledContent } from "./styles";

const Onboarding = () => {
  const { t } = useTranslation(["Onboarding", "candidates", "common"]);
  const dispatch = useDispatch();
  const [sendValidation, setSendValidation] = useState(false);
  const [isValid, setIsValid] = useState(false);
  const [modal, setModal] = useState(false);
  const [docs, setDocs] = useState([]);
  const [info, setInfo] = useState([]);
  const [sectionItems, setSectionItems] = useState([]);
  const [defaultValue, setDefaultValue] = useState({
    position: null,
    country: null,
    city: null,
    level: null,
    legalEntity: null,
  });
  const [validation, setValidation] = useState({
    position: false,
    country: false,
    currency: false,
  });

  const {
    register, control, handleSubmit, setValue,
  } = useForm({
    defaultValues: {
      "candidate.person_attributes.personal_id": "",
      "candidate.person_attributes.personal_email": "",
      "candidate.person_attributes.additional_information": "",
      "candidate.person_attributes.full_name": "",
      "candidate.job_position_attributes.area_name": "",
      "candidate.job_position_attributes.position_id": "",
      "candidate.job_position_attributes.type_of_contract_id": "",
      "candidate.salary_attributes.currency": "",
      "candidate.salary_attributes.gross_salary": MIN_VALUE,
      "candidate.starting_date": moment(),
      "candidate.job_position_attributes.hierarchy_level_id": "",
      "candidate.legal_entity_id": "",
    },
  });

  const {
    isLoadingProcess: isLoadingProcessCandidate,
    successProcess: successProcessCandidate,
  } = useSelector(({ candidateReducer }) => candidateReducer);

  const history = useHistory();

  // FIXME: area_name, country_name -> ids
  const onSubmit = async () => {
    const candidateData = control.getValues();
    candidateData.candidate.requested_documents_attributes = getDocumentFormat(docs, ONBOARDING_KEYS.documentTypeId);
    candidateData.candidate.requested_infos_attributes = getDocumentFormat(info, ONBOARDING_KEYS.infoTypeId);
    candidateData.candidate.requested_section_items_attributes = getSectionItemFormat(sectionItems, ONBOARDING_KEYS.sectionItemId);
    const { candidate } = candidateData;

    candidate.manager_id = getValueByProp(candidate.manager_id, OBJECT_KEYS.id);
    candidate.job_position_attributes.position_id = defaultValue?.position && defaultValue?.position?.id;
    candidate.job_position_attributes.country_id = defaultValue?.country && defaultValue?.country?.id;
    candidate.job_position_attributes.city_id = defaultValue?.city && defaultValue?.city?.id;
    candidate.job_position_attributes.hierarchy_level_id = defaultValue?.level && defaultValue?.level?.id;
    candidate.legal_entity_id = defaultValue?.legalEntity && defaultValue?.legalEntity?.id;

    candidate.starting_date = formatDateToSubmit(
      candidate.starting_date,
    );
    const salary = candidate.salary_attributes.gross_salary;

    if (isString(salary)) {
      candidate.salary_attributes.gross_salary = salary.replaceAll(
        ",",
        "",
      );
    }

    dispatch(create(candidateData));
    setModal(false);
  };

  useEffect(() => {
    if (successProcessCandidate) {
      const message = {
        title: t("candidates:candidate_creation_title_success"),
        message: t("candidates:alertMessage.success"),
      };
      toast(MESSAGE_TYPES.success, message, t);
      dispatch(resetState());
      dispatch(resetStateProcess());
      history.push("/onboarding", true);
    }
  }, [successProcessCandidate, dispatch, history, t]);

  const openCloseModal = () => {
    setModal(!modal);
  };

  const validateForm = () => {
    setSendValidation(true);
    if (isValid) openCloseModal();
  };

  const checkIsValid = (name, value) => {
    setValidation({ ...validation, [name]: value });
  };

  useEffect(() => {
    setIsValid(Object.values(validation).every((item) => isEqual(item, true)));
  }, [validation]);

  return (
    <StyledContent>
      <Breadcrumbs data={ getDataBreadcrumbs(t) } />
      <StyledTitle>
        { t("candidates:candidate") }
      </StyledTitle>
      <Grid>
        <form
          id={ "onboarding-form" }
          onSubmit={ handleSubmit(validateForm) }
          onKeyPress={ preventEnter }
        >
          <CardSection
            title={ t("templates:select_template") }
            icon={ templateIcon }
          >
            <Templates
              setDocs={ setDocs }
              setInfo={ setInfo }
              setSectionItems={ setSectionItems }
            />
          </CardSection>

          <CardSection
            title={ t("Onboarding:candidate_information_title") }
            icon={ candidateIcon }
          >
            <CandidateInformation
              register={ register }
              setValue={ setValue }
              control={ control }
              sendValidation={ sendValidation }
              checkIsValid={ checkIsValid }
              validation={ validation }
              defaultValue={ defaultValue }
              setDefaultValue={ setDefaultValue }
            />
          </CardSection>

          <CardSection
            title={ t("Onboarding:salary_information_title") }
            icon={ salaryIcon }
          >
            <Salary
              control={ control }
              register={ register }
              setValue={ setValue }
              sendValidation={ sendValidation }
              checkIsValid={ checkIsValid }
              validation={ validation }
            />
          </CardSection>

          <Grid container justify={ "flex-end" }>
            <StyledButton
              variant={ VARIANT.contained }
              isLoading={ isLoadingProcessCandidate }
              color={ COLORS_NAME.primary }
              type={ BUTTON_STYLE_TYPES.SUBMIT }
              typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
            >
              { t("Onboarding:send") }
            </StyledButton>
          </Grid>
        </form>
      </Grid>
      <AlertModal
        title={ t("common:common.modal_messages.sure_question") }
        text={ t("candidates:alertMessage.create_candidate") }
        textDisagree={ t("common:common.modal_messages.no_cancel") }
        textAgree={ t("common:common.modal_messages.yes_confirm") }
        onClick={ onSubmit }
        open={ modal }
        handleClose={ openCloseModal }
      />
    </StyledContent>
  );
};

export default Onboarding;
