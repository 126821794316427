import {
  useCallback, useContext, useEffect, useState,
} from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
// import { useTheme } from "@mui/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import InputTextSearch from "components/InputTextSearch";
import Filter from "components/Filter";
import NoDataMessage from "components/NoDataMessage";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import SkeletonLoader from "components/SkeletonLoader";
import {
  DIRECTION,
  PATHS,
  DAYS_TO_FINISH_ONBOARDING,
  DATE_FORMATS,
  ROLES,
} from "common/constants";
import {
  getSearchParams,
  isManager as isRolManager,
  navigateToPath,
  formatDate,
  isAdmin as isAdminFunction,
  isValidRole,
} from "common/utils";
import { isEmpty } from "common/helpers";
import { SessionContext } from "modules/session/context";
import { getSurveyProcessByUser } from "redux/actions/surveyProcessesActions";
import { getList as getFollowUpList } from "redux/actions/followUpActions";
import { getFollowUpProcess } from "views/OnboardingFollowUp/functions";
import { useNps, useEngagementAndPerformance } from "hooks/useFollowUpProcess";
import MultidataCard from "components/MultidataCard";
import NpsIcon from "assets/images/newHireExperience/nps.svg";
import SatisfactionHandIcon from "assets/images/newHireExperience/satisfaction.svg";
import PerformanceIcon from "assets/images/newHireExperience/performance.svg";
import TeamIcon from "assets/images/newHireExperience/team.svg";
import NoInfoFaceBlack from "assets/images/general/noInfoFaceBlack.svg";
import { StyledSimpleDashboardContainer } from "styledComponents/View";
import { StyledTabsContainer } from "styledComponents/Tabs";
import { CARD_RESULT } from "theme/palette";
import OverView from "./components/OverView";
import { getSatisfactionByLabelOrScore } from "./functions";
import {
  StyledSpacedGrid,
  StyledTasksGrid,
  StyledFiltersGrid,
  StyledCardsGrid,
  StyledTeamContent,
} from "./styles";

const CollaboratorsFollowUp = () => {
  const { t } = useTranslation(["onboardingFollowUp"]);
  const history = useHistory();
  const params = history.location.search;
  const allParams = getSearchParams(params);

  const { state: { user } } = useContext(SessionContext);
  const employeeId = user?.employee?.id;
  const startingDate = user?.person?.employee?.starting_date;
  const isManager = isRolManager(user?.userCookies);
  const isOnboardingAdmin = isValidRole(user?.userCookies, ROLES.ONBOARDING_ADMIN);
  const isAdmin = isAdminFunction(user?.userCookies);

  const pastDate = new Date();
  pastDate.setHours(0, 0, 0, 0);
  pastDate.setDate(pastDate.getDate() - DAYS_TO_FINISH_ONBOARDING);
  const formattedDate = formatDate(pastDate.toDateString(), DATE_FORMATS.es.dash);

  const [tabHandler, setTabHandler] = useState(0);

  const getInitialQuery = (tabValue) => ({
    q: {
      employee_id: employeeId,
      [`starting_date_${tabValue === 1 ? "lt" : "gteq"}`]: formattedDate,
      active_in: [true],
      employee_active_in: [true],
    },
  });

  const [filterQuery, setFilterQuery] = useState(getInitialQuery(tabHandler));
  const [surveyProcesses, setSurveyProcesses] = useState(null);
  const [total, setTotal] = useState(null);

  if (!isAdmin && !isOnboardingAdmin) {
    filterQuery.q.managers_ids_special_in = [employeeId];
  }

  const dispatch = useDispatch();
  const {
    list: followUpList,
    isLoadingList: isLoadingFollowUpList,
  } = useSelector(({ followUpReducer }) => followUpReducer);

  const allTabs = [{ label: t("tabs.overview") }, { label: t("tabs.finished") }];

  const handleValueChanged = (value) => {
    setTabHandler(value);

    setFilterQuery((prevQuery) => {
      const filteredQuery = Object.keys(prevQuery.q).reduce((acc, key) => {
        if (!key.startsWith("starting_date_")) {
          acc[key] = prevQuery.q[key];
        }
        return acc;
      }, {});

      return {
        ...prevQuery,
        q: {
          ...filteredQuery,
          [`starting_date_${value === 1 ? "lt" : "gteq"}`]: formattedDate,
        },
      };
    });
  };

  const handleInput = (name = "") => {
    if (name !== null) {
      setFilterQuery((prevQuery) => ({
        ...prevQuery,
        q: {
          ...prevQuery.q,
          person_full_name_cont: name,
        },
      }));
    }
  };

  const handleExternalQuery = (newQuery) => {
    setFilterQuery((prevQuery) => ({
      ...prevQuery,
      q: {
        ...prevQuery.q,
        ...newQuery.q,
      },
    }));
  };

  const getSurveyResults = useCallback(async () => {
    const response = await dispatch(getSurveyProcessByUser(employeeId, true));
    if (response?.error) {
      const toastMessage = {
        title: t("common:common.api_responses.error.title"),
        message: response?.message,
      };
      toast(MESSAGE_TYPES.error, toastMessage);
    } else {
      setSurveyProcesses(response);
    }
  }, [employeeId, dispatch, t]);

  useEffect(() => {
    dispatch(getFollowUpList());
    if (employeeId) {
      getSurveyResults();
    }
  }, [getSurveyResults, employeeId, dispatch]);

  const getSurveyDetail = (startDate) => {
    if (!isEmpty(followUpList)) {
      return getFollowUpProcess(
        followUpList,
        surveyProcesses,
        startDate,
        true,
        true,
      );
    }
    return <NoDataMessage />;
  };

  const surveyProcess = isLoadingFollowUpList ? (
    <SkeletonLoader numberOfSkeletons={ 4 } />
  ) : (
    getSurveyDetail(startingDate)
  );

  const skeletonLoader = (
    <SkeletonLoader
      numberOfSkeletons={ 1 }
      variant={ "rectangular" }
      height={ 250 }
      columnWidth={ 12 }
    />
  );

  const getCards = () => {
    const { data: totalNps, isLoading: isLoadingNps } = useNps(filterQuery);
    const {
      data: { engagement: totalEngagement, performance: totalPerformance } = {},
      isLoading: isLoadingEngagementAndPerformance,
    } = useEngagementAndPerformance(filterQuery);

    return (
      <>
        {/* Total */}
        <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
          {total === null ? skeletonLoader : (
            <MultidataCard
              icon={ TeamIcon }
              size={ 42 }
              customRender={ (
                <StyledTeamContent>
                  <Typography variant={ "h4" }>{ total }</Typography>
                  { t("collaboratorsOnboarding") }
                </StyledTeamContent>
              ) }
            />
          )}
        </Grid>

        {/* NPS card */}
        <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
          {isLoadingNps ? skeletonLoader : (
            <MultidataCard
              title={ t("engagement:nps") }
              subtitle={ `(${t("currentStage")})` }
              icon={ NpsIcon }
              color={ CARD_RESULT.cyanCard }
              dataList={ totalNps?.map((e) => ({
                value: e.days,
                label: `${e.days} ${t("planning:calendar.days")}`,
                handleOnClick: () => navigateToPath(history, PATHS.engagement, [{
                  paramName: "id",
                  value: e.process_id,
                }]),
                participation: { total: e.nps, max: 1 },
                hasResult: e.nps !== null,
              })) }
              hasScore
            />
          )}
        </Grid>

        {/* Engagement card */}
        <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
          {isLoadingEngagementAndPerformance ? skeletonLoader : (
            <MultidataCard
              title={ t("engagement:satisfaction") }
              subtitle={ `(${t("currentStage")})` }
              icon={ SatisfactionHandIcon }
              color={ CARD_RESULT.yellowCard }
              dataList={ totalEngagement?.map((engagementData) => ({
                value: engagementData.days,
                label: `${engagementData.days} ${t("planning:calendar.days")}`,
                handleOnClick: () => navigateToPath(history, PATHS.engagement, [{
                  paramName: "id",
                  value: engagementData.process_id,
                }]),
                result: (
                  <img
                    src={ engagementData?.result
                      ? getSatisfactionByLabelOrScore(
                        engagementData?.score,
                        engagementData?.result,
                        t,
                      )?.icon
                      : NoInfoFaceBlack }
                    alt={ engagementData?.result || t("noResult") }
                  />
                ),
                hasResult: engagementData?.result !== null,
              })) }
            />
          )}
        </Grid>

        {/* Performance card */}
        <Grid item xs={ 12 } sm={ 6 } md={ 3 }>
          {isLoadingEngagementAndPerformance ? skeletonLoader : (
            <MultidataCard
              title={ t("common:common.performance") }
              subtitle={ `(${t("currentStage")})` }
              icon={ PerformanceIcon }
              dataList={ totalPerformance?.map((performanceData) => ({
                value: performanceData.days,
                label: `${performanceData.days} ${t("planning:calendar.days")}`,
                handleOnClick: () => navigateToPath(history, PATHS.performance, [{
                  paramName: "id",
                  value: performanceData.process_id,
                }]),
                participation: { total: performanceData.score, max: 1 },
                hasResult: performanceData?.score !== null,
              })) }
              hasScore
              hasSymbol
            />
          )}
        </Grid>
      </>
    );
  };

  const handleTotal = (collaboratorsTotal) => {
    if (total !== collaboratorsTotal) {
      setTotal(collaboratorsTotal);
    }
  };

  return (
    <StyledSimpleDashboardContainer
      data-testid={ "collaborators-follow-up-view" }
    >
      <StyledSpacedGrid container direction={ DIRECTION.row }>
        <Grid item>
          <ViewHeaderTitle title={ t("onboardingFollowUp") } />
        </Grid>
        <Grid item xs={ 12 } md={ 4 }>
          <InputTextSearch
            handleInput={ handleInput }
            label={ t("searchCollaborators") }
            defaultValue={ allParams?.search || null }
          />
        </Grid>
      </StyledSpacedGrid>
      <StyledFiltersGrid container>
        <Grid item xs={ 12 } md={ 12 }>
          <Filter
            type={ FILTER_ACTIONS_TYPES.noActions }
            handleExternalQuery={ handleExternalQuery }
            isMultiple
            hasButton
          />
        </Grid>
      </StyledFiltersGrid>
      <StyledCardsGrid container spacing={ 2 }>
        {getCards()}
      </StyledCardsGrid>
      <Grid container spacing={ 4 }>
        <StyledTabsContainer item xs={ 12 }>
          <Tab
            tabs={ allTabs }
            onChange={ handleValueChanged }
            tabValue={ tabHandler }
          />
          <TabPanel value={ tabHandler } index={ 0 }>
            <OverView
              filterQuery={ filterQuery }
              handleTotal={ handleTotal }
            />
            {isManager && (
              <StyledTasksGrid container>
                <Grid item xs={ 12 }>
                  <ViewHeaderTitle
                    title={ t("onboardingFollowUp:tasks.titleManager") }
                    subtitle={ t("onboardingFollowUp:tasks.subtitle") }
                  />
                  <Grid container>{surveyProcess}</Grid>
                </Grid>
              </StyledTasksGrid>
            )}
          </TabPanel>
          <TabPanel value={ tabHandler } index={ 1 }>
            <OverView
              filterQuery={ filterQuery }
              handleTotal={ handleTotal }
            />
          </TabPanel>
        </StyledTabsContainer>
      </Grid>
    </StyledSimpleDashboardContainer>
  );
};

export default CollaboratorsFollowUp;
