import styled from "styled-components";
import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import RadioGroup from "@mui/material/RadioGroup";
import FormControl from "@mui/material/FormControl";
import Tooltip from "@mui/material/Tooltip";
import HelpIcon from "@mui/icons-material/Help";
import palette from "theme/palette";
import { isNotValid } from "common/helpers";

export const StyledCard = styled(Card)`
  margin: 10px 0 30px;
  .MuiFormControlLabel-labelPlacementBottom {
    margin: 0;
  }
  .opacityLabel {
    .MuiTypography-root {
      opacity: 0;
    }
  }
  .starsLabel {
    &.MuiFormControlLabel-labelPlacementBottom {
      width: 120px;
    }
    &.MuiFormControlLabel-labelPlacementTop .MuiTypography-body1 {
      font-size: 12px;
    }
  }
  .MuiSvgIcon-root {
    width: 30px;
    height: 30px;
  }
  .Mui-checked {
    color: ${palette.background.purple};
  }
  .startFlex {
    &.MuiFormControlLabel-root {
      margin-bottom: 20px;
      width: 100%;
    }
    .MuiIconButton-root {
      padding: 0 20px;
    }
  }
  .MuiSlider-mark {
    width: 18px;
    height: 18px;
    border-radius: 50px;
    color: ${palette.background.lightGrey};
    background-color: ${(props) => (isNotValid(props.value) ? palette.background.lightGrey : "")};
    :hover {
      color: ${palette.background.purple};
      background-color: ${(props) => (isNotValid(props.value) ? palette.background.purple : "")};
    }
  }
`;

export const StyledTitle = styled(Typography)`
  font-size: 16px;
  margin-bottom: 40px;
  font-weight: 500;
`;

export const StyledRadioGroup = styled(RadioGroup)`
  display: ${(props) => props.display};
`;

export const StyledFormControl = styled(FormControl)`
  display: flex;
  align-items: ${(props) => props.alignItems};
  font-size: 12px;
`;

export const StyledAnswerContent = styled.div`
  text-align: center;
  .MuiTypography-h5 {
    font-size: 52px;
    font-weight: 700;
    line-height: 48px;
    margin-top: 8px;
  }
`;

export const StyledTooltip = styled(Tooltip)`
  cursor: pointer;
`;

export const StyledHelpIcon = styled(HelpIcon)`
  width: 20px !important;
  height: 20px !important;
  position: absolute;
  right: 10px;
`;
