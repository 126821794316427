import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import CircularProgress from "@mui/material/CircularProgress";
import PropTypes from "prop-types";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import charts from "theme/charts";
import { StyledProgress, StyledBox } from "./styles";

const QuadrantInfo = (props) => {
  const {
    percentage, totalCollaborators, t, isSelected,
  } = props;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  return (
    <StyledBox
      display={ "flex" }
      alignItems={ "center" }
      justifyContent={ isMobile ? "center" : "left" }
      height={ 8 }
      active={ isSelected }
    >
      {!isMobile
    && (
      <StyledProgress>
        <CircularProgress
          variant={ "determinate" }
          value={ percentage }
          size={ 8 }
          thickness={ 4 }
        />
        <Box
          top={ 0 }
          left={ 0 }
          bottom={ 0 }
          right={ 0 }
          position={ "absolute" }
          display={ "flex" }
          alignItems={ "center" }
          justifyContent={ "center" }
        >
          <Typography
            variant={ "caption" }
            component={ "div" }
            fontSize={ 2 }
          >
            {`${percentage}%`}
          </Typography>
        </Box>
      </StyledProgress>
    )}
      <Box ml={ 0.1 }>
        <Typography
          variant={ "body2" }
          fontSize={ isMobile ? 3 : 2 }
        >
          {isMobile ? `${totalCollaborators} ${t("common:abbreviations.employees")} (${percentage}%)` : `${totalCollaborators} ${t("developmentPlan:details.collaborators")}`}
        </Typography>
      </Box>
    </StyledBox>
  );
};

QuadrantInfo.propTypes = {
  percentage: PropTypes.number.isRequired,
  totalCollaborators: PropTypes.number.isRequired,
  t: PropTypes.func.isRequired,
  isSelected: PropTypes.bool.isRequired,
};

export default QuadrantInfo;
