import { Typography } from "@mui/material";
import styled from "styled-components";

export const StyledMultilineAdornment = styled(Typography)`
  p {
    margin-top: -50px;
    margin-left: auto;
    margin-right: 10px;
  }
`;
