import React, { useState, useEffect, useCallback, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import {
  Drawer, Tabs, Tab, Box, FormControlLabel, IconButton, Collapse,
} from "@mui/material";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import PreviewIcon from "@mui/icons-material/Preview";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { SessionContext } from "modules/session/context";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { sendGoalsReminder, getStatus } from "redux/actions/goalsActions";
import CloseIcon from "@mui/icons-material/Close";
import InputTag from "components/InputTag";
import PropTypes from "prop-types";
import InputEditor from "components/InputEditor";
import { getTemplateListByProcess } from "redux/actions/template/mandrillTemplateActions";
import { getList as getAudiences, getAudience } from "redux/actions/audience/audienceActions";
import { getFormattedAudiencesTags } from "views/Planning/functions";
import AudienceByCollaboratorModal from "views/Collaborators/components/AudienceByCollaboratorModal";
import {
  VARIANT, OBJECT_KEYS, SIZE, ALIGN_ITEMS,
} from "common/constants";
import GoalsTotalAudience from "../GoalsTotalAudience";
import ModalAudience from "../../../Planning/components/ProcessData/components/ModalAudience";
import { useGoalsDataHook } from "../../hooks/goals.hook";
import {
  StyledDrawer,
  StyledHeader,
  StyledTitle,
  StyledIconButton,
  StyledForm,
  StyledTextField,
  StyledButtonContainer,
  StyledSendButton,
  StyledCancelButton,
  StyledInputContainer,
  StyledMailIcon,
  StyledCheckbox,
  StyledTypography,
  StyledSubtitle,
  StyledPreviewBox,
  StyledPreviewContent,
  StyledPreview,
} from "./styles";

const ReminderModal = ({ open, onClose, t }) => {
  const [subjectEs, setSubjectEs] = useState(t("goals:reminder_modal.subject_placeholder_es"));
  const [subjectEn, setSubjectEn] = useState(t("goals:reminder_modal.subject_placeholder_en"));
  const [subjectPt, setSubjectPt] = useState(t("goals:reminder_modal.subject_placeholder_pt"));

  const [contentEs, setContentEs] = useState(t("goals:reminder_modal.content_placeholder_es"));
  const [contentEn, setContentEn] = useState(t("goals:reminder_modal.content_placeholder_en"));
  const [contentPt, setContentPt] = useState(t("goals:reminder_modal.content_placeholder_pt"));

  const {
    state: { user },
  } = useContext(SessionContext);

  const { i18n } = useTranslation();
  const languageIndex = {
    es: 0,
    en: 1,
    pt: 2,
  };

  const employeeName = user?.person?.full_name;
  const [selectedTab, setSelectedTab] = useState(languageIndex[i18n.language] || 0);
  const [sendToLeaders, setSendToLeaders] = useState(true);
  const [sendToCollaborators, setSendToCollaborators] = useState(true);
  const [mandrillTemplates, setMandrillTemplates] = useState(null);
  const [isPreviewOpen, setPreviewOpen] = useState(false);
  const [previewHtml, setPreviewHtml] = useState("");

  const [isAudienceCollaboratorModalOpen, setAudienceCollaboratorModalOpen] = useState(false);
  const [isModalAudienceOpen, setModalAudienceOpen] = useState(false);

  const dispatch = useDispatch();

  const getMandrillTemplates = useCallback(async () => {
    const templates = await dispatch(getTemplateListByProcess("goal"));
    setMandrillTemplates(templates);
  });

  useEffect(() => {
    getMandrillTemplates();
  }, []);

  const replaceTemplateVariables = (templateCode, subject, content) => {
    const updatedHtml = templateCode
      .replace(/\*\|name\|\*/g, employeeName)
      .replace(/\*\|subject\|\*/g, subject)
      .replace(/\*\|content\|\*/g, content);
    return updatedHtml;
  };

  const getTemplatePreview = () => {
    if (!mandrillTemplates) return null;
    const language = selectedTab === 0 ? "es" : selectedTab === 1 ? "en" : "pt";
    const template = mandrillTemplates.find((tpl) => tpl.labels.includes(language));
    return template ? template.publish_code : null;
  };

  const updatePreview = useCallback(() => {
    const templateCode = getTemplatePreview();
    const languageSubject = selectedTab === 0 ? subjectEs : selectedTab === 1 ? subjectEn : subjectPt;
    const languageContent = selectedTab === 0 ? contentEs : selectedTab === 1 ? contentEn : contentPt;

    if (templateCode) {
      const updatedHtml = replaceTemplateVariables(templateCode, languageSubject, languageContent);
      setPreviewHtml(updatedHtml);
    }
  }, [getTemplatePreview, subjectEs, subjectEn, subjectPt, contentEs, contentEn, contentPt, selectedTab]);

  useEffect(() => {
    updatePreview();
  }, [updatePreview]);

  const { dataStates, handleTags } = useGoalsDataHook(
    { dispatch, t },
    { formStates: { resetAudience: null, audienceSelected: null }, setFormStates: () => {} },
  );

  const { inputTagsSelected } = dataStates;

  useEffect(() => {
    dispatch(getAudiences());
  }, [dispatch]);

  const {
    list: audiencesList,
    isLoadingList: isLoadingAudienceList,
  } = useSelector(({ audienceReducer }) => audienceReducer);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    setPreviewOpen(false);
  };

  const handleSend = async () => {
    let recipients;
    if (sendToLeaders && sendToCollaborators) {
      recipients = "all";
    } else if (sendToLeaders) {
      recipients = "leaders";
    } else if (sendToCollaborators) {
      recipients = "collaborators";
    } else {
      recipients = null;
    }
    const data = {
      audience_id: inputTagsSelected[0]?.id,
      subject: {
        en: subjectEn,
        es: subjectEs,
        pt: subjectPt,
      },
      message: {
        en: contentEn,
        es: contentEs,
        pt: contentPt,
      },
      recipients,
    };
    try {
      const asyncTaskId = await sendGoalsReminder(data);

      if (asyncTaskId) {
        toast(MESSAGE_TYPES.info, {
          message: t("common:common.api_responses.info.notify_shipped"),
        });

        const interval = setInterval(async () => {
          try {
            const status = await dispatch(getStatus(asyncTaskId));
            if (status.aasm_state === "successfully") {
              clearInterval(interval);
              toast(MESSAGE_TYPES.success, {
                message: t("common:common.api_responses.success.submitted"),
              });
              onClose();
            } else if (status.aasm_state === "failed") {
              clearInterval(interval);
              toast(MESSAGE_TYPES.error, {
                message: t("common:common.api_responses.error.title"),
              });
            }
          } catch (error) {
            clearInterval(interval);
            toast(MESSAGE_TYPES.error, {
              message: t("common:common.api_responses.error.title"),
            });
          }
        }, 3000);
      } else {
        toast(MESSAGE_TYPES.error, {
          message: t("common:common.api_responses.error.title"),
        });
      }
    } catch (error) {
      toast(MESSAGE_TYPES.error, {
        message: t("common:common.api_responses.error.title"),
      });
    }
    onClose();
  };

  const togglePreview = () => {
    setPreviewOpen((prev) => !prev);
  };

  const editorStyle = (index) => ({
    display: selectedTab === index ? "block" : "none",
  });

  const validateInputEditEs = (value) => setContentEs(value);
  const validateInputEditEn = (value) => setContentEn(value);
  const validateInputEditPt = (value) => setContentPt(value);


  const toggleAudienceCollaboratorModal = () => setAudienceCollaboratorModalOpen((prev) => !prev);
  const toggleModalAudience = () => setModalAudienceOpen((prev) => !prev);

  return (
    <Drawer anchor={ "right" } open={ open } onClose={ onClose }>
      <StyledDrawer>
        <StyledHeader>
          <Box display={ "flex" } alignItems={ "center" }>
            <StyledMailIcon />
            <StyledTitle variant={ "h6" }>{t("goals:reminder_modal.title")}</StyledTitle>
          </Box>
          <StyledIconButton onClick={ onClose }>
            <CloseIcon />
          </StyledIconButton>
        </StyledHeader>
        <Box mb={ 2 }>
          <StyledSubtitle>
            {t("goals:reminder_modal.subtitle")}
          </StyledSubtitle>
        </Box>
        <>
          <StyledInputContainer item xs={ 12 }>
            <InputTag
              id={ OBJECT_KEYS.audienceId }
              label={ t("planning:surveys.target_audience") }
              size={ SIZE.small }
              itemsSelected={ inputTagsSelected }
              name={ OBJECT_KEYS.audienceId }
              onChange={ handleTags }
              data={ getFormattedAudiencesTags(audiencesList) }
              disableClearable
              forcePopupIcon={ false }
              onAddButtonField={ toggleAudienceCollaboratorModal }
            />
          </StyledInputContainer>
          {!isLoadingAudienceList && (
            <AudienceByCollaboratorModal
              isOpen={ isAudienceCollaboratorModalOpen }
              onClose={ toggleAudienceCollaboratorModal }
            />
          )}
          {!isLoadingAudienceList && inputTagsSelected[0]?.id && (
            <GoalsTotalAudience
              t={ t }
              audienceSelected={ inputTagsSelected[0] }
              handleCloseModal={ toggleModalAudience }
            />
          )}
          {!isLoadingAudienceList && inputTagsSelected[0]?.id && (
            <ModalAudience
              t={ t }
              isOpen={ isModalAudienceOpen }
              handleCloseModal={ toggleModalAudience }
              audienceSelected={ inputTagsSelected[0] }
              refetchAudienceData={ () => dispatch(getAudience(inputTagsSelected[0].id)) }
            />
          )}
        </>
        <StyledForm component={ "form" }>
          <Tabs value={ selectedTab } onChange={ handleTabChange } aria-label={ "language tabs" } variant={ "fullWidth" }>
            <Tab label={ t("goals:reminder_modal.email_content_es") } />
            <Tab label={ t("goals:reminder_modal.email_content_en") } />
            <Tab label={ t("goals:reminder_modal.email_content_pt") } />
          </Tabs>

          <Box mt={ 2 }>
            <div style={ editorStyle(0) }>
              <StyledTextField
                value={ subjectEs }
                onChange={ (e) => setSubjectEs(e.target.value) }
                variant={ "outlined" }
                fullWidth
                label={ t("goals:reminder_modal.subject_label_es") }
                required
              />
            </div>
            <div style={ editorStyle(1) }>
              <StyledTextField
                value={ subjectEn }
                onChange={ (e) => setSubjectEn(e.target.value) }
                variant={ "outlined" }
                fullWidth
                label={ t("goals:reminder_modal.subject_label_en") }
                required
              />
            </div>
            <div style={ editorStyle(2) }>
              <StyledTextField
                value={ subjectPt }
                onChange={ (e) => setSubjectPt(e.target.value) }
                variant={ "outlined" }
                fullWidth
                label={ t("goals:reminder_modal.subject_label_pt") }
                required
              />
            </div>
            <StyledTypography>{t("goals:reminder_modal.body")}</StyledTypography>
            <div style={ { ...editorStyle(0), marginTop: "10px" } }>
              <InputEditor
                validateInputEdit={ validateInputEditEs }
              />
            </div>
            <div style={ { ...editorStyle(1), marginTop: "10px" } }>
              <InputEditor
                validateInputEdit={ validateInputEditEn }
              />
            </div>
            <div style={ { ...editorStyle(2), marginTop: "10px" } }>
              <InputEditor
                validateInputEdit={ validateInputEditPt }
              />
            </div>
          </Box>

          <StyledPreviewBox onClick={ togglePreview }>
            <Box display={ ALIGN_ITEMS.flex } alignItems={ "center" }>
              <PreviewIcon />
              <StyledPreview>{t("goals:reminder_modal.preview")}</StyledPreview>
            </Box>
            <IconButton>
              {isPreviewOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </StyledPreviewBox>

          <Collapse in={ isPreviewOpen }>
            <StyledPreviewContent>
              <div dangerouslySetInnerHTML={ { __html: previewHtml } } />
            </StyledPreviewContent>
          </Collapse>

          <Box mt={ 2 } display={ ALIGN_ITEMS.flex } flexDirection={ "column" } gap={ 1 }>
            <FormControlLabel
              control={ (
                <StyledCheckbox
                  checked={ sendToLeaders }
                  onChange={ (e) => setSendToLeaders(e.target.checked) }
                />
              ) }
              label={ t("goals:reminder_modal.send_to_leaders") }
            />
            <FormControlLabel
              control={ (
                <StyledCheckbox
                  checked={ sendToCollaborators }
                  onChange={ (e) => setSendToCollaborators(e.target.checked) }
                />
              ) }
              label={ t("goals:reminder_modal.send_to_collaborators") }
            />
          </Box>

          <StyledButtonContainer>
            <StyledCancelButton variant={ VARIANT.outlined } onClick={ onClose }>
              {t("goals:reminder_modal.cancel_button")}
            </StyledCancelButton>
            <StyledSendButton variant={ VARIANT.contained } onClick={ handleSend }>
              {t("goals:reminder_modal.send_button")}
            </StyledSendButton>
          </StyledButtonContainer>
        </StyledForm>
      </StyledDrawer>
    </Drawer>
  );
};

ReminderModal.propTypes = {
  open: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
};

export default ReminderModal;
