import configAxios from "../../configAxios";
import errorHandler from "../../errorHandler";

import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_PROCESS_AUDIENCE,
  PROCESS_AUDIENCE,
  PROCESS_AUDIENCE_LOADING,
  PROCESS_AUDIENCE_ERROR,
  UPDATE_PROCESS_AUDIENCE,
  UPDATE_PROCESS_AUDIENCE_LOADING,
  UPDATE_PROCESS_AUDIENCE_ERROR,
  GET_AUDIENCE,
  GET_AUDIENCE_LOADING,
  GET_AUDIENCE_ERROR,
} from "../../actionTypes/audience";

const URL = {
  main: "/audiences",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateProcess = () => (dispatch) => {
  dispatch({
    type: RESET_PROCESS_AUDIENCE,
  });
};

export const getList = () => async (dispatch) => {
  dispatch({ type: GET_LIST_LOADING });
  try {
    const response = await configAxios.get(URL.main);
    dispatch({
      type: GET_LIST,
      payload: response.data.audiences,
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR);
  }
};

export const getAudience = (id) => async (dispatch) => {
  dispatch({ type: GET_AUDIENCE_LOADING });
  try {
    const response = await configAxios.get(`${URL.main}/${id}`);
    dispatch({
      type: GET_AUDIENCE,
      payload: response.data.audience,
    });
    return response.data.audience;
  } catch (error) {
    errorHandler(error, dispatch, GET_AUDIENCE_ERROR);
  }
};

export const create = (data) => async (dispatch) => {
  dispatch({
    type: PROCESS_AUDIENCE_LOADING,
  });
  try {
    const response = await configAxios.post(URL.main, data);
    dispatch({
      type: PROCESS_AUDIENCE,
      payload: response.data.audience,
    });
  } catch (error) {
    errorHandler(error, dispatch, PROCESS_AUDIENCE_ERROR);
  }
};

export const update = (id, data) => async (dispatch) => {
  dispatch({ type: UPDATE_PROCESS_AUDIENCE_LOADING });
  try {
    const response = await configAxios.put(`${URL.main}/${id}`, data);
    dispatch({
      type: UPDATE_PROCESS_AUDIENCE,
      payload: response.data.audience,
    });
  } catch (error) {
    errorHandler(error, dispatch, UPDATE_PROCESS_AUDIENCE_ERROR);
  }
};
