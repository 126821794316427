import PropTypes from "prop-types"; // Import PropTypes
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import CardResult from "components/CardResult";
import SatisfactionIcon from "assets/images/engagement/satisfaction.svg";
import ParticipationIcon from "assets/images/engagement/participa.svg";
import NPSIcon from "assets/images/engagement/nps-total.svg";
import { getPercent } from "common/utils";
import { CARD_CLASS } from "common/constants/charts";
import { getParticipation } from "../../functions";
import {
  StyledGridContainer,
  StyledParticipationResult,
  StyledParticipationTitle,
} from "../GeneralContent/styles";

const Participation = (props) => {
  const {
    t, participationData,
    isLoadingParticipation, npsData,
    isLoadingNps, engagementParticipation,
    isLoadingEngagementParticipation,
  } = props;

  return (
    <StyledGridContainer container spacing={ 2 }>
      <Grid item xs={ 12 } md={ 4 }>
        <CardResult
          title={ t("generalSatisfaction") }
          icon={ SatisfactionIcon }
          result={ getPercent(participationData || 0) }
          isEmptyResult={ participationData === null }
          hasSymbol
          isLoading={ isLoadingParticipation }
        />
      </Grid>
      <Grid item xs={ 12 } md={ 4 }>
        <CardResult
          title={ t("participation") }
          icon={ ParticipationIcon }
          color={ CARD_CLASS.blue }
          isLoading={ isLoadingEngagementParticipation }
        >
          {engagementParticipation ? (
            <StyledParticipationResult>
              <Typography variant={ "h2" }>
                {`${engagementParticipation?.surveys_answered || 0}/`}
                <span>{engagementParticipation?.surveys_sent}</span>
              </Typography>
              <StyledParticipationTitle variant={ "h3" }>
                {getParticipation(
                  engagementParticipation?.surveys_answered,
                  engagementParticipation?.surveys_sent,
                )}
              </StyledParticipationTitle>
            </StyledParticipationResult>
          ) : (
            <StyledParticipationResult>
              <Typography variant={ "h2" }>
                {"0/"}
                <span>{"0"}</span>
              </Typography>
              <StyledParticipationTitle variant={ "h3" }>
                {"0%"}
              </StyledParticipationTitle>
            </StyledParticipationResult>
          )}
        </CardResult>
      </Grid>
      <Grid item xs={ 12 } md={ 4 }>
        <CardResult
          title={ t("nps") }
          icon={ NPSIcon }
          color={ CARD_CLASS.green }
          result={ getPercent(npsData || 0) }
          text={ t("npsDescription") }
          isEmptyResult={ npsData === null }
          isLoading={ isLoadingNps }
        />
      </Grid>
    </StyledGridContainer>
  );
};

Participation.propTypes = {
  t: PropTypes.func.isRequired,
  participationData: PropTypes.number,
  npsData: PropTypes.number,
  engagementParticipation: PropTypes.shape({ 
    surveys_answered: PropTypes.number,
    surveys_sent: PropTypes.number,
  }),
  isLoadingEngagementParticipation: PropTypes.bool.isRequired,
  isLoadingParticipation: PropTypes.bool.isRequired,
  isLoadingNps: PropTypes.bool.isRequired,
};

export default Participation;
