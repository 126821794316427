import { Doughnut, Chart } from "react-chartjs-2";
import PropTypes from "prop-types";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import TooltipIcon from "components/TooltipIcon";
import { PERCENTAGE, VARIANT } from "common/constants";
import { CARD_CLASS, DOUGHNUT_ROUNDED } from "common/constants/charts";
import CircleLoader from "components_refactor/CircleLoader";
import {
  optionsDoughnut, roundCorners, textResult, getResult,
} from "./functions";
import {
  StyledCard, StyledCardContent, StyledTitle, StyledIcon,
} from "./styles";

const CardResult = (props) => {
  const {
    title, icon, color, maxResult, result,
    children, hasSymbol, text, isEmptyResult,
    isLoading,
  } = props;

  roundCorners(Chart);
  textResult(Chart);

  return (
    <StyledCard
      data-testid={ "card-result-component" }
      key={ `card-${title}` }
      color={ color }
      elevation={ 0 }
    >
      <StyledCardContent>
        <Grid container spacing={ 2 }>
          <Grid item xs={ children ? 6 : 8 } lg={ children ? 7 : 9 }>
            <div>
              {icon && (
                <Box pb={ 2 }>
                  <StyledIcon src={ icon } alt={ title } />
                </Box>
              )}
              <StyledTitle variant={ VARIANT.h4 }>
                {title}
                {text && (
                  <TooltipIcon
                    title={ text }
                    isHelpIcon
                  />
                )}
              </StyledTitle>
            </div>
          </Grid>
          <Grid item xs={ children ? 6 : 4 } lg={ children ? 5 : 3 }>
            {isLoading ? <CircleLoader size={ 40 } /> : (children || (
              <Doughnut
                data={ getResult(result, maxResult, title, color) }
                options={ optionsDoughnut(result, hasSymbol ? "%" : "", isEmptyResult) }
                width={ DOUGHNUT_ROUNDED.width.min }
                height={ DOUGHNUT_ROUNDED.height.min }
              />
            ))}
          </Grid>
        </Grid>
      </StyledCardContent>
    </StyledCard>
  );
};

CardResult.propTypes = {
  title: PropTypes.string.isRequired,
  icon: PropTypes.string,
  color: PropTypes.string,
  maxResult: PropTypes.number,
  result: PropTypes.number,
  children: PropTypes.object,
  hasSymbol: PropTypes.bool,
  text: PropTypes.string,
  isEmptyResult: PropTypes.bool,
  isLoading: PropTypes.bool,
};

CardResult.defaultProps = {
  icon: null,
  color: CARD_CLASS.purple,
  maxResult: PERCENTAGE.max,
  result: PERCENTAGE.min,
  children: null,
  hasSymbol: false,
  text: null,
  isEmptyResult: false,
  isLoading: false,
};

export default CardResult;
