import styled from "styled-components";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Divider from "@mui/material/Divider";
import Charts from "theme/charts";
import pallete from "theme/palette";
import { hexToRgbA } from "common/utils";

export const StyledCardContainer = styled.div`
  margin-top: ${(props) => (props.hasMargin ? "10px" : "0px")};
  height: 100%;
  @media (max-width: 600px) {
    padding: 10px 0;
  }
`;

export const StyledCard = styled(Card)`
  width: 100%;
  &.MuiPaper-root {
    height: 100%;
    background: ${(props) => hexToRgbA(props.color, Charts.opacity.fillOpacity)};
    box-shadow: none;
  }
  @media (max-width: 600px) {
    width: 100%;
  }
`;

export const StyledCardContent = styled(CardContent)`
  &.MuiCardContent-root {
    padding: 12px;
    min-height: 250px;
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  .MuiTypography-h5 {
    font-size: 20px;
    font-weight: 700;
  }
`;

export const StyledIcon = styled.img`
  width: ${(props) => props.size}px;
  height: ${(props) => props.size}px;
`;

export const StyledTitleItem = styled.div`
  font-size: 18px;
  font-weight: bold;
  text-align: left;
`;

export const StyledOneResult = styled.div`
  margin: 50px 0;
`;

export const StyledDivider = styled(Divider)`
  &.MuiDivider-root {
    margin: 15px 0 10px;
    border-width: 0 0 thin 0;
    border-color: ${(props) => props.color};
  }
`;

export const StyledGrid = styled(Grid)`
  border-right: ${(props) => (props.borderColor ? `1px solid ${props.borderColor}` : "none") };
`;

export const StyledDetail = styled.div`
  display: ${(props) => (props.isHorizontal ? "flex" : "block")};
  text-align: center;
  align-items: center;
  justify-content: center;
  margin: ${(props) => (props.isHorizontal ? "15px 0px" : "5px 0px")};
  img {
    width: ${(props) => props.size}px;
    height: ${(props) => props.size}px;
  }
  .MuiTypography-body1 {
    font-size: ${(props) => (props.isHorizontal ? "18" : "16")}px;
    font-weight: ${(props) => (props.isHorizontal ? "700" : "400")};

    margin-left: ${(props) => (props.isHorizontal ? "20" : "0")}px;

    margin-bottom: ${(props) => (props.isHorizontal ? "0" : "6")}px;
  }
  h5 {
    font-size: 14px;
    font-weight: 400;
    margin-left: ${(props) => (props.isHorizontal ? "20" : "0")}px;
  }
`;

export const StyledButton = styled.div`
  button {
    font-size: ${(props) => (props.isHorizontal ? "18" : "16")}px;
    font-weight: ${(props) => (props.isHorizontal ? "700" : "400")};
    margin-left: ${(props) => (props.isHorizontal ? "20" : "0")}px;
    margin-bottom: 0;
    text-align: left;
    text-decoration: underline;
    padding: 0;
    line-height: inherit;
    vertical-align: inherit;
    transition: all 0.2s ease-in-out;

    &:hover {
      background-color: transparent;
      color: ${pallete.text.link};
      font-weight: 700;
      text-decoration: underline;
    }
  }
`;
