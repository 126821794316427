import { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useCookies } from "react-cookie";
import CryptoJS from "crypto-js";
import { useTranslation } from "react-i18next";
import {
  CRYPTO_KEY,
  INDEX,
  LOCAL_STORAGE_NAMES,
  ROLES,
} from "common/constants";
import { isEmpty, isEqual } from "common/helpers";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import { getList as getCountriesList } from "redux/actions/common/countryActions";
import { getList as getOrgUnitsTypes } from "redux/actions/organizationUnits/orgUnitsTypesActions";
import { getList as getOrgUnits } from "redux/actions/organizationUnits/orgUnitsActions";
import { getList as getTypeOfContract } from "redux/actions/typeOfContractActions";
import { getList as getPositions } from "redux/actions/position/positionActions";
// import { getList as getRoles } from "redux/actions/common/roleActions";
import { getList as getCurrencies } from "redux/actions/common/currencyActions";
// import { getList as getFollowUpList } from "redux/actions/followUpActions";
import {
  dispatchIfNotLocalStorage,
  setInLocalStorage,
  setInLocalStorageAsync,
  getItemFromLocalStorage,
  isValidAdmin,
  isValidRole,
} from "common/utils";
import useSignInService from "hooks/auth/useSignInService";
import {
  getPages,
  MENU_STRUCTURE,
  swapItems,
} from "layouts/Main/components/SidebarRefactored/functions";
import auth from "./api";

const SessionContext = createContext(null);

// TODO remove once new cache management is created
const SessionProvider = ({ children }) => {
  const { t, i18n } = useTranslation("common");
  const [user, setUser] = useState(
    localStorage.user ? JSON.parse(localStorage.user) : null,
  );
  const { userResult, logout, isLogout } = useSignInService();

  const { list: listOrgUnitsTypes } = useSelector(
    ({ orgUnitsTypesReducer }) => orgUnitsTypesReducer,
  );
  const { list: listTypeOfContract } = useSelector(
    ({ typeOfContractReducer }) => typeOfContractReducer,
  );

  const { list: listCountries } = useSelector(
    ({ countryReducer }) => countryReducer,
  );
  const { list: listOrgUnits } = useSelector(
    ({ orgUnitsReducer }) => orgUnitsReducer,
  );
  const { list: listPositions } = useSelector(
    ({ positionReducer }) => positionReducer,
  );
  const { list: listCurrencies } = useSelector(
    ({ currencyReducer }) => currencyReducer,
  );
  const { headers } = useSelector(({ signInReducer }) => signInReducer);

  const [cookies] = useCookies(["language"]);

  const dispatch = useDispatch();

  useEffect(() => {
    if (
      user?.company
      && localStorage?.user
      && !isEqual(localStorage.getItem(LOCAL_STORAGE_NAMES.logOutError), "true")
    ) {
      // dispatch(getRoles());

      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.currencies,
        getCurrencies(),
        dispatch,
      );

      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.orgUnits,
        getOrgUnits(),
        dispatch,
      );

      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.typeOfContract,
        getTypeOfContract(),
        dispatch,
      );

      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.countries,
        getCountriesList(),
        dispatch,
      );

      dispatchIfNotLocalStorage(
        LOCAL_STORAGE_NAMES.orgUnitsTypes,
        getOrgUnitsTypes(),
        dispatch,
      );

      const isOnboardingAdmin = isValidRole(user?.userCookies, ROLES.ONBOARDING_ADMIN);
      const isSuccessionsAdmin = isValidRole(user?.userCookies, ROLES.SUCCESSIONS_ADMIN);
      const isAdmin = isValidAdmin(user?.userCookies);

      if (isAdmin || isOnboardingAdmin || isSuccessionsAdmin) {
        dispatchIfNotLocalStorage(
          LOCAL_STORAGE_NAMES.positions,
          getPositions(),
          dispatch,
        );
      }
      // dispatch(getFollowUpList());
      if (
        isEmpty(getItemFromLocalStorage(LOCAL_STORAGE_NAMES.userMenuOptions))
      ) {
        const rolesName = user.roles.map((rol) => rol.name);
        const swappedMenu = rolesName.includes(ROLES.MANAGER)
          ? swapItems(MENU_STRUCTURE, INDEX.three, INDEX.four, INDEX.five)
          : MENU_STRUCTURE;
        if (user.company?.visible_items) {
          const pages = getPages(
            user.company.visible_items,
            user.follow_up_processes,
            user.email,
            user.company.slug,
            rolesName,
            swappedMenu,
          );
          setInLocalStorageAsync(
            LOCAL_STORAGE_NAMES.userMenuOptions,
            pages.updatedMenu,
          );
          setInLocalStorageAsync(
            LOCAL_STORAGE_NAMES.userPathOptions,
            pages.allowedRoutes,
          );
        }
      }
    }
  }, [dispatch, user]);

  useEffect(() => {
    setInLocalStorage(LOCAL_STORAGE_NAMES.typeOfContract, listTypeOfContract);
    setInLocalStorage(LOCAL_STORAGE_NAMES.countries, listCountries);
    setInLocalStorage(LOCAL_STORAGE_NAMES.orgUnitsTypes, listOrgUnitsTypes);
    setInLocalStorage(LOCAL_STORAGE_NAMES.orgUnits, listOrgUnits);
    setInLocalStorage(LOCAL_STORAGE_NAMES.currencies, listCurrencies);
    setInLocalStorage(LOCAL_STORAGE_NAMES.positions, listPositions);
  }, [
    listTypeOfContract,
    listCountries,
    listOrgUnitsTypes,
    listOrgUnits,
    listCurrencies,
    listPositions,
  ]);

  useEffect(() => {
    if (cookies.language) {
      i18n.changeLanguage(cookies.language);
    }
    if (!userResult && !localStorage.user) {
      return;
    }

    let data = null;
    if (localStorage.user) {
      data = JSON.parse(localStorage.user);
      if (data?.user) {
        data = null;
        localStorage.clear();
      }
      setUser(data ? { ...data } : null);
    }

    if (!userResult?.user?.userCookies && userResult?.user?.roles.length > 0) {
      // in order to "keep save" the roles names
      // we decided to encrypt the string with this format:
      // company_id + user.id + role.name

      // keep this code close to the
      const userData = userResult.user;
      const companyId = userResult.user.company_id;
      const userId = userResult.user.id;
      const roles = userResult?.user?.roles;
      let userCookies = [];
      userCookies = roles.map((role) => {
        const roleName = role.name;
        const validString = `${companyId}-${userId}-${roleName}`;
        const encrypted = CryptoJS.AES.encrypt(validString, CRYPTO_KEY);
        const cryptText = encrypted.toString();
        return cryptText;
      });
      userData.userCookies = userCookies;
      userData.sessionCookies = headers;
      localStorage.setItem(LOCAL_STORAGE_NAMES.user, JSON.stringify(userData));
      setUser({ ...userData });
    }
    if (
      (!userResult && user && !user?.userCookies)
      || isEqual(localStorage.getItem(LOCAL_STORAGE_NAMES.logOutError), "true")
    ) {
      const toastMessage = isEqual(
        localStorage.getItem(LOCAL_STORAGE_NAMES.logOutError),
        "true",
      )
        ? {
          title: t("common.api_responses.error.unauthorized"),
          message: t("common.api_responses.error.unauthorized_message"),
        }
        : {
          title: t("common.api_responses.info.token_expired"),
          message: t("common.api_responses.info.token_expired_message"),
        };
      toast(MESSAGE_TYPES.info, toastMessage);

      auth.signOut();
      localStorage.clear();
      setUser(null);
      logout();
    }
    // eslint-disable-next-line
  }, [i18n, cookies, userResult]);

  const state = { user };
  const actions = {
    signOut: () => {
      auth.signOut();
      localStorage.clear();
      setUser(null);
      logout();
    },
  };

  return (
    // eslint-disable-next-line react/jsx-no-constructed-context-values
    <SessionContext.Provider value={ { state, actions, isLogout } }>
      {children}
    </SessionContext.Provider>
  );
};

export { SessionProvider, SessionContext };
