import { getGoalsParticipationList } from "redux/actions/goalsActions";
import { mainDownloadExcel } from "common/utils";

const goalsParticipationData = async (t, filterQuery) => {
  const responseData = await getGoalsParticipationList(filterQuery);

  return responseData.collaborators.map((collaborator) => ({
    [t("goals:participation.file.full_name")]: collaborator.full_name,
    [t("goals:participation.file.email")]: collaborator.email,
    [t("goals:participation.file.position_name")]: collaborator.position_name,
    [t("goals:participation.file.area_name")]: collaborator.area_name,
    [t("goals:participation.file.manager_name")]: collaborator.manager_name,
    [t("goals:participation.file.manager_email")]: collaborator.manager_email,
    [t("goals:participation.file.active_goals")]: collaborator.active_goals,
  }));
};

const downloadGoalsParticipationExcel = async (t, filterQuery) => {
  const data = await goalsParticipationData(t, filterQuery);

  mainDownloadExcel(data, t("goals:participation.file.title"));
};

export default downloadGoalsParticipationExcel;
