import ReactDOM from "react-dom";
import { QueryClient, QueryClientProvider } from "react-query";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { I18nextProvider } from "react-i18next";
import i18next from "i18next";
import * as serviceWorker from "./serviceWorker";
import App from "./App";

import store from "./store";

// Lang
import commonEn from "./translations/en/common.json";
import commonEs from "./translations/es/common.json";
import commonPt from "./translations/pt/common.json";
import collaboratorsEn from "./translations/en/collaborators/collaborators.json";
import collaboratorsEs from "./translations/es/collaborators/collaborators.json";
import collaboratorsPt from "./translations/pt/collaborators/collaborators.json";
import HRAnalyticsEn from "./translations/en/HRAnalytics/hrAnalytics.json";
import HRAnalyticsEs from "./translations/es/HRAnalytics/hrAnalytics.json";
import HRAnalyticsPt from "./translations/pt/HRAnalytics/hrAnalytics.json";
import OnboardingEn from "./translations/en/Onboarding/onboarding.json";
import OnboardingEs from "./translations/es/Onboarding/onboarding.json";
import OnboardingPt from "./translations/pt/Onboarding/onboarding.json";
import templatesEn from "./translations/en/templates/templates.json";
import templatesEs from "./translations/es/templates/templates.json";
import templatesPt from "./translations/pt/templates/templates.json";
import accountEn from "./translations/en/account/account.json";
import accountEs from "./translations/es/account/account.json";
import accountPt from "./translations/pt/account/account.json";
import candidatesEn from "./translations/en/candidates/candidates.json";
import candidatesEs from "./translations/es/candidates/candidates.json";
import candidatesPt from "./translations/pt/candidates/candidates.json";
import performanceEn from "./translations/en/performance/performance.json";
import performanceEs from "./translations/es/performance/performance.json";
import performancePt from "./translations/pt/performance/performance.json";
import potentialEn from "./translations/en/Potential/potential.json";
import potentialEs from "./translations/es/Potential/potential.json";
import potentialPt from "./translations/pt/Potential/potential.json";
import bulkUploadEn from "./translations/en/bulkUpload/bulkUpload.json";
import bulkUploadEs from "./translations/es/bulkUpload/bulkUpload.json";
import bulkUploadPt from "./translations/pt/bulkUpload/bulkUpload.json";
import authenticationEn from "./translations/en/authentication/authentication.json";
import authenticationEs from "./translations/es/authentication/authentication.json";
import authenticationPt from "./translations/pt/authentication/authentication.json";
import engagementEn from "./translations/en/engagement/engagement.json";
import engagementEs from "./translations/es/engagement/engagement.json";
import engagementPt from "./translations/pt/engagement/engagement.json";
import goalsEn from "./translations/en/goals/goals.json";
import goalsEs from "./translations/es/goals/goals.json";
import goalsPt from "./translations/pt/goals/goals.json";
import developmentPlanEn from "./translations/en/developmentPlan/developmentPlan.json";
import developmentPlanEs from "./translations/es/developmentPlan/developmentPlan.json";
import developmentPlanPt from "./translations/pt/developmentPlan/developmentPlan.json";
import generalAdministratorEn from "./translations/en/generalAdministrator/generalAdministrator.json";
import generalAdministratorEs from "./translations/es/generalAdministrator/generalAdministrator.json";
import generalAdministratorPt from "./translations/pt/generalAdministrator/generalAdministrator.json";
import feedbackEn from "./translations/en/account/feedback.json";
import feedbackEs from "./translations/es/account/feedback.json";
import feedbackPt from "./translations/pt/account/feedback.json";
import surveyProcessEn from "./translations/en/surveyProcess/surveyProcess.json";
import surveyProcessEs from "./translations/es/surveyProcess/surveyProcess.json";
import surveyProcessPt from "./translations/pt/surveyProcess/surveyProcess.json";
import planningEn from "./translations/en/planning/planning.json";
import planningEs from "./translations/es/planning/planning.json";
import planningPt from "./translations/pt/planning/planning.json";
import formValidationEn from "./translations/en/formValidations/formValidations.json";
import formValidationEs from "./translations/es/formValidations/formValidations.json";
import formValidationPt from "./translations/pt/formValidations/formValidations.json";
import talentDrainEn from "./translations/en/talentDrain/talentDrain.json";
import talentDrainEs from "./translations/es/talentDrain/talentDrain.json";
import talentDrainPt from "./translations/pt/talentDrain/talentDrain.json";
import timeOffEn from "./translations/en/timeOff/timeOff.json";
import timeOffEs from "./translations/es/timeOff/timeOff.json";
import timeOffPt from "./translations/pt/timeOff/timeOff.json";
import tablesEn from "./translations/en/tables/tables.json";
import tablesEs from "./translations/es/tables/tables.json";
import tablesPt from "./translations/pt/tables/tables.json";
import OnboardingFollowUpEn from "./translations/en/onboardingFollowUp/onboardingFollowUp.json";
import OnboardingFollowUpEs from "./translations/es/onboardingFollowUp/onboardingFollowUp.json";
import OnboardingFollowUpPt from "./translations/pt/onboardingFollowUp/onboardingFollowUp.json";
import AudiencesEn from "./translations/en/audiences/audiences.json";
import AudiencesEs from "./translations/es/audiences/audiences.json";
import AudiencesPt from "./translations/pt/audiences/audiences.json";
import PreboardingEn from "./translations/en/Preboarding/preboarding.json";
import PreboardingEs from "./translations/es/Preboarding/preboarding.json";
import PreboardingPt from "./translations/pt/Preboarding/preboarding.json";

import ProfileEn from "./translations/en/profile/profile.json";
import ProfileEs from "./translations/es/profile/profile.json";
import ProfilePt from "./translations/pt/profile/profile.json";
import FormsButtonsEn from "./translations/en/forms/forms_buttons.json";
import FormsButtonsEs from "./translations/es/forms/forms_buttons.json";
import FormsButtonsPt from "./translations/pt/forms/forms_buttons.json";
import HomeEn from "./translations/en/home/home.json";
import HomeEs from "./translations/es/home/home.json";
import HomePt from "./translations/pt/home/home.json";
import GoodLeaderEn from "./translations/en/goodLeader/goodLeader.json";
import GoodLeaderEs from "./translations/es/goodLeader/goodLeader.json";
import GoodLeaderPt from "./translations/pt/goodLeader/goodLeader.json";
import NalaAnalyticsEn from "./translations/en/nalaAnalytics/nalaAnalytics.json";
import NalaAnalyticsEs from "./translations/es/nalaAnalytics/nalaAnalytics.json";
import NalaAnalyticsPt from "./translations/pt/nalaAnalytics/nalaAnalytics.json";
import nineboxEn from "./translations/en/ninebox/ninebox.json";
import nineboxEs from "./translations/es/ninebox/ninebox.json";
import nineboxPt from "./translations/pt/ninebox/ninebox.json";
import leadershipEn from "./translations/en/leadership/leadership.json";
import leadershipEs from "./translations/es/leadership/leadership.json";
import leadershipPt from "./translations/pt/leadership/leadership.json";
import periodsEn from "./translations/en/periods/periods.json";
import periodsEs from "./translations/es/periods/periods.json";
import periodsPt from "./translations/pt/periods/periods.json";
import successionsEn from "./translations/en/successions/successions.json";
import successionsEs from "./translations/es/successions/successions.json";
import successionsPt from "./translations/pt/successions/successions.json";
import participationEn from "./translations/en/participation/participation.json";
import participationEs from "./translations/es/participation/participation.json";
import participationPt from "./translations/pt/participation/participation.json";

i18next
  .init({
    interpolation: { escapeValue: false },
    lng: "es",
    fallbackLng: "es",
    resources: {
      en: {
        common: commonEn,
        account: accountEn,
        feedback: feedbackEn,
        collaborators: collaboratorsEn,
        Onboarding: OnboardingEn,
        templates: templatesEn,
        candidates: candidatesEn,
        HRAnalytics: HRAnalyticsEn,
        performance: performanceEn,
        potential: potentialEn,
        bulkUpload: bulkUploadEn,
        authentication: authenticationEn,
        engagement: engagementEn,
        goals: goalsEn,
        developmentPlan: developmentPlanEn,
        administrator: generalAdministratorEn,
        surveys: surveyProcessEn,
        planning: planningEn,
        formValidations: formValidationEn,
        talentDrain: talentDrainEn,
        timeOff: timeOffEn,
        tables: tablesEn,
        onboardingFollowUp: OnboardingFollowUpEn,
        audiences: AudiencesEn,
        preboarding: PreboardingEn,
        profile: ProfileEn,
        buttons: FormsButtonsEn,
        home: HomeEn,
        goodLeader: GoodLeaderEn,
        nalaAnalytics: NalaAnalyticsEn,
        ninebox: nineboxEn,
        leadership: leadershipEn,
        periods: periodsEn,
        successions: successionsEn,
        participation: participationEn,
      },
      es: {
        common: commonEs,
        account: accountEs,
        feedback: feedbackEs,
        collaborators: collaboratorsEs,
        candidates: candidatesEs,
        HRAnalytics: HRAnalyticsEs,
        Onboarding: OnboardingEs,
        templates: templatesEs,
        performance: performanceEs,
        potential: potentialEs,
        bulkUpload: bulkUploadEs,
        authentication: authenticationEs,
        engagement: engagementEs,
        goals: goalsEs,
        developmentPlan: developmentPlanEs,
        administrator: generalAdministratorEs,
        surveys: surveyProcessEs,
        planning: planningEs,
        formValidations: formValidationEs,
        talentDrain: talentDrainEs,
        timeOff: timeOffEs,
        tables: tablesEs,
        onboardingFollowUp: OnboardingFollowUpEs,
        audiences: AudiencesEs,
        preboarding: PreboardingEs,
        profile: ProfileEs,
        buttons: FormsButtonsEs,
        home: HomeEs,
        goodLeader: GoodLeaderEs,
        nalaAnalytics: NalaAnalyticsEs,
        ninebox: nineboxEs,
        leadership: leadershipEs,
        periods: periodsEs,
        successions: successionsEs,
        participation: participationEs,
      },
      pt: {
        common: commonPt,
        account: accountPt,
        feedback: feedbackPt,
        collaborators: collaboratorsPt,
        Onboarding: OnboardingPt,
        templates: templatesPt,
        candidates: candidatesPt,
        HRAnalytics: HRAnalyticsPt,
        performance: performancePt,
        potential: potentialPt,
        bulkUpload: bulkUploadPt,
        authentication: authenticationPt,
        engagement: engagementPt,
        goals: goalsPt,
        developmentPlan: developmentPlanPt,
        administrator: generalAdministratorPt,
        surveys: surveyProcessPt,
        planning: planningPt,
        formValidations: formValidationPt,
        talentDrain: talentDrainPt,
        timeOff: timeOffPt,
        tables: tablesPt,
        onboardingFollowUp: OnboardingFollowUpPt,
        audiences: AudiencesPt,
        preboarding: PreboardingPt,
        profile: ProfilePt,
        buttons: FormsButtonsPt,
        home: HomePt,
        goodLeader: GoodLeaderPt,
        nalaAnalytics: NalaAnalyticsPt,
        ninebox: nineboxPt,
        leadership: leadershipPt,
        periods: periodsPt,
        successions: successionsPt,
        participation: participationPt,
      },
    },
  })
  .then(() => {});

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      // if the query has any errors by default it tries 3 times,
      // use retry to make it try only 1 time or false
      retry: 1,
      retryDelay: 1000,
    },
  },
});

ReactDOM.render(
  <I18nextProvider i18n={ i18next }>
    <CookiesProvider>
      <Provider store={ store }>
        <QueryClientProvider client={ queryClient }>
          <App />
        </QueryClientProvider>
      </Provider>
    </CookiesProvider>
  </I18nextProvider>,
  document.getElementById("root"),
);

serviceWorker.unregister();
