import { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import clsx from "clsx";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import Grid from "@mui/material/Grid";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import AccordionSummary from "@mui/material/AccordionSummary";
import {
  COLORS_NAME,
  SCROLL_BEHAVIOR,
  BORDER_ACCORDION_TYPE,
} from "common/constants";
import { isNullOrUndefined } from "common/validators";
import { getAccordionIndex, getAccordionData } from "./functions";
import { useStyles, AccordionStyled, AccordionDetailsStyled } from "./styles";

const Accordion = (props) => {
  const {
    data,
    type,
    isPadding,
    background,
    isBackgroundContent,
    isDefaultIconStyle,
    activePanel,
    accordionSelected,
    setSelectedPanel,
    iconLeft,
  } = props;
  const classes = useStyles();
  const [expanded, setExpanded] = useState(`panel${activePanel}`);

  const accordionRefs = useRef([]);

  const handleChange = (index, accordionData) => (event, isExpanded) => {
    setExpanded(isExpanded ? `panel${index}` : false);
    setSelectedPanel && setSelectedPanel(isExpanded ? accordionData : []);
  };

  useEffect(() => {
    !isNullOrUndefined(activePanel) && setExpanded(`panel${activePanel}`);
  }, [activePanel]);

  const handleAccordionClick = (index) => {
    if (accordionRefs.current[index]) {
      accordionRefs.current[index].scrollIntoView({
        behavior: SCROLL_BEHAVIOR.smooth,
      });
    }
  };

  useEffect(() => {
    !_.isUndefined(accordionSelected)
      && handleAccordionClick(accordionSelected);
  }, [accordionSelected]);

  return (
    <div className={ classes.root } data-testid={ "accordion" }>
      {data
        && data.map((result, index) => {
          const accordionIndex = getAccordionIndex(result, index);
          const accordionData = getAccordionData(result);
          return (
            <AccordionStyled
              ref={ (el) => (accordionRefs.current[accordionIndex] = el) }
              key={ result.title }
              expanded={ _.isEqual(expanded, `panel${accordionIndex}`) }
              onChange={ handleChange(accordionIndex, accordionData) }
              className={ clsx(
                _.isEqual(type, BORDER_ACCORDION_TYPE.rounded)
                  ? classes.customAccordion
                  : classes.simpleAccordion,
                isBackgroundContent
                  && background
                  && _.isEqual(background, COLORS_NAME.purple)
                  ? classes.bgPurple
                  : _.isEqual(background, COLORS_NAME.light)
                    ? classes.bgLight
                    : "",
                isBackgroundContent && classes.customExpanded,
              ) }
              square={ _.isEqual(type, BORDER_ACCORDION_TYPE.square) }
            >
              <AccordionSummary
                aria-controls={ `panel${accordionIndex}-content` }
                id={ `panel${accordionIndex}-header` }
                expandIcon={ (
                  <ExpandMoreIcon
                    className={ clsx(isDefaultIconStyle
                      ? classes.defaultIcon
                      : result.customRender
                        ? classes.icon
                        : classes.disabledIcon, !result.customRender ? classes.disabledIcon : "") }
                  />
                ) }
                disabled={ !result.customRender }
                className={ clsx(background && _.isEqual(background, COLORS_NAME.purple)
                  ? classes.bgPurple
                  : _.isEqual(background, COLORS_NAME.light)
                    ? classes.bgLight
                    : "", iconLeft ? classes.iconLeft : "") }
              >
                <Grid
                  container
                  className={ classes.staticProgressBorder }
                  alignItems={ "center" }
                >
                  <Grid item xs={ 12 }>
                    <Box display={ "flex" } alignItems={ "center" }>
                      {result.src && (
                        <Box pr={ 1 }>
                          <Avatar
                            alt={ "Icon" }
                            src={ result.src }
                            className={ classes.smallIcon }
                          />
                        </Box>
                      )}
                      <Box flexGrow={ 1 }>
                        {result.customHeader ? (
                          result.customHeader
                        ) : (
                          <Typography variant={ result.variant }>
                            {result.title}
                          </Typography>
                        )}
                      </Box>
                    </Box>
                  </Grid>
                  {result.text && (
                    <Grid item xs={ 12 }>
                      <Typography variant={ "body1" } className={ classes.details }>
                        {result.text}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </AccordionSummary>
              {result.customRender && (
                <AccordionDetailsStyled
                  className={ isPadding && classes.customDetails }
                >
                  {result.customRender && typeof result.customRender === "function"
                    ? result.customRender()
                    : result.customRender}
                </AccordionDetailsStyled>

              )}
            </AccordionStyled>
          );
        })}
    </div>
  );
};
Accordion.propTypes = {
  data: PropTypes.array.isRequired,
  background: PropTypes.string,
  type: PropTypes.string,
  isPadding: PropTypes.bool,
  isBackgroundContent: PropTypes.bool,
  isDefaultIconStyle: PropTypes.bool,
  activePanel: PropTypes.number,
  accordionSelected: PropTypes.number,
  setSelectedPanel: PropTypes.func,
  iconLeft: PropTypes.bool,
};

export default Accordion;
