import { useQuery, useQueryClient } from "react-query";
import { getGoalById } from "redux/actions/goalsActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

const fetchGoal = async (goalId) => {
  const response = await getGoalById(goalId);
  return response;
};

export const useGoal = (goalId) => {
  const queryClient = useQueryClient();

  const {
    data: goal, isLoading, isError, error,
  } = useQuery(
    ["goal", goalId],
    () => fetchGoal(goalId),
    {
      enabled: !!goalId,
      staleTime: Infinity,
      onError: (queryError) => {
        toast(
          MESSAGE_TYPES.error,
          {
            message: queryError.message || queryError.toString(),
          },
        );
      },
    },
  );

  return {
    goal,
    isLoading,
    isError,
    error,
    queryClient,
  };
};
