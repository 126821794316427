import qs from "qs";
import {
  PARAMS_SERIALIZER_OPTIONS, INDEX,
} from "common/constants";
import { cleanQuery } from "common/utils";
import configAxios from "../configAxios";
import errorHandler from "../errorHandler";
import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_STATE_BY_COLLABORATORS,
  GET_LIST_BY_COLLABORATORS,
  GET_LIST_BY_COLLABORATORS_LOADING,
  GET_LIST_BY_COLLABORATORS_ERROR,
  RESET_STATE_BY_COLLABORATORS_TO_DOWNLOAD,
  GET_LIST_BY_COLLABORATORS_TO_DOWNLOAD,
  GET_LIST_LOADING_BY_COLLABORATORS_TO_DOWNLOAD,
  GET_LIST_ERROR_BY_COLLABORATORS_TO_DOWNLOAD,
} from "../actionTypes/followUp";

const URL = {
  main: "/follow_up_processes",
  collaboratorsFollowUpProcesses: "/employees_follow_up_processes",
  nps: "total_nps",
  engagementAndPerformance: "total_engagement_and_performance",
};

export const resetState = () => (dispatch) => {
  dispatch({
    type: RESET_STATE,
  });
};

export const resetStateByCollaborators = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_BY_COLLABORATORS,
  });
};

export const resetStateByCollaboratorsToDownload = () => (dispatch) => {
  dispatch({
    type: RESET_STATE_BY_COLLABORATORS_TO_DOWNLOAD,
  });
};

export const getList = () => async (dispatch, getState) => {
  const { list } = getState().followUpReducer;

  if (!list?.length) {
    dispatch({
      type: GET_LIST_LOADING,
    });
    try {
      const response = await configAxios.get(URL.main);
      dispatch({
        type: GET_LIST,
        payload: response.data.follow_up_processes || response.data,
      });
    } catch (error) {
      errorHandler(error, dispatch, GET_LIST_ERROR);
    }
  }
};

export const getCollaboratorsFollowUpProcesses = (query, pagination) => async (dispatch) => {
  dispatch({ type: GET_LIST_BY_COLLABORATORS_LOADING });
  try {
    const response = await configAxios.get(URL.collaboratorsFollowUpProcesses, {
      params: { ...query, page: { size: pagination.size, number: pagination.number + INDEX.one } },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    const data = response.data?.employees;
    dispatch({
      type: GET_LIST_BY_COLLABORATORS,
      payload: data,
      meta: response.data?.meta,
      total: response.headers["x-total"],
    });
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_BY_COLLABORATORS_ERROR);
  }
};

export const getCollaboratorsFollowUpProcessesToDownload = (query, total) => async (dispatch) => {
  dispatch({
    type: GET_LIST_LOADING_BY_COLLABORATORS_TO_DOWNLOAD,
  });
  try {
    const response = await configAxios.get(URL.collaboratorsFollowUpProcesses, {
      params: { ...query, page: { size: total, number: INDEX.one } },
      paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
    });
    const data = response.data?.employees;
    dispatch({
      type: GET_LIST_BY_COLLABORATORS_TO_DOWNLOAD,
      payload: data,
    });
    return data;
  } catch (error) {
    errorHandler(error, dispatch, GET_LIST_ERROR_BY_COLLABORATORS_TO_DOWNLOAD);
  }
};

// nps
export const getNps = async (query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${URL.collaboratorsFollowUpProcesses}/${URL.nps}`, {
    params: {
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data.nps;
};

// engagement and performance
export const getEngagementAndPerformance = async (query = null) => {
  const lastQuery = query && { q: cleanQuery(query.q) };
  const response = await configAxios.get(`${URL.collaboratorsFollowUpProcesses}/${URL.engagementAndPerformance}`, {
    params: {
      ...lastQuery,
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data;
};

export const getFollowUpResults = async (
  query = null,
  pageFilter = { size: 10, number: 0 },
  tableSort = null,
) => {
  const lastQuery = query && { q: cleanQuery(query.q) };

  if (tableSort) {
    lastQuery.q = { ...lastQuery.q, s: tableSort };
  }

  const response = await configAxios.get(URL.collaboratorsFollowUpProcesses, {
    params: {
      ...lastQuery,
      page: { size: pageFilter.size, number: pageFilter.number + 1 },
    },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });

  const data = response.data?.employees;
  const total = parseInt(response.data?.meta?.total_collaborators, 10);
  return { data, total };
};
