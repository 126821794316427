import styled from "styled-components";
import palette from "theme/palette";

export const StyledCard = styled.div`
  border: 0.5px solid ${palette.black};
  border-radius: 8px;
  padding: 12px;
  text-align: center;
`;

export const StyledTitle = styled.div`
  text-align: left;
  position: absolute;
  margin-top: -24px;
  margin-left: -2px;
  background-color: white;
  padding: 0 15px 0 10px;
  font-weight: 700;
`;
export const StyledList = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: ${(props) => (props.hasHeight
    ? "125px" : props.hasLink || props.showMore ? "85%" : "110%")};
  @media (max-width: 600px) {
    height: ${(props) => (props.showAll
      ? "0px" : props.hasLink ? "125px" : "142px")};
    display: ${(props) => (props.showAll ? "inline-block" : "flex")};
  }
`;

export const StyledBox = styled.div`
  width: ${(props) => (props.width ? props.width : "100%")};
  border: 0.5px solid ${palette.black};
  border-radius: 8px;
  padding: 12px 12px 25px;
  text-align: center;
  min-height: 180px;
  margin: 0 7px;
  @media (max-width: 600px) {
    width: 100%;
    margin: 12px 0;
  }
`;

export const StyledLinkContent = styled.div`
  a {
    color: ${palette.text.link};
    cursor: pointer;
    text-decoration: underline;
  }
`;

export const StyledHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 10px 10px;
  .MuiTypography-body1 {
    width: 500px;
    font-size: 16px;
    text-align: left;
  }
  @media (max-width: 600px) {
    display: ${(props) => (props.inline ? "inline-block" : "flex")};
    .MuiTypography-body1 {
      width: 100%;
      padding-right: 20px;
      padding-bottom: ${(props) => (props.inline ? 10 : 0)}px;
    }
  }
`;

export const StyledBoxButtons = styled.div`
  display flex;
  column-gap: 10px;
`;
