import { Box, TextField, StepLabel, Select, StepContent } from "@mui/material";
import makeStyles from "@mui/styles/makeStyles";
import styled from "styled-components";
import palette from "theme/palette";
import Typography from "@mui/material/Typography";

export const StyledBox = styled(Box)`
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 15px;
  padding-left: 25px;
  background-color: ${palette.background.lightPurple};
  border-radius: 4px;
  margin-top: 10px;
  margin-left: 25px;
`;

export const StyledTypography = styled(Typography)`
  color: ${palette.text.darkPurple};
`;

export const StyledTextField = styled(TextField)`
  margin-bottom: 15px;
  margin-top: 15px;
  & input[type="number"]::-webkit-outer-spin-button,
  & input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type="number"] {
    -moz-appearance: textfield; // For Firefox to hide the spin buttons
  }
`;

export const StyledSelect = styled(Select)`
  margin-top: 16px;
  width: 100%;

  @media (min-width: 800px) {
    width: 200px;
  }

  @media (min-width: 960px) {
    width: 400px;
  }
`;


export const StyledHeader = styled.div`
  p {
    margin-top:10px;
  }
`;

export const StyledStepLabel = styled(StepLabel)`
  .MuiStepIcon-root {
    color: ${palette.background.purpleBox};
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
  }

  .MuiStepLabel-label {
    margin-left: 20px;
  }
`;

export const StyledContent = styled(StepContent)`
  border-left-color: white;
   padding-left: 65px;
`;

export const StyledEvaluationScaleForm = styled.div`
  margin-top: 20px;
  border-radius: 4px;
  border: 1px dashed ${palette.background.inputTags};
  padding: 16px;
  width: 100%;

  @media (min-width: 600px) {
    width: 80%;
  }

  @media (min-width: 960px) {
    width: 700px;
  }

  @media (min-width: 1280px) {
    width: 800px;
  }
`;


const useStyles = makeStyles(() => ({
  dateInputs: {
    width: "50%",
  },
}));

export default useStyles;
