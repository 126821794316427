import { useState } from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import CardContent from "@mui/material/CardContent";
// import Typography from "@mui/material/Typography";
// import Slider from "@mui/material/Slider";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import RadioButtonController from "components/RadioButtonController";
import InputText from "components/InputText";
import {
  VARIANT,
  ALIGN_ITEMS,
  COMPONENT,
  INDEX,
  // AUTOCOMPLETE,
  // ORIENTATION,
  // SCALE_ORDER,
} from "common/constants";
// import { isEqual, isNumber } from "common/helpers";
// import { StyledSliderContent } from "styledComponents/SliderQuestion";
import { getLabelAnswer, getLabelPlacement, getLabelClassName } from "./functions";
import {
  StyledCard,
  StyledTitle,
  StyledRadioGroup,
  StyledFormControl,
  // StyledAnswerContent,
  // StyledTooltip,
  // StyledHelpIcon,
} from "./styles";

const Question = (props) => {
  const {
    title,
    scale,
    control,
    id,
    handleAnswers,
    value,
    isDisabled,
  } = props;
  const { t, i18n } = useTranslation();
  const { language } = i18n;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const [answer, setAnswer] = useState(null);
  const firstIdScale = scale?.indices[INDEX.zero]?.id;
  const lastIdScale = scale?.indices?.length ? scale.indices.at(-1)?.id : null;
  // const isNumberScale = isNumber(parseInt(scale?.indices[INDEX.zero]?.answer));
  // const verticalIndices = isNumberScale
  //   ? scale?.indices : scale?.indices.slice(INDEX.zero).reverse();

  const handleChange = (event) => {
    setAnswer(event.target.value);
  };

  const descriptionScale = scale ? scale[`description_${language}`] : null;

  const questions = scale?.indices?.map((item) => {
    const label = getLabelAnswer(item.id, item[`answer_${language}`], scale, firstIdScale, lastIdScale);
    const labelPlacementType = getLabelPlacement(scale);
    const labelClassName = getLabelClassName(labelPlacementType, label);
    return (
      <RadioButtonController
        label={ label }
        value={ item[`answer_${language}`] }
        key={ item.id }
        labelPlacement={ labelPlacementType }
        className={ labelClassName }
        isDisabled={ isDisabled }
        tooltip={ item.id === lastIdScale ? descriptionScale : null }
      />
    );
  });

  // const marks = verticalIndices?.map((item, index) => ({
  //   value: index,
  //   label: getLabelAnswer(item.id, item[`answer_${language}`], scale, firstIdScale, lastIdScale, isMobile),
  //   firstLabel: item[`answer_${language}`],
  // }));

  // const handleSliderChange = (event, newValue) => {
  //   setAnswer(newValue);
  //   handleAnswers(id, isNumberScale ? marks[newValue].firstLabel : marks[newValue].label, false);
  // };

  const handleTextChange = (prop, event) => {
    handleAnswers(id, event.target.value, false);
  };

  // const isValidAnswer = (answer) => isEqual(
  //   isNumberScale ? answer.firstLabel : answer.label,
  //   value,
  // );

  // TODO: Remove
  // const sliderRender = () => {
  //   const defaultValue = value && scale
  //     ? marks.find((answer) => isValidAnswer(answer))?.value
  //     : null;

  //   return (
  //     <StyledSliderContent
  //       isVertical={ !isNumberScale }
  //       height={ scale?.indices?.length > INDEX.three ? "330px" : "220px" }
  //       isShortAnswer={ scale?.indices[INDEX.zero]?.answer_es?.length <= SCALE_ORDER.min }
  //       value={ defaultValue }
  //       marginBottom={ "55px" }
  //       marginTop={ "15px" }
  //       marginLeft={ "10px" }
  //     >
  //       <Slider
  //         orientation={ isNumberScale ? ORIENTATION.horizontal : ORIENTATION.vertical }
  //         value={ defaultValue }
  //         valueLabelDisplay={ AUTOCOMPLETE.off }
  //         step={ null }
  //         track={ false }
  //         marks={ marks }
  //         max={ marks ? marks.length - INDEX.one : INDEX.zero }
  //         onChange={ handleSliderChange }
  //         // onTouchEnd: Handles the completion of the slider on touch devices
  //         onTouchEnd={ () => handleAnswers(id, value) }
  //         // onMouseUp: Handles the completion of the slider on desktop devices
  //         onMouseUp={ !isMobile ? () => {
  //           handleAnswers(id, value);
  //         } : null }
  //         disabled={ isDisabled }
  //       />
  //       {isNumberScale && defaultValue && (
  //         <StyledAnswerContent>
  //           <Typography>{ `${t("surveys:your_answer")}:` }</Typography>
  //           <Typography variant={ VARIANT.h5 }>{ marks[defaultValue]?.firstLabel }</Typography>
  //         </StyledAnswerContent>
  //       )}
  //       {descriptionScale
  //        && (
  //          <StyledTooltip title={ descriptionScale }>
  //            <StyledHelpIcon />
  //          </StyledTooltip>
  //        )}
  //     </StyledSliderContent>
  //   );
  // };

  const radioAnswer = (
    <StyledFormControl
      component={ COMPONENT.fieldset }
      alignItems={ !isMobile && ALIGN_ITEMS.center }
    >
      <Controller
        control={ control }
        name={ id }
        as={ (
          <StyledRadioGroup
            aria-label={ title }
            name={ title }
            defaultValue={ answer || value }
            onChange={ handleChange }
            control={ control }
            display={ ALIGN_ITEMS.inline }
          >
            { questions }
          </StyledRadioGroup>
        ) }
      />
    </StyledFormControl>
  );

  const inputAnswer = scale ? radioAnswer : (
    <InputText
      id={ id }
      label={ "" }
      name={ id }
      variant={ VARIANT.outlined }
      onChange={ handleTextChange }
      value={ value }
      multiline={ 4 }
      onBlur={ () => handleAnswers(id, value) }
      disabled={ isDisabled }
    />
  );

  return (
    <StyledCard value={ value }>
      <CardContent>
        <StyledTitle variant={ VARIANT.bodyOne }>
          {title}
        </StyledTitle>
        { inputAnswer }
      </CardContent>
    </StyledCard>
  );
};

Question.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  control: PropTypes.object.isRequired,
  handleAnswers: PropTypes.func.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  scale: PropTypes.object,
  value: PropTypes.string,
};

Question.defaultProps = {
  scale: null,
  value: null,
};

export default Question;
