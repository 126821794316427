export const EVALUATION_TYPE = {
  AUTOEVALUATION: "Autoevaluation",
  MANAGER: "ManagerEvaluation",
  PAIRS: "PairsEvaluation",
  COLLABORATORS: "CollaboratorsEvaluation",
  OVERALL: "OverallEvaluation",
  INTERNAL_CLIENT: "InternalClientEvaluation",
};

export const SLIDER = {
  min: 1,
  max: 20,
  breakpoint: {
    simple: {
      375: {
        slidesPerView: 1,
        spaceBetween: 30,
      },
      640: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      768: {
        slidesPerView: 2,
        spaceBetween: 10,
      },
      1024: {
        slidesPerView: 3,
        spaceBetween: 10,
      },
      1360: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
      2000: {
        slidesPerView: 4,
        spaceBetween: 10,
      },
    },
  },
};

export const MAX_PARTICIPATION_TO_DOWNLOAD = 200;

export const ROWS_HEADER = ["dateUpdate", "empty", "headers"];

export const PARTICIPATION_COLUMNS = [
  "collaborators:table.table-head.personal_id",
  "collaborators:table.table-head.name",
  "collaborators:table.table-head.email",
  "collaborators:table.excel.manager_name",
  "collaborators:table.table-head.position",
  "collaborators:table.excel.department",
  "surveys:report",
  "surveys:evaluations_to_answers",
];

export const RESULTS_COLUMNS = [
  "collaborators:table.table-head.personal_id",
  "collaborators:table.table-head.name",
  "surveys:leader_name",
  "collaborators:table.table-head.position",
  "surveys:country",
  "common:common.city",
];
