import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Chip from "@mui/material/Chip";
import Grid from "@mui/material/Grid";
import theme from "theme";
import palette from "theme/palette";

export const StyledContainer = styled.div`
  padding: ${theme.spacing(4)};
`;

export const StyledTitle = styled(Typography)`
  font-weight: bold;
  font-size: 24px;
  line-height: 28px;
  color: ${palette.black};
  padding-bottom: ${theme.spacing(2)};
`;

export const StyledCard = styled(Card)`
  padding: ${theme.spacing(2)};
  border-top: 3px solid ${palette.text.iconDisabled};
  border-radius: 0;
`;

export const StyledChip = styled(Chip)`
  background-color: ${(props) => props.background || palette.background.iconBtn } !important;
  color: ${(props) => (props.textcolor || (props.blacktextcolor ? palette.black : palette.white))};
  margin-right: ${(props) => props.marginRight || "0"};
  padding: 0px;
  font-size: 10px;
  font-weight: 500;
  height: auto;
  min-width: ${(props) => props.minWidth || "100px" };
`;

export const StyledResult = styled.div`
  text-align:center;
`;

export const StyledGrid = styled(Grid)`
    min-width: 145px;
`;

export const StyledTitleTable = styled.div`
  padding: 5px;
  width: 150px;
`;
