import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import NineBox from "components/NineBox";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import { MATRIX_SWITCH_OPTIONS } from "common/constants/ninebox";
import { switchOptions } from "common/utils";
import { calculateTotalByQuadrants } from "../../../functions";
import {
  StyledNineboxMatrix,
  StyledOptionDescription,
} from "../styles";

const MatrixSmallDataSet = ({
  t,
  nineboxStates,
  getQuadrantDetail,
  handleSelectedQuadrant,
  handleChangeDataByOption,
  quadrants,
  isAllData,
}) => {
  const {
    selectedQuadrant,
    optionSelected,
  } = nineboxStates;

  const quadrantInfo = calculateTotalByQuadrants(quadrants, isAllData);

  const options = [
    switchOptions(t("ninebox:switch.manager"), MATRIX_SWITCH_OPTIONS.manager),
    switchOptions(t("ninebox:switch.all"), MATRIX_SWITCH_OPTIONS.all),
  ];

  const handleChangeSwitchOption = (option) => {
    handleChangeDataByOption(option);
  };

  return (
    <StyledNineboxMatrix>
      <Grid container>
        <Grid item xs={ 12 } md={ 5 } />
        <Grid item xs={ 12 } md={ 7 }>
          <SwitchSelectorContent
            options={ options }
            hasTitle={ false }
            onChangeOption={ handleChangeSwitchOption }
            isFull
          />
        </Grid>
        <Grid item xs={ 12 } md={ 1 } />
        <Grid item xs={ 12 } md={ 11 }>
          <StyledOptionDescription variant={ "body1" }>
            {t(`ninebox:switch.${optionSelected === MATRIX_SWITCH_OPTIONS.all ? "allDescription" : "managerDescription"}`)}
          </StyledOptionDescription>
        </Grid>
      </Grid>
      <NineBox
        getQuadrantDetail={ getQuadrantDetail }
        quadrant={ selectedQuadrant }
        setSelectedQuadrant={ handleSelectedQuadrant }
        quadrantInfo={ quadrantInfo }
      />
    </StyledNineboxMatrix>
  );
};

MatrixSmallDataSet.propTypes = {
  t: PropTypes.func.isRequired,
  nineboxStates: PropTypes.object.isRequired,
  getQuadrantDetail: PropTypes.func.isRequired,
  handleSelectedQuadrant: PropTypes.func.isRequired,
  resultScaleList: PropTypes.array.isRequired,
  handleChangeDataByOption: PropTypes.func.isRequired,
  quadrants: PropTypes.any,
  isAllData: PropTypes.bool,
};

MatrixSmallDataSet.defaultProps = {
  quadrants: null,
  isAllData: true,
};

export default MatrixSmallDataSet;
