import { useTranslation } from "react-i18next";
import Iframe from "react-iframe";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import {
  BASE_URL_HR_ANALYTICS,
  DIRECTION,
} from "common/constants";
import useStyles from "./styles";

// TODO: remove
const HrAnalyticsBeta = () => {
  const { t } = useTranslation("HRAnalytics");
  const classes = useStyles();

  return (
    <div className={ classes.root }>
      <Typography className={ classes.titleText }>{t("beta-title")}</Typography>
      <Grid
        container
        direction={ DIRECTION.row }
        justify={ "center" }
        alignItems={ "center" }
      >
        <Iframe
          url={ BASE_URL_HR_ANALYTICS }
          width={ "100%" }
          className={ classes.imgIframe }
          allowFullScreen
        />
      </Grid>
    </div>
  );
};

export default HrAnalyticsBeta;
