import configAxios from "../configAxios";

const TAGS_URL = "/tags";
const ASSIGN_TAGS_URL = (goalId) => `/goals/${goalId}/assign_tags`;
const REMOVE_TAGS_URL = (goalId) => `/goals/${goalId}/remove_tags`;

export const getTags = async () => {
  const response = await configAxios.get(TAGS_URL);
  return response.data.tags;
};

export const assignTags = async ({ goalId, tagNames }) => {
  const response = await configAxios.post(ASSIGN_TAGS_URL(goalId), { tag_names: tagNames });
  return response.data.goal;
};

export const removeTags = async ({ goalId, tagNames }) => {
  const response = await configAxios.post(REMOVE_TAGS_URL(goalId), { tag_names: tagNames });
  return response.data.goal;
};
