import {
  useCallback, useContext, useEffect, useState,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import includes from "lodash/includes";
import toNumber from "lodash/toNumber";
import clsx from "clsx";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Avatar from "@mui/material/Avatar";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import { toast, MESSAGE_TYPES } from "components/Toast/functions";
import SkeletonLoader from "components/SkeletonLoader";
import NoDataMessage from "components/NoDataMessage";
import {
  ROLES,
  PROFILE_INDEX_TABS,
  SKELETONS_NUMBER,
  SKELETON_VARIANT,
  ALIGN_ITEMS,
  VARIANT,
  MODULES,
} from "common/constants";
import { isRolManager } from "common/validators";
import {
  isValidRole,
  getUserRoles,
  isAdminOrManager,
  canAccessModule,
} from "common/utils";
import { isEmpty, isNull, isEqual } from "common/helpers";
import { SessionContext } from "modules/session/context";
import { getCollaboratorByManager, resetStateOne as resetStateCollaborator } from "redux/actions/collaboratorActions";
import { resetStateOne as resetStateCandidate } from "redux/actions/candidateActions";
import QueryString from "query-string";
import {
  getEmployeeFullName,
  getTabs,
  dispatchToUse,
  getTabID,
  getUserTopInformation,
  isProfileView,
  isCandidateSearchView,
  feedbackQueryParam,
} from "./functions";
import Profile from "./components/Profile";
import PersonalData from "./components/PersonalData";
import Salary from "./components/Salary";
import Performance from "./components/Performance";
import AccountInductionTab from "./components/AccountInductionTab";
import PotentialTab from "./components/PotentialTab";
import Documents from "./components/Documents";
import LossRisk from "./components/LossRisk";
import Feedback from "./components/Feedback";
import GoodLeaderResult from "./components/GoodLeaderResult";
import {
  useStyles,
  StyledPaper,
  StyledMainContainer,
  StyledLinkContainer,
  StyledTopInfoContainer,
  StyledGrid,
  StyledProfileBox,
} from "./styles";

const Account = (props) => {
  const locationSearch = props.location.search;
  const locationState = props.location.state;
  const params = QueryString.parse(props.location.search);

  const classes = useStyles();
  const { t } = useTranslation(["common", "account"]);
  const dispatch = useDispatch();
  const {
    state: { user },
  } = useContext(SessionContext);
  const { isLoadingOne: isLoadingCollaboratorReducer } = useSelector(
    (state) => state.collaboratorReducer,
  );

  const history = useHistory();

  const [externalUser, setExternaluser] = useState(null);
  const [dataTopInformation, setDataTopInformation] = useState();
  const [tabHandler, setTabHandler] = useState(0);
  const [isTabSelectedQueryParam, setIsTabSelectedQueryParam] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const searchBy = Object.keys(params)[0];
  const selectedId = params[searchBy];

  const employee = includes(searchBy, ROLES.CANDIDATE)
    || (isEmpty(locationSearch)
      && includes(ROLES.CANDIDATE, getUserRoles(user?.userCookies)))
    ? ROLES.CANDIDATE
    : ROLES.COLLABORATOR;

  const candidateReducer = useSelector((state) => state.candidateReducer);
  const collaboratorReducer = useSelector((state) => state.collaboratorReducer);

  const reducerToUse = isCandidateSearchView(searchBy)
    || isCandidateSearchView(user?.employee?.type)
    ? candidateReducer
    : collaboratorReducer;

  const canAccessProfileCandidate = canAccessModule(user?.userCookies, MODULES.candidate);

  useEffect(() => {
    if (!externalUser) {
      const dataUser = user;
      setExternaluser(dataUser);
      setTabHandler(getTabID(props, dataUser));
    }
  }, [externalUser, user, props, dispatch]);

  // validate selected id by manager

  const validateSelectedIdByManager = useCallback(async () => {
    const collaboratorId = toNumber(selectedId);
    const collaboratorData = await dispatch(getCollaboratorByManager(collaboratorId));
    if (collaboratorData?.error) {
      const toastMessage = {
        title: t("common:common.api_responses.error.title"),
        message: collaboratorData?.message,
      };
      toast(MESSAGE_TYPES.error, toastMessage);
    } else if (!isEmpty(collaboratorData)) {
      dispatchToUse(
        dispatch,
        locationSearch,
        searchBy,
        externalUser,
        selectedId,
      );
    } else {
      toast(MESSAGE_TYPES.warning, {
        title: t("common:common.api_responses.warning.title"),
        message: t("common.api_responses.warning.access_denied"),
      });
      history.push("/collaborators");
    }
    setIsLoading(false);
  }, [
    externalUser,
    dispatch,
    selectedId,
    searchBy,
    locationSearch,
    history,
    t,
  ]);

  useEffect(() => {
    if (
      !isNull(externalUser)
      && (!isProfileView(searchBy)
        || (isProfileView(searchBy)
          && (canAccessProfileCandidate
            || isRolManager(externalUser?.userCookies)
            || isValidRole(externalUser?.userCookies, ROLES.TALENT_MANAGER)
            || isValidRole(externalUser?.userCookies, ROLES.ONBOARDING_ADMIN))))
    ) {
      if (isRolManager(externalUser?.userCookies) && isProfileView(searchBy)
        && !canAccessProfileCandidate) {
        setIsLoading(true);
        validateSelectedIdByManager();
      } else {
        dispatchToUse(
          dispatch,
          locationSearch,
          searchBy,
          externalUser,
          selectedId,
        );
      }
    }
  }, [
    externalUser,
    dispatch,
    locationState,
    selectedId,
    searchBy,
    locationSearch,
    validateSelectedIdByManager,
    canAccessProfileCandidate,
  ]);

  useEffect(() => {
    if (
      isProfileView(searchBy)
      && !isEmpty(
        getEmployeeFullName(searchBy, candidateReducer, collaboratorReducer),
      )
    ) {
      setDataTopInformation(
        getUserTopInformation(
          t,
          searchBy,
          candidateReducer,
          collaboratorReducer,
        ),
      );
    }
  }, [candidateReducer, collaboratorReducer, t, searchBy]);

  useEffect(() => {
    if (searchBy === feedbackQueryParam && !isTabSelectedQueryParam) {
      setTabHandler(PROFILE_INDEX_TABS.feedback);
      setIsTabSelectedQueryParam(true);
    }
  }, [searchBy, isTabSelectedQueryParam]);

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  const handleTabs = () => getTabs(user, externalUser, searchBy, candidateReducer, reducerToUse, tabHandler, t);

  useEffect(() => {
    dispatch(resetStateCollaborator());
    dispatch(resetStateCandidate());
  }, [dispatch]);

  useEffect(() => {
    if (reducerToUse?.successProcess && locationState?.edit) {
      locationState.edit = false;
    }
  }, [locationState, reducerToUse.successProcess]);

  const tabsRender = isNull(reducerToUse.one) ? (
    <NoDataMessage
      message={ `${t("common:common.api_responses.error.title")}. ${t(
        "common:common.api_responses.error.contact_administrator",
      )}` }
    />
  ) : (
    <>
      <Tab
        tabs={ handleTabs() }
        onChange={ handleValueChanged }
        tabValue={ tabHandler }
        byValue
      />
      <TabPanel
        value={ tabHandler }
        index={ PROFILE_INDEX_TABS.profile }
        isLoading={ reducerToUse.isLoadingOne }
      >
        <Profile
          user={ externalUser }
          locationState={ locationState }
          from={ locationSearch }
          classes={ classes }
          searchBy={ searchBy }
          employee={ employee }
          reducerToUse={ reducerToUse }
          selectedId={ selectedId }
        />
      </TabPanel>
      <TabPanel
        value={ tabHandler }
        index={ PROFILE_INDEX_TABS.personalData }
        isLoading={ reducerToUse.isLoadingOne }
      >
        <StyledPaper>
          <PersonalData
            user={ externalUser }
            isEdit={ !isValidRole(externalUser?.userCookies, ROLES.TALENT_MANAGER)
              || (isValidRole(externalUser?.userCookies, ROLES.TALENT_MANAGER)
                && !searchBy) }
            from={ locationSearch }
            searchBy={ searchBy }
            employee={ employee }
            reducerToUse={ reducerToUse }
            selectedId={ selectedId }
          />
        </StyledPaper>
      </TabPanel>
      {(!isValidRole(externalUser?.userCookies, ROLES.TALENT_MANAGER)
        || (isValidRole(externalUser?.userCookies, ROLES.TALENT_MANAGER)
          && !searchBy)) && (
        <TabPanel
            value={ tabHandler }
            index={ PROFILE_INDEX_TABS.salary }
            isLoading={ reducerToUse.isLoadingOne }
          >
            <Salary
            user={ externalUser }
            locationState={ locationState }
            from={ locationSearch }
            classes={ classes }
            searchBy={ searchBy }
            employee={ employee }
            reducerToUse={ reducerToUse }
            selectedId={ selectedId }
          />
          </TabPanel>
      )}
      <TabPanel
        value={ tabHandler }
        index={ PROFILE_INDEX_TABS.performance }
        isLoading={ reducerToUse.isLoadingOne }
      >
        <Card data-testid={ "performance-tab" }>
          <CardContent className={ classes.content }>
            <NoDataMessage />
          </CardContent>
        </Card>
      </TabPanel>
      {(reducerToUse?.one?.roles?.some((rol) => isEqual(rol.name, ROLES.MANAGER))
        || isEqual(tabHandler, PROFILE_INDEX_TABS.leadership)) && (
        <TabPanel
          value={ tabHandler }
          index={ PROFILE_INDEX_TABS.leadership }
          isLoading={ reducerToUse.isLoadingOne }
        >
          <GoodLeaderResult
            user={ externalUser }
            from={ locationSearch }
            reducerToUse={ reducerToUse }
            searchBy={ searchBy }
          />
        </TabPanel>
      )}
      {(includes(getUserRoles(user?.userCookies), ROLES.CANDIDATE)
        || includes(searchBy, ROLES.CANDIDATE)) && (
        <TabPanel
          value={ tabHandler }
          index={ PROFILE_INDEX_TABS.induction }
          isLoading={ reducerToUse.isLoadingOne }
        >
          <AccountInductionTab user={ externalUser } from={ locationSearch } />
        </TabPanel>
      )}
      {!includes(getUserRoles(user?.userCookies), ROLES.CANDIDATE)
        && !includes(searchBy, ROLES.CANDIDATE) && (
        <TabPanel
          value={ tabHandler }
          index={ PROFILE_INDEX_TABS.potential }
          isLoading={ reducerToUse?.isLoadingOne }
        >
          <PotentialTab
            user={ externalUser }
            from={ locationSearch }
            reducerToUse={ reducerToUse }
          />
        </TabPanel>
      )}

      <TabPanel
        value={ tabHandler }
        index={ PROFILE_INDEX_TABS.documents }
        isLoading={ reducerToUse?.isLoadingOne }
      >
        <Documents params={ params } />
      </TabPanel>

      {!isProfileView(searchBy) && searchBy !== ROLES.CANDIDATE && (
        <TabPanel
          value={ tabHandler }
          index={ PROFILE_INDEX_TABS.feedback }
          isLoading={ reducerToUse?.isLoadingOne }
        >
          <Feedback />
        </TabPanel>
      )}
      {isProfileView(searchBy)
        && searchBy !== ROLES.CANDIDATE
        && isAdminOrManager(externalUser?.userCookies) && (
        <TabPanel
          value={ tabHandler }
          index={ PROFILE_INDEX_TABS.feedback }
          isLoading={ reducerToUse?.isLoadingOne }
        >
          <Feedback profileViewEmployeeId={ selectedId } />
        </TabPanel>
      )}
      {isProfileView(searchBy) && searchBy !== ROLES.CANDIDATE && (
        <TabPanel
          value={ tabHandler }
          index={ PROFILE_INDEX_TABS.lossRisk }
          isLoading={ reducerToUse?.isLoadingOne }
        >
          <LossRisk params={ params } />
        </TabPanel>
      )}
    </>
  );

  return (
    <StyledMainContainer>
      {!isEmpty(locationSearch)
        && dataTopInformation && (
        <StyledLinkContainer>
          <Link to={ dataTopInformation.path }>
            <Box
              display={ ALIGN_ITEMS.flex }
              alignItems={ ALIGN_ITEMS.center }
              marginBottom={ 1 }
            >
              <ArrowBackIosIcon className={ classes.arrowBack } />
              <Typography
                variant={ VARIANT.bodyOne }
                className={ classes.arrowBack }
              >
                {t("common.go_back")}
              </Typography>
            </Box>
          </Link>
        </StyledLinkContainer>
      )}
      <StyledTopInfoContainer container spacing={ 3 }>
        {!isEmpty(locationSearch) ? (
          !reducerToUse.isLoadingOne
          && dataTopInformation && !isLoading && (
            <StyledGrid item xs={ 12 } sm={ 6 }>
              <StyledProfileBox>
                <Box marginRight={ 1 }>
                  <Avatar
                    src={ dataTopInformation.profileImage }
                    alt={ dataTopInformation.fullName }
                  />
                </Box>
                <Box>
                  <Typography
                    variant={ VARIANT.bodyOne }
                    className={ classes.userNameTitle }
                  >
                    {dataTopInformation.fullName}
                  </Typography>
                  <Typography
                    variant={ VARIANT.bodyTwo }
                    className={ classes.userPositionTitle }
                  >
                    {dataTopInformation.positionName}
                  </Typography>
                </Box>
              </StyledProfileBox>
            </StyledGrid>
          )
        ) : (
          <Box flexGrow={ 1 }>
            <Typography className={ classes.titleText }>
              {t("account:accountDetails.title")}
            </Typography>
          </Box>
        )}
        {isProfileView(searchBy) && (
          <Grid item xs={ 12 } sm={ 6 } className={ classes.gridActive }>
            {((!isEmpty(locationSearch)
              && includes(searchBy, ROLES.COLLABORATOR))
              || (isEmpty(locationSearch)
                && includes(
                  externalUser && getUserRoles(user?.userCookies),
                  ROLES.COLLABORATOR,
                )))
              && !collaboratorReducer.isLoadingOne && !isNull(collaboratorReducer.one) && (
              <p
                className={ clsx(
                  classes.statusContainer,
                  classes.subtitleText,
                  collaboratorReducer.one?.is_active
                    ? classes.success
                    : classes.error,
                ) }
              >
                <AccountCircleIcon />
                {t(
                    `account:accountDetails.${
                      collaboratorReducer.one?.is_active
                        ? "collaborator_active_status"
                        : "collaborator_inactive_status"
                    }`,
                )}
              </p>
            )}
          </Grid>
        )}
      </StyledTopInfoContainer>
      <StyledGrid container spacing={ 4 }>
        <Grid item xs={ 12 }>
          {!isLoadingCollaboratorReducer && externalUser && !reducerToUse.isLoadingOne && !isLoading ? (
            tabsRender
          ) : (
            <>
              <SkeletonLoader
                variant={ SKELETON_VARIANT.rectangular }
                numberOfSkeletons={ SKELETONS_NUMBER.SIX }
                height={ 30 }
                columnWidth={ 2 }
                isInline={ false }
              />
              <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.EIGTH } />
            </>
          )}
        </Grid>
      </StyledGrid>
    </StyledMainContainer>
  );
};

export default Account;
