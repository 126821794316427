import configAxios from "../../configAxios";

const URL = {
  main: "/notifications_settings",
};

const PATH = {
  toggle: "toggle",
  launchDate: "launch_date",
  launchNala: "launch_nala",
};

export const getNotificationsSettings = async () => {
  const response = await configAxios.get(URL.main);

  return response.data.notifications_settings[0];
};

export const toggleNotificationSetting = async (notificationName) => {
  const response = await configAxios.put(
    `${URL.main}/${PATH.toggle}`,
    {
      notification_name: notificationName,
    },
  );

  return response.data.notifications_settings;
};

export const getLaunchDate = async () => {
  const response = await configAxios.get(`${URL.main}/${PATH.launchDate}`);

  return response.data.launch_date;
};

export const launchNala = async () => {
  const response = await configAxios.post(`${URL.main}/${PATH.launchNala}`);

  return response.data;
};
