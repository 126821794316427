import { useQuery, useQueryClient } from "react-query";
import { getGoalsParticipation } from "redux/actions/goalsActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

const handleQueryError = (error, t) => {
  let errorMessage = t("common:common.api_responses.error.title");

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  } else {
    errorMessage = error.toString();
  }

  toast(MESSAGE_TYPES.error, { message: errorMessage });
};

export const useGoalsParticipation = (t, filterQuery) => {
  const queryClient = useQueryClient();

  const {
    data: goalsParticipation, isLoading, isError, error,
  } = useQuery(
    ["goalsParticipation", filterQuery],
    () => getGoalsParticipation(filterQuery),
    {
      staleTime: Infinity,
      onError: (queryError) => handleQueryError(queryError, t),
    },
  );

  return {
    goalsParticipation,
    isLoading,
    isError,
    queryClient,
    error,
  };
};
