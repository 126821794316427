import {
  useMemo, useState, useEffect, useCallback,
} from "react";
import { useQueryClient, useQuery } from "react-query";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import PropTypes from "prop-types";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import AlarmIcon from "@mui/icons-material/Alarm";
import DownloadExcelButton from "components/DownloadExcelButton";
import TableGrid from "components/TableGrid";
import ProfileInfo from "components/ProfileInfo";
import AlertDialog from "components/AlertDialog";
import MenuPopup from "components/MenuPopup";
import TooltipIcon from "components/TooltipIcon";
import { ROWS_RANGE_PER_PAGE } from "common/constants";
import { mainDownloadExcel, truncateName } from "common/utils";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import {
  createParticipationSchema,
  createEvaluator,
  updateEvaluatorData,
  deleteEvaluatorData,
  updateParticipationSchema,
  sendSurveyProcessReminder,
  sendSurveyProcessReminders,
  getStatus,
  sendUpdateParticipation,
  getProcessByIdAsync,
} from "redux/actions/surveyProcessesActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import Filters from "./components/Filters";
import EmployeeAutocomplete from "./components/EmployeeAutocomplete";
import EvaluatorList from "./components/EvaluatorList";
import {
  generateRows,
  evaluationOrder,
  getParticipationDataToDownload,
} from "./functions";
import { processStates } from "../Planning/functions";
import useCollaboratorList from "./hooks/useCollaboratorList";
import useParticipationSchema from "./hooks/useParticipationSchema";
import {
  StyledContainer, StyledChip,
  StyledError, StyledSwitch,
  StyledIconButton, StyledEmptyTitle,
  StyledButton,
  StyledDeleteIcon,
} from "./styles";

const Participation = ({ employeeInfo, shouldRefetch }) => {
  const queryClient = useQueryClient();
  const { t } = useTranslation(["common", "surveys"]);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const hasParticipationSchema = Boolean(employeeInfo?.participation_schema);
  const isFinished = employeeInfo?.state === processStates.finished;
  const isRunning = employeeInfo?.state === processStates.running;
  const isCustomParticipationSchema = hasParticipationSchema && !isFinished
    ? !employeeInfo.participation_schema.nala_protected
    : false;
  const isValidToUpdateParticipation = employeeInfo?.participation_schema?.nala_protected
  && !isFinished;
  const {
    successProcessSchema,
    isLoadingProcessSchema,
    errorProcessSchema,
  } = useSelector(({ surveysReducer }) => surveysReducer);

  const dispatch = useDispatch();

  const defaultFilters = {
    manager: [],
    country: [],
    city: [],
    unit: [],
    state: [],
  };

  const [filteredRows, setFilteredRows] = useState([]);
  const [isEvaluatorListVisible, setIsEvaluatorListVisible] = useState(false);
  const [allRows, setAllRows] = useState([]);
  const [filters, setFilters] = useState(defaultFilters);
  const [resetFilters, setResetFilters] = useState(false);
  const [dialog, setDialog] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  // Delete dialog
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedParticipation, setSelectedParticipation] = useState(null);

  // Reminder
  const [reminderDialog, setReminderDialog] = useState(false);
  const [reminderInfo, setReminderInfo] = useState(null);

  // reminder status
  const [taskId, setTaskId] = useState(null);

  const { data, isLoading: isLoadingTask, error } = useQuery(
    ["taskStatus", taskId],
    () => dispatch(getStatus(taskId)),
    {
      refetchInterval: (task) => (task?.aasm_state === "pending" ? 3000 : false),
      enabled: !!taskId,
    },
  );

  const {
    collaboratorList,
    isLoadingCollaborators,
    // isFetching,
    refetchCollaborators,
    collaboratorError,
  } = useCollaboratorList(employeeInfo, shouldRefetch);

  const queryKey = hasParticipationSchema
    ? ["participationSchema", employeeInfo.id, employeeInfo.participation_schema.id]
    : ["participationSchema", employeeInfo.id];

  const {
    participationSchema,
    isLoadingParticipation,
    isFetchingParticipation,
    participationError,
  } = useParticipationSchema(queryKey, hasParticipationSchema, shouldRefetch);

  useEffect(() => {
    if (shouldRefetch) {
      // When there are adjustments that affect the audience
      // we need to refetch to update the collection
      refetchCollaborators();
    }
  }, [shouldRefetch]);

  // Filters
  const applyFilters = (newFilters = filters, dataRows = allRows) => dataRows.filter((row) => {
    const {
      managerId = null,
      countryId = null,
      cityId = null,
      unitIds = [],
      isActive = null,
    } = row.filters;
    const {
      manager,
      country,
      city,
      unit,
      state,
    } = newFilters;

    const managerMatch = manager.length === 0 || manager.includes(managerId);
    const countryMatch = country.length === 0 || country.includes(countryId);
    const cityMatch = city.length === 0 || city.includes(cityId);
    const unitMatch = unit.length === 0 || unit.some((id) => unitIds.includes(id));
    const stateMatch = state.length === 0 || state.includes(isActive);

    return managerMatch && countryMatch && cityMatch && unitMatch && stateMatch;
  });

  // Delete evaluatee
  const handleDelete = async (evalualeeId, participationTypeId, participationId) => {
    const currentData = queryClient.getQueryData(queryKey);
    const isValidCurrentData = currentData && Array.isArray(currentData.pages);

    if (!isValidCurrentData) return;

    const evaluatorData = {
      participation: {
        participation_types_attributes: [
          {
            id: participationTypeId,
            evaluatees_attributes: [
              {
                id: evalualeeId,
                _destroy: true,
              },
            ],
          },
        ],
      },
    };

    const updatedEvaluator = await dispatch(
      updateEvaluatorData(participationId, evaluatorData),
    );

    if (!updatedEvaluator?.id) return;

    const updatedRow = generateRows([updatedEvaluator])[0];

    setAllRows((prevRows) => {
      const newRows = prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
      setFilteredRows(applyFilters(filters, newRows));
      return newRows;
    });

    const updatedPages = currentData.pages.map((page) => {
      const updatedParticipations = page.participations.map((participation) => {
        if (participation.id === participationId) {
          return { ...participation, ...updatedEvaluator };
        }
        return participation;
      });
      return { ...page, participations: updatedParticipations };
    });

    queryClient.setQueryData(queryKey, { ...currentData, pages: updatedPages });
  };

  // Self Evaluation - autoevaluation
  const handleToggle = async (participationId, participationTypeId) => {
    const currentData = queryClient.getQueryData(queryKey);
    const isValidCurrentData = currentData && Array.isArray(currentData.pages);

    if (!isValidCurrentData) return;

    let targetParticipation = null;
    let targetPageIndex = -1;
    let targetParticipationIndex = -1;

    currentData.pages.forEach((page, pageIndex) => {
      page.participations.forEach((participation, participationIndex) => {
        if (participation.id === participationId) {
          targetParticipation = participation;
          targetPageIndex = pageIndex;
          targetParticipationIndex = participationIndex;
        }
      });
    });

    if (!targetParticipation) return;

    const participationType = targetParticipation.participation_types.find(
      (pt) => pt.id === participationTypeId,
    );
    if (!participationType) return;

    const evaluateeExists = participationType.evaluatees.length > 0;

    let evaluatorData;

    if (evaluateeExists) {
      evaluatorData = {
        participation: {
          participation_types_attributes: [{
            id: participationTypeId,
            evaluatees_attributes: [{
              id: participationType.evaluatees[0].id,
              _destroy: true,
            }],
          }],
        },
      };
    } else {
      evaluatorData = {
        participation: {
          participation_types_attributes: [{
            id: participationTypeId,
            evaluatees_attributes: [{
              evaluatee_id: targetParticipation.evaluator_id,
            }],
          }],
        },
      };
    }

    const updatedData = await dispatch(
      updateEvaluatorData(participationId, evaluatorData),
    );

    if (updatedData) {
      const updatedRow = generateRows([updatedData])[0];

      setAllRows((prevRows) => {
        const newRows = prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
        setFilteredRows(applyFilters(filters, newRows));
        return newRows;
      });

      const updatedPages = [...currentData.pages];
      updatedPages[targetPageIndex].participations[targetParticipationIndex] = {
        ...targetParticipation,
        participation_types: targetParticipation.participation_types.map((pt) => {
          if (pt.id === participationTypeId) {
            return {
              ...pt,
              evaluatees: updatedData.participation_types.find(
                (upt) => upt.id === participationTypeId,
              ).evaluatees,
            };
          }
          return pt;
        }),
      };
      queryClient.setQueryData(queryKey, { ...currentData, pages: updatedPages });
    }
  };

  const toggleAddingMode = (employeeId, evaluationType) => {
    setAllRows((prevRows) => {
      const newRows = prevRows.map((row) => {
        if (row.employee.id === employeeId) {
          return {
            ...row,
            participation_types: row.participation_types.map((evaluation) => {
              if (evaluation.evaluation_type === evaluationType) {
                return { ...evaluation, isEditing: !evaluation.isEditing };
              }
              return evaluation;
            }),
          };
        }
        return row;
      });

      setFilteredRows(applyFilters(filters, newRows));
      return newRows;
    });
  };

  const handleClose = (employeeId, evaluationType) => {
    setAllRows((prevRows) => {
      const newRows = prevRows.map((row) => {
        if (row.employee.id === employeeId) {
          return {
            ...row,
            participation_types: row.participation_types.map((evaluation) => {
              if (evaluation.evaluation_type === evaluationType) {
                return { ...evaluation, isEditing: false };
              }
              return evaluation;
            }),
          };
        }
        return row;
      });
      setFilteredRows(applyFilters(filters, newRows));
      return newRows;
    });
  };

  // Add and delete - evaluatees
  const handleSaveEmployees = async (newEmployees, evaluationType, participationId) => {
    const currentData = queryClient.getQueryData(queryKey);
    const isValidCurrentData = currentData && Array.isArray(currentData.pages);

    if (!isValidCurrentData) return;

    setIsLoading(true);

    let targetParticipation = null;
    let targetPageIndex = -1;
    let targetParticipationIndex = -1;

    currentData.pages.forEach((page, pageIndex) => {
      page.participations.forEach((participation, participationIndex) => {
        if (participation.id === participationId) {
          targetParticipation = participation;
          targetPageIndex = pageIndex;
          targetParticipationIndex = participationIndex;
        }
      });
    });

    if (!targetParticipation) {
      setIsLoading(false);
      return;
    }

    const participationType = targetParticipation.participation_types.find(
      (pt) => pt.evaluation_type === evaluationType,
    );
    if (!participationType) {
      setIsLoading(false);
      return;
    }

    const currentEvaluatees = participationType.evaluatees;

    const cleanedNewEmployees = newEmployees.filter((e) => !e.evaluatee_id);
    const lastEmployee = newEmployees.filter((e) => e.evaluatee_id);

    const currentEvaluateeIds = new Set(currentEvaluatees.map((e) => e.evaluatee_id));
    const noUpdated = new Set(lastEmployee.map((e) => e.evaluatee_id));

    const evaluateesToDelete = currentEvaluatees
      .filter((e) => !noUpdated.has(e.evaluatee_id))
      .map((e) => ({
        id: e.id,
        _destroy: true,
      }));

    const evaluateesToAdd = cleanedNewEmployees
      .filter((e) => !currentEvaluateeIds.has(e.id))
      .map((e) => ({
        evaluatee_id: e.id,
      }));

    if (evaluateesToDelete.length === 0 && evaluateesToAdd.length === 0) {
      setIsLoading(false);
      return;
    }

    const evaluatorData = {
      participation: {
        evaluator_id: targetParticipation.evaluator_id,
        participation_types_attributes: [
          {
            id: participationType.id,
            evaluatees_attributes: [...evaluateesToDelete, ...evaluateesToAdd],
          },
        ],
      },
    };

    const updatedEvaluator = await dispatch(updateEvaluatorData(participationId, evaluatorData));

    if (!updatedEvaluator?.id) {
      setIsLoading(false);
      return;
    }

    const updatedPages = currentData.pages.map((page, pageIndex) => {
      if (pageIndex === targetPageIndex) {
        return {
          ...page,
          participations: page.participations.map((participation, participationIndex) => {
            if (participationIndex === targetParticipationIndex) {
              return {
                ...participation,
                participation_types: participation.participation_types.map((pt) => {
                  if (pt.id === participationType.id) {
                    return {
                      ...pt,
                      evaluatees: updatedEvaluator.participation_types.find(
                        (upt) => upt.id === participationType.id,
                      ).evaluatees,
                    };
                  }
                  return pt;
                }),
              };
            }
            return participation;
          }),
        };
      }
      return page;
    });

    queryClient.setQueryData(queryKey, { ...currentData, pages: updatedPages });

    const updatedRow = generateRows([{
      ...targetParticipation,
      participation_types: targetParticipation.participation_types.map((pt) => {
        if (pt.id === participationType.id) {
          return {
            ...pt,
            evaluatees: updatedEvaluator.participation_types.find(
              (upt) => upt.id === participationType.id,
            ).evaluatees,
          };
        }
        return pt;
      }),
    }])[0];

    setAllRows((prevRows) => {
      const newRows = prevRows.map((row) => (row.id === updatedRow.id ? updatedRow : row));
      setFilteredRows(applyFilters(filters, newRows));
      return newRows;
    });
    setIsLoading(false);
  };

  // Delete participation
  const handleDeleteEvaluator = async (participationId) => {
    const currentData = queryClient.getQueryData(queryKey);
    const isValidCurrentData = currentData && Array.isArray(currentData.pages);

    if (!isValidCurrentData) return;

    const deleteEvaluator = await dispatch(deleteEvaluatorData(participationId));

    if (deleteEvaluator) {
      const updatedPages = currentData.pages.map((page) => ({
        ...page,
        participations: page.participations.filter(
          (participation) => participation.id !== participationId,
        ),
      }));

      queryClient.setQueryData(queryKey, { ...currentData, pages: updatedPages });

      setAllRows((prevRows) => {
        const newRows = prevRows.filter((row) => row.id !== participationId);
        setFilteredRows(applyFilters(filters, newRows));
        return newRows;
      });
    }
  };

  // Helper function to generate a chip for each name with a tooltip
  const generateChips = (evaluatees, evaluation, employeeId, participationId) => {
    const isEditing = evaluation?.isEditing || false;
    const evaluationType = evaluation.evaluation_type;

    const renderEmployeeChips = (editable) => (
      evaluatees.map((employee) => (
        <Tooltip key={ `${evaluationType}-${employee.evaluatee_id}` } title={ employee.full_name } arrow>
          <StyledChip
            label={ truncateName(employee.full_name) }
            variant={ "outlined" }
            color={ "primary" }
            size={ "small" }
            onDelete={ editable
              ? () => handleDelete(employee.id, evaluation.id, participationId)
              : undefined }
          />
        </Tooltip>
      ))
    );

    const renderEmptyMessage = (messageKey) => (
      evaluatees.length === 0 && <StyledEmptyTitle>{t(messageKey)}</StyledEmptyTitle>
    );

    return (
      <Stack
        direction={ "row" }
        spacing={ 1 }
        flexWrap={ "wrap" }
        key={ `${employeeId}-${evaluationType}` }
        display={ "inline" }
      >
        {isCustomParticipationSchema ? (
          isEditing ? (
            <EmployeeAutocomplete
              t={ t }
              initialEmployees={ evaluatees }
              onSave={ (newEmployees) => handleSaveEmployees(newEmployees, evaluationType, participationId) }
              onCancel={ () => handleClose(employeeId, evaluationType) }
              allEmployees={ collaboratorList.filter((emp) => emp.id !== employeeId) }
            />
          ) : (
            <>
              {renderEmployeeChips(true)}
              {renderEmptyMessage("participation:no_assigned")}
              <Tooltip title={ t("common:common.add") }>
                <StyledIconButton onClick={ () => toggleAddingMode(employeeId, evaluationType) }>
                  <AddCircleIcon />
                </StyledIconButton>
              </Tooltip>
            </>
          )
        ) : (
          <>
            {renderEmployeeChips(false)}
            {renderEmptyMessage("participation:no_applicable_employees")}
          </>
        )}
      </Stack>
    );
  };

  const generateSwitch = (hasNames, evaluationType, participationId, participationTypeId) => (
    <Stack direction={ "row" } spacing={ 1 } alignItems={ "center" }>
      {isCustomParticipationSchema
      && (
        <StyledSwitch
          id={ `${participationId}-${evaluationType}` }
          checked={ hasNames }
          onChange={ () => handleToggle(participationId, participationTypeId) }
          size={ "small" }
        />
      )}
      <p>
        {t(`common:common.${hasNames ? "yes" : "no"}`)}
      </p>
    </Stack>
  );

  // Function to render the collaborator profile
  const getProfile = (data) => (
    <ProfileInfo
      collaborator={ data }
      isShortVersion
      hasStatus
    />
  );

  const handleDeleteParticipation = (participationId, fullName) => {
    setSelectedParticipation({ id: participationId, name: fullName });
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogOpen(false);
    setSelectedParticipation(null);
  };

  const handleDialogSubmit = async () => {
    if (selectedParticipation) {
      await handleDeleteEvaluator(selectedParticipation.id);
      handleDialogClose();
    }
  };

  const handleSendReminder = (id, name) => {
    setReminderInfo({ id, name });
    setReminderDialog(true);
  };

  const handleReminderDialogClose = () => {
    setReminderDialog(false);
    setReminderInfo(null);
    setTaskId(null);
  };

  const handleSendReminderSubmit = async () => {
    if (reminderInfo) {
      const reminderData = {
        survey_process: {
          is_reminder: true,
          employee_ids: [reminderInfo.id],
        },
      };
      const reminder = await dispatch(sendSurveyProcessReminder(employeeInfo.id, reminderData));
      handleReminderDialogClose();

      if (reminder?.message) {
        toast(MESSAGE_TYPES.success, {
          message: t("participation:actions.reminder.success"),
        });
      }
    }
  };

  useEffect(() => {
    if (data && data?.aasm_state !== "pending") {
      toast(MESSAGE_TYPES.success, {
        message: t("participation:actions.reminder.success"),
      });
      setTaskId(null);
      handleReminderDialogClose();
    }
    // eslint-disable-next-line
  }, [data]);

  useEffect(() => {
    if (error) {
      setTaskId(null);
      handleReminderDialogClose();
    }
    // eslint-disable-next-line
  }, [error]);

  const handleSendReminders = async () => {
    const reminder = await dispatch(sendSurveyProcessReminders(employeeInfo.id));
    if (reminder?.id) {
      setTaskId(reminder.id);
    } else {
      handleReminderDialogClose();
    }
  };

  // Actions by evaluator
  const getActions = (participationId, fullName, employeeId, hasEvaluatees) => {
    const actions = [
      {
        title: t("common:common.delete"),
        icon: <StyledDeleteIcon fontSize={ "small" } />,
        onClick: () => handleDeleteParticipation(participationId, fullName),
      },
    ];

    if (isRunning && hasEvaluatees) {
      actions.push(
        {
          title: t("participation:actions.send_reminder"),
          icon: <AlarmIcon fontSize={ "small" } />,
          onClick: () => {
            handleSendReminder(employeeId, fullName);
          },
        },
      );
    }
    return (
      <MenuPopup items={ actions } />
    );
  };

  // Function to generate dynamic table header
  const generateDynamicHeader = (participation) => {
    if (participation.length === 0) return [];

    // Get evaluation types from the first element
    const firstRowEvaluations = participation[0].participation_types.map(
      (evaluation) => evaluation.evaluation_type,
    );

    // Order evaluation types according to evaluationOrder and filter out non-existing types
    const orderedEvaluationTypes = evaluationOrder.filter(
      (type) => firstRowEvaluations.includes(type),
    );

    // Base table headers
    const header = [
      {
        id: "name",
        label: t("tables:headers.name"),
        isParent: true,
        customRender: (rowData) => getProfile(rowData.employee),
      },
      {
        id: "position",
        label: t("tables:headers.position"),
        parent: "name",
      },
      {
        id: "manager",
        label: t("tables:headers.manager"),
        parent: "name",
      },
      {
        id: "organization_unit_path",
        label: t("administrator:modules.positions.header.unit"),
        parent: "name",
      },
    ];

    // Add evaluation columns in the desired order only if they exist
    orderedEvaluationTypes.forEach((type) => {
      header.push({
        id: type,
        label: t(`participation:evaluations.${type}`),
        customRender: (rowData) => {
          const evaluation = rowData.participation_types.find(
            (item) => item.evaluation_type === type,
          );

          // If the type is `autoevaluation`, render a Switch
          if (type === "autoevaluation") {
            const hasNames = evaluation && evaluation.evaluatees.length > 0;
            return generateSwitch(
              hasNames,
              type,
              rowData.participationId,
              evaluation.id,
            );
          }

          // Otherwise, render chips
          const evaluatees = evaluation
            ? evaluation.evaluatees
            : [];
          return generateChips(evaluatees, evaluation, rowData.id, rowData.participationId);
        },
      });
    });
    if (!isFinished && hasParticipationSchema) {
      header.push(
        {
          id: "actions",
          label: "",
          customRender: (rowData) => {
            const peerEvaluation = rowData.participation_types.find(
              (item) => item.evaluation_type === "pairs_evaluation",
            );
            const allEvaluatees = rowData.participation_types.flatMap((pt) => pt.evaluatees);
            const hasEvaluatees = allEvaluatees.length > 0 || peerEvaluation;
            return getActions(rowData.participationId, rowData.name, rowData.id, hasEvaluatees);
          },
        },
      );
    }

    return header;
  };

  // Generate table header
  const header = useMemo(() => generateDynamicHeader(allRows), [
    allRows,
  ]);

  const handleFiltersChange = (newFilters) => {
    setFilters(newFilters);
  };

  useEffect(() => {
    const newFilteredRows = applyFilters(filters, allRows);
    setFilteredRows(newFilteredRows);
    // eslint-disable-next-line
  }, [filters]);

  const handleResetFilter = () => {
    setResetFilters(false);
  };

  useEffect(() => {
    if (!collaboratorError && !participationError
      && participationSchema.length !== allRows.length
    ) {
      const rows = generateRows(participationSchema);
      setAllRows(rows);
      setFilteredRows(applyFilters(filters, rows));
    }
    // eslint-disable-next-line
  }, [participationSchema]);

  // Handle download Excel
  const handleDownloadExcel = () => {
    const dataToDownload = getParticipationDataToDownload(filteredRows, t);
    mainDownloadExcel(dataToDownload, t("participation:title"));
  };

  // Add new participation
  const handleAddEmployee = async (newEmployee) => {
    const participationSchemaId = employeeInfo.participation_schema.id;
    const currentData = queryClient.getQueryData(queryKey);

    const isValidCurrentData = currentData && Array.isArray(currentData.pages);

    if (!isValidCurrentData) return;

    setIsLoading(true);
    const participationTypes = currentData.pages[0].participations[0].participation_types.map(
      (evaluation) => ({
        evaluation_type: evaluation.evaluation_type,
      }
      ),
    ) || [];

    const evaluatorData = {
      participation: {
        evaluator_id: newEmployee.id,
        participation_types_attributes: participationTypes,
      },
    };

    const newEvaluator = await dispatch(createEvaluator(participationSchemaId, evaluatorData));

    if (newEvaluator?.id) {
      const updatedPages = currentData.pages.map((page, pageIndex) => {
        if (pageIndex === 0) {
          return {
            ...page,
            participations: [newEvaluator, ...page.participations],
          };
        }
        return page;
      });

      queryClient.setQueryData(queryKey, { ...currentData, pages: updatedPages });

      const newRow = generateRows([newEvaluator])[0];

      setAllRows((prevRows) => {
        const newRows = [newRow, ...prevRows];
        setFilteredRows(applyFilters(filters, newRows));
        return newRows;
      });
    }

    setIsLoading(false);
    setIsEvaluatorListVisible(false);
  };

  const handleAdd = () => {
    setResetFilters(true);
    setIsEvaluatorListVisible(true);
  };

  const handleCreateParticipationSchema = async () => {
    dispatch(createParticipationSchema(employeeInfo.id));
  };

  const handleUpdateSchema = () => {
    if (hasParticipationSchema) {
      const schemaId = employeeInfo.participation_schema.id;
      if (schemaId) {
        dispatch(updateParticipationSchema(schemaId));
      }
    } else {
      setDialog(false);
    }
  };

  useEffect(() => {
    if (successProcessSchema || errorProcessSchema) {
      setDialog(false);
    }
    if (successProcessSchema) {
      employeeInfo.participation_schema = successProcessSchema;
      queryClient.invalidateQueries(queryKey);
    }
    // eslint-disable-next-line
  }, [successProcessSchema, errorProcessSchema]);

  const availableEmployees = collaboratorList.filter(
    (collaborator) => !allRows.some(
      (participation) => participation.employee.id === collaborator.id,
    ),
  );

  const evaluationCount = !collaboratorError && !participationError
    && allRows.length > 0
    ? allRows[0].participation_types.length : 0;

  // update participation
  const [updateParticipation, setUpdateParticipation] = useState(false);
  const [updateTaskId, setUpdateTaskId] = useState(null);

  const { data: updateTask, isLoading: isLoadingUpdateTask, error: errorTask } = useQuery(
    ["updateTaskStatus", updateTaskId],
    () => dispatch(getStatus(updateTaskId)),
    {
      refetchInterval: (task) => (task?.aasm_state === "pending" ? 3000 : false),
      enabled: !!updateTaskId,
    },
  );

  const handleUpdateParticipationAsync = useCallback(async () => {
    const surveyProcess = await dispatch(getProcessByIdAsync(employeeInfo.id));
    const surveyProcessUpdated = Object.values(surveyProcess)[0];
    if (surveyProcessUpdated) {
      toast(MESSAGE_TYPES.success, {
        message: t("planning:steps.participation.actions.alert.update.alert.success"),
      });
      setUpdateTaskId(null);
      setUpdateParticipation(false);
      employeeInfo.participation_schema = surveyProcessUpdated.participation_schema;
      queryClient.invalidateQueries(queryKey);
    }
  });

  useEffect(() => {
    if (updateTask && updateTask?.aasm_state !== "pending") {
      handleUpdateParticipationAsync();
    }
    // eslint-disable-next-line
  }, [updateTask]);

  useEffect(() => {
    if (errorTask) {
      setUpdateTaskId(null);
      setUpdateParticipation(false);
    }
    // eslint-disable-next-line
  }, [errorTask]);

  const handleUpdateParticipation = async () => {
    const updateParticipationTask = await dispatch(
      sendUpdateParticipation(employeeInfo.id),
    );
    if (updateParticipationTask?.id) {
      setUpdateTaskId(updateParticipationTask.id);
    } else {
      setUpdateTaskId(null);
      setUpdateParticipation(false);
    }
  };

  return (
    <StyledContainer
      evaluations={ isCustomParticipationSchema ? evaluationCount + 1 : evaluationCount }
    >
      {collaboratorError && <StyledError>{collaboratorError}</StyledError>}
      {participationError && <StyledError>{participationError}</StyledError>}
      {allRows.length > 0
      && (
        <Filters
          collaboratorList={ collaboratorList }
          isDisabled={ isLoadingCollaborators || isFetchingParticipation }
          onFiltersChange={ handleFiltersChange }
          resetFilters={ resetFilters }
          handleResetFilter={ handleResetFilter }
          t={ t }
        />
      )}
      {isValidToUpdateParticipation
        && (
          <StyledButton
            isDisabled={ isLoadingCollaborators || isLoadingParticipation
          || collaboratorList.length === 0 || isFetchingParticipation
          || isFinished }
            onClick={ () => setUpdateParticipation(true) }
            variant={ "outlined" }
          >
            {t("participation:actions.update_participation")}
            <TooltipIcon
              title={ t("participation:actions.update_participation_description") }
              isHelpIcon
            />
          </StyledButton>
        )}
      {isCustomParticipationSchema
        ? (
          <StyledButton
            isDisabled={ isLoadingCollaborators || isEvaluatorListVisible
          || availableEmployees.length === 0 || filteredRows.length === 0
          || isFetchingParticipation || isFinished }
            onClick={ handleAdd }
            variant={ "outlined" }
          >
            {t("participation:actions.add_employees")}
          </StyledButton>
        ) : (
          <StyledButton
            isDisabled={ isLoadingCollaborators || isLoadingParticipation
                || collaboratorList.length === 0 || isFetchingParticipation
                || isFinished }
            onClick={ () => setDialog(true) }
            variant={ "outlined" }
          >
            {t("participation:actions.customize_participation")}
          </StyledButton>
        )}
      {isRunning
        && (
          <StyledButton
            isDisabled={ isLoadingCollaborators || isLoadingParticipation
              || allRows.length === 0 }
            onClick={ () => setReminderDialog(true) }
            variant={ "outlined" }
          >
            {t("participation:actions.send_reminder")}
          </StyledButton>
        )}
      {isEvaluatorListVisible && (
        <EvaluatorList
          t={ t }
          onSave={ handleAddEmployee }
          onCancel={ () => setIsEvaluatorListVisible(false) }
          allEmployees={ availableEmployees }
          isLoading={ isLoading }
        />
      )}
      <TableGrid
        rows={ filteredRows }
        id={ "collaborator-table-grid" }
        header={ header }
        paginationOptions={ {
          maxPerPage: ROWS_RANGE_PER_PAGE[0],
          rowsRange: ROWS_RANGE_PER_PAGE,
        } }
        topContent={ (
          <DownloadExcelButton
            onClick={ handleDownloadExcel }
            isDisabled={ filteredRows.length === 0 || isFetchingParticipation }
          />
        ) }
        isMobile={ isMobile }
        isLoading={ isLoadingParticipation }
        isDisabledSearch={ filteredRows.length === 0 }
      />
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => setDialog(false) }
        title={ t("participation:actions.customize_participation") }
        message={ t("participation:actions.customize_participation_description") }
        onSubmit={ isRunning ? handleUpdateSchema : handleCreateParticipationSchema }
        buttons={ {
          isLoading: isLoadingProcessSchema,
        } }
      />
      <AlertDialog
        isOpen={ dialogOpen }
        onClose={ handleDialogClose }
        title={ t("participation:actions.delete.title", { name: selectedParticipation?.name }) }
        message={ t("participation:actions.delete.message") }
        onSubmit={ handleDialogSubmit }
      />
      <AlertDialog
        isOpen={ reminderDialog }
        onClose={ handleReminderDialogClose }
        title={ t("participation:actions.reminder.title") }
        message={ reminderInfo
          ? t("participation:actions.reminder.message", { name: reminderInfo?.name })
          : t("participation:actions.reminder.all") }
        onSubmit={ reminderInfo ? handleSendReminderSubmit : handleSendReminders }
        buttons={ {
          isLoading: isLoadingTask || taskId,
        } }
      />
      <AlertDialog
        isOpen={ updateParticipation }
        onClose={ () => setUpdateParticipation(false) }
        title={ t("participation:actions.update_participation") }
        message={ t("participation:actions.update_participation_description") }
        onSubmit={ handleUpdateParticipation }
        buttons={ {
          isLoading: isLoadingUpdateTask || updateTaskId,
        } }
        alertMessage={ isLoadingUpdateTask || updateTaskId ? {
          title: t("planning:steps.participation.actions.alert.update.alert.title"),
          message: t("planning:steps.participation.actions.alert.update.alert.message"),
        } : null }
      />
    </StyledContainer>
  );
};

Participation.propTypes = {
  employeeInfo: PropTypes.shape({
    audience_id: PropTypes.number,
    id: PropTypes.number.isRequired,
    participation_schema: PropTypes.shape({
      id: PropTypes.number,
      nala_protected: PropTypes.bool,
    }),
    isLoading: PropTypes.bool,
    state: PropTypes.string,
    goals_active: PropTypes.bool,
  }).isRequired,
  shouldRefetch: PropTypes.bool.isRequired,
};

export default Participation;
