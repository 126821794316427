import { useState } from "react";
import PropTypes from "prop-types";
import { useForm } from "react-hook-form"; // Import useForm
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { updateEvaluationScale } from "redux/actions/evaluationScaleActions";
import InputTextController from "components/InputTextController";
import SimpleAlert from "components/SimpleAlert";
import Button from "components/Button";
import AlertDialog from "components/AlertDialog";
import {
  MESSAGE_TYPES,
  toast,
  handleMessages,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import {
  BUTTON_STYLE_TYPES,
  VARIANT,
  COLORS_NAME,
  ALIGN_ITEMS,
  TYPES,
  INFO,
} from "common/constants";
import { getPercent } from "common/utils";
import { orderByDesc } from "common/helpers";
import LanguageSelector from "components/LanguageSelector";
import OptionInput from "./components/OptionInput";
import { isAtLeastOneLanguageComplete, transformFormData } from "./functions";
import { StyledContainer } from "../EvaluationScaleForm/styles";

const EvaluationScaleEditForm = (props) => {
  const {
    t, onLanguageChange,
    formLanguage, dispatch, handleCancelSectionClick,
    handleEditScale, scale,
  } = props;

  const [dialog, setDialog] = useState(false);
  const {
    control, handleSubmit, register,
    setValue, watch, reset,
  } = useForm();

  const languageCodes = ["es", "en", "pt"];

  const optionIds = scale.indices.map((index) => index.id);

  const editEvaluationScale = async (data) => {
    const transformedData = transformFormData(data, optionIds, scale);
    if (transformedData === null) {
      const message = t("planning:alert.noChanges");
      toast(
        MESSAGE_TYPES.warning,
        handleMessages(MESSAGE_TYPES.warning, HTTP_STATUS_RESPONSE.ok, t, message),
      );
    } else {
      const evaluationScaleResponse = await dispatch(
        updateEvaluationScale(transformedData, scale.id),
      );
      if (evaluationScaleResponse) {
        const message = t("planning:templates.evaluation_scales.alert.edit_alert.success");
        toast(
          MESSAGE_TYPES.info,
          handleMessages(MESSAGE_TYPES.info, HTTP_STATUS_RESPONSE.ok, t, message),
        );
        setDialog(false);
        reset();
        handleCancelSectionClick();
        handleEditScale(evaluationScaleResponse);
      }
    }
  };

  const onSubmit = (data) => {
    const isValidData = isAtLeastOneLanguageComplete(data, optionIds, languageCodes);

    if (isValidData) {
      editEvaluationScale(data);
    } else {
      const message = t("planning:templates.evaluation_scales.alert.emptyOptions");
      toast(
        MESSAGE_TYPES.error,
        handleMessages(MESSAGE_TYPES.error, HTTP_STATUS_RESPONSE.ok, t, message),
      );
    }
  };

  const saveForm = () => {
    setDialog(true);
  };

  return (
    <StyledContainer component={ "form" }>
      <LanguageSelector
        onLanguageChange={ onLanguageChange }
        value={ formLanguage }
      />
      <Typography variant={ "h4" }>
        {t("planning:templates.evaluation_scales.edit")}
      </Typography>
      <SimpleAlert
        type={ INFO }
        message={ t("planning:templates.evaluation_scales.alert.edit") }
      />
      {languageCodes.map((langCode) => (
        <div
          key={ `scale_name_${langCode}` }
          style={ { display: formLanguage === langCode ? "block" : "none" } }
        >
          <InputTextController
            type={ TYPES.text }
            label={ `${t("common:common.name")} (${t(`common:locales:${langCode}`)})` }
            control={ control }
            name={ `scale_name_${langCode}` }
            defaultValue={ scale[`name_${langCode}`] }
            disabled
          />
          <InputTextController
            type={ TYPES.text }
            label={ `${t("tables:headers.description")} (${t(`common:locales:${langCode}`)})` }
            control={ control }
            name={ `description_${langCode}` }
            defaultValue={ scale[`description_${langCode}`] }
            multiline={ 2 }
          />
        </div>
      ))}
      <Typography variant={ "h5" }>
        {t("planning:templates.evaluation_scales.options")}
      </Typography>
      {orderByDesc(scale?.indices, "value").map((option) => (
        <OptionInput
          key={ option.id }
          id={ option.id }
          percentage={ getPercent(option.value) }
          formLanguage={ formLanguage }
          register={ register }
          setValue={ setValue }
          watch={ watch }
          option={ option }
        />
      ))}
      <Box
        display={ ALIGN_ITEMS.flex }
        justifyContent={ ALIGN_ITEMS.flexEnd }
      >
        <Box mt={ 2 } ml={ 2 }>
          <Button
            onClick={ handleCancelSectionClick }
            variant={ VARIANT.contained }
            color={ COLORS_NAME.primary }
            typeStyle={ BUTTON_STYLE_TYPES.CANCEL }
          >
            {t("common:common.cancel")}
          </Button>
        </Box>
        <Box mt={ 2 } ml={ 2 }>
          <Button
            onClick={ saveForm }
            variant={ VARIANT.contained }
            color={ COLORS_NAME.primary }
            typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
          >
            {t("common:common.save")}
          </Button>
        </Box>
      </Box>
      <AlertDialog
        isOpen={ dialog }
        onClose={ () => setDialog(false) }
        title={ t("planning:templates.evaluation_scales.alert.edit_alert.title") }
        message={ t("planning:templates.evaluation_scales.alert.edit_alert.description") }
        onSubmit={ handleSubmit(onSubmit) }
      />
    </StyledContainer>
  );
};

EvaluationScaleEditForm.propTypes = {
  t: PropTypes.func.isRequired,
  onLanguageChange: PropTypes.func.isRequired,
  formLanguage: PropTypes.string.isRequired,
  dispatch: PropTypes.func.isRequired,
  handleCancelSectionClick: PropTypes.func.isRequired,
  handleEditScale: PropTypes.func.isRequired,
  scale: PropTypes.array.isRequired,
};

export default EvaluationScaleEditForm;
