import forEach from "lodash/forEach";
import find from "lodash/find";
import inRange from "lodash/inRange";
import orderBy from "lodash/orderBy";
import includes from "lodash/includes";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import NoDataMessage from "components/NoDataMessage";
import StaticProgressBar from "components/StaticProgressBar";
import BubbleProgress from "components/BubbleProgress";
import HorizontalBarChart from "components/HorizontalBarChart";
import DownloadExcelButton from "components/DownloadExcelButton";
import { PIE_GRAPHICS_COLOR, ENGAGEMENT } from "theme/palette";
import TotallySatisfiedIcon from "assets/images/engagement/totally_satisfied.svg";
import SatisfiedIcon from "assets/images/engagement/satisfied.svg";
import NeutralIcon from "assets/images/engagement/neutral.svg";
import DissatisfiedIcon from "assets/images/engagement/dissatisfied.svg";
import TotallyDissatisfiedIcon from "assets/images/engagement/totally_dissatisfied.svg";
import SatisfactionIcon from "assets/images/engagement/satisfied_legend.svg";
import MediumIcon from "assets/images/engagement/neutral_legend.svg";
import UnsatisfiedIcon from "assets/images/engagement/unsatisfied_legend.svg";
import {
  ENGAGEMENT_DATA,
  ASC, VARIANT,
  COMPANY_SLUGS,
  MIN_VALUE, PERCENTAGE,
  OBJECT_KEYS,
  INDEX,
} from "common/constants";
import { SATISFACTION_RANGE, ENGAGEMENT_SEARCH } from "common/constants/engagement";
import {
  getMonthName, getMonthYear, getPercent, isAdmin,
} from "common/utils";
import { DIRECTIVES_ENGAGEMENT } from "common/access";
import {
  isUndefined,
  isNull,
  isEmpty,
  isEqual,
  orderByAsc,
  orderByDesc,
} from "common/helpers";
import commentsIcon from "assets/images/potential/icon-recomendacion.svg";
import charts from "theme/charts";
import ResultsByQuestion from "../components/ResultsByQuestion";
import LegendByRanking from "../components/LegendByRanking";
import NpsAndSatisfaction from "../components/NpsAndSatisfaction";
import NpsAndSatisfactionTable from "../components/NpsAndSatisfactionTable";
import FavorabilityRankingTable from "../components/FavorabilityRankingTable";
// news
import SatisfactionByQuestion from "../components_refactor/GeneralEngagement/SatisfactionByQuestion";
// end
import {
  StyledAnswers,
  StyledAccordionHeader,
  StyledQuestion,
  StyledScaleIcon,
  StyledGridContainer,
} from "../styledComponents";

export const getColorsPie = [
  PIE_GRAPHICS_COLOR.GREEN,
  PIE_GRAPHICS_COLOR.RED,
  PIE_GRAPHICS_COLOR.BLUE,
  PIE_GRAPHICS_COLOR.ORANGE,
];

export const getResultByDirectories = (data, element, percentage = false) => {
  const results = [];

  if (!isEmpty(data) && !isUndefined(data)) {
    data.forEach((item) => {
      if (!isNull(item[element])) {
        results.push(percentage ? getPercent(item[element]) : item[element]);
      }
    });
  }
  return results;
};

const question = (name, result, cellClass) => ({ name, result, cellClass });

export const getCellClassByRange = (value, classes) => {
  switch (true) {
  case inRange(
    value,
    ENGAGEMENT_DATA.range.unsatisfied.min,
    ENGAGEMENT_DATA.range.unsatisfied.max,
  ):
    return classes.unsatisfied;
  case inRange(
    value,
    ENGAGEMENT_DATA.range.unsatisfiedLight.min,
    ENGAGEMENT_DATA.range.unsatisfiedLight.max,
  ):
    return classes.unsatisfiedLight;
  case inRange(
    value,
    ENGAGEMENT_DATA.range.neutralLight.min,
    ENGAGEMENT_DATA.range.neutralLight.max,
  ):
    return classes.neutralLight;
  case inRange(
    value,
    ENGAGEMENT_DATA.range.neutral.min,
    ENGAGEMENT_DATA.range.neutral.max,
  ):
    return classes.neutral;
  case inRange(
    value,
    ENGAGEMENT_DATA.range.satisfiedLight.min,
    ENGAGEMENT_DATA.range.satisfiedLight.max,
  ):
    return classes.satisfiedLight;
  default:
    return classes.satisfied;
  }
};

export const questionResult = (data, directory, classes) => {
  const results = [];
  directory?.questions_result
    && forEach(directory.questions_result, (item) => {
      const questionName = find(data.questions, { id: item.question_id })
        ?.question;
      const cellClass = getCellClassByRange(item.result, classes);
      results.push(question(questionName, item.result, cellClass));
    });
  return results.sort((a, b) => b.result - a.result);
};

export const getThemes = (t) => {
  const themes = [
    t("themes.visionAndLeadership"),
    t("themes.opportunities"),
    t("themes.impactAndIncentives"),
    t("themes.communityAndCommunication"),
    t("themes.entrepreneurship"),
    t("themes.engagementAndMotivation"),
  ];
  return themes;
};

const setThemeDetails = (name, description, questions) => ({ name, description, questions });

const getQuestions = (questionsId, questions) => {
  const questionList = [];
  questionsId.forEach((questionId) => {
    const questionDetails = find(questions, { id: questionId });
    questionDetails && questionList.push(questionDetails);
  });
  return questionList;
};

export const getThemeDetails = (themes, descriptions, questions, directory) => {
  const dataByTheme = [];
  directory?.themes_result
    && forEach(directory.themes_result, (result, index) => {
      dataByTheme.push(
        setThemeDetails(
          themes[index],
          descriptions[index].description,
          getQuestions(result.questions, questions),
        ),
      );
    });
  return dataByTheme;
};

export const getFormatDates = (previousDate, currentDate, t) => {
  const formatDates = {
    previousMonth: getMonthName(new Date(previousDate), t),
    currentMonth: getMonthName(new Date(currentDate), t),
    previousMonthYear: getMonthYear(previousDate, t),
    currentMonthYear: getMonthYear(currentDate, t),
  };
  return formatDates;
};

const comparativeDateHeader = (t, formatDates, classes) => ({
  id: 0,
  name: "",
  currentMonth: formatDates.currentMonth,
  previousMonth: formatDates.previousMonth,
  cellClass: classes.comparativeHeader,
});

export const getDirectoryAndPrincipal = (
  data,
  directories,
  formatDates,
  t,
  classes,
) => {
  const comparativeDates = formatDates && classes && comparativeDateHeader(t, formatDates, classes);
  const result = [comparativeDates, data];
  const principal = find(directories, { principal: true });
  result.push(principal);
  return orderBy(result, [ENGAGEMENT_DATA.searchItems.id], [ASC]);
};

export const getResultsByArea = (data, directories) => {
  const principal = find(directories, { principal: true });
  const result = [principal, data];
  data.areas
    && data.areas.forEach((area) => {
      result.push(area);
    });
  return result;
};

const getComments = (comments, name, classes) => (
  <div>
    <ul variant={ VARIANT.bodyOne }>
      {comments.map((comment, index) => (
        <div key={ `${name}${index}` }>
          <Box pb={ 3 }>
            <Typography variant={ VARIANT.h5 }>{comment.question}</Typography>
          </Box>
          {comment.answers.map((answer, index) => (
            <Box
              key={ `${name}${index}` }
              pb={ 2 }
              className={ classes.commentList }
            >
              <Typography variant={ VARIANT.bodyOne }>{`${answer}`}</Typography>
            </Box>
          ))}
        </div>
      ))}
    </ul>
  </div>
);

export const getCommentsByArea = (data, classes) => {
  const commentsList = [];
  data.comments
    && commentsList.push({
      title: data.name,
      src: commentsIcon,
      variant: VARIANT.button,
      customRender: getComments(data.comments, data.name, classes),
    });

  data.areas
    && data.areas.forEach((area) => {
      area.comments
        && commentsList.push({
          title: area.name,
          src: commentsIcon,
          variant: VARIANT.button,
          customRender: getComments(area.comments, area.name, classes),
        });
    });
  return commentsList;
};

export const hasData = (formatDates, data, allData) => formatDates && data && allData;

export const isNotBluUser = (user) => !isEqual(
  user.company?.slug,
  COMPANY_SLUGS.blu
      || (!isAdmin(user?.userCookies)
        && !includes(DIRECTIVES_ENGAGEMENT.blu, user.email)),
);

export const isHaulmerUser = (user) => user?.company?.slug === COMPANY_SLUGS.haulmer;

export const getSatisfactionIcon = (value) => {
  let iconResult = null;

  switch (true) {
  case inRange(value, SATISFACTION_RANGE.totallySatisfied.min, SATISFACTION_RANGE.totallySatisfied.max):
    iconResult = TotallySatisfiedIcon;
    break;
  case inRange(value, SATISFACTION_RANGE.satisfied.min, SATISFACTION_RANGE.satisfied.max):
    iconResult = SatisfiedIcon;
    break;
  case inRange(value, SATISFACTION_RANGE.neutral.min, SATISFACTION_RANGE.neutral.max):
    iconResult = NeutralIcon;
    break;
  case inRange(value, SATISFACTION_RANGE.dissatisfied.min, SATISFACTION_RANGE.dissatisfied.max):
    iconResult = DissatisfiedIcon;
    break;
  case inRange(value, SATISFACTION_RANGE.totallyDissatisfied.min, SATISFACTION_RANGE.totallyDissatisfied.max):
    iconResult = TotallyDissatisfiedIcon;
    break;
  default:
    break;
  }

  return iconResult;
};

export const getHeader = (topic, prop, language) => (
  <StyledAccordionHeader container spacing={ 2 }>
    <Grid item xs={ 12 } md={ 6 }>
      <Typography variant={ VARIANT.h6 }>{ topic[`name_${language}`] }</Typography>
    </Grid>
    <Grid item xs={ 12 } md={ 6 }>
      <StaticProgressBar
        icon={ getSatisfactionIcon(getPercent(topic[prop])) }
        percentage={ getPercent(topic[prop] || MIN_VALUE) }
      />
    </Grid>
  </StyledAccordionHeader>
);

const getResults = (questions, prop, language) => (isEmpty(questions) ? <NoDataMessage />
  : (
    <StyledAnswers>
      {questions?.map((item) => (
        <div key={ item.id }>
          <StyledQuestion container spacing={ 3 }>
            <Grid item xs={ 12 } md={ 5 }>
              <Typography variant={ VARIANT.bodyOne }>{ item[`question_${language}`] }</Typography>
            </Grid>
            <Grid item xs={ 12 } md={ 7 }>
              <StaticProgressBar
                percentage={ getPercent(item[prop] || MIN_VALUE) }
                isSublevel
              />
            </Grid>
          </StyledQuestion>
        </div>
      ))}
    </StyledAnswers>
  ));

export const getTopics = (topics, prop, language) => topics?.map((topic) => {
  const questions = isEqual(prop, OBJECT_KEYS.satisfaction) ? topic.evaluation_questions : topic.evaluation_results[MIN_VALUE].evaluation_answers;
  return {
    title: topic[`name_${language}`],
    customRender: getResults(questions, prop, language),
    customHeader: getHeader(topic, prop, language),
  };
});

export const getResultsByDivision = (divisions) => getResultByDirectories(
  divisions,
  ENGAGEMENT_SEARCH.name,
);

export const getRandomColors = (data) => data.map((item) => charts.colors.engagementBar[item.index]);

const truncateLabel = (label) => (label.length > 10 ? `${label.substring(0, 10) }...` : label);

export const getDataBarNps = (divisions, randomColors = false) => {
  const npsResult = divisions.filter((division) => !isNull(division.nps));
  const labels = getResultsByDivision(npsResult);
  const truncatedLabels = labels.map(truncateLabel);

  return {
    labels: truncatedLabels,
    fullLabels: labels,
    datasets: [
      {
        data: getResultByDirectories(
          npsResult,
          ENGAGEMENT_SEARCH.nps,
          true,
        ),
        backgroundColor: randomColors ? getRandomColors(npsResult) : ENGAGEMENT.default,
        hoverBackgroundColor: randomColors ? getRandomColors(npsResult) : ENGAGEMENT.default,
      },
    ],
  };
};

export const getDataSatisfactionBar = (divisions, t, randomColors = false) => {
  const satisfactionResult = divisions.filter((division) => !isNull(division.satisfaction));
  const labels = getResultsByDivision(satisfactionResult);
  const truncatedLabels = labels.map(truncateLabel);

  return {
    labels: truncatedLabels,
    fullLabels: labels,
    datasets: [
      {
        label: t("satisfied"),
        data: getResultByDirectories(
          satisfactionResult,
          ENGAGEMENT_SEARCH.satisfaction,
          true,
        ),
        backgroundColor: randomColors ? getRandomColors(satisfactionResult) : ENGAGEMENT.default,
        hoverBackgroundColor: randomColors ? getRandomColors(satisfactionResult) : ENGAGEMENT.default,
      },
      {
        label: t("neutral"),
        data: getResultByDirectories(
          satisfactionResult,
          ENGAGEMENT_SEARCH.neutrality,
          true,
        ),
        backgroundColor: ENGAGEMENT.neutral_purple,
        hoverBackgroundColor: ENGAGEMENT.neutral_purple,
      },
      {
        label: t("unsatisfied"),
        data: getResultByDirectories(
          satisfactionResult,
          ENGAGEMENT_SEARCH.insatisfaction,
          true,
        ),
        backgroundColor: ENGAGEMENT.unsatisfied_purple,
        hoverBackgroundColor: ENGAGEMENT.unsatisfied_purple,
      },
    ],
  };
};

const getCommentsByQuestion = (comments, t) => (
  <Grid container spacing={ 2 }>
    <Grid item xs={ 12 } md={ 6 } key={ t("keepDoing") }>
      <Box p={ 2 }>
        <Box pb={ 3 }>
          <Typography variant={ VARIANT.h6 }>{t("keepDoing")}</Typography>
        </Box>
        {comments?.keep_doing.map((comment, index) => (
          <Box
            key={ `${OBJECT_KEYS.keepDoing}${index}` }
            pb={ 2 }
          >
            <Typography variant={ VARIANT.bodyOne }>{`${comment}`}</Typography>
          </Box>
        ))}
      </Box>
    </Grid>
    <Grid item xs={ 12 } md={ 6 } key={ t("stopDoing") }>
      <Box p={ 2 }>
        <Box pb={ 3 }>
          <Typography variant={ VARIANT.h6 }>{t("stopDoing")}</Typography>
        </Box>
        {comments?.stop_doing.map((comment, index) => (
          <Box
            key={ `${OBJECT_KEYS.stopDoing}${index}` }
            pb={ 2 }
          >
            <Typography variant={ VARIANT.bodyOne }>{`${comment}`}</Typography>
          </Box>
        ))}
      </Box>
    </Grid>
  </Grid>
);

export const getCommentsByDivision = (divisions, t) => divisions.map((division) => ({
  title: division.name,
  customRender: getCommentsByQuestion(division, t),
}));

const getBubbleResult = (topic, divisions, participation) => divisions.map((division) => {
  const satisfaction = find(division.topics, { id: topic.id })?.satisfaction;
  return {
    name: division.name,
    value: getPercent(satisfaction),
    size: getPercent(division.forms_filled) / participation,
  };
});

const getSatisfactionHeader = (topic, divisions, participation, language) => (
  <StyledAccordionHeader container spacing={ 2 }>
    <Grid item xs={ 12 } md={ 5 }>
      <Typography variant={ VARIANT.h6 }>{ topic[`name_${language}`] }</Typography>
    </Grid>
    <Grid item xs={ 12 } md={ 7 }>
      <BubbleProgress data={ getBubbleResult(topic, divisions, participation) } />
    </Grid>
  </StyledAccordionHeader>
);

const getResultsBySatisfaction = (topic, divisions, t) => {
  const dataByDivisions = divisions?.map((division) => ({
    name: division.name,
    total: division.forms_filled,
    score: find(division.topics, { id: topic.id })?.satisfaction,

  }));
  return (
    <StyledAnswers>
      <HorizontalBarChart data={ dataByDivisions } label={ t("totalCollaborators") } />
    </StyledAnswers>
  );
};

const getIconHeader = (t) => (
  <StyledGridContainer container spacing={ 2 }>
    <Grid item xs={ 12 } md={ 7 }>
      <StyledScaleIcon>
        <img src={ UnsatisfiedIcon } alt={ t("unsatisfied") } />
        <img src={ MediumIcon } alt={ t("neutral") } />
        <img src={ SatisfactionIcon } alt={ t("satisfied") } />
      </StyledScaleIcon>
    </Grid>
  </StyledGridContainer>
);

export const getSatisfactionByTopic = (topics, formsFilled, divisions, t, language) => {
  const results = topics?.map((topic) => ({
    title: topic[`name_${language}`],
    customRender: getResultsBySatisfaction(topic, divisions, t),
    customHeader: getSatisfactionHeader(topic, divisions, formsFilled, language),
  }));
  results.unshift(
    {
      title: t("topics"),
      customHeader: getIconHeader(t),
    },
  );
  return results;
};

export const getParticipation = (participation, collaborators) => `${Math.round((participation * PERCENTAGE.max) / collaborators) || MIN_VALUE}%`;

export const isInvalidFormsFilled = (item, MIN_FILLED) => item > MIN_VALUE && item < MIN_FILLED;

export const switchOptions = (label, value, content = "", customTitle = null, isTitleBelow = false, selectedBackgroundColor = ENGAGEMENT.selector.selectedBg, selectedFontColor = ENGAGEMENT.selector.selectedFont) => (
  {
    label, value, content, customTitle, selectedBackgroundColor, selectedFontColor, isTitleBelow,
  }
);

const satisfactionRange = (min, max, color, title, minValue, maxValue) => ({
  min, max, color, title, minValue, maxValue,
});

export const satisfactionRangeData = () => {
  const dataRange = ENGAGEMENT_DATA.range;
  const rangeTitle = ["unsatisfied", "unsatisfiedLight", "neutralLight", "neutral", "satisfiedLight", "satisfied"];
  return rangeTitle.map((item, index) => {
    let minValue = dataRange[item].min;
    let maxValue = dataRange[item].max;
    if (index !== INDEX.zero) {
      minValue = dataRange[item].min + INDEX.one;
    }
    if (index === rangeTitle.length - INDEX.one) {
      maxValue = dataRange[item].max - INDEX.one;
    }
    return satisfactionRange(dataRange[item].min, dataRange[item].max, ENGAGEMENT[item], item, minValue, maxValue);
  });
};

const getCellColorByRange = (value) => {
  const result = getPercent(value);
  const satisfactionData = satisfactionRangeData();
  const colorsByRange = satisfactionData.map((item) => inRange(result, item.min, item.max));
  const getValidIndex = colorsByRange.indexOf(true);
  return satisfactionData[getValidIndex]?.color;
};

export const getOptionsByQuestions = (t, data, language, prop = OBJECT_KEYS.satisfaction) => {
  let engagementContentByTopics = <NoDataMessage />;
  let favorabilityRanking = <NoDataMessage />;
  const favorabilityRankingData = { questionList: [], criticalTopics: [] };
  const isTitleBelow = !isEqual(prop, OBJECT_KEYS.satisfaction);
  if (data) {
    orderByAsc(data, prop).forEach((item, index) => {
      if (item[prop] <= ENGAGEMENT_DATA.criticalTopic && index <= INDEX.two) {
        const criticResult = getPercent(item[prop], true);
        favorabilityRankingData.criticalTopics.push(`${ index + INDEX.one }. ${ item[`name_${language}`] } (${ criticResult })`);
      }
      const questions = isEqual(prop, OBJECT_KEYS.satisfaction) ? item.evaluation_questions : item.evaluation_results[MIN_VALUE].evaluation_answers;
      questions.forEach((evalQuestion, QuestionIndex) => {
        evalQuestion.cellColor = getCellColorByRange(evalQuestion[prop]);
        evalQuestion.id = `${index}-${QuestionIndex}`;
        favorabilityRankingData.questionList.push(evalQuestion);
      });
    });
  }

  const topicsData = getTopics(data, prop, language);

  if (topicsData?.length !== INDEX.zero) {
    favorabilityRankingData.questionList = orderByDesc(favorabilityRankingData.questionList, prop);
    engagementContentByTopics = <ResultsByQuestion values={ topicsData } />;
    favorabilityRanking = <FavorabilityRankingTable values={ favorabilityRankingData } prop={ prop } />;
  }
  return [
    switchOptions(t("generalEngagement"), INDEX.zero, engagementContentByTopics, null, isTitleBelow),
    switchOptions(t("favorabilityRanking"), INDEX.one, favorabilityRanking, <LegendByRanking />, isTitleBelow),
  ];
};

const resultsByDivisionHeader = (t) => [
  {
    id: "name",
    label: t("division"),
  },
  {
    id: "nps",
    label: t("nps"),
    isParent: true,
  },
  {
    id: "promotors",
    label: `${t("promoters")} (9-10)`,
    icon: SatisfactionIcon,
    color: ENGAGEMENT.bgLight,
    key: "nps",
  },
  {
    id: "neutral",
    label: `${t("neutral")} (7-8)`,
    icon: NeutralIcon,
    color: ENGAGEMENT.bgLight,
    key: "nps",
  },
  {
    id: "detractors",
    label: `${t("detractors")} (1-6)`,
    icon: UnsatisfiedIcon,
    color: ENGAGEMENT.bgLight,
    key: "nps",
  },
  {
    id: "forms_filled",
    label: t("participation"),
  },
  {
    id: "satisfaction",
    label: t("satisfaction"),
    icon: SatisfactionIcon,
    isParent: true,
  },
  {
    id: "neutrality",
    label: t("neutral"),
    icon: NeutralIcon,
    color: ENGAGEMENT.bgLight,
    key: "satisfaction",
  },
  {
    id: "insatisfaction",
    label: t("unsatisfied"),
    icon: UnsatisfiedIcon,
    color: ENGAGEMENT.bgLight,
    key: "satisfaction",
  },
];

export const getNpsAndSatisfactionResult = (satisfaction, t) => {
  satisfaction.forEach((item) => {
    item.nps = getPercent(item.nps);
    item.satisfaction = getPercent(item.satisfaction, true);
    item.neutrality = getPercent(item.neutrality, true);
    item.insatisfaction = getPercent(item.insatisfaction, true);
  });

  const resultsByDivision = { header: resultsByDivisionHeader(t), data: satisfaction };
  return resultsByDivision;
};

export const downloadExcel = (t, npsAndSatisfactionData) => () => {
  const filename = t("generalSatisfaction");
  const data = npsAndSatisfactionData.map((item) => (
    {
      [t("division")]: item.name,
      [t("nps")]: item.nps,
      [`${t("promoters")} (9-10)`]: item.promotors,
      [`${t("neutral")} (7-8)`]: item.neutral,
      [`${t("detractors")} (1-6)`]: item.detractors,
      [t("participation")]: item.forms_filled,
      [t("satisfaction")]: item.satisfaction,
      [t("neutral")]: item.neutrality,
      [t("unsatisfied")]: item.insatisfaction,
    }));

  return [data, filename];
};

export const getNpsAndSatisfactionChart = (t, nps, satisfaction, npsAndSatisfactionResult) => {
  const npsAndSatisfactionChart = <NpsAndSatisfaction nps={ nps } satisfaction={ satisfaction } />;
  const npsAndSatisfactionTable = <NpsAndSatisfactionTable values={ npsAndSatisfactionResult } />;
  const { data } = npsAndSatisfactionResult;

  return [
    switchOptions(t("seeChart"), INDEX.zero, npsAndSatisfactionChart, " "),
    switchOptions(
      t("seeTable"),
      INDEX.one,
      npsAndSatisfactionTable,
      <DownloadExcelButton
        onClick={ downloadExcel(t, data) }
        isDisabled={ data?.length === 0 }
        className={ "download-satisfaction" }
      />,
    ),
  ];
};

export const orderEngagementProcesses = (processes, language) => {
  const evaluationsWithoutFollow = processes
    ?.filter((process) => process.has_results && !process.follow_up_process_id)
    .sort((a, b) => Date(a.end_date) - Date(b.end_date))
    .map((item) => ({
      value: item.id,
      label: item[`name_${language}`],
      follow_up_process_id: null,
    }));
  const evaluationsWithFollow = processes
    ?.filter((process) => process.has_results && process.follow_up_process_id)
    .sort((a, b) => Date(a.end_date) - Date(b.end_date))
    .map((item) => ({
      value: item.id,
      label: item[`name_${language}`],
      follow_up_process_id: item.follow_up_process_id,
    }));
  return evaluationsWithoutFollow.concat(evaluationsWithFollow);
};

// news
export const getTopicData = (topics, prop, language) => topics?.map((topic) => ({
  title: topic[`name_${language}`],
  id: topic.id,
  customRender: [],
  customHeader: getHeader(topic, prop, language),
}));

export const getQuestionContent = (questions, language) => (
  (questions.length === 0) ? <NoDataMessage />
    : (
      <StyledAnswers>
        {questions?.map((item) => (
          <div key={ item.id }>
            <StyledQuestion container spacing={ 3 }>
              <Grid item xs={ 12 } md={ 5 }>
                <Typography variant={ VARIANT.bodyOne }>{ item[`question_${language}`] }</Typography>
              </Grid>
              <Grid item xs={ 12 } md={ 7 }>
                <StaticProgressBar
                  percentage={ getPercent(item[OBJECT_KEYS.satisfaction] || 0) }
                  isSublevel
                />
              </Grid>
            </StyledQuestion>
          </div>
        ))}
      </StyledAnswers>
    ));

// with handle
export const getSections = (t, topics, language) => {
  let engagementGeneralContent = <NoDataMessage />;
  let favorabilityRanking = <NoDataMessage />;

  const favorabilityRankingData = { questionList: [], criticalTopics: [] };
  const topicsData = getTopicData(topics, OBJECT_KEYS.satisfaction, language);
  if (topicsData?.length !== 0) {
    engagementGeneralContent = (
      <SatisfactionByQuestion
        values={ topicsData }
        language={ language }
      />
    );
    favorabilityRanking = (
      <FavorabilityRankingTable
        values={ favorabilityRankingData }
        prop={ OBJECT_KEYS.satisfaction }
      />
    );
  }
  return [
    switchOptions(t("generalEngagement"), 0, engagementGeneralContent, null, false),
    switchOptions(t("favorabilityRanking"), INDEX.one, favorabilityRanking, <LegendByRanking />, false),
  ];
};

// FIXME: add new component
export const getOptions = (t, data, language, isInvalidData, prop = OBJECT_KEYS.satisfaction) => {
  let engagementContentByTopics = <NoDataMessage />;
  let favorabilityRanking = <NoDataMessage />;
  const favorabilityRankingData = { questionList: [], criticalTopics: [] };
  const isTitleBelow = prop !== OBJECT_KEYS.satisfaction;
  if (data) {
    orderByAsc(data, prop).forEach((item, index) => {
      if (item[prop] <= ENGAGEMENT_DATA.criticalTopic && index <= 2) {
        const criticResult = getPercent(item[prop], true);
        favorabilityRankingData.criticalTopics.push(`${ index + 1 }. ${ item[`name_${language}`] } (${ criticResult })`);
      }
      const questions = prop === OBJECT_KEYS.satisfaction
        ? item.evaluation_questions
        : item.evaluation_results[0].evaluation_answers;
      questions.forEach((evalQuestion, QuestionIndex) => {
        evalQuestion.cellColor = getCellColorByRange(evalQuestion[prop]);
        evalQuestion.id = `${index}-${QuestionIndex}`;
        favorabilityRankingData.questionList.push(evalQuestion);
      });
    });
  }

  const topicsData = getTopics(data, prop, language);

  if (topicsData?.length !== 0 && !isInvalidData) {
    favorabilityRankingData.questionList = orderByDesc(favorabilityRankingData.questionList, prop);
    engagementContentByTopics = <ResultsByQuestion values={ topicsData } />;
    favorabilityRanking = (
      <FavorabilityRankingTable
        values={ favorabilityRankingData }
        prop={ prop }
      />
    );
  }
  return [
    switchOptions(t("generalEngagement"), 0, engagementContentByTopics, null, isTitleBelow),
    switchOptions(t("favorabilityRanking"), 1, favorabilityRanking, <LegendByRanking />, isTitleBelow),
  ];
};
