import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { orderByDesc } from "common/helpers";
import { useEvaluationScale } from "hooks/useEvaluationScale";
import GoalList from "../GoalList";
import {
  StyledContainer,
  StyledMemberSection,
} from "../styles";

const SingleEvaluation = (props) => {
  const {
    t, member,
    isMobile, goalSection,
    handleToCoverOrHomeProcess,
  } = props;

  const evaluationScaleId = goalSection?.evaluation_scale_id;

  const { evaluationScale, isLoading, isError } = useEvaluationScale(evaluationScaleId);

  let scaleIndices = null;

  if (evaluationScale && !isError) {
    scaleIndices = orderByDesc(evaluationScale.indices, "top");
  }

  return (
    <>
      <StyledContainer>
        <Grid container alignItems={ "center" } spacing={ 2 }>
          {/* Evaluated Member Section */}
          <Grid item>
            <StyledMemberSection>
              <Typography variant={ "h6" } fontWeight={ "bold" }>
                {t("surveys:form.fields.evaluated")}
              </Typography>
            </StyledMemberSection>
          </Grid>
        </Grid>
      </StyledContainer>
      {!isLoading
      && (
        <GoalList
          currentMember={ member }
          t={ t }
          isMobile={ isMobile }
          scaleIndices={ scaleIndices }
          handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
        />
      )}
    </>
  );
};

SingleEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  member: PropTypes.object,
  goalSection: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleToCoverOrHomeProcess: PropTypes.func.isRequired,
};

SingleEvaluation.defaultProps = {
  member: {},
};

export default SingleEvaluation;
