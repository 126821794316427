import { useState } from "react";
import isEqual from "lodash/isEqual";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Filter from "components/Filter";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import ViewModalComponent from "components/ViewModalComponent";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import { StyledTabsContainer } from "styledComponents/Tabs";
import {
  StyledDashboardContainer,
  StyledPaperContainer,
} from "styledComponents/View";
import MainModule from "views/GeneralAdministrator/modules/Main";
import { isValidToKeyPositionAction } from "common/utils";
import PositionsUnitTable from "./components/PositionsUnitTable";

const PositionsView = (props) => {
  const { t, handleOpenModal, user } = props;

  const isValidAdmin = isValidToKeyPositionAction(user);

  const [filterQuery, setFilterQuery] = useState(null);
  const [tabHandler, setTabHandler] = useState(0);

  const handleExternalQuery = (query) => {
    const filteredInnerQuery = Object.entries(query.q).reduce((acc, [key, value]) => {
      if (value !== "" && value != null) {
        acc[key] = value;
      }
      return acc;
    }, {});

    filteredInnerQuery.s = "position_name asc";
    const filteredQuery = { q: filteredInnerQuery };

    if (!isEqual(filteredQuery, filterQuery)) {
      setFilterQuery(filteredQuery);
    }
  };

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  const allTabs = isValidAdmin ? [
    { label: t("administrator:modules.positions.tabs.active") },
    { label: t("administrator:modules.positions.tabs.directory") },
  ] : [
    { label: t("administrator:modules.positions.tabs.directory") },
  ];

  return (
    <ViewModalComponent viewComponent={ (
      <StyledDashboardContainer>
        <ViewHeaderTitle
          title={ t("administrator:modules.positions.title") }
          subtitle={ t("administrator:modules.positions.subtitle") }
          breadcrumb={ [{
            label: t("administrator:title"),
            path: "/administrator",
          },
          {
            label: t("administrator:modules.positions.title"),
            path: "",
          }] }
        />
        {isValidAdmin
          ? (
            <Grid container spacing={ 2 }>
              {tabHandler === 0
          && (
            <Grid item xs={ 12 }>
              <Filter
                type={ FILTER_ACTIONS_TYPES.positions }
                isLabel
                handleExternalQuery={ handleExternalQuery }
                isLoading={ false }
                isMultiple
              />
            </Grid>
          )}
              <StyledTabsContainer item xs={ 12 } spacing={ 1 }>
                <Tab
                  tabs={ allTabs }
                  onChange={ handleValueChanged }
                  tabValue={ tabHandler }
                />
                <TabPanel value={ tabHandler } index={ 0 }>
                  <StyledPaperContainer marginTop={ "0px" }>
                    <PositionsUnitTable
                      t={ t }
                      filterQuery={ filterQuery }
                      handleOpenModal={ handleOpenModal }
                    />
                  </StyledPaperContainer>
                </TabPanel>
                {/* TODO: refactor and add filterQuery */}
                <TabPanel value={ tabHandler } index={ 1 }>
                  <StyledPaperContainer marginTop={ "0px" }>
                    <MainModule />
                  </StyledPaperContainer>
                </TabPanel>
              </StyledTabsContainer>
            </Grid>
          ) : (
            <Grid container spacing={ 2 }>
              <StyledTabsContainer item xs={ 12 } spacing={ 1 }>
                <Tab
                  tabs={ allTabs }
                  onChange={ handleValueChanged }
                  tabValue={ tabHandler }
                />
                <TabPanel value={ tabHandler } index={ 0 }>
                  <StyledPaperContainer marginTop={ "0px" }>
                    <MainModule />
                  </StyledPaperContainer>
                </TabPanel>
              </StyledTabsContainer>
            </Grid>
          )}
      </StyledDashboardContainer>
    ) }
    />
  );
};

PositionsView.propTypes = {
  t: PropTypes.func.isRequired,
  handleOpenModal: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

export default PositionsView;
