import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import StarRating from "components/StarRating";
import NoData from "components/NoData";
import NoDataResult from "components/NoDataResult";
import SkeletonLoader from "components/SkeletonLoader";
import SectionContainer from "components_refactor/SectionContainer";
import constants from "styledComponents/constants";
import {
  replacePath, createResultToSkillLevelMap,
  findDataInScale, getPercent,
} from "common/utils";
import { getMaxScale } from "common/validators";
import { SKELETONS_NUMBER } from "common/constants";
import { skillTypeKeys } from "views_refactor/Profile/functions/skills";
import { getInformationSkill } from "views/Potential/components/TableCollaborators/functions";
import localRoutes from "../../functions/routes";
import { skillType, setSkillCategoryAndTip, getResultsBySection } from "../../functions/skills";
import { getIcon } from "../ProfileInfo/components/Modals/components/SkillsModal/components/SkillCard/functions";
import {
  StyledCardTitle,
  StyledFixedContainer,
  StyledLink,
  StyledResultContainer,
} from "../../styles";

const PotentialInfo = ({
  t, history, currentPotential, isOwnProfile, isLoadingCurrentPotential,
}) => {
  const potentialData = currentPotential?.[0];
  const resultToSkillLevelMap = potentialData && createResultToSkillLevelMap(potentialData);
  const skillLevel = potentialData && resultToSkillLevelMap[potentialData.result];
  const skillInfo = potentialData && getInformationSkill(potentialData.result, skillLevel, t);

  // Processing skill categories
  potentialData?.complete_results_by_section.forEach((item) => {
    if (skillType?.includes(item.tag)) {
      const skillLevelName = resultToSkillLevelMap[item.result];
      setSkillCategoryAndTip(skillLevelName, item);
    }
  });

  const completeResultsBySection = potentialData?.complete_results_by_section || [];
  const skillDetails = completeResultsBySection.length > 0
  && getResultsBySection(t, completeResultsBySection, isOwnProfile);

  const skillScore = skillDetails[0]?.skills[0]?.score;
  const scaleIndices = potentialData?.result_scale_indices;

  const potentialPosition = scaleIndices && skillScore ? findDataInScale(
    scaleIndices,
    skillScore,
    false,
  ) : null;

  const scaleMaxIndex = scaleIndices?.length;

  const maxScale = scaleIndices ? getPercent(getMaxScale(scaleIndices), true) : null;

  // Tooltip for the skill
  const getTooltip = (tag) => skillTypeKeys.filter((item) => item.value === tag)
    .map((item) => t(`potential:tabPotential.skills.tooltips.${item.id}`));

  // Displaying skill information with icon and star rating
  const getSkillInfo = () => ((skillDetails.length > 0 && skillDetails[0]?.skills[0]) ? (
    <div style={ { display: "flex", alignItems: "center" } }>
      <img
        src={ getIcon(skillDetails[0].skills[0]?.tag) }
        alt={ "Icon" }
        style={ { width: "40px", height: "40px", marginRight: "5px" } } // Adjusting image size and alignment
      />
      <StarRating
        name={ getTooltip(skillDetails[0].skills[0]?.tag)[0] }
        value={ potentialPosition?.position }
        isReadOnly
        maxRating={ scaleMaxIndex }
        max={ scaleMaxIndex }
        precision={ 1 }
        hasNoFormattedValue
        label={ skillDetails[0].skills[0].name }
        tooltipText={ getTooltip(skillDetails[0].skills[0]?.tag)[0] }
        tooltip={ potentialPosition && skillScore && maxScale ? {
          score: getPercent(skillScore, true, 2),
          maxScore: maxScale,
        } : null }
      />
    </div>
  ) : <NoData />);

  // Render content based on the component's state
  const renderContent = () => {
    if (isLoadingCurrentPotential) {
      return <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.TWO } />;
    }

    if (currentPotential[0]) {
      return (
        <>
          { currentPotential[0]?.result
            ? (
              <StyledResultContainer>
                {isOwnProfile ? getSkillInfo() : skillInfo}
              </StyledResultContainer>
            )
            : <NoDataResult />}
          <StyledFixedContainer>
            <StyledLink onClick={ () => replacePath(history, localRoutes.skills) }>
              {t("common:common.viewMore")}
            </StyledLink>
          </StyledFixedContainer>
        </>
      );
    }

    return <NoData />;
  };

  return (
    <SectionContainer>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 12 }>
          <StyledCardTitle weight={ constants.fontConstants.bold }>
            {t("profile:skills")}
          </StyledCardTitle>
          {renderContent()}
        </Grid>
      </Grid>
    </SectionContainer>
  );
};

PotentialInfo.propTypes = {
  t: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  isOwnProfile: PropTypes.bool.isRequired,
  currentPotential: PropTypes.arrayOf(PropTypes.object),
  isLoadingCurrentPotential: PropTypes.bool,
};

PotentialInfo.defaultProps = {
  currentPotential: [],
  isLoadingCurrentPotential: false,
};

export default PotentialInfo;
