import qs from "qs";
import { PARAMS_SERIALIZER_OPTIONS } from "common/constants";
import configAxios from "../configAxios";

const URL = {
  main: "/employees_internal_clients",
  internalClientFor: "internal_client_for",
};

export const getInternalClients = async () => {
  const response = await configAxios.get(`${URL.main}`);
  return response.data.internal_clients;
};

export const getInternalClientsByEmployee = async (employeeId) => {
  const query = {
    q: {
      employee_id_eq: employeeId,
    },
  };
  const response = await configAxios.get(`${URL.main}`, {
    params: { ...query },
    paramsSerializer: (params) => qs.stringify(params, PARAMS_SERIALIZER_OPTIONS),
  });
  return response.data.employees_internal_clients;
};

export const createInternalClients = async (data) => {
  const response = await configAxios.post(`${URL.main}`, data);
  return response.data.internal_clients;
};

export const getInternalClient = async (id) => {
  const response = await configAxios.get(`${URL.main}/${id}`);
  return response.data.internal_client;
};

export const updateInternalClient = async (id, data) => {
  const response = await configAxios.put(`${URL.main}/${id}`, data);
  return response.data.internal_client;
};

export const deleteInternalClient = async (id) => {
  const response = await configAxios.delete(`${URL.main}/${id}`);
  return response;
};

export const getInternalClientsAssignedToEmployee = async (employeeId) => {
  const response = await configAxios.get(`/employees/${employeeId}/${URL.internalClientFor}`);
  return response.data.employees_internal_clients;
};
