import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import TabPanel from "components_refactor/TabPanel";
import Tab from "components/Tab";
import GoalsInfo from "../GoalsInfo";
import AgreementsInfo from "../AgreementsInfo";
import { StyledContainer, StyledSectionContainer } from "./styles";

const TaskTabs = (props) => {
  const { t, hasAccessGoals, hasAccessDevelopmentPlan } = props;
  const [tabHandler, setTabHandler] = useState(0);

  const tabs = [];
  if (hasAccessGoals) tabs.push({ label: t("profile:goals"), component: <GoalsInfo { ...props } />, index: 0 });
  if (hasAccessDevelopmentPlan) tabs.push({ label: t("common:sidebar.development-plan"), component: <AgreementsInfo { ...props } />, index: tabs.length });

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  return (
    <StyledSectionContainer>
      <Grid container>
        <Grid item xs={ 12 }>
          <StyledContainer>
            {tabs.length > 0 && (
              <Tab
                tabs={ tabs.map((tab) => ({ label: tab.label })) }
                onChange={ handleValueChanged }
                tabValue={ tabHandler }
              />
            )}
            {tabs.map((tab) => (
              <TabPanel key={ tab.index } value={ tabHandler } index={ tab.index }>
                {tab.component}
              </TabPanel>
            ))}
          </StyledContainer>
        </Grid>
      </Grid>
    </StyledSectionContainer>
  );
};

TaskTabs.propTypes = {
  t: PropTypes.func.isRequired,
  hasAccessGoals: PropTypes.bool.isRequired,
  hasAccessDevelopmentPlan: PropTypes.bool.isRequired,
};

export default TaskTabs;
