import { useState } from "react";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import { StyledTabsContainer } from "styledComponents/Tabs";
import {
  StyledDashboardContainer,
} from "styledComponents/View";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Box from "@mui/material/Box";
import ToggleList from "./components/ToggleList";
import { LaunchSection } from "./components/LaunchSection";

const NotificationsSettingsView = () => {
  const [tabHandler, setTabHandler] = useState(0);

  const { t } = useTranslation();

  const breadcrumb = [
    {
      label: t("administrator:title"),
      path: "/administrator",
    },
    {
      label: t("administrator:modules.notifications.title"),
      path: "",
    },
  ];

  const allTabs = [
    { label: t("administrator:modules.notifications.tabs.emails") },
  ];

  const handleTabChanged = (value) => {
    setTabHandler(value);
  };

  return (
    <div data-testid={ "notifications-settings-view" }>
      <StyledDashboardContainer>
        <ViewHeaderTitle
          title={ t("administrator:modules.notifications.title") }
          subtitle={ t("administrator:modules.notifications.subtitle") }
          breadcrumb={ breadcrumb }
        />

        <Grid container spacing={ 2 }>
          <StyledTabsContainer item xs={ 12 } spacing={ 1 }>
            <Box sx={ { borderBottom: 1, borderColor: "divider" } }>
              <Tab
                tabs={ allTabs }
                onChange={ handleTabChanged }
                tabValue={ tabHandler }
              />
            </Box>
            <TabPanel value={ tabHandler } index={ 0 }>
              <ToggleList />
            </TabPanel>
          </StyledTabsContainer>
        </Grid>
        <LaunchSection />
      </StyledDashboardContainer>
    </div>
  );
};

export default NotificationsSettingsView;
