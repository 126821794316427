import {
  useState, useEffect, useContext, useRef,
} from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import { SessionContext } from "modules/session/context";
import { useProcessToast } from "hooks/timeOff/useProcessToast";
import Tab from "components/Tab";
import TabPanel from "components_refactor/TabPanel";
import DateFilter from "components/DateFilter";
import ViewHeaderTitle from "components/ViewHeaderTitle";
import Filter from "components/Filter";
import { StyledTabsContainer } from "styledComponents/Tabs";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import {
  COLORS_NAME,
  PAGINATION,
  DIRECTION,
  ALIGN_ITEMS,
  VARIANT,
  SIZE,
} from "common/constants";
import {
  isManager,
  isValidVacationAdmin,
  isAdminNala,
  isMainCompany,
} from "common/utils";
import {
  getList,
  getTimeOffList,
  getExcelList,
  resetTimeOffList,
  resetState,
} from "redux/actions/timeOffActions";
import InputTextSearch from "./components/InputTextSearch";
import PopUpForm from "./components/PopUpForm";
import CollaboratorsCard from "./components/TabGantTable/components/CollaboratorsCard";
import TabGantTable from "./components/TabGantTable";
import PersonalTab from "./components/PersonalTab";
import { getExcelData, getTabs } from "./functions";
import {
  StyledTimeOffGrid,
  StyledCustomPaper,
  StyledSpacedGrid,
  StyledTimeOffPopUp,
  StyledDownloadExcelButton,
} from "./styles";

const TimeOff = () => {
  const { t } = useTranslation(["common", "timeOff"]);
  const dataTestId = "time-off-component-view";
  const {
    state: { user },
  } = useContext(SessionContext);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const isNalaAdmin = isAdminNala(user?.userCookies);
  const isAdminOrManager = isValidVacationAdmin(user?.userCookies) || isManager(user?.userCookies);
  const isAdmin = isValidVacationAdmin(user?.userCookies);
  const activeTab = isAdminOrManager ? 1 : 0;

  const [tabHandler, setTabHandler] = useState(activeTab);
  const [countriesFilter, setCountriesFilter] = useState([]);
  const [citiesFilter, setCitiesFilter] = useState([]);
  const [unitiesFilter, setUnitiesFilter] = useState([]);
  const [statesFilter, setStatesFilter] = useState([]);
  const [searchFilter, setSearchFilter] = useState("");
  const [pageFilter, setPageFilter] = useState({
    number: 0,
    size: PAGINATION.maxPerPage,
  });
  const [startingDate, setStartingDate] = useState("");
  const [endingDate, setEndingDate] = useState("");
  const [processState, setProcessState] = useState("");
  const [selectedData, setSelectedData] = useState(null);

  const usePrevious = (value) => {
    const ref = useRef();
    useEffect(() => {
      ref.current = value;
    });
    return ref.current;
  };

  const prevCount = usePrevious(pageFilter);

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  const {
    list,
    isLoadingList,
    timeOffList,
    timeOffListTotal,
    timeOffLoadingList,
  } = useSelector(({ timeOffReducer }) => timeOffReducer);

  const FILTERS = {
    countries: {
      state: countriesFilter,
      set: setCountriesFilter,
    },
    cities: {
      state: citiesFilter,
      set: setCitiesFilter,
    },
    unities: {
      state: unitiesFilter,
      set: setUnitiesFilter,
    },
    states: {
      state: statesFilter,
      set: setStatesFilter,
    },
  };

  const handleExternalFilter = (selectedfilter, values) => {
    const filterSet = FILTERS[selectedfilter].set;
    const filteredValues = values[selectedfilter].map((item) => item.id);
    filterSet(filteredValues || []);
  };

  const dispatch = useDispatch();

  const pageHandler = (event, newPage) => {
    setPageFilter({ ...pageFilter, number: newPage });
    window.scroll(0, 0);
  };

  const inputHandler = (name = "") => {
    setSearchFilter(name);
  };

  const endingDateHandler = (date) => {
    setEndingDate(date);
  };

  const startingDateHandler = (date) => {
    setStartingDate(date);
  };

  useProcessToast("", setProcessState, processState);

  let queryTimeOff = {};
  let queryCollaborator = {};

  const updateQueries = () => {
    const query = {
      q: {
        country_id_in: countriesFilter,
        city_id_in: citiesFilter,
        organization_unit_id_in: unitiesFilter,
      },
    };

    queryCollaborator = JSON.parse(JSON.stringify(query));
    queryCollaborator.q.time_offs_state_in = statesFilter;

    queryTimeOff = JSON.parse(JSON.stringify(query));
    queryTimeOff.q.state_in = statesFilter;
  };

  useEffect(() => {
    updateQueries();

    const formatedPage = {
      size: pageFilter.size,
      number: pageFilter.number + 1,
    };

    if (processState !== "") {
      setProcessState("");
    } else if (prevCount !== pageFilter) {
      dispatch(resetTimeOffList());
      dispatch(resetState());
      dispatch(
        getList(searchFilter, startingDate, endingDate, queryCollaborator),
      );
      dispatch(
        getTimeOffList(
          searchFilter,
          startingDate,
          endingDate,
          formatedPage,
          queryTimeOff,
        ),
      );
    } else {
      setPageFilter({ number: 0, size: PAGINATION.maxPerPage });
    }
    // note: the way how they approach this needs this, we MUST change this
    // eslint-disable-next-line
  }, [
    searchFilter,
    citiesFilter,
    countriesFilter,
    dispatch,
    statesFilter,
    unitiesFilter,
    pageFilter,
    startingDate,
    endingDate,
    processState,
  ]);

  const handleEdit = (data) => {
    setSelectedData(data);
  };

  const showPersonal = !isNalaAdmin && isMainCompany(user);

  const onDownloadExcel = async () => {
    updateQueries();
    const excelList = await dispatch(
      getExcelList(searchFilter, startingDate, endingDate, queryTimeOff, timeOffListTotal),
    );
    if (excelList) {
      const data = getExcelData(excelList, t);
      return [data, t("timeOff:viewTitle"), false, false];
    }
    return null;
  };

  return (
    <div data-testid={ dataTestId }>
      <Box p={ 4 }>
        <StyledSpacedGrid container direction={ DIRECTION.row }>
          <Grid item>
            <ViewHeaderTitle title={ t("timeOff:viewTitle") } />
          </Grid>
          {!isMobile && (
            <Grid item>
              {showPersonal && (
                <PopUpForm data={ selectedData } setData={ setSelectedData } />
              )}
            </Grid>
          )}
        </StyledSpacedGrid>

        <Grid container spacing={ 4 }>
          <Grid item xs={ 12 }>
            <StyledTabsContainer
              spacing={ 4 }
              fontsize={ 14 }
              textcolor={ COLORS_NAME.black }
              normaltext={ "true" }
              uppercase={ "true" }
            >
              {isAdminOrManager && showPersonal && (
                <Tab
                  tabs={ getTabs(t) }
                  onChange={ handleValueChanged }
                  tabValue={ tabHandler }
                  variant={ isMobile ? VARIANT.fullWidth : VARIANT.none }
                />
              )}
              <StyledCustomPaper>
                <TabPanel value={ tabHandler } index={ 0 }>
                  <PersonalTab handleEdit={ handleEdit } />
                </TabPanel>

                {isAdminOrManager && (
                  <TabPanel value={ tabHandler } index={ 1 } isLoading={ false }>
                    <Grid container>
                      <StyledTimeOffGrid item xs={ 12 }>
                        <Filter
                          type={ FILTER_ACTIONS_TYPES.timeOff }
                          isLabel
                          isMultiple
                          externalParams={ [
                            searchFilter,
                            startingDate,
                            endingDate,
                            pageFilter,
                          ] }
                          externalHandler={ handleExternalFilter }
                          isButton={ isMobile }
                        />
                      </StyledTimeOffGrid>
                    </Grid>

                    <Grid
                      container
                      spacing={ -1 }
                      alignItems={ ALIGN_ITEMS.center }
                    >
                      <Grid item xs={ 12 } md={ 5 }>
                        <DateFilter
                          startingDateHandler={ startingDateHandler }
                          endingDateHandler={ endingDateHandler }
                          fullWidth={ isMobile }
                          size={ SIZE.small }
                          startingDate={ startingDate }
                          endingDate={ endingDate }
                        />
                      </Grid>
                      <Grid item xs={ 12 } md={ 2 }>
                        <StyledDownloadExcelButton
                          onClick={ onDownloadExcel }
                          isDisabled={ timeOffListTotal === 0 }
                        />
                      </Grid>
                      <Grid item xs={ 12 } md={ 3 }>
                        <InputTextSearch inputHandler={ inputHandler } />
                      </Grid>
                      <Grid item xs={ 12 } md={ 1 } />
                    </Grid>
                    {isMobile ? (
                      <CollaboratorsCard
                        data={ timeOffList }
                        pagination={ {
                          number: pageFilter.number,
                          size: pageFilter.size,
                          handlePage: pageHandler,
                        } }
                        isLoading={ timeOffLoadingList }
                        isAdmin={ isAdmin }
                      />
                    ) : (
                      <TabGantTable
                        gantData={ list }
                        tableData={ timeOffList }
                        total={ timeOffListTotal }
                        tableIsLoading={ timeOffLoadingList }
                        gantIsLoading={ isLoadingList }
                        pageHandler={ pageHandler }
                        pageFilter={ pageFilter }
                        statesFilter={ statesFilter }
                      />
                    )}
                  </TabPanel>
                )}
              </StyledCustomPaper>
            </StyledTabsContainer>
          </Grid>
        </Grid>
      </Box>
      { isMobile && (
        <StyledTimeOffPopUp>
          <PopUpForm data={ selectedData } setData={ setSelectedData } />
        </StyledTimeOffPopUp>
      )}
    </div>
  );
};

export default TimeOff;
