import { useQuery, useQueryClient } from "react-query";
import { getEvaluationScaleById } from "redux/actions/evaluationScaleActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

const fetchEvaluationScale = async (evaluationScaleId) => {
  const response = await getEvaluationScaleById(evaluationScaleId);
  return response;
};

export const useEvaluationScale = (evaluationScaleId) => {
  const queryClient = useQueryClient();

  const {
    data: evaluationScale, isLoading, isError, error,
  } = useQuery(
    ["evaluationScale", evaluationScaleId],
    () => fetchEvaluationScale(evaluationScaleId),
    {
      enabled: !!evaluationScaleId,
      staleTime: Infinity,
      onError: (queryError) => {
        toast(
          MESSAGE_TYPES.error,
          {
            message: queryError.message || queryError.toString(),
          },
        );
      },
    },
  );

  return {
    evaluationScale,
    isLoading,
    isError,
    error,
    queryClient,
  };
};
