import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Avatar from "@mui/material/Avatar";
import StarRating from "components/StarRating";
import { VARIANT } from "common/constants";
import { findDataInScale, getAvatar } from "common/utils";
import { StyledMember } from "./styles";

const EvaluationResult = (props) => {
  const { members, scaleIndices, isValidToStarRating } = props;

  // TODO: fix starRating
  return (
    members.map((member) => {
      const resultIndex = findDataInScale(scaleIndices, member?.result);
      return (
        <Grid item xs={ 12 } key={ member.id } data-testid={ "evaluation-result-member-item" }>
          <StyledMember>
            <Avatar
              alt={ member?.evaluation_details?.evaluated?.name }
              src={ getAvatar(member?.evaluation_details?.evaluated) }
              key={ member.id }
            />
            <div>
              {isValidToStarRating
                ? (
                  <StarRating
                    name={ `star-rating-${member.id}` }
                    value={ resultIndex?.position }
                    isReadOnly
                    maxRating={ scaleIndices?.length }
                    max={ scaleIndices?.length }
                    precision={ 1 }
                    hasNoFormattedValue
                    label={ resultIndex?.result }
                    size={ "small" }
                  />
                ) : <Typography variant={ VARIANT.h6 }>{member?.result }</Typography>}
              <Typography>{member?.evaluation_details?.evaluated?.name}</Typography>
            </div>
          </StyledMember>
        </Grid>
      );
    })
  );
};

EvaluationResult.propTypes = {
  members: PropTypes.array.isRequired,
  scaleIndices: PropTypes.object.isRequired,
  isValidToStarRating: PropTypes.bool.isRequired,
};

export default EvaluationResult;
