import clsx from "clsx";
import PropTypes from "prop-types";
import get from "lodash/get";
import TableRow from "@mui/material/TableRow";
import IconButton from "@mui/material/IconButton";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import { getRowClass } from "../../functions";
import {
  StyledTableCell,
  StyledTableCellDragable,
  StyledCustomTableCell,
} from "../../styles";

const TRowCustom = (props) => {
  const {
    item,
    keys,
    isNotHeader,
    isDragable,
    onClick,
    classes,
    isOpen,
    isActive,
  } = props;

  const showCollapseIcon = item.goal_rule_id && !item.isEdit && !isActive;
  const isValidToCollapse = item.isEdit || (showCollapseIcon
  && (item.description || (item.tags && item.tags.length > 0)));

  return (
    <TableRow
      className={ clsx(
        getRowClass(item, classes, false, isNotHeader, isDragable),
        classes.fadeIn,
        showCollapseIcon && isValidToCollapse && classes.clickableRow,
      ) }
      onClick={ showCollapseIcon ? onClick : undefined }
      hover={ showCollapseIcon }
    >
      {keys?.map((element, index) => {
        const valueToRender = get(item, element.id);

        if (index === 0) {
          return (
            <StyledCustomTableCell
              width={ element.width }
              align={ element.align }
            >
              <div className={ classes.cellContent }>
                {showCollapseIcon && (
                  <IconButton
                    size={ "small" }
                    onClick={ (e) => {
                      if (isValidToCollapse) {
                        e.stopPropagation();
                        onClick();
                      }
                    } }
                    disabled={ !isValidToCollapse }
                  >
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                )}
                {element.customRender ? element.customRender(item) : valueToRender}
              </div>
            </StyledCustomTableCell>
          );
        }
        return element.dragable ? (
          <StyledTableCellDragable
            key={ index }
            width={ element.width }
            align={ element.align }
          >
            {element.customRender ? element.customRender(item) : valueToRender}
          </StyledTableCellDragable>
        ) : element.customRender ? (
          <StyledTableCell
            key={ index }
            width={ element.width }
            align={ element.align }
          >
            {element.customRender(item)}
          </StyledTableCell>
        ) : (
          <StyledTableCell
            key={ index }
            width={ element.width }
            align={ element.align }
          >
            {valueToRender}
          </StyledTableCell>
        );
      })}
    </TableRow>
  );
};

TRowCustom.propTypes = {
  item: PropTypes.object,
  keys: PropTypes.array,
  isNotHeader: PropTypes.bool,
  isDragable: PropTypes.bool,
  onClick: PropTypes.func,
  classes: PropTypes.object,
  isOpen: PropTypes.bool,
  isActive: PropTypes.bool,
};

export default TRowCustom;
