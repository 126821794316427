import { getDataToDownloadParticipation } from "redux/actions/surveyProcessesActions";
import {
  MESSAGE_TYPES,
  toast,
  handleMessages,
  HTTP_STATUS_RESPONSE,
} from "components/Toast/functions";
import { getPercent, customDownloadExcel } from "common/utils";
import { ORG_UNITS } from "common/constants";
import {
  EVALUATION_TYPE,
  ROWS_HEADER,
  PARTICIPATION_COLUMNS,
  RESULTS_COLUMNS,
} from "common/constants/surveyProcess";

/**
 * @param {Array} orgUnits
 * @returns {Object} - Object with the names of the organization units
 * to show in the excel file
 */
const getOrganizationUnits = (orgUnits) => {
  let department = "";
  let area = "";
  let subarea = "";

  if (orgUnits) {
    department = orgUnits.find(
      (orgUnit) => orgUnit.organization_unit_type_name === ORG_UNITS.division
    )?.name;
    area = orgUnits.find(
      (orgUnit) => orgUnit.organization_unit_type_name === ORG_UNITS.area
    )?.name;
    subarea = orgUnits.find(
      (orgUnit) => orgUnit.organization_unit_type_name === ORG_UNITS.subarea
    )?.name;
  }

  return { department, area, subarea };
};

/**
 * @param {Object} t - i18n function
 * @returns {Object} - Object with the names of the evaluation types
 * to show in the excel file depending on the language
 */
const evaluationTypeNames = (t) => ({
  [EVALUATION_TYPE.AUTOEVALUATION]: {
    submittedName: t("surveys:evaluation_types.self_evaluation"),
    receivedName: t("surveys:evaluation_types.self_evaluation"),
  },
  [EVALUATION_TYPE.MANAGER]: {
    submittedName: t("surveys:evaluations.manager.submitted"),
    receivedName: t("surveys:evaluations.manager.receivedShort"),
  },
  [EVALUATION_TYPE.PAIRS]: {
    submittedName: t("surveys:evaluations.pairs.submitted"),
    receivedName: t("surveys:evaluations.pairs.receivedShort"),
  },
  [EVALUATION_TYPE.COLLABORATORS]: {
    submittedName: t("surveys:evaluations.collaborators.toLeader"),
    receivedName: t("surveys:evaluations.collaborators.receivedShort"),
  },
  [EVALUATION_TYPE.OVERALL]: {
    submittedName: t("surveys:evaluation_types.overall"),
    receivedName: t("surveys:evaluation_types.overall"),
  },
  [EVALUATION_TYPE.INTERNAL_CLIENT]: {
    submittedName: t("surveys:evaluations.internal_client.submitted"),
    receivedName: t("surveys:evaluations.internal_client.receivedShort"),
  },
});

/**
 * @param {Object} t - i18n function
 * @param {Array} evaluationsToShow - Array of evaluations to show
 * @returns {Array} - Array of objects with the three first rows of the excel file
 * date of update, empty row and headers
 */
const getInitialData = (t, evaluationsToShow) => ROWS_HEADER.map((row) => {
  const data = {};
  PARTICIPATION_COLUMNS.forEach((column) => {
    if (row === "dateUpdate") {
      if (column === "collaborators:table.table-head.personal_id") {
        data[t(column)] = t("surveys:update");
        return;
      }

      if (column === "collaborators:table.table-head.name") {
        data[t(column)] = new Date().toLocaleDateString();
        return;
      }
      data[t(column)] = "";
    }

    if (row === "empty") {
      data[t(column)] = "";
    }

    if (row === "headers") {
      data[t(column)] = t(column);
    }
  });

  if (row === "headers") {
    evaluationsToShow.forEach((evaluation) => {
      const evaluationLabel = evaluationTypeNames(t)[evaluation.type]
        ?.submittedName;
      data[evaluationLabel] = evaluationLabel;
    });
    data[t("tables:headers.state")] = t("tables:headers.state");
  }

  return data;
});

/**
 * @param {Object} t - i18n function
 * @param {Array} evaluationsToShow - Array of evaluations to show
 * @returns {Array} - Array of objects with the headers
 * of the second sheet (Results) of the excel file
 */
const getHeadersResults = (t, evaluationsToShow) => {
  const headers = [];
  RESULTS_COLUMNS.forEach((column) => {
    headers[t(column)] = t(column);
  });

  evaluationsToShow.forEach((evaluation) => {
    const evaluationLabel = evaluationTypeNames(t)[evaluation.type]
      ?.receivedName;
    headers[evaluationLabel] = evaluationLabel;
  });

  headers[t("common:common.result")] = t("common:common.result");
  headers["%"] = "%";
  return headers;
};

/**
 * @param {Array} surveyResultList
 * @param {function} t - i18n function
 * @param {Array} evaluationsToShow - Array of evaluations to show
 * @param {Array} surveyResults - Array of survey results
 * @returns {Array} - Array of objects with the data to download
 * with the correct format of the columns
 */
export const getResultByEmployeeToDownload = (
  surveyResultList,
  t,
  evaluationsToShow,
  surveyResults,
) => {
  const participationData = [];
  const evaluationDetails = [];
  const evaluations = surveyResultList?.employees[0]?.results;
  const evaluationTypeArray = Object.entries(evaluationTypeNames(t));
  participationData.push(...getInitialData(t, evaluationsToShow));
  evaluationDetails.push(getHeadersResults(t, evaluationsToShow));

  // Get the employees data to first sheet
  surveyResultList.employees.forEach((employee, index) => {
    const organizationUnits = getOrganizationUnits(employee?.organization_units);

    participationData.push({
      [t("collaborators:table.table-head.personal_id")]: employee?.personal_id,
      [t("collaborators:table.table-head.name")]: employee?.full_name,
      [t("collaborators:table.table-head.email")]: employee?.email,
      [t("collaborators:table.excel.manager_name")]: employee?.manager_name,
      [t("collaborators:table.table-head.position")]: employee?.position_name,
      [t("collaborators:table.excel.department")]: organizationUnits?.department,
      [t("surveys:report")]: employee?.total_team,
      [t("surveys:evaluations_to_answers")]: employee?.total_to_evaluate,
    });

    if (evaluations) {
      // Evaluations submitted
      evaluations.forEach((evaluation) => {
        const isToShow = evaluationsToShow.find((item) => item.type === evaluation.evaluation_type);
        if (!isToShow) return;

        const evaluationLabel = evaluationTypeNames(t)[
          evaluation.evaluation_type
        ]?.submittedName;
        const totalResult = employee.results?.find(
          (item) => item.evaluation_type === evaluation.evaluation_type
        );
        participationData[index + 3][evaluationLabel] = totalResult.forms_filled_submitted;
      });
      // Status
      participationData[index + 3][t("tables:headers.state")] = t(
        `planning:processDetail.status.${employee?.status}`
      );
    }
  });

  // Get the results data to second sheet
  surveyResults.forEach((result, index) => {
    const { employee } = result || {};
    const resultsByEvaluationType = result?.results_by_evaluation_type || [];

    evaluationDetails.push({
      [t("collaborators:table.table-head.personal_id")]: employee?.personal_id,
      [t("collaborators:table.table-head.name")]: employee?.full_name,
      [t("surveys:leader_name")]: employee?.manager_name,
      [t("collaborators:table.table-head.position")]: employee?.position_name,
      [t("surveys:country")]: employee?.country_name,
      [t("common:common.city")]: employee?.city_name,
    });

    // Add the results of each evaluation type
    evaluationTypeArray.forEach(([evaluationType, names]) => {
      const isToShow = evaluationsToShow.find((item) => item.type === evaluationType);
      if (!isToShow) return;

      const resultByType = resultsByEvaluationType.find(
        (item) => item.evaluation_type === evaluationType
      )?.result;
      const evaluationLabel = names.receivedName;
      evaluationDetails[index + 1][evaluationLabel] = resultByType || "";
    });

    evaluationDetails[index + 1][t("common:common.result")] = result?.result;
    evaluationDetails[index + 1]["%"] = result?.score
      ? getPercent(result.score)
      : "";
  });

  // Format the data to download in two sheets
  const allData = [
    {
      name: t("performance:goodLeaderData.participation"),
      data: participationData,
    },
    {
      name: t("common:common.results"),
      data: evaluationDetails,
    },
  ];

  return allData;
};

/**
 * @param {string} id - Survey id
 * @param {string} filterQuery - Filter query
 * @param {function} dispatch - Dispatch function
 * @param {function} t - i18n function
 * @param {function} setIsDownloading - Function to set the downloading state
 * @param {Array} evaluationsToShow - Array of evaluations to show
 * @param {Array} surveyResults - Array of survey results
 * @returns {Promise} - Promise that resolves with the data to download
 * for the specified survey id and filter query
 */
export const downloadExcelFile = async (
  dispatch,
  id,
  filterQuery,
  t,
  setIsDownloading,
  evaluationsToShow,
  surveyResults = [],
) => {
  setIsDownloading(true);
  const message = t("common:common.download_message");
  // Show an info toast message with the download message
  toast(
    MESSAGE_TYPES.info,
    handleMessages(MESSAGE_TYPES.info, HTTP_STATUS_RESPONSE.ok, t, message)
  );

  // Get data to download for the specified id and filter query
  const dataToDownload = await dispatch(getDataToDownloadParticipation(id, filterQuery));
  // Prepare the data to be downloaded by formatting it for display
  const data = getResultByEmployeeToDownload(
    dataToDownload,
    t,
    evaluationsToShow,
    surveyResults,
  );
  const filename = t("common:common.participation");
  // Initiate the download of the Excel file with the prepared data
  customDownloadExcel(data, filename, true, true);
  setIsDownloading(false);
};
