export const RESET_STATE = "RESET_STATE_AUDIENCE";
export const GET_LIST = "GET_LIST_AUDIENCE";
export const GET_LIST_LOADING = "GET_LIST_LOADING_AUDIENCE";
export const GET_LIST_ERROR = "GET_LIST_ERROR_AUDIENCE";

export const RESET_PROCESS_AUDIENCE = "RESET_PROCESS_AUDIENCE";
export const PROCESS_AUDIENCE = "PROCESS_AUDIENCE";
export const PROCESS_AUDIENCE_LOADING = "PROCESS_AUDIENCE_LOADING";
export const PROCESS_AUDIENCE_ERROR = "PROCESS_AUDIENCE_ERROR";

export const UPDATE_PROCESS_AUDIENCE = "UPDATE_PROCESS_AUDIENCE";
export const UPDATE_PROCESS_AUDIENCE_LOADING = "UPDATE_PROCESS_AUDIENCE_LOADING";
export const UPDATE_PROCESS_AUDIENCE_ERROR = "UPDATE_PROCESS_AUDIENCE_ERROR";

export const GET_AUDIENCE = "GET_AUDIENCE";
export const GET_AUDIENCE_LOADING = "GET_AUDIENCE_LOADING";
export const GET_AUDIENCE_ERROR = "GET_AUDIENCE_ERROR";
