import styled from "styled-components";

export const StyledLanguage = styled.div`
  width: 150px;
  float: right;
`;

export const StyledFormContainer = styled.div`
  textarea {
    margin-bottom: 30px;
  }
`;
