import { LOCAL_STORAGE_NAMES, OBJECT_KEYS, TYPES } from "common/constants";
import { getItemFromLocalStorage, excelHeader } from "common/utils";

export const getDataBreadcrumbs = (t) => [
  {
    label: t("Onboarding:title"),
    path: "/onboarding",
  },
  {
    label: t("Onboarding:massiveTitle"),
    path: "",
  },
];

export const excelHeaderByDefault = (t) => [
  excelHeader(OBJECT_KEYS.id, t("excelData.id"), true, TYPES.string),
  excelHeader(OBJECT_KEYS.name, t("excelData.name"), true, TYPES.string),
  excelHeader(
    OBJECT_KEYS.startingDate,
    t("excelData.startDate"),
    true,
    TYPES.date,
  ),
  excelHeader(
    OBJECT_KEYS.personalEmail,
    t("excelData.personalEmail"),
    true,
    TYPES.email,
  ),
  excelHeader(
    OBJECT_KEYS.managerId,
    t("excelData.leaderId"),
    false,
    TYPES.string,
  ),
  excelHeader(
    OBJECT_KEYS.position,
    t("excelData.position"),
    true,
    TYPES.string,
  ),
  excelHeader(OBJECT_KEYS.level, t("excelData.level"), true, TYPES.string),
  excelHeader(
    OBJECT_KEYS.typeOfContract,
    t("excelData.typeOfContract"),
    true,
    TYPES.string,
  ),
  excelHeader(
    OBJECT_KEYS.country,
    t("excelData.country"),
    true,
    TYPES.string,
    getItemFromLocalStorage(LOCAL_STORAGE_NAMES.countries),
    OBJECT_KEYS.name,
  ),
  excelHeader(OBJECT_KEYS.city, t("excelData.city"), false, TYPES.string),
  excelHeader(
    OBJECT_KEYS.division,
    t("excelData.division"),
    true,
    TYPES.string,
  ),
  excelHeader(OBJECT_KEYS.area, t("excelData.area"), false, TYPES.string),
  excelHeader(OBJECT_KEYS.gender, t("excelData.gender"), true, TYPES.string),
  excelHeader(
    OBJECT_KEYS.currency,
    t("excelData.typeOfCurrency"),
    true,
    TYPES.string,
    getItemFromLocalStorage(LOCAL_STORAGE_NAMES.currencies),
    OBJECT_KEYS.code,
  ),
  excelHeader(
    OBJECT_KEYS.salary,
    t("excelData.salary"),
    true,
    TYPES.number
  ),
  excelHeader(
    OBJECT_KEYS.averageCommissions,
    t("excelData.averageCommissions"),
    false,
    TYPES.number,
  ),
  excelHeader(
    OBJECT_KEYS.healthInsurance,
    t("excelData.healthInsurance"),
    false,
    TYPES.number,
  ),
  excelHeader(
    OBJECT_KEYS.mealTicket,
    t("excelData.mealTicket"),
    false,
    TYPES.number,
  ),
  excelHeader(
    OBJECT_KEYS.dentalInsurance,
    t("excelData.dentalInsurance"),
    false,
    TYPES.number,
  ),
  excelHeader(
    OBJECT_KEYS.lifeInsurance,
    t("excelData.lifeInsurance"),
    false,
    TYPES.number,
  ),
  excelHeader(
    OBJECT_KEYS.otherBenefits,
    t("excelData.otherBenefits"),
    false,
    TYPES.number,
  ),
];
