import styled from "styled-components";
import palette from "theme/palette";
import Typography from "@mui/material/Typography";

export const StyledEvaluationTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StyledTitle = styled.div`
  font-weight: 700;
  font-size: 16px;
  display: flex;
  align-items: center;
`;

export const StyledImage = styled.img`
  width: 55px;
  height: 55px;
  margin-right: 12px;
`;

export const StyledDashboardContainer = styled.div`
  padding: 32px;
  @media (max-width: 960px) {
    padding: 16px 12px;
    font-family: "Lato";
    background: ${ palette.white };
    min-height: 100%;
  }
`;

export const StyledMember = styled.div`
  display: flex;
  align-items: center;
  .MuiTypography-body1 {
    font-size: 16px;
    margin-left: 12px;
  }
  .MuiAvatar-root, .MuiSvgIcon-root.MuiAvatar-fallback {
    width: 40px;
    height: 40px;
  }
`;

export const StyledEvaluated = styled.h3`
  font-size: 20px;
  font-weight: 700;
  line-height: 22px;
  margin-bottom: 10px;
`;

export const StyledTabsContainer = styled.div`
  margin-top: 20px;
`;

export const StyledGoalContainer = styled.div`
  background: white;
  border-radius: 5px;
  padding: ${(props) => (props.isMobile ? 10 : 30)}px;
`;

export const StyledGoalImage = styled.img`
  width: ${(props) => (props.isMobile ? "100%" : "70%")};
  height: auto;
  object-fit: cover;
`;

export const StyledPreviewContent = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 20px ${(props) => (props.isMobile ? 0 : 20)}px;
`;

export const StyledBoxWithBorders = styled.div`
  position: relative;
  border-top: 1px solid #000;
  border-right: 1px solid #000;
  border-bottom: 1px solid #000;
  padding: 50px;
  width: 100px;
  background-color: #fff;
`;

export const FloatingText = styled(Typography)`
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translateX(-10%);
  background-color: #fff;
  padding: 10px;
  font-size: 17px;
  font-weight: 500;
  width: 250px;
  color: #565656;
`;
