import { useState, useEffect, useCallback } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import clsx from "clsx";
import { useSelector } from "react-redux";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/styles";
import TooltipIcon from "components/TooltipIcon";
import { NINEBOX } from "common/constants";
import { isEqual, isNotValid } from "common/helpers";
// import Carrousel from "./components/Carrousel";
import charts from "theme/charts";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import QuadrantInfo from "./components/QuadrantInfo";
import { getDataByBox, getFooterBox, getDefaultFooterBox } from "./functions";
import useStyles from "./styles";

const NineBox = (props) => {
  // TODO: Refactor
  const {
    getQuadrantDetail, quadrant, setSelectedQuadrant, quadrantInfo,
  } = props;
  const classes = useStyles();
  const { t, i18n } = useTranslation(["potential", "common", "performance"]);
  const [quadrantSelected, setQuadrantSelected] = useState();
  // const [activeStep, setActiveStep] = useState(NINEBOX.boxesGroup.narrow);

  const {
    list: resultScaleList,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const { nineBoxTypeList, isLoading } = useNineBoxTypes(t);

  const yScale = resultScaleList?.find((scale) => scale.process_type === "potential")?.result_scale_indices;
  const dataBox = getDataByBox(nineBoxTypeList);
  const footerBox = yScale ? getFooterBox(yScale, i18n.language) : getDefaultFooterBox(t);

  const setQuadrant = useCallback(
    (quadrantIndex) => {
      setQuadrantSelected(quadrantIndex);
      if (getQuadrantDetail) {
        getQuadrantDetail(dataBox[quadrantIndex]);
      }
    },
    [dataBox, getQuadrantDetail],
  );

  useEffect(() => {
    if (quadrant) {
      setQuadrant(quadrant);
      setSelectedQuadrant();
    }
  }, [quadrant, setQuadrant, quadrantSelected, setSelectedQuadrant]);

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const getValueRect = (value, isSelectedQuadrant, type) => {
    let rectValue = value;
    if (isSelectedQuadrant) {
      if (isEqual(type, "x")) {
        rectValue = value + (isMobile ? 1.8 : 0.9);
      } else if (isEqual(type, "y")) {
        rectValue = value + (isMobile ? 1.7 : 0.6);
      } else if (isEqual(type, "width")) {
        rectValue = value - (isMobile ? 3.5 : 1.8);
      } else if (isEqual(type, "height")) {
        rectValue = value - (isMobile ? 4 : 1.6);
      }
    }
    return rectValue;
  };

  return (
    <Box data-testid={ "nine-box" }>
      <Grid container spacing={ 2 }>
        <Grid item xs={ 1 } md={ 1 }>
          <Box className={ classes.potential }>
            <Typography className={ classes.performance } variant={ "h5" }>
              {t("ninebox:performance")}
            </Typography>
            <Typography
              variant={ "body1" }
              className={ clsx(classes.boxSubtitle, classes.boxSubtitleA) }
            >
              {t("ninebox:subtitle-box.ya")}
            </Typography>
            <Typography
              variant={ "body1" }
              className={ clsx(classes.boxSubtitle, classes.boxSubtitleB) }
            >
              {t("ninebox:subtitle-box.yb")}
            </Typography>
            <Typography
              variant={ "body1" }
              className={ clsx(classes.boxSubtitle, classes.boxSubtitleC) }
            >
              {t("ninebox:subtitle-box.yc")}
            </Typography>
          </Box>
        </Grid>
        <Grid item xs={ 11 } className={ classes.nineBoxContainer }>
          <Grid className={ classes.nineBox }>
            <svg
              className={ clsx(
                classes.generalSvg,
              ) }
              viewBox={ NINEBOX.generalPaddingViewBox }
            >
              {dataBox
                && !isNotValid(dataBox)
                && dataBox.map((item, index) => (
                  <g key={ `box${index}` }>
                    <rect
                      x={ getValueRect(
                        item[NINEBOX.quadrantData.x],
                        isEqual(quadrantSelected, index),
                        "x",
                      ) }
                      y={ getValueRect(
                        item[NINEBOX.quadrantData.y],
                        isEqual(quadrantSelected, index),
                        "y",
                      ) }
                      width={ getValueRect(
                        item[NINEBOX.quadrantData.width],
                        isEqual(quadrantSelected, index),
                        "width",
                      ) }
                      height={ getValueRect(
                        item[NINEBOX.quadrantData.width],
                        isEqual(quadrantSelected, index),
                        "height",
                      ) }
                      fill={ item[NINEBOX.quadrantData.fill] }
                      className={ isEqual(quadrantSelected, index)
                        ? classes.rectBoxActive
                        : classes.rectBox }
                      onClick={ () => setQuadrant(index) }
                    />
                    {!isLoading
                    && (
                      <>
                        <text
                          x={ item[NINEBOX.quadrantData.xText] }
                          y={ item[NINEBOX.quadrantData.yText] }
                          className={ classes.boxTitle }
                        >
                          {item[NINEBOX.quadrantData.name]}
                        </text>
                        <svg
                          viewBox={ NINEBOX.iconViewBox }
                          className={ classes.iconSvg }
                          x={ item[NINEBOX.quadrantData.xText] + NINEBOX.iconX }
                          y={ item[NINEBOX.quadrantData.yText] - NINEBOX.iconY }
                          width={ NINEBOX.iconWidth }
                          height={ NINEBOX.iconY }
                        >
                          <TooltipIcon
                            title={ item[NINEBOX.quadrantData.description] }
                            isHelpIcon
                          />
                        </svg>
                        {quadrantInfo
                        && (
                          <foreignObject
                            x={ item[NINEBOX.quadrantData.xText] }
                            y={ item[NINEBOX.quadrantData.yText] + 10 }
                            width={ 28 }
                            height={ 8 }
                          >
                            <QuadrantInfo
                              percentage={ quadrantInfo[item[16]]?.percentage || 0 }
                              totalCollaborators={ quadrantInfo[item[16]]?.totalCollaborators || 0 }
                              t={ t }
                              isSelected={ isEqual(quadrantSelected, index) }
                            />
                          </foreignObject>
                        )}
                      </>
                    )}
                  </g>
                ))}
              {props.children}
            </svg>
            <svg className={ classes.footerSvg } viewBox={ NINEBOX.footerViewBox }>
              <g className={ classes.boxFooter }>
                {footerBox
                  && footerBox.map((text) => (
                    <text
                      key={ text }
                      x={ text[NINEBOX.footerData.x] }
                      y={ NINEBOX.box.a.x }
                      dx={ NINEBOX.zero }
                      dy={ NINEBOX.zero }
                    >
                      {text[NINEBOX.footerData.xText]}
                    </text>
                  ))}
              </g>
            </svg>
          </Grid>
          <Box className={ classes.potentialTitle }>
            <Typography className={ classes.performance } variant={ "h5" }>
              {t("ninebox:potential")}
            </Typography>
          </Box>
          {/* <Carrousel activeStep={activeStep} setActiveStep={setActiveStep} /> */}
        </Grid>
      </Grid>
    </Box>
  );
};

NineBox.propTypes = {
  getQuadrantDetail: PropTypes.func,
  quadrant: PropTypes.number,
  setSelectedQuadrant: PropTypes.func,
  quadrantInfo: PropTypes.object,
};

export default NineBox;
