import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";
import SkeletonLoader from "components/SkeletonLoader";
import SwitchSelectorContent from "components/SwitchSelectorContent";
import { getOptions } from "../../functions";
import { StyledCardContent } from "../GeneralContent/styles";

const GeneralEngagement = ({
  t, language, topicSatisfaction, isInvalidData,
}) => {
  const [values, setValues] = useState({
    satisfactionByTopic: null,
  });

  useEffect(() => {
    if (topicSatisfaction) {
      const satisfactionData = getOptions(
        t,
        topicSatisfaction,
        language,
        isInvalidData,
      );
      setValues((prevValues) => ({
        ...prevValues,
        satisfactionByTopic: satisfactionData,
      }));
    }
  }, [t, language, topicSatisfaction, isInvalidData]);

  return (
    <Grid item xs={ 12 }>
      {values?.satisfactionByTopic ? (
        <Card elevation={ 0 }>
          <StyledCardContent>
            <SwitchSelectorContent
              options={ values.satisfactionByTopic }
              customStyles={ { switch: { height: "36px" } } }
            />
          </StyledCardContent>
        </Card>
      ) : (
        <SkeletonLoader numberOfSkeletons={ 8 } />
      )}
    </Grid>
  );
};

GeneralEngagement.propTypes = {
  t: PropTypes.func.isRequired,
  language: PropTypes.string.isRequired,
  topicSatisfaction: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
      value: PropTypes.number,
    }),
  ).isRequired,
  isInvalidData: PropTypes.bool.isRequired,
};

export default GeneralEngagement;
