import { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Typography from "@mui/material/Typography";
import Stack from "@mui/material/Stack";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import TitleIcon from "components/TitleIcon";
import StakeholdersIcon from "assets/images/planning/team_group_thin.svg";
import { getPercent } from "common/utils";
import {
  EVALUATION_TYPE, ensureTemplateSuffix,
  getEvaluationType, getDefaultStakeholders,
} from "views/SurveyTemplate/functions";
import {
  StyledTitle, StyledTableCell,
  StyledTotalTableCell,
  StyledTableContainer,
  StyledHeader,
  StyledTotalTitle,
} from "./styles";

const Stakeholders = ({
  t, evaluations, formLanguage, user,
}) => {
  const hasInternalClient = user?.company?.internal_client;
  const [stakeholders, setStakeholders] = useState(getDefaultStakeholders(t, hasInternalClient));
  const { i18n } = useTranslation();

  const types = {
    [EVALUATION_TYPE.AUTOEVALUATION]: "auto",
    [EVALUATION_TYPE.MANAGER]: "manager",
    [EVALUATION_TYPE.PAIRS]: "peers",
    [EVALUATION_TYPE.COLLABORATORS]: "team",
    ...(hasInternalClient && {
      [EVALUATION_TYPE.INTERNAL_CLIENT]: "internal_client",
    }),
  };

  useEffect(() => {
    if (evaluations) {
      const updatedStakeholders = { ...stakeholders };
      if (evaluations.length > 0) {
        evaluations.forEach((evaluation) => {
          const typeWithTemplate = ensureTemplateSuffix(evaluation.type);
          const stakeholderKey = types[typeWithTemplate];

          if (stakeholderKey && evaluation.weighing !== 0) {
            updatedStakeholders[stakeholderKey] = {
              ...updatedStakeholders[stakeholderKey],
              value: getPercent(evaluation.weighing),
            };
          }
        });
      }
      setStakeholders(updatedStakeholders);
    }
    // eslint-disable-next-line
  }, [evaluations]);

  const totalValue = Object.values(stakeholders)
    .reduce((sum, stakeholder) => sum + stakeholder.value, 0);

  return (
    <>
      <StyledHeader>
        <TitleIcon icon={ { src: StakeholdersIcon } } title={ t("planning:templates.stakeholders.title") } />
        <Typography>{t("planning:templates.stakeholders.description")}</Typography>
      </StyledHeader>
      <StyledTableContainer component={ Paper }>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell />
              {Object.keys(stakeholders).map((key) => (
                <StyledTableCell key={ key }>
                  <Stack direction={ "row" } justifyContent={ "center" } alignItems={ "center" } spacing={ 1 }>
                    <StyledTitle>
                      {getEvaluationType(stakeholders[key].type, i18n)[formLanguage]}
                    </StyledTitle>
                  </Stack>
                </StyledTableCell>
              ))}
              <StyledTotalTableCell>
                <StyledTitle>{t("planning:total")}</StyledTitle>
              </StyledTotalTableCell>
            </TableRow>
            <TableRow>
              <StyledTotalTableCell>
                <StyledTitle>{t("surveys:form.fields.weighing")}</StyledTitle>
              </StyledTotalTableCell>
              {Object.keys(stakeholders).map((key) => (
                <TableCell key={ key }>
                  <StyledTotalTitle>{stakeholders[key].value}</StyledTotalTitle>
                </TableCell>
              ))}
              <TableCell><StyledTotalTitle>{`${totalValue}%`}</StyledTotalTitle></TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
};

Stakeholders.propTypes = {
  t: PropTypes.func.isRequired,
  evaluations: PropTypes.arrayOf(PropTypes.shape({
    type: PropTypes.oneOf(Object.values(EVALUATION_TYPE)),
    weighing: PropTypes.number,
  })),
  i18n: PropTypes.any,
  formLanguage: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
};

Stakeholders.defaultProps = {
  evaluations: [],
  i18n: null,
};

export default Stakeholders;
