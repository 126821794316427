import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import Avatar from "@mui/material/Avatar";
import { formatDate, getAvatar } from "common/utils";
import Results from "./Results";
import {
  StyledBoxContainer,
  StyledImageContainer,
  StyledTitleWithSubtitle,
  StyledTitle,
  StyledSubtitle,
  StyledProfileInfo,
} from "./styles";

const CollaboratorProfileSummary = (props) => {
  const {
    collaborator,
  } = props;

  const { t } = useTranslation(["common", "talentDrain"]);

  const positionName = collaborator?.job_position?.position_name || collaborator?.position_name;
  const countryName = collaborator?.job_position?.country?.name || collaborator?.country_name;
  const startingDate = collaborator?.job_position?.starting_date || collaborator?.starting_date;

  return (
    <>
      <StyledBoxContainer>
        <StyledProfileInfo>
          <StyledImageContainer>
            <Avatar src={ getAvatar(collaborator) } />
          </StyledImageContainer>
          <StyledTitleWithSubtitle>
            <StyledTitle>
              {collaborator.full_name}
            </StyledTitle>
            {positionName
            && (
              <StyledSubtitle>
                {positionName}
                <span>{`${t("common:common.from")} ${formatDate(startingDate)}`}</span>
              </StyledSubtitle>
            ) }
            {countryName
            && (
              <p>
                <span>
                  {`${t("common:common.location")}: ${countryName}`}
                </span>
              </p>
            ) }
          </StyledTitleWithSubtitle>
        </StyledProfileInfo>
      </StyledBoxContainer>
      <Results t={ t } collaborator={ collaborator } />
    </>
  );
};

CollaboratorProfileSummary.propTypes = {
  collaborator: PropTypes.object.isRequired,
};

export default CollaboratorProfileSummary;
