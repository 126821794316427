import styled from "styled-components";
import Typography from "@mui/material/Typography";
import Rating from "@mui/material/Rating";
import StarIcon from "@mui/icons-material/Star";
import palette from "theme/palette";

export const StyledRating = styled(Rating)`
    &span {
      z-index: 0;
    }
  .MuiRating-iconFilled {
    color: ${palette.iconRating.default};
  }
`;

export const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  color: ${palette.iconRating.mediumGrey};
  font-weight: 700;
`;

export const StyledEmptyStar = styled(StarIcon)`
  color: ${palette.iconRating.empty};
`;

export const StyledTooltipScore = styled.span`
  font-size: 14px;
  color: rgba(255,255,255,0.7);
  strong {
    color: ${palette.white};
  }
`;
