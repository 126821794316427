export const getDataBreadcrumbs = (t) => [
  {
    label: t("title"),
    path: "/onboarding",
  },
  {
    label: t("candidates:candidate"),
    path: "",
  },
];

export const getDocumentFormat = (list, index) => list.map((item) => ({
  [index]: item.id,
  required: item.required,
}));

export const getSectionItemFormat = (list, index) => list.map((item) => ({
  [index]: item.id,
  required: item.required,
  order: item.order,
}));
