import { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import TabPanel from "components_refactor/TabPanel";
import Tab from "components/Tab";
import { getList as getFeedbacksByEmployeeIdAndType } from "redux/actions/feedbackActions";
import { getList } from "redux/actions/acknowledgementTypeActions";
import FeedbackList from "../FeedbackList";
import { FEEDBACK_TYPE, INDEX_TABS, TABS } from "../../functions";
import { useStyles } from "./styles";

const FeedbackContent = (props) => {
  const { companyId, employeeId } = props;
  const [tabHandler, setTabHandler] = useState(0);
  const { t } = useTranslation("feedback");
  const classes = useStyles();
  const dispatch = useDispatch();

  const {
    isLoadingList,
  } = useSelector(({ acknowledgementTypesReducer }) => acknowledgementTypesReducer);

  const {
    listSent: feedbacksSent,
    isLoadingListSent,
    listReceived: feedbacksReceived,
    isLoadingListReceived,
  } = useSelector(({ feedbackReducer }) => feedbackReducer);

  useEffect(() => {
    dispatch(getList());
  }, [dispatch]);

  useEffect(() => {
    dispatch(getFeedbacksByEmployeeIdAndType(employeeId));
    dispatch(getFeedbacksByEmployeeIdAndType(employeeId, true));
  }, [companyId, employeeId, dispatch]);

  const handleValueChanged = (value) => {
    setTabHandler(value);
  };

  return (
    <div className={ classes.root } data-testid={ "feedback-content-view" }>
      <Tab
        tabs={ TABS(t, feedbacksReceived?.length, feedbacksSent?.length) }
        onChange={ handleValueChanged }
        tabValue={ tabHandler }
      />
      <TabPanel
        value={ tabHandler }
        index={ INDEX_TABS.received }
      >
        {!isLoadingList && (
          <FeedbackList
            data={ feedbacksReceived }
            isLoading={ isLoadingListReceived }
            viewType={ FEEDBACK_TYPE.received }
          />
        )}
      </TabPanel>
      <TabPanel
        value={ tabHandler }
        index={ INDEX_TABS.sent }
      >
        {!isLoadingList && (
          <FeedbackList
            data={ feedbacksSent }
            isLoading={ isLoadingListSent }
            viewType={ FEEDBACK_TYPE.sent }
          />
        )}
      </TabPanel>
    </div>
  );
};

FeedbackContent.propTypes = {
  companyId: PropTypes.number.isRequired,
  employeeId: PropTypes.number.isRequired,
};

export default FeedbackContent;
