import styled from "styled-components";
import Modal from "components/Modal";
import EditIcon from "@mui/icons-material/Edit";
import palette from "theme/palette";

export const StyledModal = styled(Modal)`
  overflow-x: hidden;
`;

export const ButtonGroup = styled.div`
  display: flex;
  gap: 10px;
  margin-bottom: 10px;
`;

export const StyledEditIcon = styled(EditIcon)`
  color: ${palette.table.blue};
  width: 30px;
  height: 30px;
  margin: 0px !important;
`;
