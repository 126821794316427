import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import PropTypes from "prop-types";
import isEmpty from "lodash/isEmpty";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import LinearProgress from "@mui/material/LinearProgress";
import MobileStepper from "@mui/material/MobileStepper";
import { useTheme } from "@mui/styles";
import useMediaQuery from "@mui/material/useMediaQuery";
import charts from "theme/charts";
import Question from "components/Question";
import Button from "components/Button";
import SkeletonLoader from "components/SkeletonLoader";
import MultipleAnswers from "components/MultipleAnswers";
import AlertModal from "components/AlertModal";
// import Scale from "components/Scale";
import {
  BUTTON_STYLE_TYPES,
  COLORS_NAME,
  POSITION,
  VARIANT,
  SKELETONS_NUMBER,
  SKELETON_VARIANT,
  TYPES,
  SURVEY_PROCESS_TYPE,
  OBJECT_KEYS,
} from "common/constants";
import { getPercent, /* getScaleWithColors, */ findNearestNullIndex } from "common/utils";
import { isNullOrUndefined } from "common/helpers";
import useComplexState from "hooks/utils/useComplexState";
import { getStepsBySection } from "views/EvaluationForm/functions";
import { getLabelByType } from "views/Cover/functions";
import {
  isValidToViewFinalResults,
  sortAllResponses,
  sortIndividualAnswers,
  memberDataByDefault,
} from "../../functions";
import EvaluationResult from "../EvaluationResult";
import {
  StyledAnswersContent,
  StyledContainer,
  StyledContent,
  StyledMobileContainer,
  StyledSectionContainer,
  StyledProgressContainer,
  StyledBox,
  StyledBoxTitle,
  StyledBoxDescription,
  StickyDetail,
  StyledTooltip,
  StyledHelpIcon,
  StyledStepButtons,
} from "./styles";

const ResponsiveQuestions = (props) => {
  const {
    evaluation,
    evaluationScales,
    control,
    onSubmit,
    mobileAnswers,
    setMobileAnswers,
    teamAnswers,
    setTeamAnswers,
    isLoading,
    members,
    isDisabled,
    evaluationResult,
    handleSendDataByQuestion,
    handleToCoverOrHomeProcess,
    handleConfirmPage,
    handleNextRef,
    onProgressUpdate,
    hasGoalEvaluation,
  } = props;
  const { t, i18n } = useTranslation("surveys", "common");
  const { language } = i18n;
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const labels = getLabelByType(t);
  const isValidToViewResults = isValidToViewFinalResults(evaluation.type);

  const [questionStates, setQuestionStates] = useComplexState({
    activeStep: 0,
    allSteps: null,
    activeDefaultStep: false,
    hasComments: false,
    isTeamType: false,
    isOpenModal: false,
    incompleteAnswers: [],
  });
  const [completedClosedQuestions, setCompletedClosedQuestions] = useState(0);

  const [dataToSave, setDataToSave] = useState(null);
  const [memberDataToSave, setMemberDataToSave] = useState(null);

  const {
    one: resultScale,
  } = useSelector(({ resultScalesReducer }) => resultScalesReducer);

  const scaleIndices = resultScale?.result_scale_indices;
  // const scaleIndices = resultScale?.result_scale_indices ? getScaleWithColors(resultScale?.result_scale_indices) : [];
  const isPerformanceProcess = SURVEY_PROCESS_TYPE.performance.key === evaluation.survey_process_type;

  // Set the step with incomplete information as the default - teamAnswers
  useEffect(() => {
    if (teamAnswers && Object.values(teamAnswers).length !== 0
      && !questionStates?.activeDefaultStep && questionStates?.allSteps) {
      const sortedResponses = sortAllResponses(
        teamAnswers,
        questionStates.allSteps,
        questionStates.hasComments,
      );
      const arrayOfArrays = Object.values(sortedResponses).map(
        (teamAnswer) => Object.values(teamAnswer),
      );
      const nearestNullIndex = findNearestNullIndex(arrayOfArrays);

      const activeStep = nearestNullIndex !== null ? nearestNullIndex : (0);
      setQuestionStates({
        activeStep,
        activeDefaultStep: true,
        isTeamType: true,
      });
    }
    // eslint-disable-next-line
  }, [teamAnswers]);

  useEffect(() => {
    if (questionStates.activeStep === questionStates.allSteps?.length) {
      handleConfirmPage(true);
    } else {
      handleConfirmPage(false);
    }
    // eslint-disable-next-line
  }, [questionStates.activeStep]);

  // Set the step with incomplete information as the default - evaluationResult
  useEffect(() => {
    if (isEmpty(teamAnswers) && mobileAnswers && questionStates?.allSteps
        && !questionStates?.activeDefaultStep
    ) {
      const hasComments = questionStates?.hasComments;
      const mobileAnswersKeysLength = Object.keys(mobileAnswers).length;
      const validAnswers = hasComments ? mobileAnswersKeysLength + 1 : mobileAnswersKeysLength;
      if (validAnswers === questionStates.allSteps?.length) {
        const sortedResponses = sortIndividualAnswers(
          mobileAnswers,
          questionStates.allSteps,
          hasComments,
          mobileAnswers?.comment,
        );
        const arrayResults = Object.values(sortedResponses).map((obj) => Object.values(obj)[0]);
        const nullIndex = arrayResults.indexOf(null);
        let activeStep;
        if (nullIndex >= 0) {
          activeStep = nullIndex;
        } else {
          // eslint-disable-next-line no-unsafe-optional-chaining
          activeStep = questionStates.allSteps?.length - (isValidToViewResults ? 0 : 1);
        }
        setQuestionStates({
          activeStep,
          activeDefaultStep: true,
        });
      }
    }
    // eslint-disable-next-line
  }, [mobileAnswers]);

  const handleSlideAnswers = (id, label) => {
    setMobileAnswers({
      ...mobileAnswers,
      [id]: label,
    });
  };

  const handleTextareaToSave = () => {
    if (memberDataToSave) {
      handleSendDataByQuestion(memberDataToSave.memberData, memberDataToSave.memberId);
      setMemberDataToSave(null);
    } else if (dataToSave) {
      handleSendDataByQuestion(dataToSave);
      setDataToSave(null);
    }
  };

  const allStepsLength = questionStates.allSteps?.length;
  const isValidToSubmit = questionStates.activeStep === allStepsLength
  || (questionStates.activeStep === allStepsLength - 1 && !isValidToViewResults);

  const handleNext = () => {
    if (isValidToSubmit && hasGoalEvaluation) {
      onProgressUpdate(questionStates?.closedQuestions, completedClosedQuestions);
    } else {
      handleTextareaToSave();
      setQuestionStates({ activeStep: questionStates.activeStep + 1 });
    }
  };

  useEffect(() => {
    if (!handleNextRef || !questionStates?.allSteps?.[questionStates.activeStep]) {
      return;
    }
    const isLastStep = questionStates.activeStep === allStepsLength - 1;
    const isCurrentStepClosed = !questionStates.allSteps[questionStates.activeStep]?.isOpen;

    handleNextRef.current = isCurrentStepClosed && (!isLastStep || isValidToViewResults)
      ? handleNext
      : null;
  }, [handleNextRef, questionStates.activeStep, questionStates.allSteps]);

  const handleBack = () => {
    handleTextareaToSave();
    setQuestionStates({ activeStep: questionStates.activeStep - 1 });
    handleNextRef.current = null;
  };

  const handleChangePage = () => {
    handleTextareaToSave();
    handleToCoverOrHomeProcess();
  };

  const handleTeamAnswers = (id, label, memberId) => {
    setTeamAnswers((prevState) => ({
      ...prevState,
      [memberId]: {
        ...prevState[memberId],
        [id]: label,
      },
    }));
  };

  // Save data by evaluated, if isValidToSave is true it automatically saves, in the
  // case of questions with a scale, for those with text this value is set to false and
  // changed to true when the input loses focus.

  // Data by team member
  const handleMemberAnswers = (id, label, memberId, isValidToSave = true) => {
    setTeamAnswers((prevState) => ({
      ...prevState,
      [memberId]: {
        ...prevState[memberId],
        [id]: label,
      },
    }));
    const memberData = {
      [id]: label,
    };
    if (!isNullOrUndefined(label)) {
      setMemberDataToSave({
        id,
        memberData,
        memberId,
      });
    }
    if (isValidToSave) {
      setMemberDataToSave(memberDataByDefault);
      handleSendDataByQuestion(memberData, memberId);
    }
  };

  // Data by evaluated - simple form
  const handleAnswers = (id, label, isValidToSave = true) => {
    setMobileAnswers({
      ...mobileAnswers,
      [id]: label,
    });
    const evaluatedData = {
      [id]: label,
    };
    if (!isNullOrUndefined(label)) {
      setDataToSave(evaluatedData);
    }
    if (isValidToSave) {
      setDataToSave();
      handleSendDataByQuestion(evaluatedData);
    }
    const isClosedQuestion = questionStates.closedQuestions.some((step) => step.id === id);

    if (isClosedQuestion && !isNullOrUndefined(label)) {
      setCompletedClosedQuestions((prev) => prev + 1);
    }
  };

  useEffect(() => {
    if (questionStates?.closedQuestions) {
      onProgressUpdate(questionStates.closedQuestions, completedClosedQuestions);
    }
  }, [completedClosedQuestions]);

  useEffect(() => {
    if (questionStates.closedQuestions) {
      const answeredClosedQuestions = questionStates.closedQuestions.filter((step) => {
        const questionId = step.id ? step.id.toString() : null;
        if (!questionId) return false;

        if (questionStates.isTeamType) {
          return Object.values(teamAnswers).every(
            (memberAnswers) => !isNullOrUndefined(memberAnswers[questionId]) && memberAnswers[questionId] !== "",
          );
        }
        const answer = mobileAnswers[questionId];
        return !isNullOrUndefined(answer) && answer !== "";
      }).length;

      setCompletedClosedQuestions(answeredClosedQuestions);
    }
  }, [mobileAnswers, teamAnswers, questionStates.closedQuestions]);

  const handleModal = (state) => {
    setQuestionStates({ isOpenModal: state });
  };

  const handleModalOnClick = () => {
    setQuestionStates({
      activeStep: questionStates.incompleteAnswers[0],
      activeDefaultStep: true,
    });
    handleModal(false);
  };

  const handleSubmit = () => {
    const { isTeamType } = questionStates;

    const sortedResponses = isTeamType
      ? sortAllResponses(teamAnswers, questionStates.allSteps, questionStates.hasComments)
      : sortIndividualAnswers(mobileAnswers, questionStates.allSteps, questionStates.hasComments, mobileAnswers?.comment);

    const stepsToEvaluate = questionStates.allSteps;

    const incompleteAnswers = stepsToEvaluate.filter((step) => !step.isOpen).reduce((indices, step, index, isopen) => {
      const questionId = step.id ? step.id.toString() : null;

      if (questionId) {
        if (isTeamType) {
          const memberAnswersIncomplete = Object.values(teamAnswers).some((memberAnswers) => !memberAnswers[questionId] || memberAnswers[questionId].trim() === "");
          if (memberAnswersIncomplete) {
            indices.push(index);
          }
        } else {
          const responseObj = Object.values(sortedResponses).find((response) => questionId in response);
          const answer = responseObj ? responseObj[questionId] : null;
          if (!answer || answer.trim() === "") {
            indices.push(index);
          }
        }
      } else {
        indices.push(index);
      }
      return indices;
    }, []);
    if (incompleteAnswers.length > 0) {
      setQuestionStates({ incompleteAnswers });
      handleModal(true);
    } else {
      onSubmit();
    }
  };

  useEffect(() => {
    if (evaluation) {
      let steps = [];
      evaluation.evaluation_sections.forEach((section) => {
        steps = [
          ...steps,
          ...getStepsBySection(
            t,
            section,
            control,
            evaluationScales,
            isMobile,
            mobileAnswers,
            handleSlideAnswers,
            teamAnswers,
            handleTeamAnswers,
            language,
            members,
            isDisabled,
            handleMemberAnswers,
            evaluationResult,
            handleAnswers,
            evaluation?.has_comments,
          ),
        ];
      });

      if (evaluation?.has_comments) {
        let defaultComment = null;
        if (evaluationResult?.comment) {
          defaultComment = evaluationResult.comment;
        }
        steps.push({
          isOpen: true,
          questionName: members ? t("surveys:form.fields.general_data.general_comments_title") : null,
          detail: members ? (
            <MultipleAnswers
              id={ OBJECT_KEYS.comment }
              members={ members }
              teamAnswers={ teamAnswers }
              handleTeamAnswers={ handleMemberAnswers }
            />
          ) : (
            <Question
              id={ OBJECT_KEYS.comment }
              title={ t("surveys:form.fields.general_data.general_comments_title") }
              control={ control }
              handleAnswers={ handleAnswers }
              value={ mobileAnswers?.comment === undefined ? defaultComment : mobileAnswers?.comment }
              isDisabled={ isDisabled }
            />
          ),
        });
      }
      const closedQuestions = steps.filter((step) => !step.isOpen);

      if (members && members.length > 0) {
        setQuestionStates({
          allSteps: steps,
          hasComments: evaluation?.has_comments,
          isTeamType: true,
          closedQuestions,
        });
      } else {
        setQuestionStates({
          allSteps: steps,
          hasComments: evaluation?.has_comments,
          closedQuestions,
        });
      }
    }
    // eslint-disable-next-line
  }, [evaluation, mobileAnswers, teamAnswers, isDisabled]);

  const getStepButtons = (isSubmit = false) => (
    <StyledStepButtons>
      <Button
        color={ COLORS_NAME.primary }
        variant={ BUTTON_STYLE_TYPES.OUTLINED }
        onMouseDown={ handleBack }
        isDisabled={ questionStates.activeStep === 0 || isDisabled }
        typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
      >
        { t("common:common.previous") }
      </Button>
      <Button
        isDisabled={ isLoading || isDisabled }
        variant={ VARIANT.contained }
        color={ COLORS_NAME.primary }
        typeStyle={ BUTTON_STYLE_TYPES.SUBMIT }
        onMouseDown={ () => (isSubmit ? handleSubmit() : handleNext()) }
      >
        {isSubmit ? t("common:common.submit") : t("common:common.next")}
      </Button>
    </StyledStepButtons>
  );

  const buttons = (
    <MobileStepper
      variant={ VARIANT.none }
      steps={ allStepsLength }
      position={ POSITION.static }
      activeStep={ questionStates.activeStep }
      nextButton={ !hasGoalEvaluation && isValidToSubmit
        ? getStepButtons(true) : getStepButtons() }
      backButton={ (
        <Button
          variant={ BUTTON_STYLE_TYPES.OUTLINED }
          type={ TYPES.text }
          onMouseDown={ handleChangePage }
          isDisabled={ isDisabled }
          typeStyle={ BUTTON_STYLE_TYPES.OUTLINED }
        >
          { isMobile ? t("common:common.cancel") : t("surveys:continueLater") }
        </Button>
      ) }
    />
  );

  const getQuestionsRenderMobile = () => (
    <StyledAnswersContent>
      <LinearProgress
        variant={ VARIANT.determinate }
        value={ getPercent(questionStates.activeStep / (allStepsLength - 1)) }
      />
      <StyledMobileContainer key={ questionStates.allSteps[questionStates.activeStep].title }>
        {!questionStates.allSteps[questionStates.activeStep].isOpen
        && (
          <Typography variant={ VARIANT.h3 }>
            {questionStates.allSteps[questionStates.activeStep].title}
          </Typography>
        )}
        {questionStates.allSteps[questionStates.activeStep].questionName
          && (
            <StickyDetail>
              <Typography variant={ VARIANT.h5 }>
                {questionStates.allSteps[questionStates.activeStep].questionName}
              </Typography>
              {questionStates.allSteps[questionStates.activeStep].descriptionScale
              && (
                <StyledTooltip
                  title={ questionStates.allSteps[questionStates.activeStep].descriptionScale }
                >
                  <StyledHelpIcon />
                </StyledTooltip>
              )}
            </StickyDetail>
          )}
        {questionStates.allSteps[questionStates.activeStep].detail}
        {buttons}
      </StyledMobileContainer>
    </StyledAnswersContent>
  );

  const getQuestionsRenderDesktop = () => (
    <StyledContainer>
      <StyledContent>
        <StyledSectionContainer key={ questionStates.allSteps[questionStates.activeStep].title }>
          {!questionStates.allSteps[questionStates.activeStep].isOpen
        && (
          <Typography variant={ VARIANT.h3 }>
            {questionStates.allSteps[questionStates.activeStep].title}
          </Typography>
        )}
          {questionStates.allSteps[questionStates.activeStep].questionName
          && (
            <StickyDetail>
              <Typography variant={ VARIANT.h5 }>
                {questionStates.allSteps[questionStates.activeStep].questionName}
              </Typography>
              {questionStates.allSteps[questionStates.activeStep].descriptionScale
              && (
                <StyledTooltip
                  title={ questionStates.allSteps[questionStates.activeStep].descriptionScale }
                >
                  <StyledHelpIcon />
                </StyledTooltip>
              )}
            </StickyDetail>
          )}
          {questionStates.allSteps[questionStates.activeStep].detail}
          {/* progress */}
          <StyledProgressContainer>
            <Typography variant={ VARIANT.bodyOne }>
              { `${t("common:common.page")} ${questionStates.activeStep + 1} ${t("tables:footer.of")} ${allStepsLength}`}
            </Typography>
            <LinearProgress
              variant={ VARIANT.determinate }
              value={ getPercent(questionStates.activeStep
                / (allStepsLength - 1)) }
            />
          </StyledProgressContainer>
          {buttons}
        </StyledSectionContainer>
      </StyledContent>
    </StyledContainer>
  );

  // FIXME: goal section
  const getResults = () => (
    <StyledContainer>
      <StyledContent>
        <StyledSectionContainer key={ "result" }>
          {!hasGoalEvaluation
          && (
            <Typography variant={ VARIANT.h4 }>
              { t("surveys:confirmAnswers") }
            </Typography>
          )}
          <StyledBox>
            <StyledBoxTitle>{ `${labels[evaluation.type]}:` }</StyledBoxTitle>
            <StyledBoxDescription variant={ VARIANT.bodyOne }>
              {t("surveys:confirmAnswersDescription", { type: t(`surveys:type.${ evaluationResult || members.length === 1 ? "singular" : "plural"}`) })}
            </StyledBoxDescription>
            <Grid container spacing={ 1 }>
              <EvaluationResult
                members={ members ? members.map(
                  (member) => member.evaluationResult,
                ) : [evaluationResult] }
                scaleIndices={ scaleIndices }
                isValidToStarRating={ isPerformanceProcess }
              />
              {/* TODO: change design */}
              {/* <Grid item xs={ 12 } md={ 8 } />
              <Grid item xs={ 12 } md={ 4 }>
                <Scale scaleIndices={ scaleIndices } />
              </Grid> */}
            </Grid>
          </StyledBox>
          {buttons}
        </StyledSectionContainer>
      </StyledContent>
    </StyledContainer>
  );

  // TODO
  // Add a final step that should have the total per person
  // only for peers, self-evaluation, and team
  const getQuestionsRender = () => (
    allStepsLength === questionStates.activeStep ? getResults()
      : isMobile ? getQuestionsRenderMobile()
        : getQuestionsRenderDesktop());

  return (
    <>
      {!questionStates.allSteps
        ? (
          <SkeletonLoader
            numberOfSkeletons={ SKELETONS_NUMBER.FOUR }
            variant={ SKELETON_VARIANT.rectangular }
            isInline={ false }
          />
        ) : questionStates.allSteps?.length === 0 ? "" : getQuestionsRender()}
      <AlertModal
        title={ t("surveys:alert.info.title") }
        text={ t("surveys:incompleteAnswers", { incompleteAnswers: questionStates.incompleteAnswers.length }) }
        textAgree={ t("common:answer") }
        onClick={ handleModalOnClick }
        open={ questionStates.isOpenModal }
        handleClose={ () => handleModal(false) }
      />
    </>
  );
};

ResponsiveQuestions.propTypes = {
  evaluation: PropTypes.object.isRequired,
  evaluationScales: PropTypes.object.isRequired,
  control: PropTypes.object.isRequired,
  onSubmit: PropTypes.func.isRequired,
  mobileAnswers: PropTypes.object.isRequired,
  setMobileAnswers: PropTypes.func.isRequired,
  teamAnswers: PropTypes.object.isRequired,
  setTeamAnswers: PropTypes.func.isRequired,
  members: PropTypes.array.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  handleSendDataByQuestion: PropTypes.func.isRequired,
  handleToCoverOrHomeProcess: PropTypes.func.isRequired,
  handleConfirmPage: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  evaluationResult: PropTypes.object,
  handleNextRef: PropTypes.object.isRequired,
  onProgressUpdate: PropTypes.func.isRequired,
  hasGoalEvaluation: PropTypes.bool.isRequired,
};

ResponsiveQuestions.defaultProps = {
  isLoading: false,
  evaluationResult: null,
};

export default ResponsiveQuestions;
