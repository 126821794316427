import { useQuery, useMutation, useQueryClient } from "react-query";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import {
  // getInternalClients,
  getInternalClientsByEmployee,
  createInternalClients,
  // updateInternalClient,
  deleteInternalClient,
  getInternalClientsAssignedToEmployee,
} from "redux/actions/internalClientActions";

// export const useInternalClients = (t) => {
//   const {
//     data: internalClients, isLoading, isError, error,
//   } = useQuery(
//     "internalClients",
//     getInternalClients,
//     {
//       staleTime: Infinity,
//       onError: (queryError) => {
//         const errorMessage = queryError?.response?.data?.error || t("common:error.default");
//         toast(MESSAGE_TYPES.error, { message: errorMessage });
//       },
//     },
//   );

//   return {
//     internalClients, isLoading, isError, error,
//   };
// };

export const useInternalClientsByEmployee = (employeeId, t, hasInternalClient = false) => {
  const {
    data: internalClients, isLoading, isError, error,
  } = useQuery(
    ["internalClientsByEmployee", employeeId],
    () => getInternalClientsByEmployee(employeeId),
    {
      enabled: !!employeeId && hasInternalClient,
      staleTime: Infinity,
      retry: false,
      onError: (queryError) => {
        const errorMessage = queryError?.response?.data?.error || t("common:error.default");
        toast(MESSAGE_TYPES.error, { message: errorMessage });
      },
    },
  );

  return {
    internalClients, isLoading, isError, error,
  };
};

export const useCreateInternalClient = (t) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(createInternalClients, {
    onSuccess: () => {
      queryClient.invalidateQueries("internalClientsByEmployee");
      toast(MESSAGE_TYPES.success, { message: t("common:internal_clients.alert.create") });
    },
    onError: (mutationError) => {
      const errorMessage = mutationError?.response?.data?.error || t("common:error.default");
      toast(MESSAGE_TYPES.error, { message: errorMessage });
    },
  });

  return mutation;
};

// export const useUpdateInternalClient = (t) => {
//   const queryClient = useQueryClient();

//   const mutation = useMutation(
//     ({ id, data }) => updateInternalClient(id, data),
//     {
//       onSuccess: () => {
//         queryClient.invalidateQueries("internalClientsByEmployee");
//         toast(MESSAGE_TYPES.success, { message: t("common:internal_clients.alert.update") });
//       },
//       onError: (mutationError) => {
//         const errorMessage = mutationError?.response?.data?.error || t("common:error.default");
//         toast(MESSAGE_TYPES.error, { message: errorMessage });
//       },
//     },
//   );

//   return mutation;
// };

export const useDeleteInternalClient = (t, employeeId) => {
  const queryClient = useQueryClient();

  const mutation = useMutation((id) => deleteInternalClient(id), {
    onSuccess: (data, id) => {
      queryClient.setQueryData(["internalClientsByEmployee", employeeId], (oldClients) => {
        if (!oldClients) return [];
        return oldClients.filter((client) => client.id !== id);
      });

      toast(MESSAGE_TYPES.success, { message: t("common:internal_clients.alert.delete") });
    },
    onError: (mutationError) => {
      const errorMessage = mutationError?.response?.data?.error || t("common:error.default");
      toast(MESSAGE_TYPES.error, { message: errorMessage });
    },
  });

  return mutation;
};

export const useInternalClientsAssignedToEmployee = (employeeId, t) => {
  const {
    data: assignedInternalClients, isLoading, isError, error,
  } = useQuery(
    ["internalClientsAssignedToEmployee", employeeId],
    () => getInternalClientsAssignedToEmployee(employeeId),
    {
      enabled: !!employeeId,
      staleTime: Infinity,
      retry: false,
      onError: (queryError) => {
        const errorMessage = queryError?.response?.data?.error || t("common:error.default");
        toast(MESSAGE_TYPES.error, { message: errorMessage });
      },
    },
  );

  return {
    assignedInternalClients, isLoading, isError, error,
  };
};
