import PropTypes from "prop-types";
import IconButton from "@mui/material/IconButton";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import CopyAllIcon from "@mui/icons-material/CopyAll";
import DeleteIcon from "@mui/icons-material/Delete";
import { DIRECTION } from "common/constants";
import { StyledContainer, StyledStack } from "./styles";

const SectionActions = ({ onAdd, onDuplicate, onDelete }) => (
  <StyledContainer>
    <StyledStack direction={ DIRECTION.row } >
      <IconButton onClick={ onAdd } aria-label={ "add section" }>
        <AddCircleIcon />
      </IconButton>
      <IconButton onClick={ onDuplicate } aria-label={ "copy section" }>
        <CopyAllIcon />
      </IconButton>
      <IconButton onClick={ onDelete } aria-label={ "delete section" }>
        <DeleteIcon />
      </IconButton>
    </StyledStack>
  </StyledContainer>
);

SectionActions.propTypes = {
  onAdd: PropTypes.func.isRequired,
  onDuplicate: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
};

export default SectionActions;
