import {
  useState, useContext, useEffect, useRef,
} from "react";
import PropTypes from "prop-types";
import { Controller } from "react-hook-form";
import { SessionContext } from "modules/session/context";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import EditIcon from "@mui/icons-material/Edit";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Switch from "@mui/material/Switch";
import InputEditor from "components/InputEditor";
import { formatDate } from "common/utils";
import {
  StyledAccordion, StyledAccordionOption, StyledTitle,
  StyledOptionContainer, StyledFullWidthButton,
} from "./styles";

const NotificationAccordion = (props) => {
  const {
    t, setPreviewHtml, mandrillTemplates, control, process, formValues, setFormValues,
  } = props;
  const {
    state: { user },
  } = useContext(SessionContext);
  const debounceTimerRef = useRef(null);

  const companyName = user?.company?.name;
  const employeeName = user?.person?.full_name;
  const [expanded, setExpanded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [selectedTab, setSelectedTab] = useState(0);
  const [contentEs, setContentEs] = useState(localStorage.getItem("email_content_es"));
  const [contentEn, setContentEn] = useState(localStorage.getItem("email_content_en"));
  const [contentPt, setContentPt] = useState(localStorage.getItem("email_content_pt"));

  useEffect(() => {
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      email_content_es: localStorage.getItem("email_content_es"),
      email_content_en: localStorage.getItem("email_content_en"),
      email_content_pt: localStorage.getItem("email_content_pt"),
    }));
    // eslint-disable-next-line
  }, []);

  const [switchStates, setSwitchStates] = useState({
    // reminder: true,
    closure: formValues.closure_email || false,
  });

  const replaceTemplateVariables = (templateCode, emailContent = "") => {
    let updatedHtml = templateCode;
    const values = control.getValues();
    const startDate = formatDate(values.start_date);
    const endDate = formatDate(values.end_date);
    updatedHtml = updatedHtml.replace(/\*\|name\|\*/g, employeeName);
    updatedHtml = updatedHtml.replace(/\*\|company\|\*/g, companyName);
    updatedHtml = updatedHtml.replace(/\*\|open_date\|\*/g, startDate);
    updatedHtml = updatedHtml.replace(/\*\|close_date\|\*/g, endDate);
    updatedHtml = updatedHtml.replace(/\*\|client_content\|\*/g, emailContent);
    updatedHtml = updatedHtml.replace(/<a href="\*\|TEXT:button_url\|\*" target="_blank">/g, "<a href=\"javascript:void(0);\">");
    return updatedHtml;
  };

  const handleEmailPreview = async (templateSlug, emailContent) => {
    const template = mandrillTemplates?.find((item) => item.slug === templateSlug);
    const htmlTemplate = replaceTemplateVariables(template.code, emailContent);
    setPreviewHtml(htmlTemplate);
  };

  const handleOptionClick = (option, templateSlug, emailContent = null) => {
    setSelectedOption(option);
    handleEmailPreview(templateSlug, emailContent);
  };

  const handleOptionClickNoExpanded = (option, templateSlug, emailContent = null) => {
    if (option !== "launch") {
      setExpanded(false);
    }
    handleOptionClick(option, templateSlug, emailContent);
  };

  const handleSwitchChange = (option) => (event) => {
    const isChecked = event.target.checked;
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      closure_email: isChecked,
    }));
    setSwitchStates({ ...switchStates, [option]: isChecked });
  };

  const debouncedHandleOptionClick = (option, templateSlug, value) => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }

    debounceTimerRef.current = setTimeout(() => {
      handleOptionClick(option, templateSlug, value);
    }, 1000);
  };

  const getTemplate = (value, lang = "es") => {
    const templateSlug = `${process.templates.send_process_custom_content_basic}-${lang}`;
    debouncedHandleOptionClick("launch", templateSlug, value);
  };

  useEffect(() => {
    if (process && mandrillTemplates) {
      if (process.onlyClosure) {
        handleOptionClick("closure", process.templates.closure_type_manager);
      } else if (contentEs) {
        getTemplate(contentEs, "es");
      } else {
        handleOptionClick("launch", process.templates.send_process);
      }
    }
  }, [mandrillTemplates]);

  const handleAccordionChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (isExpanded) {
      if (contentEs) {
        getTemplate(contentEs, "es");
      } else {
        handleOptionClick("launch", process.templates.send_process);
      }
    }
  };

  const validateInputEditEs = (value) => {
    getTemplate(value);
    setContentEs(value);
    localStorage.setItem("email_content_es", value);
    return false;
  };

  const validateInputEditEn = (value) => {
    getTemplate(value, "en");
    setContentEn(value);
    localStorage.setItem("email_content_en", value);
    return false;
  };

  const validateInputEditPt = (value) => {
    getTemplate(value, "pt");
    setContentPt(value);
    localStorage.setItem("email_content_pt", value);
    return false;
  };

  useEffect(() => () => {
    if (debounceTimerRef.current) {
      clearTimeout(debounceTimerRef.current);
    }
  }, []);

  const languageCodes = ["es", "en", "pt"];

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
    const languageCode = languageCodes[newValue];
    const contentValue = {
      es: contentEs,
      en: contentEn,
      pt: contentPt,
    }[languageCode];
    getTemplate(contentValue, languageCode);
  };

  const editorStyle = (index) => ({
    display: selectedTab === index ? "block" : "none",
  });

  return (
    <div>
      {!process.onlyClosure && (
        <>
          <StyledAccordion
            expanded={ expanded === "panel1" }
            onChange={ handleAccordionChange("panel1") }
            className={ selectedOption === "launch" ? "active" : "" }
          >
            <AccordionSummary
              expandIcon={ <ExpandMoreIcon /> }
              aria-controls={ "panel1bh-content" }
              id={ "panel1bh-header" }
            >
              <StyledAccordionOption>
                <StyledTitle isSelected={ selectedOption === "launch" }>
                  <EditIcon />
                  {t("surveys:notifications.launch")}
                </StyledTitle>
              </StyledAccordionOption>
            </AccordionSummary>
            <AccordionDetails>
              <Tabs
                value={ selectedTab }
                onChange={ handleTabChange }
                aria-label={ "language tabs" }
                variant={ "fullWidth" }
              >
                <Tab label={ t("planning:email_content_es") } />
                <Tab label={ t("planning:email_content_en") } />
                <Tab label={ t("planning:email_content_pt") } />
              </Tabs>

              <div style={ editorStyle(0) }>
                <Controller
                  as={ (
                    <InputEditor
                      validateInputEdit={ validateInputEditEs }
                      text={ formValues.email_content_es }
                    />
                  ) }
                  name={ "email_content_es" }
                  control={ control }
                />
              </div>
              <div style={ editorStyle(1) }>
                <Controller
                  as={ (
                    <InputEditor
                      validateInputEdit={ validateInputEditEn }
                      text={ formValues.email_content_en }
                    />
                  ) }
                  name={ "email_content_en" }
                  control={ control }
                />
              </div>
              <div style={ editorStyle(2) }>
                <Controller
                  as={ (
                    <InputEditor
                      validateInputEdit={ validateInputEditPt }
                      text={ formValues.email_content_pt }
                    />
                  ) }
                  name={ "email_content_pt" }
                  control={ control }
                />
              </div>
            </AccordionDetails>
          </StyledAccordion>
          <StyledOptionContainer
            onClick={ () => handleOptionClickNoExpanded("reminder", process.templates.send_process_reminder) }
            className={ selectedOption === "reminder" ? "active" : "" }
          >
            <StyledFullWidthButton>
              <StyledTitle isSelected={ selectedOption === "reminder" }>
                <VisibilityIcon />
                {t("surveys:notifications.reminder")}
              </StyledTitle>
            </StyledFullWidthButton>
            {/* <Switch checked={ switchStates.reminder } onChange={ handleSwitchChange("reminder") } /> */}
          </StyledOptionContainer>
        </>
      )}

      <StyledOptionContainer
        onClick={ () => handleOptionClickNoExpanded("closure", process.templates.closure_type_manager) }
        className={ selectedOption === "closure" ? "active" : "" }
      >
        <StyledFullWidthButton>
          <StyledTitle isSelected={ selectedOption === "closure" }>
            <VisibilityIcon />
            {t("surveys:notifications.closure")}
          </StyledTitle>
        </StyledFullWidthButton>
        <Switch checked={ switchStates.closure } onChange={ handleSwitchChange("closure") } />
      </StyledOptionContainer>
    </div>
  );
};

NotificationAccordion.propTypes = {
  t: PropTypes.func.isRequired,
  setPreviewHtml: PropTypes.func.isRequired,
  mandrillTemplates: PropTypes.arrayOf(
    PropTypes.shape({
      slug: PropTypes.string.isRequired,
      code: PropTypes.string.isRequired,
    }),
  ),
  control: PropTypes.shape({
    getValues: PropTypes.func.isRequired,
  }).isRequired,
  process: PropTypes.shape({
    onlyClosure: PropTypes.bool.isRequired,
    templates: PropTypes.shape({
      send_process: PropTypes.string.isRequired,
      send_process_reminder: PropTypes.string.isRequired,
      closure_type_manager: PropTypes.string.isRequired,
      send_process_custom_content_basic: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  formValues: PropTypes.shape({
    email_content_es: PropTypes.string.isRequired,
    email_content_en: PropTypes.string.isRequired,
    email_content_pt: PropTypes.string.isRequired,
    closure_email: PropTypes.bool.isRequired,
  }).isRequired,
  setFormValues: PropTypes.func.isRequired,
};

NotificationAccordion.defaultProps = {
  mandrillTemplates: null,
};

export default NotificationAccordion;
