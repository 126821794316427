import PropTypes from "prop-types";
import Typography from "@mui/material/Typography";
import StarRating from "components/StarRating";
import { NINEBOX } from "common/constants";
import { getPercent } from "common/utils";
import { starRatingAttr } from "../../functions";
import {
  Container, Results, PerformanceData,
} from "./styles";

const HistoricalResult = (props) => {
  const { data, t } = props;

  return (
    <Container>
      {data && (
        <>
          <div>
            {data.quadrantData && !data.title
            && (
              <Typography variant={ "h6" }>
                {`${t("ninebox:quadrant")}: ${data.quadrantData[NINEBOX.quadrantData.name]}`}
              </Typography>
            )}
          </div>
          <Results>
            <PerformanceData>
              <Typography variant={ "h6" }>
                {`${t("performance:title")} `}
                {data.process?.evaluationDates.performanceDate
                  && `| ${data.process.evaluationDates.performanceDate}`}
              </Typography>
              <StarRating
                name={ starRatingAttr(data.performanceResultText, "") }
                value={ data?.performancePosition?.position }
                isReadOnly
                maxRating={ data?.performancePosition?.max }
                max={ data?.performancePosition?.max }
                precision={ 1 }
                hasNoFormattedValue
                label={ data?.performanceResultText }
                size={ "small" }
                tooltip={ data?.performanceScore && data?.performanceMaxScale ? {
                  score: `${getPercent(data.performanceScore, true, 2)}`,
                  maxScore: getPercent(data.performanceMaxScale, true, 2),
                } : null }
              />
            </PerformanceData>
            <div>
              <Typography variant={ "h6" }>
                {`${t("potential:title")} `}
                {data.process?.evaluationDates.potentialDate
                  && `| ${data.process.evaluationDates.potentialDate}`}
              </Typography>
              {data.potentialResultText
              && (
                <Typography>
                  {data.potentialResultText}
                </Typography>
              )}
            </div>
          </Results>
        </>
      )}
    </Container>
  );
};

HistoricalResult.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
};

export default HistoricalResult;
