import styled from "styled-components";
import IconButton from "@mui/material/IconButton";
import palette from "theme/palette";

export const StyledInfoContainer = styled.div`
  min-height: ${(props) => props.minHeight };
  p {
    margin-top: 8px;
  }
  .imageTextContainer{
    margin-bottom: 4px;
    display: flex;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      path {
        fill: ${(props) => props.color && palette.text[props.color]};
      }
    }
    a {
      color: ${(props) => props.color && palette.text[props.color]};
      margin: 0;
      margin-left: 4px;
    }
  }
`;

export const StyledTitle = styled.div`
  font-size: 16px;
  color: ${palette.text.secondaryTitle};
  font-weight: ${(props) => props.weight};
  margin-bottom: ${(props) => (props.isTitle ? "16px" : "0px")};
`;

export const StyleRolesContainer = styled.p`
  overflow-wrap: break-word;
  span {
    color: ${palette.text.link};
    padding: 0 4px;
  }
`;

export const StyledLink = styled.p`
  color: ${palette.text.link};
  cursor: pointer;
  :hover {
    text-decoration: underline;
  }
`;

export const StyledTabsContainer = styled.div`
  .MuiTab-textColorPrimary {
    color: ${palette.text.secondary};
  }
  .Mui-selected {
    background: ${palette.backgroundTab};
    color: ${palette.black};
  }
  .MuiTab-root {
    min-width: auto;
  }
`;

export const StyledEmptyContainer = styled.div`
  div {
    padding: 0;
    width: 100%;
    p {
      padding: 10px;
    }
    img {
      width: 150px;
      margin: 0;
    }
  }
  button {
    display: flex;
    text-align: center;
    width: 100%;
    margin-bottom: 10px;
    &:hover {
      background: none;
      color: ${palette.primaryApp};
    }
  }
`;

export const StyledIconButton = styled(IconButton)`
  margin-left: 0px !important;
  padding: 0;
  svg {
    width: 20px;
    height: 20px;
    fill: ${(props) => (props.disabled ? palette.background.gray : palette.background.valid)};
  }
`;
