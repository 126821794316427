import { useQuery, useMutation, useQueryClient } from "react-query";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { getTags, assignTags, removeTags } from "redux/actions/tagActions";

const handleQueryError = (error, t) => {
  let errorMessage = t("common:common.api_responses.error.title");

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  } else {
    errorMessage = error.toString();
  }

  toast(MESSAGE_TYPES.error, { message: errorMessage });
};

export const useTags = (t, resetData) => {
  const queryClient = useQueryClient();

  const {
    data: currentTags = [],
    isLoading,
    isError,
    error,
  } = useQuery("tags", getTags, {
    staleTime: Infinity,
    onError: (queryError) => handleQueryError(queryError, t),
  });

  const assignTagsMutation = useMutation(
    ({ goalId, tagNames }) => assignTags({ goalId, tagNames }),
    {
      onSuccess: (updatedGoal) => {
        queryClient.invalidateQueries("tags");
        resetData(updatedGoal);
      },
      onError: (errorResponse) => {
        handleQueryError(errorResponse, t);
      },
    },
  );

  const removeTagsMutation = useMutation(
    ({ goalId, tagNames }) => removeTags({ goalId, tagNames }),
    {
      onSuccess: (updatedGoal) => {
        resetData(updatedGoal);
      },
      onError: (errorResponse) => {
        handleQueryError(errorResponse, t);
      },
    },
  );

  return {
    tags: currentTags,
    isLoading,
    isError,
    error,
    assignTagsMutation,
    removeTagsMutation,
  };
};
