import { StyledSectionContainer } from "./styles";

const SectionContainer = (props) => {
  const { children, height, isFlex, padding } = props;
  return (
    <StyledSectionContainer height={ height } isFlex={ isFlex } padding={ padding }>
      {children}
    </StyledSectionContainer>
  );
};

export default SectionContainer;
