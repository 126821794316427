import {
  RESET_STATE,
  GET_LIST,
  GET_LIST_LOADING,
  GET_LIST_ERROR,
  RESET_PROCESS_AUDIENCE,
  PROCESS_AUDIENCE,
  PROCESS_AUDIENCE_LOADING,
  PROCESS_AUDIENCE_ERROR,
  UPDATE_PROCESS_AUDIENCE,
  UPDATE_PROCESS_AUDIENCE_LOADING,
  UPDATE_PROCESS_AUDIENCE_ERROR,
  GET_AUDIENCE,
  GET_AUDIENCE_LOADING,
  GET_AUDIENCE_ERROR,
} from "../../actionTypes/audience";

const INITIAL_STATE = {
  list: null,
  isLoadingList: false,
  errorList: "",

  isLoadingProcessAudience: false,
  successProcessAudience: null,
  errorProcessAudience: null,

  isLoadingUpdateAudience: false,
  successUpdateAudience: null,
  errorUpdateAudience: null,

  audience: null,
  isLoadingAudience: false,
  errorAudience: null,
};

// eslint-disable-next-line default-param-last
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
  case RESET_STATE:
    return {
      ...state,
      isLoadingList: false,
      errorList: null,
      list: null,
    };
  case RESET_PROCESS_AUDIENCE:
    return {
      ...state,
      isLoadingProcessAudience: false,
      successProcessAudience: null,
      errorProcessAudience: null,
    };
  case GET_LIST:
    return {
      ...state,
      list: action.payload,
      isLoadingList: false,
      errorList: "",
    };
  case GET_LIST_LOADING:
    return {
      ...state,
      isLoadingList: true,
    };
  case GET_LIST_ERROR:
    return {
      ...state,
      isLoadingList: false,
      errorList: action.payload,
    };
  case PROCESS_AUDIENCE:
    return {
      ...state,
      isLoadingProcessAudience: false,
      errorProcessAudience: null,
      successProcessAudience: action.payload,
    };
  case PROCESS_AUDIENCE_LOADING:
    return {
      ...state,
      isLoadingProcessAudience: true,
      errorProcessAudience: null,
      successProcessAudience: null,
    };
  case PROCESS_AUDIENCE_ERROR:
    return {
      ...state,
      errorProcessAudience: action.payload,
      isLoadingProcessAudience: false,
    };
  case UPDATE_PROCESS_AUDIENCE:
    return {
      ...state,
      isLoadingUpdateAudience: false,
      errorUpdateAudience: null,
      successUpdateAudience: action.payload,
    };
  case UPDATE_PROCESS_AUDIENCE_LOADING:
    return {
      ...state,
      isLoadingUpdateAudience: true,
      errorUpdateAudience: null,
      successUpdateAudience: null,
    };
  case UPDATE_PROCESS_AUDIENCE_ERROR:
    return {
      ...state,
      errorUpdateAudience: action.payload,
      isLoadingUpdateAudience: false,
    };
  case GET_AUDIENCE:
    return {
      ...state,
      audience: action.payload,
      isLoadingAudience: false,
      errorAudience: null,
    };
  case GET_AUDIENCE_LOADING:
    return {
      ...state,
      isLoadingAudience: true,
      errorAudience: null,
      audience: null,
    };
  case GET_AUDIENCE_ERROR:
    return {
      ...state,
      isLoadingAudience: false,
      errorAudience: action.payload,
    };
  default:
    return state;
  }
};
