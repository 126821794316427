import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import includes from "lodash/includes";
import isNull from "lodash/isNull";
import isEmpty from "lodash/isEmpty";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import Box from "@mui/material/Box";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import NoDataMessage from "components/NoDataMessage";
import ButtonAddTable from "components/ButtonAddTable";
import { SORT_COMPARATOR, VARIANT } from "common/constants";
import {
  getComparator,
  sortTable,
  isNewInputRow,
  isMainEmptyList,
} from "../../functions";
import TRowCustom from "../TRowCustom";
import { useStyles, StyledTableCell } from "../../styles";

const TBodyCustom = (props) => {
  const {
    list,
    keys,
    order,
    orderBy,
    currentPage,
    maxPerPage,
    colspan,
    isLoading,
    getCollapseData,
    isNotPagination,
    isNotHeader,
    isDragable,
    actions,
    showCollapseData,
    isActive,
  } = props;

  const classes = useStyles();
  const { t } = useTranslation("common");

  const [openRow, setOpenRow] = useState(null);

  let parentId;
  if (actions) {
    parentId = !isNull(actions.newIdElement) ? actions.newIdElement : actions.parentId;
  }

  const handleAddNew = () => {
    actions.addNew(actions.module, actions.parentId);
  };

  const getNewCustomRow = () => actions.getNewCustomRow(actions.module, actions.parentId);

  const handleToggleRow = (id) => {
    setOpenRow((prev) => (prev === id ? null : id));
  };

  const emptyBody = (
    <TableRow>
      <StyledTableCell colSpan={ colspan }>
        {!isLoading && !actions && (
          <NoDataMessage customStyles={ classes.emptyInfoButton } />
        )}
        {!isLoading && actions && isNewInputRow(actions, parentId) && getNewCustomRow()}
        {isMainEmptyList(isLoading, actions) && (
          <>
            <NoDataMessage customStyles={ classes.emptyInfoButton } />
            <Box className={ classes.emptyContainer }>
              <ButtonAddTable
                variant={ VARIANT.outlined }
                onClick={ handleAddNew }
                icon={ <AddCircleOutlineIcon /> }
                text={ t("common.add") }
                isDisabled={ actions?.isDisabled }
              />
            </Box>
          </>
        )}
      </StyledTableCell>
    </TableRow>
  );

  return (
    <TableBody>
      {isEmpty(list) || isLoading
        ? emptyBody
        : list && (
          <>
            {sortTable(list, getComparator(order, orderBy))
              .slice(
                (currentPage - SORT_COMPARATOR.one) * maxPerPage,
                isNotPagination
                  ? list?.length
                  : (currentPage - SORT_COMPARATOR.one) * maxPerPage + maxPerPage,
              )
              .map((item) => {
                const collapseContent = getCollapseData ? getCollapseData(item) : null;
                const isOpen = item?.isEdit
                    || showCollapseData
                    || openRow === item.id;

                return (
                  <React.Fragment key={ item.id }>
                    <TRowCustom
                      item={ item }
                      keys={ keys }
                      colspan={ colspan }
                      isNotHeader={ isNotHeader }
                      isDragable={ isDragable }
                      onClick={ () => handleToggleRow(item.id) }
                      classes={ classes }
                      isOpen={ isOpen }
                      isActive={ isActive }
                    />
                    {collapseContent && isOpen && (
                      <TableRow>
                        <TableCell colSpan={ colspan }>
                          {collapseContent}
                        </TableCell>
                      </TableRow>
                    )}
                  </React.Fragment>
                );
              })}
            {actions && (
              <>
                {((isNull(parentId) && actions?.isDisabled)
                    || (actions[`isNewRow_${parentId}`] && isNull(actions.newIdElement))) && (
                    <TableRow>
                    <StyledTableCell colSpan={ colspan }>
                        {getNewCustomRow()}
                      </StyledTableCell>
                  </TableRow>
                )}
                <TableRow>
                  <StyledTableCell colSpan={ colspan }>
                    <ButtonAddTable
                      variant={ VARIANT.outlined }
                      onClick={ handleAddNew }
                      icon={ <AddCircleOutlineIcon /> }
                      text={ t("common.add") }
                      isDisabled={ actions?.isDisabled && includes(`isNewRow_${parentId}`, parentId) }
                    />
                  </StyledTableCell>
                </TableRow>
              </>
            )}
          </>
        )}
    </TableBody>
  );
};

TBodyCustom.propTypes = {
  list: PropTypes.array,
  keys: PropTypes.array,
  order: PropTypes.string,
  orderBy: PropTypes.string,
  currentPage: PropTypes.number,
  maxPerPage: PropTypes.number,
  colspan: PropTypes.number,
  isLoading: PropTypes.bool,
  getCollapseData: PropTypes.func,
  isNotPagination: PropTypes.bool,
  isNotHeader: PropTypes.bool,
  isDragable: PropTypes.bool,
  actions: PropTypes.object,
  showCollapseData: PropTypes.bool,
  isActive: PropTypes.bool,
};

TBodyCustom.defaultProps = {
  showCollapseData: false,
  isActive: false,
};

export default TBodyCustom;
