import styled from "styled-components";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import palette from "theme/palette";
import Switch from "@mui/material/Switch";
import TableRow from "@mui/material/TableRow";
import TextField from "@mui/material/TextField";

export const StyledTitle = styled.p`
  font-size: 12px;
  font-weight: 500;
  text-align: center;
`;

export const StyledTableCell = styled(TableCell)`
  text-align: center;
  background: ${palette.background.lightPurple};
`;

export const StyledTotalTableCell = styled(TableCell)`
  width: 120px;
  text-align: center;
  background: ${palette.background.purple};
  color: ${palette.white};
  p {
    font-weight: 500;
  }
`;

export const StyledTableContainer = styled(TableContainer)`
  box-shadow: none;
  border: none;
  margin-top: 20px;
  table {
    table-layout: fixed;
    border-collapse: separate;
  }
  td {
    border-collapse: none;
    padding: 2px;
    border: 1px solid ${palette.background.purpleBox};
  }
  tr:first-child td:first-child {
    border: none;
  }
  tr:first-child td:nth-child(2) {
    border-top: 1px solid ${palette.background.purpleBox};
    border-left: 1px solid ${palette.background.purpleBox};
    border-right: none;
    border-top-left-radius: 4px;
  }
  tr:nth-child(2) td:first-child {
    border: none;
    border-top-left-radius: 4px;
  }
  tr td:not(:last-child) {
    border-right: none;
  }
  tr:first-child td {
    border-bottom: none;
  }
`;

export const StyledHeader = styled.div`
  p {
    margin-top:10px;
  }
`;

export const StyledTotalTitle = styled.p`
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  color: ${(props) => (props.isInvalid ? palette.table.red : palette.black) }
`;

export const StyledSwitch = styled(Switch)`
  & .MuiSwitch-switchBase.Mui-checked {
    color: ${palette.background.purple};
  }
  & .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track {
    background-color: ${palette.background.purple};
  }
  & .MuiSwitch-switchBase {
    color: ${palette.background.gray};
  }
  & .MuiSwitch-track {
    background-color: ${palette.background.lightGray};
  }
`;

export const StyledTableRow = styled(TableRow)`
  &:nth-child(2) {
    td {
      padding: 0px;
      height: 20px;
    }
  }
`;

export const StyledInputWrapper = styled.div`
  & .MuiInputBase-input {
    padding: 4px;
    height: 40px;
    font-size: 14px;
    box-sizing: border-box;
    text-align: center;
  }

  & .MuiOutlinedInput-root {
    min-height: 40px;
    border-color: white;
  }

  & .MuiFormControl-root {
    margin: 0px;
  }
`;

export const StyledTextField = styled(TextField)`
  .MuiOutlinedInput-root {
    fieldset {
      border: none;
    }
  }

  & input[type="number"]::-webkit-outer-spin-button,
  & input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  & input[type="number"] {
    -moz-appearance: textfield;
  }
`;
