import React, { useEffect, useState, useRef } from "react";
import PropTypes from "prop-types";
import { useFormContext } from "react-hook-form";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepContent from "@mui/material/StepContent";
import Typography from "@mui/material/Typography";
import TitleIcon from "components/TitleIcon";
import { OBJECT_KEYS, ORIENTATION, ICON_TYPES } from "common/constants";
import Actions from "../Actions";
import { iconStyles } from "../../../../functions";
import { StyledContainer, StyledStepLabel } from "./styles";

const FormStepper = (props) => {
  const {
    t,
    steps,
    process,
    audienceSelected,
    setFormValues,
    isStepClickable,
    resetStep,
    activeStepIndex,
  } = props;
  const [activeStep, setActiveStep] = useState(0);
  const [completed, setCompleted] = useState([]);
  const stepRefs = useRef(steps.map(() => React.createRef()));
  const { reset, watch, getValues } = useFormContext();
  const formValuesWatch = watch();

  const isCompleted = (step) => completed.includes(step);

  const updateCompletedSteps = (step) => {
    if (!isCompleted(step)) {
      setCompleted((prevCompleted) => [...prevCompleted, step]);
    }
  };

  // Restore values from previous filled step when user go back, forward or select a step
  const restoreFormValues = (step, updatedFormValues) => {
    const valuesForStep = steps[step].fields.reduce((result, key) => {
      if (updatedFormValues[key] !== undefined && key !== "survey_process_template_id") {
        result[key] = updatedFormValues[key];
      }
      return result;
    }, {});
    // Reset form with restored values
    reset(valuesForStep);
  };

  // Update values and update form values with previous step values
  const updateStorage = (step) => {
    // Get values from current step
    const currentStepValues = steps[activeStep].key === OBJECT_KEYS.audience
      ? { [OBJECT_KEYS.audienceId]: audienceSelected?.id }
      : getValues();

    setFormValues((prevFormValues) => {
      const updatedFormValues = { ...prevFormValues, ...currentStepValues };
      restoreFormValues(step, updatedFormValues);
      return updatedFormValues;
    });

    // Update active step after reset form
    setActiveStep(step);
  };

  const handleNext = () => {
    const nextStep = activeStep + 1;
    updateCompletedSteps(activeStep);
    updateStorage(nextStep);
  };

  const handleBack = () => updateStorage(activeStep - 1);

  const handleGotoStep = (step) => {
    if (isStepClickable || completed.includes(step)) {
      updateStorage(step);
    }
  };

  useEffect(() => {
    if (formValuesWatch) {
      const hasValue = Object.values(formValuesWatch).some(
        (val) => val !== undefined && val !== null && val !== "",
      );
      if (activeStep === steps.length - 1 && !isCompleted(activeStep) && hasValue) {
        updateCompletedSteps(activeStep);
      }
    }
    // eslint-disable-next-line
  }, [formValuesWatch]);

  useEffect(() => {
    if (resetStep) {
      setActiveStep(activeStepIndex);

      if (stepRefs.current[activeStepIndex]?.current) {
        setTimeout(() => {
          const stepTop = stepRefs.current[activeStepIndex].current.getBoundingClientRect().top;
          const offsetPosition = stepTop + window.scrollY - 100;

          window.scrollTo({ top: offsetPosition, behavior: "smooth" });
        }, 0);
      }
    }
  }, [resetStep]);

  return (
    <Grid item xs={ 12 }>
      <Stepper activeStep={ activeStep } orientation={ ORIENTATION.vertical }>
        {steps.map((step, index) => (
          <Step
            key={ step.key }
            ref={ stepRefs.current[index] }
            completed={ isStepClickable || isCompleted(index) }
            disabled={ activeStep !== index && !isStepClickable && !isCompleted(index) }
          >
            <StyledStepLabel onClick={ () => handleGotoStep(index) }>
              <TitleIcon
                icon={ { src: step.icon, ...iconStyles } }
                iconType={ step.key === OBJECT_KEYS.template ? ICON_TYPES.url : ICON_TYPES.icon }
                title={ step.key === OBJECT_KEYS.dates || step.key === OBJECT_KEYS.template
                  ? t(`planning:steps.${step.key}.title.${process.itemData.toLowerCase()}`)
                  : t(`planning:steps.${step.key}.title`) }
              />
            </StyledStepLabel>
            <StepContent>
              <StyledContainer>
                {step.key !== OBJECT_KEYS.template && (
                  <Typography>
                    {step.key === OBJECT_KEYS.audience
                      ? t(`planning:steps.${step.key}.subtitle.${process.itemData.toLowerCase()}`)
                      : t(`planning:steps.${step.key}.subtitle`)}
                  </Typography>
                )}
                {activeStep === index && step.content}
              </StyledContainer>
              {activeStep === index && (
                <Actions
                  t={ t }
                  index={ index }
                  totalSteps={ steps.length }
                  handleNext={ handleNext }
                  handleBack={ handleBack }
                  isLoadingData={ step?.isLoadingData }
                />
              )}
            </StepContent>
          </Step>
        ))}
      </Stepper>
    </Grid>
  );
};

FormStepper.propTypes = {
  t: PropTypes.func.isRequired,
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      icon: PropTypes.any.isRequired,
      content: PropTypes.object.isRequired,
      fields: PropTypes.array,
      required: PropTypes.array,
      isLoadingData: PropTypes.bool,
    }),
  ).isRequired,
  process: PropTypes.object.isRequired,
  audienceSelected: PropTypes.object,
  setFormValues: PropTypes.func.isRequired,
  isStepClickable: PropTypes.bool,
  resetStep: PropTypes.bool,
  activeStepIndex: PropTypes.number,
};

FormStepper.defaultProps = {
  isStepClickable: false,
  resetStep: false,
  activeStepIndex: 0,
  audienceSelected: null,
};

export default FormStepper;
