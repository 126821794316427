import { ImageLoader } from "react-progressive-img-loader";
import moment from "moment";
import { set } from "lodash";
import Avatar from "@mui/material/Avatar";
import Tooltip from "@mui/material/Tooltip";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import VisibilityIcon from "@mui/icons-material/Visibility";
import EditIcon from "@mui/icons-material/Edit";
import GradeIcon from "@mui/icons-material/Grade";
import Check from "@mui/icons-material/Check";
import Clear from "@mui/icons-material/Clear";
import Calendar from "@mui/icons-material/CalendarToday";
import SwapHorizIcon from "@mui/icons-material/SwapHoriz";
import MonetizationOnIcon from "@mui/icons-material/MonetizationOn";
import RedoIcon from "@mui/icons-material/Redo";
import MenuPopup from "components/MenuPopup";
import { StyledTitleWithSubtitle, StyledActionItem } from "components/TablePagination/styles";
import { getCollaboratorChildrens } from "redux/actions/collaboratorActions";
import { ReactComponent as Envelop } from "assets/images/icons/envelop.svg";
import { ReactComponent as Location } from "assets/images/icons/location.svg";
import { ReactComponent as Business } from "assets/images/icons/business_center.svg";
import { ReactComponent as Account } from "assets/images/icons/account_circle.svg";
import { ReactComponent as CriticalPosition } from "assets/images/icons/critic.svg";
import RocketLaunchIcon from "@mui/icons-material/RocketLaunch";
import { ReactComponent as SuccessionIcon } from "assets/images/icons/succession.svg";
import {
  SIZE,
  COLLABORATORS_TABLE_ACTIONS,
  ORG_UNITS,
  INDEX,
  COUNTRIES,
  GENDER_STATUS as GS,
  ROLES,
  OBJECT_KEYS,
  LOCAL_STORAGE_NAMES,
} from "common/constants";
import {
  isEqual, isEmpty, isNotValid, isObject,
} from "common/helpers";
import {
  isManager as isRolManager,
  isAdminNala as isRolAdminNala,
  isColombianAdmin,
  getCurrencyFormat,
  formatDate,
  isMainAdmin,
  isValidScope,
  formatDateExcel,
  // isValidRole,
  getValueByProp,
  getItemFromLocalStorage,
  getAmountFormat,
  formatDateToSubmit,
  getAreas,
  getDivisions,
  // isNewHire,
  isValidToKeyPositionAction,
  getAvatar,
} from "common/utils";
import { STATUS } from "common/constants/gender";
import { getItemSelected } from "views/Account/functions/personalData";
import TerminateEmploymentForm from "../components/TerminateEmploymentForm";
import RelocateEmployee from "../components/RelocateEmployee";
import DeleteEmployee from "../components/DeleteEmployee";
import RehireEmployee from "../components/RehireEmployee";
import { StyledDeleteTitle } from "../components/DeleteEmployee/styles";
import localRoutes from "../../../views_refactor/Profile/functions/routes";
import {
  StyledImageContainer,
  StyledStatus,
  StyledBoxContainer,
  StyledDivider,
  StyledTurnover,
  StyledPositionName,
} from "../styles";

export const HEADER = ["name", "manager", "location", "position", "start_end_date", "actions"];

const getOptionValues = (option, t) => {
  switch (option) {
  case COLLABORATORS_TABLE_ACTIONS.view:
    return {
      title: t("collaborators:table.table-body.view_profile"),
      icon: <VisibilityIcon fontSize={ SIZE.small } />,
    };
  case COLLABORATORS_TABLE_ACTIONS.edit:
    return {
      title: t("collaborators:table.table-body.edit_profile"),
      icon: <EditIcon fontSize={ SIZE.small } />,
    };
  case COLLABORATORS_TABLE_ACTIONS.promote:
    return {
      title: t("account:accountDetails.promote"),
      icon: <GradeIcon fontSize={ SIZE.small } />,
      state: { type: OBJECT_KEYS.promotion },
    };
  case COLLABORATORS_TABLE_ACTIONS.lateralMovement:
    return {
      title: t("account:accountDetails.lateral_movement"),
      icon: <SwapHorizIcon fontSize={ SIZE.small } />,
    };
  case COLLABORATORS_TABLE_ACTIONS.salaryRaise:
    return {
      title: t("account:accountDetails.salary_increase"),
      icon: <MonetizationOnIcon fontSize={ SIZE.small } />,
      state: { type: OBJECT_KEYS.salaryRaise },
    };
  default:
    break;
  }
};

export const getMenuOption = (varCommons, option, route = null) => {
  const detailOption = getOptionValues(option, varCommons.t);
  return ({
    title: detailOption.title,
    icon: detailOption.icon,
    onClick: () => {
      varCommons.history.push({
        pathname: route || "/profile",
        search: `?collaborator=${varCommons.collaboratorId}`,
        state: detailOption?.state,
      });
    },
  });
};

// NOTE: this is the row content for collaborators table
export const getRowsDesktop = (data, t, history, user, isMobile, modal, keyPositionList) => {
  const rows = data?.map((rowData) => [
    {
      content: (
        <StyledBoxContainer>
          <StyledImageContainer>
            {(rowData.profile_img_url_thumb && rowData.profile_img_url) ? (
              <ImageLoader
                src={ rowData.profile_img_url }
                srcPreview={ rowData.profile_img_url_thumb }
                customImageComponent={ Avatar }
              />
            ) : !rowData.profile_img_url_thumb && (
              <Avatar src={ getAvatar(rowData) } />
            )}
            <StyledStatus isActive={ rowData.is_active }>
              {rowData.is_active ? <Check className={ "active" } /> : <Clear className={ "inactive" } />}
            </StyledStatus>
          </StyledImageContainer>
          <StyledTitleWithSubtitle>
            <div className={ "title" }>
              { rowData?.is_a_successor
              && (
                <Tooltip title={ t("successions:plan.title") } placement={ "top" }>
                  <SuccessionIcon />
                </Tooltip>
              )}
              {rowData.full_name}
            </div>
            <div className={ "subtitle" }>
              <Envelop />
              {rowData.email}
            </div>
          </StyledTitleWithSubtitle>
        </StyledBoxContainer>
      ),
    },
    {
      content: rowData.manager_name,
    },
    {
      content: (
        <StyledTitleWithSubtitle>
          <div>{rowData.country_name}</div>
          <div className={ "subtitle" }>
            {rowData.city_name && <Location />}
            {rowData.city_name}
          </div>
        </StyledTitleWithSubtitle>
      ),
    },
    {
      content: (
        <StyledPositionName>
          {rowData.position_name}
          {rowData?.key_position?.critical
          && <Tooltip title={ t("collaborators:key_position.title") }><CriticalPosition /></Tooltip>}
        </StyledPositionName>
      ),
    },
    {
      content: (
        <StyledTitleWithSubtitle>
          <div className={ "subtitle" }>
            <Calendar />
            { formatDate(rowData?.starting_date) }
          </div>
          {!rowData.is_active && rowData?.turnover
          && (
            <StyledTurnover className={ "subtitle" }>
              <Calendar />
              { formatDate(rowData?.turnover?.date) }
            </StyledTurnover>
          )}
        </StyledTitleWithSubtitle>

      ),
    },
    {
      content: <Actions data={ {
        rowData, history, t, user, isMobile, modal, keyPositionList,
      } }
      />,
    },
  ]);
  return rows;
};

export const getRowsMobile = (data, t, history, user, isMobile, modal) => {
  const rows = data?.map((rowData) => [
    {
      content: (
        <StyledBoxContainer isMobile className={ "boxContainerMobile" }>
          <StyledImageContainer className={ "isMobile" }>
            {(rowData.profile_img_url_thumb && rowData.profile_img_url) ? (
              <ImageLoader
                src={ rowData.profile_img_url }
                srcPreview={ rowData.profile_img_url_thumb }
                customImageComponent={ Avatar }
              />
            ) : !rowData.profile_img_url_thumb && (
              <Avatar src={ getAvatar(rowData) } />
            )}
            <StyledStatus isActive={ rowData.is_active }>
              {rowData.is_active ? <Check className={ "active" } /> : <Clear className={ "inactive" } />}
            </StyledStatus>
          </StyledImageContainer>
          <StyledTitleWithSubtitle isMobile>
            <div className={ "title" }>
              { rowData?.is_a_successor
              && (
                <Tooltip title={ t("successions:plan.title") } placement={ "top" }>
                  <SuccessionIcon />
                </Tooltip>
              )}
              {rowData.full_name}
            </div>
          </StyledTitleWithSubtitle>
          <StyledTitleWithSubtitle className={ "cardMobile" }>
            <div className={ "subtitle" }>
              <Business />
              <StyledPositionName>
                {rowData.position_name}
                {rowData?.key_position?.critical
                  && <Tooltip title={ t("collaborators:key_position.title") }><CriticalPosition /></Tooltip>}
              </StyledPositionName>
            </div>
          </StyledTitleWithSubtitle>
          <StyledTitleWithSubtitle className={ "cardMobile" }>
            <div className={ "subtitle" }>
              <Account />
              {rowData.manager_name}
            </div>
          </StyledTitleWithSubtitle>
          <StyledTitleWithSubtitle className={ "cardMobile" }>
            <div className={ "subtitle" }>
              <Envelop />
              {rowData.email}
            </div>
          </StyledTitleWithSubtitle>
          {rowData.city_name && (
            <StyledTitleWithSubtitle className={ "cardMobile" }>
              <div className={ "subtitle" }>
                <Location />
                {rowData.city_name}
              </div>
            </StyledTitleWithSubtitle>
          )}
          <StyledTitleWithSubtitle className={ "cardMobile" }>
            { !rowData.is_active && rowData?.turnover
              ? (
                <StyledTurnover className={ "subtitle" }>
                  <Calendar />
                  { `${formatDate(rowData?.starting_date)} / ${ formatDate(rowData?.turnover?.date)}` }
                </StyledTurnover>
              )
              : (
                <div className={ "subtitle" }>
                  <Calendar />
                  { formatDate(rowData?.starting_date) }
                </div>
              )}
          </StyledTitleWithSubtitle>
          <StyledDivider />
        </StyledBoxContainer>
      ),
    },
    {
      content: <Actions data={ {
        rowData, history, t, user, isMobile, modal,
      } }
      />,
    },
  ]);
  return rows;
};

const getKeyPosition = (rowData) => {
  if (rowData?.key_position?.id) {
    return {
      key_position: {
        id: rowData.key_position.id,
        critical: !rowData.key_position.critical,
      },
    };
  } if (rowData?.job_position?.position_id
    && rowData?.job_position?.lowest_level_organization_unit_id) {
    return {
      key_position: {
        position_id: rowData.job_position.position_id,
        organization_unit_id: rowData.job_position.lowest_level_organization_unit_id,
        critical: true,
      },
    };
  }
  return null;
};

const Actions = (props) => {
  const { data } = props;
  const {
    rowData, history, t, user, isMobile, modal,
  } = data;

  const TERMINATION_OPTION = {
    title: (
      <StyledActionItem>
        {t(
          `collaborators:table.table-body.${
            rowData.is_active ? "finish_contract" : "start_contract"
          }`,
        )}
      </StyledActionItem>
    ),
    icon: (
      <StyledActionItem>
        <HighlightOffIcon fontSize={ SIZE.small } />
      </StyledActionItem>
    ),
    divider: true,
    onClick: async () => {
      const query = {
        q: {
          manager_id_special_in: [rowData.id],
          active_in: [true],
        },
      };
      const childrens = await getCollaboratorChildrens(query);
      let modalContent = {
        title: t("collaborators:forms.finish_contract_title"),
        children: (
          <TerminateEmploymentForm
            id={ rowData.id }
            action={ modal.modalOptions[INDEX.one] }
            modalHandler={ modal.set }
          />
        ),
      };

      if (!isEmpty(childrens)) {
        modalContent = {
          title: "",
          children: (
            <RelocateEmployee
              id={ rowData.id }
              title={ t("collaborators:forms.finish_contract_title") }
              subtitle={ t("collaborators:forms.finish_contract_subtitle_relocate") }
              options={ modal.modalOptions[INDEX.zero] } // Note: index.zero for all collaborators (collaborators list)
              collaborators={ childrens }
              action={ modal.modalOptions[INDEX.one] } // Note: index.one for action to terminate the employment
              modalHandler={ modal.set }
            />
          ),
        };
      }
      modal.handleModalContent(modalContent);
      modal.set(!modal.value);
    },
  };

  const REHIRE_OPTION = {
    title: <StyledActionItem>{t("collaborators:table.table-body.rehire")}</StyledActionItem>,
    icon: (
      <StyledActionItem>
        <RedoIcon fontSize={ SIZE.small } />
      </StyledActionItem>
    ),
    onClick: async () => {
      const modalContent = {
        title: t("collaborators:forms.rehire_title"),
        children: (
          <RehireEmployee
            id={ rowData.id }
            modalHandler={ modal.set }
          />
        ),
      };
      modal.handleModalContent(modalContent);
      modal.set(!modal.value);
    },
  };

  const DELETE_OPTION = {
    title: <StyledActionItem>{t("collaborators:table.table-body.delete")}</StyledActionItem>,
    icon: (
      <StyledActionItem>
        <DeleteOutlineIcon fontSize={ SIZE.small } />
      </StyledActionItem>
    ),
    onClick: async () => {
      const modalContent = {
        title: <StyledDeleteTitle>{t("collaborators:forms.delete_title")}</StyledDeleteTitle>,
        children: (
          <DeleteEmployee
            id={ rowData.id }
            action={ modal.modalOptions[INDEX.two] }
            modalHandler={ modal.set }
          />
        ),
      };
      modal.handleModalContent(modalContent);
      modal.set(!modal.value);
    },
  };

  const keyPosition = getKeyPosition(rowData);

  const CRITIC_POSITION_OPTION = {
    title: <StyledActionItem>{t(`collaborators:table.table-body.${keyPosition?.key_position?.critical ? "" : "un"}mark_critical`)}</StyledActionItem>,
    icon: (
      <StyledActionItem>
        <CriticalPosition fontSize={ SIZE.small } />
      </StyledActionItem>
    ),
    onClick: () => { modal.modalOptions[3](true, keyPosition); },
  };

  const SUCCESSIONS = {
    title: <StyledActionItem>{t("successions:mark_as_successor")}</StyledActionItem>,
    icon: (
      <StyledActionItem>
        <RocketLaunchIcon fontSize={ SIZE.small } />
      </StyledActionItem>
    ),
    onClick: () => { modal.modalOptions[4](history.location.pathname, rowData.id); },
  };

  const varCommons = {
    t,
    collaboratorId: rowData.id,
    history,
  };

  const NO_OPTIONS = [
    {
      title: t("common:common.no_options"),
    },
  ];

  const menuItems = [];
  // TODO: remove ColombianAdmin
  // TODO: Refactor - Find a way to better validate these role-based accesses
  const isAdmin = isMainAdmin(user?.userCookies);
  const isValidColombianAdmin = isColombianAdmin(user?.userCookies);
  // TODO: add isOnboardingAdmin && isCollaboratorNewHire when the backend allows
  // onboarding administrators to view new hire data.
  // const isOnboardingAdmin = isValidRole(user?.userCookies, ROLES.ONBOARDING_ADMIN);
  // const isCollaboratorNewHire = isNewHire(rowData.starting_date);
  const isAdminNala = isRolAdminNala(user?.userCookies);
  const isManager = isRolManager(user?.userCookies);

  const organizationUnitIds = rowData?.organization_units?.flat().map((unit) => unit.id);
  const hasValidScope = isValidScope(
    user,
    {
      countries: [rowData?.country?.id],
      [OBJECT_KEYS.organizationUnits]: organizationUnitIds,
    },
    [ROLES.VACATION_ADMIN, ROLES.ONBOARDING_ADMIN], // ignoredRoles
  );

  // actions validation
  const isViewAllowed = hasValidScope || (isManager && rowData.is_descendant);
  const isEditAllowed = (hasValidScope && isAdmin) || (isValidColombianAdmin && rowData.country_name === COUNTRIES.colombia) || isAdminNala;

  // Add "View" option if view access is allowed
  if (isViewAllowed) {
    menuItems.push(getMenuOption(varCommons, COLLABORATORS_TABLE_ACTIONS.view));
  }

  // Add "Edit" option and additional options if edit access is allowed
  if (isEditAllowed) {
    menuItems.push(
      getMenuOption(varCommons, COLLABORATORS_TABLE_ACTIONS.edit, localRoutes.employeeInfo),
    );

    if (isValidToKeyPositionAction(user)) {
      if (keyPosition !== null) {
        menuItems.push(CRITIC_POSITION_OPTION);
      }
      if (!isMobile) {
        menuItems.push(SUCCESSIONS);
      }
    }

    if (rowData.is_active) {
      menuItems.push(
        getMenuOption(varCommons, COLLABORATORS_TABLE_ACTIONS.promote, localRoutes.addMovement),
      );
      menuItems.push(
        getMenuOption(varCommons, COLLABORATORS_TABLE_ACTIONS.lateralMovement, localRoutes.addMovement),
      );
      menuItems.push(
        getMenuOption(varCommons, COLLABORATORS_TABLE_ACTIONS.salaryRaise, localRoutes.addMovement),
      );
      menuItems.push(TERMINATION_OPTION);
    } else {
      menuItems.push(REHIRE_OPTION);
    }

    menuItems.push(DELETE_OPTION);
  }

  // Return the MenuPopup component with the generated menu items or a default
  // option if no menu items are available
  return (
    <MenuPopup
      items={ menuItems.length > 0 ? menuItems : NO_OPTIONS }
      isMobile={ isMobile }
    />
  );
};

const getAdditionalInfo = (requestedInfos) => {
  const questionWithAnswer = requestedInfos?.filter((info) => info.answer);
  const infoDetail = [];
  if (!isNotValid(questionWithAnswer)) {
    questionWithAnswer.forEach((item) => {
      infoDetail.push(`${item.name}: ${item.answer}. `);
    });
  }
  return infoDetail.join("");
};

export const getDataExcelCollaborators = (
  collaborators,
  dynamicAttributesList,
  companyId,
  currencies,
  t,
  // eslint-disable-next-line default-param-last
  country = null,
  isTalentManager,
  genderList,
) => {
  const data = [];
  if (country) {
    collaborators = collaborators.filter((item) => item.country_name === country);
  }
  collaborators.forEach((collaborator, index) => {
    const { turnover } = collaborator;
    const organizationUnits = collaborator.organization_units;

    let department = "";
    let area = "";
    let subarea = "";

    if (organizationUnits) {
      department = organizationUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.division))?.name;
      area = organizationUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.area))?.name;
      subarea = organizationUnits.find((orgUnit) => isEqual(orgUnit.organization_unit_type_name, ORG_UNITS.subarea))?.name;
    }

    const ctcInUSD = getCurrencyFormat(
      collaborator.projected_ctc,
      collaborator.currency,
      currencies,
    );

    const typeTermination = !collaborator.is_active && turnover
      ? t(
          `collaborators:table.excel.${
            turnover.voluntary
              ? "termination_type_voluntary"
              : "termination_type_involuntary"
          }`,
      )
      : "";

    const terminationDate = turnover?.date;
    const status = t(
      `collaborators:tabs.${collaborator.is_active ? "active" : "inactive"}`,
    );

    const additionalInfo = getAdditionalInfo(collaborator.requested_infos);
    const genderInfo = genderList && !isEmpty(genderList) && getItemSelected(genderList, collaborator?.gender);

    const excelData = {
      [t("collaborators:table.excel.status")]: status,
      [t(
        "collaborators:table.table-head.personal_id",
      )]: collaborator.personal_id,
      [t("collaborators:table.table-head.name")]: collaborator.full_name,
      [t(
        "collaborators:table.table-head.starting_date",
      )]: collaborator.starting_date
        ? formatDateExcel(collaborator.starting_date)
        : "",
      [t("collaborators:table.excel.termination_date")]: !collaborator.is_active && terminationDate
        ? formatDateExcel(terminationDate)
        : "",
      [t("collaborators:table.excel.type_termination")]: typeTermination,
      [t("collaborators:table.excel.reason_termination")]: !collaborator.is_active && turnover
        ? turnover.reason : "",
      [t("collaborators:table.excel.comment_termination")]: !collaborator.is_active && turnover
        ? turnover.comments : "",
      [t("collaborators:table.excel.email_corp")]: collaborator.email,
      [t(
        "collaborators:table.excel.manager_personal_id",
      )]: collaborator.manager_personal_id,
      [t("collaborators:table.excel.manager_name")]: collaborator.manager_name,
      [t(
        "collaborators:table.table-head.position",
      )]: collaborator.position_name,
      [t(
        "collaborators:table.excel.hierarchy_level",
      )]: collaborator.hierarchy_level_name,
    };
    if (!isTalentManager) {
      Object.assign(excelData, {
        [t("collaborators:table.excel.contract_type")]: collaborator
          .type_of_contract?.name,
      });
    }
    Object.assign(excelData, {
      [t("collaborators:table.excel.company_name")]: collaborator.legal_entity_name,
      [t("collaborators:table.excel.country")]: collaborator.country_name,
      [t("collaborators:table.table-head.city")]: collaborator.city_name,
      [t("collaborators:table.excel.department")]: department,
      [t("collaborators:table.excel.area")]: area,
      [t("collaborators:table.excel.subarea")]: subarea,
      // [t("collaborators:table.excel.cost_center")]: collaborator.cost_center,
      [t("collaborators:table.excel.birth_date")]: collaborator.birth_date
        ? formatDateExcel(collaborator.birth_date)
        : "",
      [t("collaborators:table.excel.gender")]: genderInfo ? genderInfo?.label : t(GS[STATUS.other]),
      [t("collaborators:table.excel.other")]: !genderInfo ? collaborator.gender : "",
    });
    if (!isTalentManager) {
      Object.assign(excelData, {
        [t("collaborators:table.excel.currency_type")]: collaborator.currency,
        [t("collaborators:table.excel.gross_salary")]: Number(
          collaborator.gross_salary,
        ),
        [t("collaborators:table.excel.projected_ctc")]: Number(
          collaborator.projected_ctc,
        ),
        [t("collaborators:table.excel.projected_ctc_usd")]: Number(ctcInUSD),
      });
    }
    Object.assign(excelData, {
      [t("common:common:personal_mail")]: collaborator.personal_email,
      [t("common:common.phone_number")]: collaborator.phone_number,
      [t("common:common:nationality")]: collaborator.nationality,
      [t("common:common:marital_status.main")]: collaborator.marital_status,
      [t("common:common:address")]: collaborator.address,
      [t("common:common:linkedin_profile")]: collaborator.linkedin_profile,
      [t("common:common:additional_information")]: additionalInfo,
    });
    data.push(excelData);

    if (!isTalentManager && data[index] && !isEmpty(dynamicAttributesList)) {
      dynamicAttributesList.forEach((item) => {
        data[index][item.name] = collaborator?.custom_attributes?.find((e) => isEqual(e.dynamic_attribute_id, item.id))?.value || "";
      });
    }

    if (!isTalentManager && data[index] && collaborator.benefits_list) {
      collaborator.benefits_list.forEach((benefit) => {
        data[index][benefit.name] = benefit.value;
        data[index][`${t("account:accountDetails.benefits.paymentFrequency")} (${benefit.name})`] = benefit.frequency;
      });
    }
  });

  return data;
};

export const getDataExcelFeedbacks = (feedbacks, t) => {
  const data = [];
  feedbacks.forEach((item) => {
    data.push({
      [t("feedback:excelHeader:employeeFromFullName")]: item.employee_from_full_name,
      [t("feedback:excelHeader:employeeFromEmail")]: item.employee_from_email,
      [t("feedback:excelHeader:employeeToFullName")]: item.employee_to_full_name,
      [t("feedback:excelHeader:employeeToEmail")]: item.employee_to_email,
      [t("feedback:excelHeader:dateSending")]: formatDate(item.sent_at),
      [t("feedback:excelHeader:type")]: t(`feedback:${item.feedbackable_type}`),
      [t("feedback:excelHeader:acknowledgement_type")]: item.acknowledgement_type,
    });
  });

  return data;
};

export const getDataExcelMovements = (movements, t) => {
  const data = [];
  movements.forEach((item) => {
    data.push({
      [t("collaborators:table.excel.type_movement")]: item?.type,
      [t("collaborators:table.excel.date_movement")]: formatDate(item.date),
      [t("collaborators:table.table-head.personal_id")]: item.employee?.personal_id,
      [t("collaborators:table.table-head.name")]: item.employee?.full_name,
      [t("collaborators:table.table-head.email")]: item.employee?.email,
      [`${t("collaborators:table.table-head.manager")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_manager?.full_name,
      [`${t("collaborators:table.table-head.manager")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_manager?.full_name,
      [`${t("collaborators:table.table-head.position")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_job_position?.position_name,
      [`${t("collaborators:table.table-head.position")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_job_position?.position_name,
      [`${t("collaborators:table.excel.department")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_job_position?.division_name,
      [`${t("collaborators:table.excel.department")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_job_position?.division_name,
      [`${t("collaborators:table.excel.area")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_job_position?.area_name,
      [`${t("collaborators:table.excel.area")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_job_position?.area_name,
      [`${t("collaborators:table.excel.hierarchy_level")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_job_position?.hierarchy_level_name,
      [`${t("collaborators:table.excel.hierarchy_level")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_job_position?.hierarchy_level_name,
      [`${t("collaborators:table.excel.country")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_job_position?.country_name,
      [`${t("collaborators:table.excel.country")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_job_position?.country_name,
      [`${t("collaborators:table.excel.city")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_job_position?.city_name,
      [`${t("collaborators:table.excel.city")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_job_position?.city_name,
      [`${t("collaborators:table.excel.contract_type")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_job_position?.type_of_contract_name,
      [`${t("collaborators:table.excel.contract_type")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_job_position?.type_of_contract_name,
      [`${t("collaborators:table.excel.gross_salary")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_salary?.gross_salary,
      [`${t("collaborators:table.excel.gross_salary")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_salary?.gross_salary,
      [`${t("collaborators:table.excel.currency_type")} - ${t("collaborators:table.excel.pre")}`]: item.pre_movement_salary?.currency,
      [`${t("collaborators:table.excel.currency_type")} - ${t("collaborators:table.excel.post")}`]: item.post_movement_salary?.currency,
    });
  });

  return data;
};

export const resetDefaultRehireValues = (employee, collaborator) => ({
  [`${employee}.date`]: moment(),
  [`${employee}.job_position_attributes.manager_id`]:
    collaborator?.manager ?? "",
  [`${employee}.job_position_attributes.position_id`]:
    collaborator?.job_position?.position ?? "",
  [`${employee}.job_position_attributes.division_name`]:
    collaborator?.job_position?.division ?? "",
  [`${employee}.job_position_attributes.area_name`]:
      collaborator?.job_position?.area ?? "",
  [`${employee}.job_position_attributes.hierarchy_level_id`]:
      collaborator?.job_position?.hierarchy_level ?? "",
  [`${employee}.job_position_attributes.country_id`]:
      collaborator?.job_position?.country ?? "",
  [`${employee}.job_position_attributes.city_id`]:
      collaborator?.job_position?.city ?? "",
  [`${employee}.salary_attributes.gross_salary`]:
    collaborator?.salary?.gross_salary ?? "",
  [`${employee}.salary_attributes.currency`]:
    collaborator?.salary?.currency ?? "",
  [`${employee}.job_position_attributes.type_of_contract_id`]:
    collaborator?.job_position?.type_of_contract?.id ?? "",
});

export const getNewValue = (list, key, value) => {
  const item = list.find((item) => isEqual(item.key, key));
  return item ? item.lastValue : value;
};

export const setUpdatedEmployeeData = (employee, collaborator, rehireState) => ({
  [`${employee}.date`]: rehireState.date || moment(),
  [`${employee}.job_position_attributes.manager_id`]:
    getNewValue(rehireState.firstStep, OBJECT_KEYS.managerId, collaborator?.manager),
  [`${employee}.job_position_attributes.position_id`]:
    getNewValue(rehireState.firstStep, OBJECT_KEYS.position, collaborator?.job_position?.position),
  [`${employee}.job_position_attributes.division_name`]:
    getNewValue(rehireState.firstStep, OBJECT_KEYS.division, collaborator?.job_position?.division),
  [`${employee}.job_position_attributes.area_name`]:
    getNewValue(rehireState.firstStep, OBJECT_KEYS.area, collaborator?.job_position?.area),
  [`${employee}.job_position_attributes.hierarchy_level_id`]:
    getNewValue(rehireState.firstStep, OBJECT_KEYS.hierarchy_level, collaborator?.job_position?.hierarchy_level),
  [`${employee}.job_position_attributes.country_id`]:
    getNewValue(rehireState.firstStep, OBJECT_KEYS.country, collaborator?.job_position?.country),
  [`${employee}.job_position_attributes.city_id`]:
    getNewValue(rehireState.firstStep, OBJECT_KEYS.city, collaborator?.job_position?.city),
  [`${employee}.salary_attributes.gross_salary`]:
    rehireState.secondStep.find((item) => isEqual(item.key, OBJECT_KEYS.salary))
      ? getAmountFormat(rehireState.secondStep.find((item) => isEqual(item.key, OBJECT_KEYS.salary))?.lastValue)
      : collaborator?.salary?.gross_salary,
  [`${employee}.salary_attributes.currency`]:
    getNewValue(rehireState.secondStep, OBJECT_KEYS.currency, collaborator?.salary?.currency),
  [`${employee}.job_position_attributes.type_of_contract_id`]:
    getNewValue(rehireState.secondStep, OBJECT_KEYS.typeOfContract, collaborator?.job_position?.type_of_contract)?.id,
});

export const fields = (collaborator, newValues, step) => [
  {
    key: OBJECT_KEYS.managerId,
    firstValue: getValueByProp(collaborator?.manager, OBJECT_KEYS.fullname),
    lastValue: newValues?.job_position_attributes?.manager_id,
    prop: OBJECT_KEYS.fullname,
    label: "common:common.manager",
    isValid: isEqual(step, INDEX.one),
  },
  {
    key: OBJECT_KEYS.position,
    firstValue: getValueByProp(collaborator?.job_position?.position, OBJECT_KEYS.name),
    lastValue: newValues?.job_position_attributes?.position_id,
    prop: OBJECT_KEYS.name,
    label: "common:common.newPosition",
    isValid: isEqual(step, INDEX.one),
  },
  {
    key: OBJECT_KEYS.division,
    firstValue: getValueByProp(collaborator?.job_position?.division, OBJECT_KEYS.name),
    lastValue: newValues?.job_position_attributes?.division_name,
    prop: OBJECT_KEYS.name,
    label: "common:common.division",
    isValid: isEqual(step, INDEX.one),
  },
  {
    key: OBJECT_KEYS.area,
    firstValue: getValueByProp(collaborator?.job_position?.area, OBJECT_KEYS.name),
    lastValue: newValues?.job_position_attributes?.area_name,
    prop: OBJECT_KEYS.name,
    label: "common:common.area",
    isValid: isEqual(step, INDEX.one),
  },
  {
    key: OBJECT_KEYS.country,
    firstValue: getValueByProp(collaborator?.job_position?.country, OBJECT_KEYS.name),
    lastValue: newValues?.job_position_attributes?.country_id,
    prop: OBJECT_KEYS.name,
    label: "common:common.country",
    isValid: isEqual(step, INDEX.one),
  },
  {
    key: OBJECT_KEYS.city,
    firstValue: getValueByProp(collaborator?.job_position?.city, OBJECT_KEYS.name),
    lastValue: newValues?.job_position_attributes?.city_id,
    prop: OBJECT_KEYS.name,
    label: "common:common.city",
    isValid: isEqual(step, INDEX.one),
  },
  {
    key: OBJECT_KEYS.hierarchy_level,
    firstValue: getValueByProp(collaborator?.job_position?.hierarchy_level, OBJECT_KEYS.name),
    lastValue: newValues?.job_position_attributes?.hierarchy_level_id,
    prop: OBJECT_KEYS.name,
    label: "common:common.hierarchyLevel",
    isValid: isEqual(step, INDEX.one),
  },
  {
    key: OBJECT_KEYS.salary,
    firstValue: parseFloat(collaborator?.salary?.gross_salary),
    lastValue: parseFloat(newValues?.salary_attributes?.gross_salary.replaceAll(",", "")),
    prop: null,
    label: "common:common.newSalary",
    isValid: isEqual(step, INDEX.two),
  },
  {
    key: OBJECT_KEYS.currency,
    firstValue: getValueByProp(collaborator?.salary?.currency, OBJECT_KEYS.name),
    lastValue: newValues?.salary_attributes?.currency,
    prop: OBJECT_KEYS.name,
    label: "common:common.currency.main",
    isValid: isEqual(step, INDEX.two),
  },
  {
    key: OBJECT_KEYS.typeOfContract,
    firstValue: getValueByProp(collaborator?.job_position?.type_of_contract, OBJECT_KEYS.name),
    lastValue: getItemFromLocalStorage(LOCAL_STORAGE_NAMES.typeOfContract).find(
      (type) => isEqual(type.id, newValues?.job_position_attributes.type_of_contract_id),
    ),
    prop: OBJECT_KEYS.name,
    label: "common:common.typeOfContract",
    isValid: isEqual(step, INDEX.two),
  },
];

export const getUpdatedData = (collaborator, newValues, step) => {
  const updates = [];
  fields(collaborator, newValues, step).filter((item) => item.isValid).forEach((item) => {
    if (!isEqual(item.firstValue || "", getValueByProp(item.lastValue, item.prop) || "")) {
      updates.push(item);
    }
  });
  return updates;
};

const getUnit = (list, unit) => (isObject(unit)
  ? unit.id : list.find((orgUnit) => isEqual(orgUnit[OBJECT_KEYS.name], unit))?.id);

export const formatRehireData = (data) => {
  const rehireData = {};
  set(rehireData, "date", formatDateToSubmit(data.date));
  set(rehireData, "job_position_attributes.manager_id", getValueByProp(data?.job_position_attributes?.manager_id, OBJECT_KEYS.id));
  set(rehireData, "job_position_attributes.position_id", getValueByProp(data?.job_position_attributes?.position_id, OBJECT_KEYS.id));
  set(rehireData, "job_position_attributes.country_id", getValueByProp(data?.job_position_attributes?.country_id, OBJECT_KEYS.id));
  set(rehireData, "job_position_attributes.city_id", getValueByProp(data?.job_position_attributes?.city_id, OBJECT_KEYS.id));
  set(rehireData, "job_position_attributes.hierarchy_level_id", getValueByProp(data?.job_position_attributes?.hierarchy_level_id, OBJECT_KEYS.id));
  set(rehireData, "job_position_attributes.type_of_contract_id", getValueByProp(data?.job_position_attributes?.type_of_contract_id, OBJECT_KEYS.id));
  set(rehireData, "salary_attributes.gross_salary", data?.salary_attributes?.gross_salary.replaceAll(",", ""));
  set(rehireData, "salary_attributes.currency", getValueByProp(data?.salary_attributes?.currency, OBJECT_KEYS.nameWithCode));

  const organizationUnits = getItemFromLocalStorage(LOCAL_STORAGE_NAMES.orgUnits);
  const listUnits = [];
  const division = getUnit(getDivisions(organizationUnits), data?.job_position_attributes?.division_name);
  if (division) {
    listUnits.push(division);
    const area = getUnit(getAreas(organizationUnits, division), data?.job_position_attributes?.area_name);
    if (area) {
      listUnits.push(area);
    }
  }
  set(rehireData, "job_position_attributes.organization_unit_ids", listUnits);
  return rehireData;
};
