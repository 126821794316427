import { useTranslation } from "react-i18next";
import { Pie } from "react-chartjs-2";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Link from "@mui/material/Link";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import SkeletonLoader from "components/SkeletonLoader";
import MultidataCard from "components/MultidataCard";
import NoDataResult from "components/NoDataResult";
import { useGoalsParticipation } from "hooks/useGoalsParticipation";
import { CARD_RESULT } from "theme/palette";
import { getPercent } from "common/utils";
import {
  VARIANT,
} from "common/constants";
import downloadGoalsParticipationExcel from "./participationGoalsDownload";
import {
  StyledGridContainer,
  StyledGrid,
} from "./styles";

const ParticipationGoalsCard = ({ filterQuery }) => {
  const { t } = useTranslation();
  const { goalsParticipation, isLoading } = useGoalsParticipation(t, filterQuery);

  const calculatePercentage = (group) => group.participation / group.total;

  const startParticipationDownload = () => downloadGoalsParticipationExcel(t, filterQuery);

  if (isLoading) {
    return (<SkeletonLoader />);
  }

  const pieOptions = {
    responsive: true,
    legend: {
      display: false,
    },
    tooltips: {
      enabled: false,
    },
    plugins: {
      padding: 0,
      datalabels: {
        display: false,
      },
    },
  };

  const totalManagers = goalsParticipation.manager.total;
  const totalCollaborators = goalsParticipation.collaborators.total;
  // eslint-disable-next-line max-len
  const totalParticipation = goalsParticipation.manager.participation + goalsParticipation.collaborators.participation;

  const cardBody = () => {
    const percentage = (groupName) => calculatePercentage(goalsParticipation[groupName]);

    const pieData = (groupName) => {
      // returns a number from 0 to 1
      const calculatedPercentage = percentage(groupName);

      return {
        datasets: [
          {
            data: [1 - calculatedPercentage, calculatedPercentage],
            backgroundColor: ["transparent", CARD_RESULT.greenCard],
            borderColor: ["transparent", CARD_RESULT.greenCard],
            borderWith: 0,
            padding: 0,
          },
        ],
      };
    };

    return (
      <Grid item xs={ 12 }>
        <CardContent xs={ 12 }>
          <Grid container justifyContent={ "center" }>
            { totalManagers > 0 && (
              <StyledGrid
                borderColor={ CARD_RESULT.greenCard }
                container
                spacing={ 2 }
                item
                xs={ 6 }
              >
                <Grid item xs={ 12 } justifyItems={ "center" }>
                  <Typography variant={ VARIANT.h5 } >{ t("common:common.managers") }</Typography>
                </Grid>
                <Grid item width={ "200px" } xs={ 12 } justifyItems={ "center" }>
                  <Pie data={ pieData("manager") } options={ pieOptions } />
                </Grid>
                <Grid item xs={ 12 } justifyItems={ "center" }>
                  <Typography variant={ VARIANT.h5 } >
                    { getPercent(percentage("manager"), true) }
                  </Typography>
                </Grid>
              </StyledGrid>
            ) }
            { totalCollaborators > 0 && (
              <StyledGrid
                container
                spacing={ 2 }
                item
                xs={ 6 }
              >
                <Grid item xs={ 12 } justifyItems={ "center" }>
                  <Typography variant={ VARIANT.h5 } >{ t("common:common.collaborators") }</Typography>
                </Grid>
                <Grid item width={ "200px" } xs={ 12 } justifyItems={ "center" }>
                  <Pie data={ pieData("collaborators") } options={ pieOptions } />
                </Grid>
                <Grid item xs={ 12 } justifyItems={ "center" }>
                  <Typography variant={ VARIANT.h5 } >
                    { getPercent(percentage("collaborators"), true) }
                  </Typography>
                </Grid>
              </StyledGrid>
            ) }
          </Grid>
        </CardContent>
        <Grid xs={ 12 } textAlign={ "right" }>
          <Link component="button" onClick={ startParticipationDownload }>{ t("goals:participation.download") }</Link>
        </Grid>
      </Grid>
    );
  };

  const cardContent = () => (
    <Grid container xs={ 12 }>
      <Grid item xs={ 12 }>
        <Typography variant={ VARIANT.h4 }>{ t("goals:participation.title") }</Typography>
      </Grid>
      { totalParticipation <= 0 ? <NoDataResult title={ " " } message={ t("goals:participation.no_data") } color={ "green" } /> : cardBody() }
    </Grid>
  );

  return (
    <StyledGridContainer
      container
      marginBottom={ 2 }
      xs={ 12 }
      md={ 7 }
      lg={ 5 }
      color={ CARD_RESULT.greenCard }
    >
      <MultidataCard
        color={ CARD_RESULT.greenCard }
        customRender={ cardContent() }
      />
    </StyledGridContainer>
  );
};

ParticipationGoalsCard.propTypes = {
  filterQuery: PropTypes.object,
};

ParticipationGoalsCard.defaultProps = {
  filterQuery: {},
};

export default ParticipationGoalsCard;
