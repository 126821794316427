import { useCallback, useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import debounce from "lodash/debounce";
import charts from "theme/charts";
import useMediaQuery from "@mui/material/useMediaQuery";
import useComplexState from "hooks/utils/useComplexState";
import { isAdmin, getPropertyByLocation, getCurrentLanguage } from "common/utils";
import { LOCATION_PROPS } from "common/constants";
import {
  getTemplate,
  createTemplate,
  updateTemplate,
  createEvaluationSection,
  updateEvaluationSection,
  deleteEvaluationSection,
  getEvaluationSectionTemplates,
  translateSectionAndQuestions,
} from "redux/actions/surveyProcessesActions";
import { getListByQuery as getEvaluationScales } from "redux/actions/evaluationScaleActions";
import { getProcessTypes } from "../../Planning/functions";
import {
  getTemplateValues,
  formatDataToSend,
  formatDataSectionToSend,
  getDefaultStakeholders,
  DEFAULT_STAKEHOLDERS_FIELDS,
  DEFAULT_STAKEHOLDERS_SWITCHS,
  transformData,
  transformToDefaultData,
  filterItemsWithEmptyNames,
} from "../functions";

export const useTemplateHook = (externalHooks) => {
  const {
    t, user, theme, dispatch,
  } = externalHooks;
  const { i18n } = useTranslation();

  const location = useLocation();
  const history = useHistory();

  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const processType = locationSearch?.split("=")[0]?.replace("?", "");
  const templateId = locationSearch?.split("=")[1];
  const processData = getProcessTypes(t).find((process) => processType === process.value);
  const appLanguage = getCurrentLanguage();
  const hasInternalClient = user?.company?.internal_client;

  const {
    list: evaluationScales,
    isLoadingList: isLoadingEvaluationScales,
  } = useSelector(
    ({ evaluationScaleReducer }) => evaluationScaleReducer,
  );

  const {
    data: templateData, isLoading: isLoadingTemplate, refetch, isFetching: isFetchingTemplate,
  } = useQuery(
    ["templateData", templateId],
    () => dispatch(getTemplate(templateId)),
    {
      staleTime: Infinity,
      enabled: !!templateId, // property is used to determine if the query should be executed or not
      retry: false,
    },
  );

  const {
    data: sectionsTemplateData,
    isLoading: isLoadingSectionsTemplate,
    refetch: updateSectionsTemplate,
  } = useQuery(
    ["sectionsTemplateData", templateId],
    () => dispatch(getEvaluationSectionTemplates(templateId)),
    {
      staleTime: Infinity,
      enabled: false,
      retry: false,
    },
  );

  const {
    register,
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
  } = useForm({
    defaultValues: {
      auto: 0,
      manager: 0,
      peers: 0,
      team: 0,
      checkbox_auto: false,
      checkbox_manager: false,
      checkbox_peers: false,
      checkbox_team: false,
      ...(hasInternalClient && {
        internal_client: 0,
        checkbox_internal_client: false,
      }),
      [`name_${appLanguage}`]: "",
    },
  });

  const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));
  const isValidUser = isAdmin(user?.userCookies);

  const [templateStates, setTemplateStates] = useComplexState({
    isOpen: false,
    language: appLanguage,
    formLanguage: appLanguage,
    isValid: true,
    stakeholders: getDefaultStakeholders(t, hasInternalClient),
    active: {
      id: null,
      type: "",
    },
  });

  const [formValues, setFormValues] = useComplexState({
    name_es: "",
    name_en: "",
    name_pt: "",
    sections: null,
    movedItems: [],
  });

  const [watchedFields, setWatchedFields] = useState({
    names: [],
    sections: [],
    stakeholders: [],
    weighings: [],
    scales: [],
    questions: [],
    generalStakeholders: hasInternalClient
      ? DEFAULT_STAKEHOLDERS_FIELDS
      : DEFAULT_STAKEHOLDERS_FIELDS.filter((field) => field !== "internal_client"),

    generalStakeholdersSwitchs: hasInternalClient
      ? DEFAULT_STAKEHOLDERS_SWITCHS
      : DEFAULT_STAKEHOLDERS_SWITCHS.filter((switchField) => switchField !== "checkbox_internal_client"),
  });

  const watchName = watch(`name_${templateStates.formLanguage}`);
  const watchedNames = watch(watchedFields.names);
  const watchedSections = watch(watchedFields.sections);
  const watchedWeighings = watch(watchedFields.weighings);
  const watchedScales = watch(watchedFields.scales);
  const watchedQuestions = watch(watchedFields.questions);
  const watchedGeneralStakeholders = watch(watchedFields.generalStakeholders);
  const watchedGeneralStakeholdersSwitchs = watch(watchedFields.generalStakeholdersSwitchs);

  const onLanguageChange = (language) => {
    setTemplateStates({
      formLanguage: language,
    });
  };

  const handleCancelClick = () => {
    history.push("/planning", { from: "/planning/templates" });
  };

  const handleSwitchChange = (name) => (event) => {
    const newValue = event.target.checked;
    setValue(name, newValue);
  };

  const handleSections = async (
    sectionsToDelete,
    sectionsToSaveOrUpdate,
    evaluations,
    emptyData,
    id,
  ) => {
    if (sectionsToDelete) {
      await Promise.all(sectionsToDelete.map(async (section) => {
        await dispatch(deleteEvaluationSection(section.id));
      }));
    }

    if (sectionsToSaveOrUpdate) {
      await Promise.all(sectionsToSaveOrUpdate.map(async (section) => {
        const movedItem = formValues.movedItems.find(
          (item) => item.id === section?.id,
        )?.newPosition;

        const formattedSectionData = formatDataSectionToSend(
          section,
          movedItem,
          evaluations,
          formValues,
          !templateData.nala_default === true,
        );
        let dataToDispatch = formattedSectionData;

        const hasEmptyTranslation = emptyData.find((item) => item.id === section.id);

        if (hasEmptyTranslation) {
          const transformedData = transformData(formattedSectionData.evaluation_section_template);
          const newData = await translateSectionAndQuestions(transformedData);
          dataToDispatch = transformToDefaultData(formattedSectionData, newData);
        }

        const action = templateData.nala_default === true || section?.isNew
          ? createEvaluationSection(dataToDispatch, id)
          : updateEvaluationSection(dataToDispatch, section.id);

        await dispatch(action);
      }));
    }
  };

  const onSubmit = async () => {
    try {
      const formattedData = formatDataToSend(formValues, templateData, hasInternalClient);
      const { sections } = formValues;

      const sectionsToDelete = sections?.filter((section) => section.isDeleted);
      const sectionsToSaveOrUpdate = sections?.filter((section) => !section.isDeleted);
      const emptyData = filterItemsWithEmptyNames(sectionsToSaveOrUpdate);

      const response = await dispatch(
        templateData.nala_default === true
          ? createTemplate(formattedData)
          : updateTemplate(formattedData, templateId),
      );

      const { id, evaluation_templates: evaluations } = response;

      await handleSections(
        sectionsToDelete,
        sectionsToSaveOrUpdate,
        evaluations,
        emptyData,
        id,
      );

      return { success: true, response };
    } catch (error) {
      return { success: false, error };
    }
  };

  /**
   * Update the stakeholders values and totals when the formValues are changed
   * @param {Object} data - The data to update
   * @returns {void}
   */
  const updateStakeholders = (data) => {
    const { sections } = data;
    const stakeholderKeys = Object.keys(templateStates.stakeholders);
    let newStakeholders = { ...templateStates.stakeholders };
    let totalGeneralStakeholders = 0;
    let isValidTotals = true;
    let hasEmptySections = false;

    stakeholderKeys.forEach((key) => {
      const { type } = templateStates.stakeholders[key];
      const stakeholderValue = data[key];

      if (newStakeholders[key].checked !== data[`checkbox_${key}`]) {
        newStakeholders = {
          ...newStakeholders,
          [key]: {
            ...newStakeholders[key],
            checked: data[`checkbox_${key}`],
          },
        };
      }

      if (stakeholderValue !== undefined && stakeholderValue !== newStakeholders[key].value) {
        newStakeholders = {
          ...newStakeholders,
          [key]: {
            ...newStakeholders[key],
            value: stakeholderValue,
          },
        };
      }

      totalGeneralStakeholders += (stakeholderValue || 0);

      // Calculate the total of each stakeholder in the sections
      const showedSections = sections?.filter((section) => !section.isDeleted);
      const totalStakeholder = showedSections?.reduce((total, section) => {
        const actualEvaluation = section?.evaluations?.find(
          (evaluation) => evaluation.type === type,
        );

        return total + (data[`weighing_${actualEvaluation?.type}_${section.id}`] || 0);
      }, 0);

      if (templateStates.stakeholders[key]?.checked && totalStakeholder !== 100) {
        isValidTotals = false;
      }

      newStakeholders = {
        ...newStakeholders,
        [key]: {
          ...newStakeholders[key],
          total: totalStakeholder,
        },
      };

      // Validate if the section is empty
      const actualSection = sections?.find((section) => section.id === templateStates.active.id);
      if (actualSection?.evaluations?.length === 0 || actualSection?.questions?.length === 0) {
        hasEmptySections = true;
      }
    });

    setTemplateStates({
      stakeholders: newStakeholders,
      isValid: isValidTotals && totalGeneralStakeholders === 100 && !hasEmptySections,
    });
  };

  // Update the evaluations when the stakeholders checkboxs are changed
  const updateEvaluations = (type, checked) => {
    const actualStakeholders = { ...templateStates.stakeholders };
    const actualSections = formValues.sections;
    const section = actualSections?.find((item) => item.id === templateStates.active.id);
    const index = actualSections?.indexOf(section);
    const actualEvaluations = section?.evaluations;
    let updatedEvaluations = [];
    Object.keys(actualStakeholders).forEach((key) => {
      if (actualStakeholders[key].checked && section !== undefined) {
        const actualIsChecked = formValues[`checkbox_${type}_${section.id}`];
        const isChecked = checked;

        if (isChecked !== undefined && isChecked !== actualIsChecked) {
          if (isChecked) {
            const actualEvaluation = actualEvaluations.find(
              (evaluation) => evaluation.type === type,
            );

            if (actualEvaluation?.isDeleted) {
              delete actualEvaluation.isDeleted;
            }

            updatedEvaluations = actualEvaluation === undefined
              ? [
                ...actualEvaluations,
                {
                  type,
                  weighing: 0,
                  isNew: true,
                },
              ] : [
                ...actualEvaluations.slice(0, actualEvaluations.indexOf(actualEvaluation)),
                {
                  ...actualEvaluation,
                  weighing: 0,
                },
                ...actualEvaluations.slice(actualEvaluations.indexOf(actualEvaluation) + 1),
              ];
          } else {
            const actualEvaluation = actualEvaluations.find(
              (evaluation) => evaluation.type === type,
            );

            updatedEvaluations = actualEvaluation.isNew
              ? [
                ...actualEvaluations.slice(0, actualEvaluations.indexOf(actualEvaluation)),
                ...actualEvaluations.slice(actualEvaluations.indexOf(actualEvaluation) + 1),
              ] : [
                ...actualEvaluations.slice(0, actualEvaluations.indexOf(actualEvaluation)),
                {
                  ...actualEvaluation,
                  weighing: 0,
                  isDeleted: true,
                },
                ...actualEvaluations.slice(actualEvaluations.indexOf(actualEvaluation) + 1),
              ];
          }

          setFormValues({
            sections: [
              ...actualSections.slice(0, index),
              {
                ...section,
                evaluations: updatedEvaluations,
              },
              ...actualSections.slice(index + 1),
            ],
            [`checkbox_${type}_${section.id}`]: isChecked,
            [`weighing_${type}_${section.id}`]: 0,
          });
        }
      }
    });
  };

  useEffect(() => {
    if (!isLoadingEvaluationScales) {
      const query = {
        q: {
          evaluation_scale_indices_value_eq: 1.2,
          hidden_not_eq_or_null: true,
        },
      };
      dispatch(getEvaluationScales(query));
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (templateId) {
      refetch();
      updateSectionsTemplate();
    }
    // eslint-disable-next-line
  }, [templateId, refetch, updateSectionsTemplate]);

  // Set the formValues with the templateData
  useEffect(() => {
    if (templateData && sectionsTemplateData) {
      let newData = getTemplateValues(templateData, sectionsTemplateData, hasInternalClient);
      const questionsKeys = [];
      const scalesKeys = [];
      const stakeholdersKeys = [];
      const sectionsKeys = [];
      const namesKeys = [];
      const weighingsKeys = [];
      const { sections } = newData;

      namesKeys.push(`name_${templateStates.formLanguage}`);

      Object.keys(sections).forEach((key) => {
        const section = sections[key];
        const { evaluations } = section;

        // Initialize the name of the sections in the formValues
        newData = {
          ...newData,
          [`section_${templateStates.formLanguage}_${section.id}`]: section[`name_${templateStates.formLanguage}`],
        };
        sectionsKeys.push(`section_${templateStates.formLanguage}_${section.id}`);

        // Initialize the stakeholders values of the sections in the formValues
        const defaultStakeholders = { ...templateStates.stakeholders };
        Object.keys(defaultStakeholders).forEach((keyStakeholder) => {
          const { type } = defaultStakeholders[keyStakeholder];
          const actualEvaluation = evaluations.find(
            (evaluation) => evaluation.type === type,
          );
          const weighing = actualEvaluation?.weighing;
          newData = {
            ...newData,
            [`weighing_${type}_${section.id}`]: weighing,
            [`checkbox_${type}_${section.id}`]: weighing !== undefined,
          };
          weighingsKeys.push(`weighing_${type}_${section.id}`);
          stakeholdersKeys.push(`checkbox_${type}_${section.id}`);
        });

        // Initialize the questions values of the sections in the formValues
        const actualQuestions = section.questions;
        actualQuestions?.forEach((question) => {
          newData = {
            ...newData,
            [`question_${templateStates.formLanguage}_${question.id}`]: question[`name_${templateStates.formLanguage}`],
            [`scale_${question.id}`]: question.evaluation_scale_id,
          };
          questionsKeys.push(`question_${templateStates.formLanguage}_${question.id}`);
          scalesKeys.push(`scale_${question.id}`);
        });
      });

      setWatchedFields({
        ...watchedFields,
        names: namesKeys,
        sections: sectionsKeys,
        stakeholders: stakeholdersKeys,
        weighings: weighingsKeys,
        scales: scalesKeys,
        questions: questionsKeys,
      });

      setFormValues(newData);
      reset(newData);
    }
    // eslint-disable-next-line
  }, [templateData, sectionsTemplateData]);

  // Restore the fields values when the language is changed
  useEffect(() => {
    const newWatchedFields = { ...watchedFields };
    setValue(`name_${templateStates.formLanguage}`, formValues[`name_${templateStates.formLanguage}`]);
    newWatchedFields.names = [`name_${templateStates.formLanguage}`];

    // Restore the sections values when the language is changed and watch the new fields
    const actualSections = formValues.sections;
    actualSections?.forEach((section) => {
      setValue(`section_${templateStates.formLanguage}_${section.id}`, section[`name_${templateStates.formLanguage}`]);
      newWatchedFields.sections = [
        ...newWatchedFields.sections,
        `section_${templateStates.formLanguage}_${section.id}`,
      ];

      // Restore the questions values when the language is changed and watch the new fields
      const actualQuestions = section.questions;
      actualQuestions?.forEach((question) => {
        setValue(`question_${templateStates.formLanguage}_${question.id}`, question[`name_${templateStates.formLanguage}`]);
        setValue(`scale_${question.id}`, question.evaluation_scale_id);

        newWatchedFields.questions = [
          ...newWatchedFields.questions,
          `question_${templateStates.formLanguage}_${question.id}`,
        ];
        newWatchedFields.scales = [
          ...newWatchedFields.scales,
          `scale_${question.id}`,
        ];
      });
    });

    setWatchedFields(newWatchedFields);
    // eslint-disable-next-line
  }, [templateStates.formLanguage]);

  // Restore the stakeholder weighing value when the active section is changed
  useEffect(() => {
    if (templateStates.active.id) {
      const { id, type } = templateStates.active;
      const weighing = formValues[`weighing_${type}_${id}`] || 0;
      setValue(`weighing_${type}_${id}`, weighing);
    }
    // eslint-disable-next-line
  }, [templateStates.active]);

  // Validate the name of the template when this is changed
  useEffect(() => {
    const templateName = templateData?.[`name_${templateStates.formLanguage}`];
    setTemplateStates({
      isValid: !(watchName?.trim() === "" || (templateData?.nala_default === true && watchName?.trim() === templateName)),
    });
    // eslint-disable-next-line
  }, [watchName]);

  useEffect(() => {
    updateStakeholders(formValues);
  }, [formValues]);

  /** Save template name - block
   * Save the name of the template in actual language in formValues
   */
  const handleNameChange = useCallback((newName) => {
    const actualName = formValues[`name_${templateStates.formLanguage}`];
    if (newName
        && actualName
        && actualName !== newName
        && newName !== "") {
      setFormValues({
        [`name_${templateStates.formLanguage}`]: newName,
      });
    }
  }, [templateStates.formLanguage, formValues]);

  const debouncedHandleNameChange = useCallback(
    debounce(handleNameChange, 300),
    [handleNameChange],
  );

  useEffect(() => {
    debouncedHandleNameChange(watchedNames[`name_${templateStates.formLanguage}`]);

    return () => {
      debouncedHandleNameChange.cancel();
    };
  }, [watchedNames, debouncedHandleNameChange]);

  /* End of the name - block */

  /** Save sections names - block
   * Save the section name in actual language in formValues
   */
  const handleSectionChange = useCallback((sectionsValues) => {
    const language = templateStates.formLanguage;
    const actualSections = formValues.sections;

    actualSections?.forEach((section, index) => {
      const sectionName = sectionsValues[`section_${language}_${section.id}`];
      if (sectionName && section[`name_${language}`] !== undefined && sectionName !== section[`name_${language}`]) {
        setFormValues({
          sections: [
            ...actualSections.slice(0, index),
            {
              ...section,
              [`name_${language}`]: sectionName,
            },
            ...actualSections.slice(index + 1),
          ],
          [`section_${language}_${section.id}`]: sectionName,
        });
      }
    });
  }, [templateStates.formLanguage, formValues.sections]);

  const debouncedHandleSectionChange = useCallback(
    debounce(handleSectionChange, 300),
    [handleSectionChange],
  );

  useEffect(() => {
    debouncedHandleSectionChange(watchedSections);

    return () => {
      debouncedHandleSectionChange.cancel();
    };
  }, [watchedSections, debouncedHandleSectionChange]);

  /* End of the sections names - block */

  /** Save the weighings values - block
   * Save the weighing value of active section and stakeholder in formValues
   */
  const handleWeighingChange = useCallback((weighingsValues) => {
    const actualSections = formValues.sections;
    const actualSection = actualSections?.find(
      (section) => section.id === templateStates.active.id,
    );
    const index = actualSections?.indexOf(actualSection);

    const actualEvaluations = actualSection?.evaluations;
    const actualStakeholders = { ...templateStates.stakeholders };
    Object.keys(actualStakeholders).forEach((key) => {
      const { type } = actualStakeholders[key];
      if (actualStakeholders[key].checked && type === templateStates.active.type) {
        const weighing = parseInt(weighingsValues[`weighing_${type}_${actualSection?.id}`], 10) || undefined;
        const actualWeighing = formValues[`weighing_${type}_${actualSection?.id}`];
        const isChecked = formValues[`checkbox_${type}_${actualSection?.id}`];
        const actualEvaluation = actualEvaluations?.find(
          (evaluation) => evaluation.type === type,
        );
        if (weighing !== undefined && weighing !== actualWeighing && isChecked) {
          setFormValues({
            sections: [
              ...actualSections.slice(0, index),
              {
                ...actualSection,
                evaluations: [
                  ...actualEvaluations.slice(0, actualEvaluations.indexOf(actualEvaluation)),
                  {
                    ...actualEvaluation,
                    weighing,
                  },
                  ...actualEvaluations.slice(actualEvaluations.indexOf(actualEvaluation) + 1),
                ],
              },
              ...actualSections.slice(index + 1),
            ],
            [`weighing_${type}_${actualSection.id}`]: weighing,
          });
        }
      }
    });
  }, [templateStates.active, formValues.sections, templateStates.stakeholders]);

  const debouncedHandleWeighingChange = useCallback(
    debounce(handleWeighingChange, 300),
    [handleWeighingChange],
  );

  useEffect(() => {
    debouncedHandleWeighingChange(watchedWeighings);

    return () => {
      debouncedHandleWeighingChange.cancel();
    };
  }, [watchedWeighings, debouncedHandleWeighingChange]);

  /* End of the weighings values - block */

  /** Save the scale selected - block
   * Save the scale selected in formValues
   */
  const handleScaleChange = useCallback((scalesValues) => {
    const actualSections = formValues.sections;
    const actualSection = actualSections?.find(
      (section) => section.id === templateStates.active.id,
    );
    const index = actualSections?.indexOf(actualSection);
    const actualQuestions = actualSections?.find(
      (section) => section.id === templateStates.active.id,
    )?.questions;

    actualQuestions?.forEach((question, indexQuestion) => {
      const scale = scalesValues[`scale_${question.id}`];
      const actualScale = question.evaluation_scale_id;
      if (scale !== undefined && scale !== actualScale) {
        setFormValues({
          sections: [
            ...actualSections.slice(0, index),
            {
              ...actualSection,
              questions: [
                ...actualQuestions.slice(0, indexQuestion),
                {
                  ...question,
                  evaluation_scale_id: scale,
                },
                ...actualQuestions.slice(indexQuestion + 1),
              ],
            },
            ...actualSections.slice(index + 1),
          ],
          [`scale_${question.id}`]: scale,
        });
      }
    });
  }, [templateStates.active, formValues.sections]);

  useEffect(() => {
    handleScaleChange(watchedScales);
  }, [watchedScales, handleScaleChange]);

  /* End of the scale selected - block */

  /** Save the questions - block
   * Save the question text in actual language in formValues
   */
  const handleQuestionChange = useCallback((questionsValues) => {
    const language = templateStates.formLanguage;
    const actualSections = formValues.sections;
    const actualSection = actualSections?.find(
      (section) => section.id === templateStates.active.id,
    );
    const index = actualSections?.indexOf(actualSection);
    const actualQuestions = actualSections?.find(
      (section) => section.id === templateStates.active.id,
    )?.questions;

    actualQuestions?.forEach((question, indexQuestion) => {
      const questionName = questionsValues[`question_${language}_${question.id}`];
      if (questionName !== undefined && question[`name_${language}`] !== undefined && questionName !== question[`name_${language}`]) {
        setFormValues({
          sections: [
            ...actualSections.slice(0, index),
            {
              ...actualSection,
              questions: [
                ...actualQuestions.slice(0, indexQuestion),
                {
                  ...question,
                  [`name_${language}`]: questionName,
                },
                ...actualQuestions.slice(indexQuestion + 1),
              ],
            },
            ...actualSections.slice(index + 1),
          ],
          [`question_${language}_${question.id}`]: questionName,
        });
      }
    });
  }, [templateStates.active, formValues.sections, templateStates.formLanguage]);

  const debouncedHandleQuestionChange = useCallback(
    debounce(handleQuestionChange, 300),
    [handleQuestionChange],
  );

  useEffect(() => {
    debouncedHandleQuestionChange(watchedQuestions);

    return () => {
      debouncedHandleQuestionChange.cancel();
    };
  }, [watchedQuestions, debouncedHandleQuestionChange]);

  /* End of the questions - block */

  /** Save the general stakeholders values - block
   * Save the general stakeholders values in formValues
   */
  const handleGeneralStakeholdersChange = useCallback((generalStakeholdersValues) => {
    const actualStakeholders = { ...templateStates.stakeholders };
    Object.keys(actualStakeholders).forEach((key) => {
      const stakeholderValue = parseInt(generalStakeholdersValues[key], 10) || 0;
      const actualStakeholderValue = formValues[key];
      let updateStakeholderValues = {};

      if (stakeholderValue !== undefined && stakeholderValue !== actualStakeholderValue) {
        updateStakeholderValues = {
          [key]: stakeholderValue,
        };
      }

      if (Object.keys(updateStakeholderValues).length > 0) {
        setFormValues(updateStakeholderValues);
        setTemplateStates({
          stakeholders: {
            ...templateStates.stakeholders,
            [key]: {
              ...templateStates.stakeholders[key],
              value: stakeholderValue,
            },
          },
        });
      }
    });
  }, [templateStates.stakeholders, formValues]);

  const debouncedHandleGeneralStakeholdersChange = useCallback(
    debounce(handleGeneralStakeholdersChange, 300),
    [handleGeneralStakeholdersChange],
  );

  useEffect(() => {
    debouncedHandleGeneralStakeholdersChange(watchedGeneralStakeholders);

    return () => {
      debouncedHandleGeneralStakeholdersChange.cancel();
    };
  }, [watchedGeneralStakeholders, debouncedHandleGeneralStakeholdersChange]);

  /* End of the general stakeholders values - block */

  /** Save the general stakeholders switchs - block
   * Save the general stakeholders switchs status in formValues
   */
  const handleGeneralStakeholdersSwitchsChange = useCallback((generalStakeholdersSwitchsValues) => {
    const actualStakeholders = { ...templateStates.stakeholders };
    Object.keys(actualStakeholders).forEach((key) => {
      const isChecked = !!generalStakeholdersSwitchsValues[`checkbox_${key}`];
      const formValue = formValues[`checkbox_${key}`];
      let updateStakeholderValues = {};

      if (isChecked !== undefined && isChecked !== formValue) {
        updateStakeholderValues = {
          ...updateStakeholderValues,
          [`checkbox_${key}`]: isChecked,
        };

        if (!isChecked) {
          const actualType = actualStakeholders[key].type;
          const { sections } = formValues;
          sections?.forEach((section) => {
            const actualIsChecked = formValues[`checkbox_${actualType}_${section.id}`];
            if (actualIsChecked) {
              updateStakeholderValues = {
                ...updateStakeholderValues,
                [`checkbox_${actualType}_${section.id}`]: false,
                [`weighing_${actualType}_${section.id}`]: 0,
              };
            }
          });
        }
      }

      if (Object.keys(updateStakeholderValues).length > 0) {
        setFormValues(updateStakeholderValues);
      }
    });
  }, [templateStates.stakeholders, formValues]);

  useEffect(() => {
    handleGeneralStakeholdersSwitchsChange(watchedGeneralStakeholdersSwitchs);
  }, [watchedGeneralStakeholdersSwitchs, handleGeneralStakeholdersSwitchsChange]);

  /* End of the general stakeholders switchs - block */

  return {
    templateStates,
    setTemplateStates,
    isValidUser,
    isMobile,
    processData,
    isLoadingTemplate,
    isFetchingTemplate,
    templateData,
    isLoadingSectionsTemplate,
    onLanguageChange,
    i18n,
    handleSubmit,
    getValues,
    handleSwitchChange,
    register,
    control,
    watch,
    onSubmit,
    reset,
    setValue,
    handleCancelClick,
    evaluationScales,
    isLoadingEvaluationScales,
    formValues,
    setFormValues,
    setWatchedFields,
    updateEvaluations,
    history,
    hasInternalClient,
  };
};
