import {
  useEffect, useState, useRef,
  useCallback, useContext,
} from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { SessionContext } from "modules/session/context";
import Grid from "@mui/material/Grid";
// import { useTheme } from "@mui/styles";
// import useMediaQuery from "@mui/material/useMediaQuery";
import Card from "@mui/material/Card";
import SkeletonLoader from "components/SkeletonLoader";
import SimpleAlert from "components/SimpleAlert";
import NoDataMessage from "components/NoDataMessage";
// import charts from "theme/charts";
import Select from "components/Select";
import Filter from "components/Filter";
import { isNull, isEmpty } from "common/helpers";
import {
  OBJECT_KEYS,
  COMPANY_SLUGS,
} from "common/constants";
import { MIN_FORMS_FILLED } from "common/constants/engagement";
import {
  getIdParam,
  isCurrentCompanySlug,
  isValidAdmin,
  isAdminOrManager,
} from "common/utils";
import { FILTER_ACTIONS_TYPES } from "common/constants/filters";
import {
  getList as getEngagementProcess,
  getEngagementEvolution,
} from "redux/actions/engagementActions";
import {
  useParticipationStatistics,
  useNpsStatistics,
  useParticipation,
  useTopics,
  useDepartments,
  useSatisfactionForTopic,
  useSatisfactionForDepartment,
  useCommentsForDepartment,
} from "hooks/engagementProcess";
import { orderEngagementProcesses } from "../../functions";
import Participation from "../Participation";
import GeneralEngagement from "../GeneralEngagement";
import SatisfactionByTopicAndDepartment from "../SatisfactionByTopicAndDepartment";
import CommentsByDepartment from "../CommentsByDepartment";
import ComparativePeriods from "../ComparativePeriods";
import {
  StyledHeader, StyledTitle,
  StyledDashboardContainer,
  StyledCardContent, StyledCard,
  StyledFilterGrid,
} from "./styles";

const GeneralContent = () => {
  const { t, i18n } = useTranslation("engagement");
  const { language } = i18n;

  const dispatch = useDispatch();
  const location = useLocation();
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down(charts.breakpoints.small));

  const {
    state: { user },
  } = useContext(SessionContext);

  // validations
  const isHmCompany = isCurrentCompanySlug(user, COMPANY_SLUGS.haulmer);
  const isCmCompany = isCurrentCompanySlug(user, COMPANY_SLUGS.comunidad_feliz);
  const isAdmin = isValidAdmin(user?.userCookies);
  const isNotHmLeader = !isHmCompany || isAdmin;
  const adminOrManager = isAdminOrManager(user?.userCookies);

  // Only valid if not Comunidad Feliz company
  // or if the user is both an admin/manager and not an Haulmer leader
  const isValidToSeeNpsAndSatisfaction = !isCmCompany || (adminOrManager && isNotHmLeader);

  const {
    list: engagementProcesses,
    isLoadingList: isLoadingEngagementProcesses,
  } = useSelector(({ engagementProcessReducer }) => engagementProcessReducer);

  const [values, setValues] = useState({
    evaluation: null,
    evaluations: null,
    minFilled: MIN_FORMS_FILLED.withoutFollow,
  });
  const [isFirstLoad, setIsFirstLoad] = useState(true);

  const [topicSatisfaction, setTopicSatisfaction] = useState(null);
  const [satisfactionByDepartment, setSatisfactionByDepartment] = useState(null);
  const [commentsByDepartment, setCommentsByDepartment] = useState(null);
  const [isLoadingSatisfactionByDepartment, setIsLoadingSatisfactionByDepartment] = useState(false);
  const [isLoadingCommentsByDepartment, setIsLoadingCommentsByDepartment] = useState(false);
  const [filterQuery, setFilterQuery] = useState();

  const prevEvaluation = useRef(values.evaluation);

  // Fetch engagement processes
  useEffect(() => {
    if (isNull(engagementProcesses) && !isLoadingEngagementProcesses) {
      dispatch(getEngagementProcess({}, true));
      dispatch(getEngagementEvolution());
    }
  }, [engagementProcesses, isLoadingEngagementProcesses, dispatch]);

  useEffect(() => {
    if (engagementProcesses && !isLoadingEngagementProcesses) {
      if (!isEmpty(engagementProcesses)) {
        const evaluations = orderEngagementProcesses(engagementProcesses, language);
        const processId = getIdParam(location.search);
        const evaluationInfo = evaluations.find(
          (item) => item.value === processId,
        ) || evaluations[0];
        const evaluation = evaluationInfo?.value;
        const minFilled = MIN_FORMS_FILLED[evaluationInfo?.follow_up_process_id ? "withFollow" : "withoutFollow"];
        setValues({ evaluations, evaluation, minFilled });
      }
    }
  }, [engagementProcesses, isLoadingEngagementProcesses, language, location.search]);

  const handleChange = (prop, event) => {
    const evaluation = event.target.value;
    setValues((prevValues) => ({ ...prevValues, [prop]: evaluation }));
  };

  // Hooks
  const {
    mutate: fetchParticipationStatistics,
    data: participationData,
    isLoading: isLoadingParticipation,
    reset: resetParticipationStatistics,
  } = useParticipationStatistics();

  const {
    mutate: fetchNpsStatistics,
    data: npsData,
    isLoading: isLoadingNps,
    reset: resetNpsData,
  } = useNpsStatistics();

  const {
    mutate: fetchParticipation,
    data: engagementParticipation,
    isLoading: isLoadingEngagementParticipation,
    reset: resetEngagementParticipation,
  } = useParticipation();

  const {
    mutate: fetchTopics,
    data: topicsData,
    isLoading: isLoadingTopics,
  } = useTopics();

  const {
    mutate: fetchDepartments,
    data: departmentsData,
    isLoading: isLoadingDepartments,
    reset: resetDepartmentsData,
  } = useDepartments();

  const {
    mutate: fetchSatisfactionForTopic,
  } = useSatisfactionForTopic();

  const {
    mutate: fetchSatisfactionForDepartment,
  } = useSatisfactionForDepartment();

  const {
    mutate: fetchCommentsForDepartment,
  } = useCommentsForDepartment();

  // Topics
  const fetchSatisfactionSequentially = useCallback(async (query = filterQuery) => {
    const satisfactionDataArray = [];

    await topicsData.reduce((promiseChain, topic) => promiseChain
      .then(() => new Promise((resolve) => {
        fetchSatisfactionForTopic(
          { id: values.evaluation, topicId: topic.id, query },
          {
            onSuccess: (data) => {
              satisfactionDataArray.push(data);
              resolve();
            },
            onError: () => {
              resolve();
            },
          },
        );
      })), Promise.resolve());

    setTopicSatisfaction(satisfactionDataArray);
  }, [topicsData, fetchSatisfactionForTopic, values.evaluation]);

  // Satisfaction by Department
  const fetchSatisfactionByDepartmentSequentially = useCallback(async () => {
    setIsLoadingSatisfactionByDepartment(true);
    const satisfactionByDepartmentDataArray = [];

    await departmentsData.reduce((promiseChain, department) => promiseChain
      .then(() => new Promise((resolve) => {
        fetchSatisfactionForDepartment(
          { id: values.evaluation, departmentId: department.id, query: filterQuery },
          {
            onSuccess: (data) => {
              satisfactionByDepartmentDataArray.push(data);
              resolve();
            },
            onError: () => {
              resolve();
            },
          },
        );
      })), Promise.resolve());

    setSatisfactionByDepartment(satisfactionByDepartmentDataArray);
    setIsLoadingSatisfactionByDepartment(false);
  }, [departmentsData, fetchSatisfactionForDepartment, values.evaluation]);

  // Comments by Department
  const fetchCommentsByDepartmentSequentially = useCallback(async () => {
    setIsLoadingCommentsByDepartment(true);
    const commentsByDepartmentDataArray = [];

    await departmentsData.reduce((promiseChain, department) => promiseChain
      .then(() => new Promise((resolve) => {
        fetchCommentsForDepartment(
          { id: values.evaluation, departmentId: department.id, query: filterQuery },
          {
            onSuccess: (data) => {
              commentsByDepartmentDataArray.push(data);
              resolve();
            },
            onError: () => {
              resolve();
            },
          },
        );
      })), Promise.resolve());

    setCommentsByDepartment(commentsByDepartmentDataArray);
    setIsLoadingCommentsByDepartment(false);
  }, [departmentsData, fetchCommentsForDepartment, values.evaluation]);

  useEffect(() => {
    if (!isLoadingDepartments) {
      if (departmentsData?.length > 0) {
        fetchSatisfactionByDepartmentSequentially();
        fetchCommentsByDepartmentSequentially();
      } else {
        setSatisfactionByDepartment([]);
        setCommentsByDepartment([]);
      }
    }
  }, [
    departmentsData,
    fetchSatisfactionByDepartmentSequentially,
    fetchCommentsByDepartmentSequentially,
  ]);

  const getData = useCallback((query = null) => {
    setSatisfactionByDepartment(null);
    setCommentsByDepartment(null);
    setTopicSatisfaction(null);

    fetchParticipation(
      { id: values.evaluation, query },
      {
        onSuccess: (data) => {
          if (data?.surveys_answered > 0) {
            fetchParticipationStatistics({ id: values.evaluation, query });
            fetchNpsStatistics({ id: values.evaluation, query });
            fetchDepartments({ id: values.evaluation, query });
            fetchSatisfactionSequentially(query);
          } else {
            setSatisfactionByDepartment([]);
            setCommentsByDepartment([]);
            setTopicSatisfaction([]);
          }
        },
      },
    );

    prevEvaluation.current = values.evaluation;
  }, [
    dispatch, fetchParticipationStatistics,
    fetchNpsStatistics, fetchParticipation, fetchDepartments,
    values.evaluation, fetchSatisfactionSequentially,
  ]);

  useEffect(() => {
    if (topicsData?.length > 0) {
      getData();
    }
  }, [topicsData]);

  useEffect(() => {
    const evaluationChanged = prevEvaluation.current !== values.evaluation;
    if (values.evaluation && (evaluationChanged || isFirstLoad)) {
      resetEngagementParticipation();
      resetNpsData();
      resetParticipationStatistics();
      resetDepartmentsData();
      fetchTopics({ id: values.evaluation });
      setIsFirstLoad(false);
    }
  // eslint-disable-next-line
}, [
    values.evaluation, fetchTopics,
  ]);

  const handleExternalQuery = (query) => {
    setFilterQuery(query);
    if (!isFirstLoad) {
      getData(query);
    }
  };

  const isLoadingProcessInfo = isLoadingEngagementProcesses || isLoadingEngagementParticipation
  || isLoadingCommentsByDepartment || isLoadingNps || isLoadingSatisfactionByDepartment
  || isLoadingDepartments;

  const isValidToEvolution = values?.evaluations?.some(
    (evaluation) => evaluation.follow_up_process_id === null,
  );

  return (
    <StyledDashboardContainer container data-testid={ "general-content-view" }>
      <StyledHeader item xs={ 12 } sm={ 6 } md={ 8 } lg={ 9 }>
        <StyledTitle>{t("title")}</StyledTitle>
      </StyledHeader>

      <Grid item xs={ 12 } sm={ 6 } md={ 4 } lg={ 3 }>
        {values.evaluations && (
          <Select
            id={ OBJECT_KEYS.evaluation }
            label={ t("selectEvaluation") }
            menuItems={ values.evaluations }
            value={ isLoadingEngagementProcesses ? "" : values.evaluation }
            disabled={ isEmpty(engagementProcesses) || isLoadingEngagementProcesses }
            onChange={ handleChange }
            className={ "custom-select" }
          />
        )}
      </Grid>
      {values?.evaluation && (
        <StyledFilterGrid container>
          <Grid item xs={ 12 }>
            <Filter
              type={ FILTER_ACTIONS_TYPES.engagement }
              externalParams={ [values.evaluation] }
              isDisabled={ isLoadingProcessInfo }
              handleExternalQuery={ handleExternalQuery }
              hasButton
            />
          </Grid>
        </StyledFilterGrid>
      )}
      {values?.evaluation
        ? (
          <Grid item xs={ 12 }>
            <Participation
              t={ t }
              participationData={ participationData }
              isLoadingParticipation={ isLoadingParticipation }
              npsData={ npsData }
              isLoadingNps={ isLoadingNps }
              engagementParticipation={ engagementParticipation }
              isLoadingEngagementParticipation={ isLoadingEngagementParticipation }
            />
            {engagementParticipation?.surveys_answered < values.minFilled && (
              <SimpleAlert type={ "info" } message={ t("message") } />
            )}
            {!isLoadingTopics && topicSatisfaction !== null
              ? (
                <GeneralEngagement
                  t={ t }
                  language={ language }
                  id={ values.evaluation }
                  topicSatisfaction={ topicSatisfaction }
                  isInvalidData={ engagementParticipation?.surveys_answered < values.minFilled }
                />
              ) : (
                <Grid item xs={ 12 }>
                  <Card elevation={ 0 }>
                    <StyledCardContent>
                      <SkeletonLoader numberOfSkeletons={ 8 } />
                    </StyledCardContent>
                  </Card>
                </Grid>
              )}
            {/* nps and satisfaction by division - chart and table */}
            {/* satisfaction by department */}
            {!isLoadingDepartments && !isLoadingSatisfactionByDepartment
            && satisfactionByDepartment !== null
          && isValidToSeeNpsAndSatisfaction && isNotHmLeader
              ? (
                <SatisfactionByTopicAndDepartment
                  t={ t }
                  satisfactionResults={ satisfactionByDepartment }
                  topicSatisfaction={ topicSatisfaction }
                  engagementParticipation={ engagementParticipation }
                  minFilled={ values.minFilled }
                />
              ) : (
                <Grid item xs={ 12 }>
                  <Card elevation={ 0 }>
                    <StyledCardContent>
                      <SkeletonLoader numberOfSkeletons={ 8 } />
                    </StyledCardContent>
                  </Card>
                </Grid>
              )}
            {/* comparative */}
            {isValidToEvolution
            && (
              <StyledCard elevation={ 0 }>
                <StyledCardContent>
                  <ComparativePeriods t={ t } />
                </StyledCardContent>
              </StyledCard>
            )}
            {isAdmin && (
              <StyledCard elevation={ 0 }>
                {/* comments by department */}
                <StyledCardContent>
                  <CommentsByDepartment
                    t={ t }
                    engagementComments={ commentsByDepartment }
                    isLoading={ isLoadingCommentsByDepartment }
                  />
                </StyledCardContent>
              </StyledCard>
            )}
          </Grid>
        ) : (
          <Grid item xs={ 12 }>
            {/* TODO: empty evaluations view */}
            <Participation
              t={ t }
              participationData={ participationData }
              isLoadingParticipation={ isLoadingParticipation }
              npsData={ npsData }
              isLoadingNps={ isLoadingNps }
              engagementParticipation={ engagementParticipation }
              isLoadingEngagementParticipation={ isLoadingEngagementParticipation }
            />
            <NoDataMessage />
          </Grid>
        )}
    </StyledDashboardContainer>
  );
};

export default GeneralContent;
