import styled from "styled-components";
import Paper from "@mui/material/Paper";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import palette from "theme/palette";
import EditIcon from "@mui/icons-material/Edit";
import ReplayIcon from "@mui/icons-material/Replay";
import IconButton from "@mui/material/IconButton";
import ContentPasteSearchOutlinedIcon from "@mui/icons-material/ContentPasteSearchOutlined";

export const StyledPaperContainer = styled(Paper)`
  margin-bottom: 16px;
`;

export const StyledLink = styled.div`
  color: ${palette.text.link};
  text-decoration: underline;
  cursor: pointer;
`;

export const StyledGridContainer = styled(Grid)`
  margin-bottom: 25px;
`;

export const StyledSubtitleText = styled(Typography)`
  font-weight: 500;
  font-size: 18px;
  line-height: 21px;
  color: ${palette.subtitle};
`;

export const StyledGridEnd = styled(Grid)`
  text-align: right;
`;

export const StyledEditIcon = styled(EditIcon)`
  cursor: pointer;
`;

export const StyledManageSearchIcon = styled(ContentPasteSearchOutlinedIcon)`
  cursor: pointer;
`;

export const StyledReplayIcon = styled(ReplayIcon)`
  cursor: pointer;
`;

export const StyledIconButton = styled(IconButton)`
  padding: 0;
  color: black;
`;
