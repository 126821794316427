import PropTypes from "prop-types";
import InputAdornment from "@mui/material/InputAdornment";
import SearchIcon from "@mui/icons-material/Search";
import IconButton from "@mui/material/IconButton";
import TextField from "@mui/material/TextField";
import { ADORMENT_POSITION, VARIANT } from "common/constants";
import {
  StyledInputContainer,
  StyledInputNumberContainer,
} from "./styles";

const GoalsTotalAudience = (props) => {
  const { t, audienceSelected, handleCloseModal } = props;
  return audienceSelected ? (
    <StyledInputContainer item xs={ 12 }>
      <StyledInputNumberContainer>
        <TextField
          label={ t("planning:surveys.target_audience") }
          value={ audienceSelected?.employees_count }
          variant={ VARIANT.outlined }
          margin={ VARIANT.dense }
          InputProps={ {
            endAdornment: (
              <InputAdornment position={ ADORMENT_POSITION.end }>
                <IconButton
                  onClick={ () => handleCloseModal() }
                  edge={ ADORMENT_POSITION.end }
                >
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
            ),
          } }
          disabled
        />
      </StyledInputNumberContainer>
    </StyledInputContainer>
  ) : null;
};

GoalsTotalAudience.propTypes = {
  t: PropTypes.func.isRequired,
  audienceSelected: PropTypes.object,
  handleCloseModal: PropTypes.func.isRequired,
};

GoalsTotalAudience.defaultProps = {
  audienceSelected: undefined,
};

export default GoalsTotalAudience;
