import { useState } from "react";
import PropTypes from "prop-types";
import DownloadExcelButton from "components/DownloadExcelButton";
import { VARIANT } from "common/constants";
import DataNineBoxIcon from "assets/images/ninebox/bill.svg";
import { getNineBoxList as getCurrentTalentScore } from "redux/actions/talentScore/talentScoreActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";
import { downloadExcel, getDataNineBoxResult } from "../../functions";

import {
  StyledTitle,
  StyledImage,
  StyledNineboxTitle,
  StyledHeader,
} from "./styles";

const HistoricalHeader = (props) => {
  const {
    t,
    nineBoxResult,
    isLoading,
    totalResult,
    cleanedQuery,
    resultScaleList,
    nineBoxTypeList,
    isAllData,
  } = props;

  const [isPreparingDownload, setIsPreparingDownload] = useState(false);
  const [dataToDownload, setDataToDownload] = useState(null);

  const handleDownloadClick = async () => {
    if (nineBoxResult?.length > 0) {
      return downloadExcel(t("ninebox:title"), nineBoxResult, t);
    }

    setIsPreparingDownload(true);
    let currentTalentScores = [];
    if (dataToDownload) {
      currentTalentScores = dataToDownload;
    } else {
      currentTalentScores = await getCurrentTalentScore(cleanedQuery, 1, totalResult);
      setDataToDownload(currentTalentScores);
    }

    if (currentTalentScores?.data) {
      const results = await getDataNineBoxResult(
        currentTalentScores.data,
        resultScaleList,
        t,
        nineBoxTypeList,
      );

      const filteredDataByManagerAnswered = results.filter(
        (result) => result.hasManagerEvaluationAnswered,
      );

      const data = isAllData ? results : filteredDataByManagerAnswered;
      setIsPreparingDownload(false);

      return downloadExcel(t("ninebox:title"), data, t);
    }
    const errorMessage = t("common:common.api_responses.error.default_error");
    toast(MESSAGE_TYPES.error, { message: errorMessage });
    setIsPreparingDownload(false);
  };

  const isDownloadDisabled = () => {
    if (totalResult !== null && totalResult > 0) return false;

    return !nineBoxResult || nineBoxResult.length === 0;
  };

  return (
    <StyledHeader>
      <StyledNineboxTitle>
        <StyledImage src={ DataNineBoxIcon } alt={ "Ninebox" } />
        <StyledTitle variant={ VARIANT.h4 }>{t("ninebox:title")}</StyledTitle>
      </StyledNineboxTitle>

      <DownloadExcelButton
        onClick={ handleDownloadClick }
        isDisabled={ isDownloadDisabled() }
        isLoading={ isLoading || isPreparingDownload }
      />
    </StyledHeader>
  );
};

HistoricalHeader.propTypes = {
  t: PropTypes.func.isRequired,
  nineBoxResult: PropTypes.array.isRequired,
  isLoading: PropTypes.bool,
  totalResult: PropTypes.number,
  cleanedQuery: PropTypes.object,
  resultScaleList: PropTypes.array,
  nineBoxTypeList: PropTypes.array,
  isAllData: PropTypes.bool,
};

HistoricalHeader.defaultProps = {
  isLoading: false,
  totalResult: null,
  cleanedQuery: null,
  resultScaleList: null,
  nineBoxTypeList: null,
  isAllData: null,
};

export default HistoricalHeader;
