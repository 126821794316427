import styled from "styled-components";
import palette from "theme/palette";

export const StyledSectionContainer = styled.div`
  background: ${palette.white};
  border-radius: 4px;
  padding: ${(props) => (props.padding || "16px")};;
  height: ${(props) => (props.height || "100%")};
  position: relative;
  display: ${(props) => (props.isFlex ? "flex" : "block")};
`;
