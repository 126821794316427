import {
  useCallback, useContext, useEffect,
} from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { toNumber } from "lodash";
import { SessionContext } from "modules/session/context";
import useComplexState from "hooks/utils/useComplexState";
import {
  isEmpty, isEqual, isNull,
} from "common/helpers";
import {
  COMPANIES_WITHOUT_PERCENTAGE_PROFILE_PERFORMANCE,
  INDEX,
  LOCATION_PROPS,
  SURVEY_PROCESS_TYPE,
  COMPANY_SLUGS,
} from "common/constants";
import { enableComponent, getEmployeeCompanySlug, getPropertyByLocation } from "common/utils";
import paths from "common/paths";
import { getList as getIndividualSurveyResult } from "redux/actions/performance/surveyResultsActions";
import localRoutes from "../functions/routes";

export const usePerformanceHook = (externalHooks, internalHooks) => {
  const { dispatch, location } = externalHooks;
  const {
    collaborator, searchCollaborator,
    isValidToShowAttrition: isValidToShowResult,
  } = internalHooks;
  const {
    state: { user },
  } = useContext(SessionContext);

  const history = useHistory();

  const {
    list: surveyResults,
    isLoadingList: isLoadingSurveyResults,
  } = useSelector(
    ({ performanceSurveyResultsReducer }) => performanceSurveyResultsReducer,
  );

  if (user?.company?.slug === COMPANY_SLUGS.shn
    && location.pathname === localRoutes.performance
    && !isValidToShowResult) {
    history.push("/not-found");
  }

  // search process id by url
  const locationSearch = getPropertyByLocation(location, LOCATION_PROPS.search);
  const processParam = locationSearch?.split(paths.process)[INDEX.one];
  const processId = processParam ? locationSearch?.split("=")[INDEX.two] : null;
  const isPerformanceModalOpen = isEqual(location.pathname, localRoutes.performance);

  const [performanceStates, setPerformanceStates] = useComplexState({
    performanceData: null,
    evaluationList: null,
    evaluationSelected: null,
  });

  const companySlug = getEmployeeCompanySlug(user);
  const isValidShowPercentage = enableComponent(
    companySlug,
    COMPANIES_WITHOUT_PERCENTAGE_PROFILE_PERFORMANCE,
  );

  const getGeneralData = useCallback((employeeId) => {
    // TODO: handle validation for candidates
    // const userProfile = from
    //   ? getReducerToUse(searchBy, reducerToUse, user, from)
    //   : user;
    if (employeeId) {
      // TODO: do not dispatch this for candidates
      const query = {
        q: {
          score_not_null: INDEX.one,
          survey_process_type_eq: SURVEY_PROCESS_TYPE.performance.key,
          s: "end_date desc",
        },
      };
      dispatch(getIndividualSurveyResult(employeeId, query));
    }
  }, [dispatch]);

  useEffect(() => {
    if (collaborator
      && isPerformanceModalOpen
      && isEqual(collaborator?.id, searchCollaborator)
      && isNull(surveyResults)
    ) {
      // TODO: do not dispatch this for candidates
      getGeneralData(collaborator.id);
    }
  }, [collaborator, surveyResults, isPerformanceModalOpen, searchCollaborator, getGeneralData]);

  useEffect(() => {
    if (
      !isLoadingSurveyResults
      && !isNull(surveyResults)
      && isPerformanceModalOpen
    ) {
      if (isEmpty(surveyResults)) {
        setPerformanceStates({
          evaluationList: [],
          performanceData: {},
        });
      } else {
        const list = surveyResults.filter((result) => result.score !== null).map((item) => ({
          value: item.survey_process?.id,
          label: item.survey_process?.name,
        }));
        const evaluationId = toNumber(processId) || list[0]?.value;
        setPerformanceStates({
          evaluationList: list,
          evaluationSelected: evaluationId,
        });
      }
    }
    // eslint-disable-next-line
  }, [surveyResults, processId]);

  useEffect(() => {
    if (!isNull(performanceStates?.evaluationSelected)
      && surveyResults
      && !isEmpty(surveyResults)
    ) {
      const validSurveyResults = surveyResults.filter((result) => result.score !== null);
      setPerformanceStates({
        performanceData: validSurveyResults?.length > 0 ? validSurveyResults.find(
          (item) => item.survey_process
          && isEqual(item.survey_process.id, performanceStates.evaluationSelected),
        ) : {},
      });
    }
    // eslint-disable-next-line
	}, [performanceStates.evaluationSelected, surveyResults]);

  const handleChangeFilterPeriod = (prop, event) => {
    setPerformanceStates({
      evaluationSelected: event.target.value,
    });
  };

  return {
    isValidShowPercentage,
    performanceStates,
    surveyStates: {
      surveyResults,
      isLoadingSurveyResults,
    },
    handleChangeFilterPeriod,
  };
};
