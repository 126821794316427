import Avatar from "@mui/material/Avatar";
import Typography from "@mui/material/Typography";
import AssignmentIndIcon from "@mui/icons-material/AssignmentInd";
import EqualizerIcon from "@mui/icons-material/Equalizer";
import TooltipIcon from "components/TooltipIcon";
import StarRating from "components/StarRating";
import Tooltip from "@mui/material/Tooltip";
import {
  VARIANT, TYPES_RESULTS,
} from "common/constants";
import {
  createResultToSkillLevelMap,
  findDataInScale,
  getPercent,
  getAvatar,
} from "common/utils";
import { POTENTIAL_COLORS } from "theme/palette";
import { ReactComponent as Location } from "assets/images/icons/location.svg";
import largeIcon from "assets/images/potential/large.svg";
import balancedIcon from "assets/images/potential/balanced.svg";
import deepIcon from "assets/images/potential/deep.svg";
import {
  StyledContent, StyledTitleWithSubtitle,
  StyledText, StyledCalibrationRow,
} from "styledComponents/Tables";
import { viewPotentialProfile } from "common/functions";
import { StyledSkill, StyledInfoOutlinedIcon, StyledCalibrationDetail, StyledEditIcon, StyledManageSearchIcon, StyledReplayIcon, StyledIconButton, } from "../styles";

export const getHeader = (evaluations, t, hasCalibration = false) => {
  const header = [{
    id: "name",
    label: t("tables:headers.name"),
    isParent: true,
  },
  {
    id: "manager",
    label: t("tables:headers.manager"),
    key: "name",
  },
  {
    id: "location",
    label: t("tables:headers.location"),
    key: "name",
  },
  {
    id: "position",
    label: t("tables:headers.position"),
    key: "name",
  }];

  evaluations.forEach((evaluation) => {
    header.push({
      id: evaluation,
      label: t(`tables:headers.${evaluation}`),
    });
  });

  header.push({
    id: hasCalibration ? "calibrated" : "total_evaluation",
    label: hasCalibration ? `${t("tables:headers.potential")} ${t("common:calibration.general")}` : t("tables:headers.potential"),
    hasSorting: true,
    sortingColumn: "score",
  });

  if (hasCalibration) {
    header.push({
      id: "total_evaluation",
      label: `${t("tables:headers.potential")} ${t("common:calibration.calibrated")}`,
      hasSorting: true,
      sortingColumn: "score",
    });
  }

  header.push(
    {
      id: "performance_score",
      label: t("tables:headers.performance"),
      hasSorting: true,
      sortingColumn: "performance_score",
    },
    {
      id: "actions",
      label: t("tables:headers.actions"),
    },
  );

  return header;
};

const getActionsIcons = (
  t,
  history,
  employeeId,
  processId,
  hasCalibration,
  handleOpenCalibrationModal,
  result,
  hasCalibrationActivated,
  handleOpenRestoreModal,
) => {
  const isValidToView = (
    (!hasCalibrationActivated && result?.calibration?.id) || hasCalibrationActivated
  );
  return isValidToView && hasCalibration ? (
    <div style={ { display: "flex", gap: "10px" } }>
      <Tooltip title={ t(`common:calibration.actions.${hasCalibrationActivated ? "calibrate" : "view"}`) }>
        <StyledEditIcon
          fontSize={ "small" }
          onClick={ () => handleOpenCalibrationModal(result) }
        />
      </Tooltip>
      <Tooltip title={ t("common:common.viewMore") }>
        <StyledManageSearchIcon
          fontSize={ "small" }
          onClick={ () => viewPotentialProfile(history, employeeId, processId) }
        />
      </Tooltip>
      <Tooltip title={ t("common:calibration.actions.restore") }>
        <StyledIconButton
          onClick={ () => handleOpenRestoreModal(result.calibration.id, result) }
          disabled={ !result.calibration }
          fontSize={ "small" }
        >
          <StyledReplayIcon fontSize={ "small" } />
        </StyledIconButton>
      </Tooltip>
    </div>
  ) : (
    <Tooltip title={ t("common:common.viewMore") }>
      <StyledManageSearchIcon
        fontSize={ "small" }
        onClick={ () => viewPotentialProfile(history, employeeId, processId) }
      />
    </Tooltip>
  );
};

export const skillInformationLevels = (t, name) => ({
  high: {
    name,
    icon: <img alt={ TYPES_RESULTS.large } src={ largeIcon } />,
    color: POTENTIAL_COLORS.purple,
    tooltip: t("potential:potential-measures.comprehensive"),
  },
  medium: {
    name,
    icon: <img alt={ TYPES_RESULTS.balanced } src={ balancedIcon } />,
    color: POTENTIAL_COLORS.orange,
    tooltip: t("potential:potential-measures.balanced"),
  },
  low: {
    name,
    icon: <img alt={ TYPES_RESULTS.deep } src={ deepIcon } />,
    color: POTENTIAL_COLORS.green,
    tooltip: t("potential:potential-measures.deep"),
  },
});

export const getInformationSkill = (skillResult, skillNineBox, t, withDescription = false) => {
  const skillInfo = skillInformationLevels(t, skillResult)[skillNineBox];

  if (skillInfo) {
    return (
      <StyledSkill color={ skillInfo.color }>
        {skillInfo.icon}
        {skillInfo.name}
        {withDescription && (
          <TooltipIcon
            title={ skillInfo.tooltip }
            element={ <StyledInfoOutlinedIcon fontSize={ "small" } /> }
          />
        )}
      </StyledSkill>
    );
  }
  return "";
};

export const getRowsDesktop = (data, t, history, extraParam) => {
  const resultToSkillLevelMap = createResultToSkillLevelMap(extraParam.resultScale);
  const rows = data?.map((rowData) => {
    const performanceProcess = rowData.employee?.performance_results;
    const skillLevel = resultToSkillLevelMap[rowData.result];
    const skillInfo = getInformationSkill(rowData.result, skillLevel, t);

    const baseRows = [
      {
        content: (
          <StyledContent>
            <Avatar src={ getAvatar(rowData.employee) } />
            <StyledTitleWithSubtitle pl={ 5 } >
              <Typography variant={ VARIANT.h6 }>{rowData.employee.full_name}</Typography>
              <StyledContent>
                <AssignmentIndIcon />
                <Typography variant={ VARIANT.bodyOne }>{rowData.employee.personal_id}</Typography>
              </StyledContent>
            </StyledTitleWithSubtitle>
          </StyledContent>
        ),
      },
      {
        content: <StyledText>{ rowData.job_position?.manager_name }</StyledText>,
      },
      {
        content: (
          <StyledTitleWithSubtitle>
            <Typography variant={ VARIANT.h6 }>{rowData.job_position?.country?.name}</Typography>
            <Typography variant={ VARIANT.bodyOne }>
              {rowData.job_position?.city?.name && (
                <StyledContent mt={ 5 }>
                  <Location />
                  {rowData.job_position.city.name}
                </StyledContent>
              )}
            </Typography>
          </StyledTitleWithSubtitle>
        ),
      },
      {
        content: <StyledText>{ rowData.job_position?.position_name }</StyledText>,
      },
      ...extraParam.evaluations.map((evaluation) => {
        const evaluationResult = evaluation.customRender(rowData);
        const skillLevelEvaluation = resultToSkillLevelMap[evaluationResult];
        const skillInfoEvaluation = getInformationSkill(evaluationResult, skillLevelEvaluation, t);
        return {
          content: skillInfoEvaluation,
        };
      }),
    ];

    const scale = extraParam.resultScale.result_scale_indices;

    if (extraParam.hasCalibration) {
      const scalePrevious = findDataInScale(
        scale,
        rowData.calibration?.previous_score,
        false,
      );

      const scaleNewValue = findDataInScale(
        scale,
        rowData.calibration?.calibrated_score,
        false,
      );

      const nineBoxResult = scalePrevious?.nine_box_result;
      const skillPreviousInfo = nineBoxResult
        ? skillInformationLevels(t)[nineBoxResult] : null;

      const nineBoxResultCalibrated = scaleNewValue?.nine_box_result;
      const skillCalibratedInfo = nineBoxResultCalibrated
        ? skillInformationLevels(t)[nineBoxResultCalibrated] : null;

      baseRows.push(
        {
          content:
            rowData?.calibration?.id ? (
              <StyledSkill color={ skillPreviousInfo.color }>
                {skillPreviousInfo.icon}
                <p>{scalePrevious?.result}</p>
              </StyledSkill>
            ) : skillInfo,
        },
        {
          content:
          rowData?.calibration?.id
            ? (
              <div>
                <StyledSkill color={ skillCalibratedInfo.color }>
                  {skillCalibratedInfo.icon}
                  <p>{scaleNewValue?.result}</p>
                </StyledSkill>
                <StyledCalibrationDetail component={ "legend" } variant={ "caption" }>
                  {t("calibration.last_update_simple", { name: rowData.calibration.last_updated_by })}

                </StyledCalibrationDetail>
              </div>
            )
            : (
              <StyledCalibrationRow>
                <EqualizerIcon />
                <h4>{t("common:calibration.uncalibrated")}</h4>
              </StyledCalibrationRow>
            ),
        },
      );
    } else {
      baseRows.push({
        content: skillInfo,
      });
    }

    baseRows.push(
      {
        content: performanceProcess?.scale_index_count ? (
          <StarRating
            name={ `star-rating-${rowData.id}` }
            value={ performanceProcess?.position }
            isReadOnly
            maxRating={ performanceProcess?.scale_index_count }
            max={ performanceProcess?.scale_index_count }
            precision={ 1 }
            label={ performanceProcess?.result }
            hasNoFormattedValue
            tooltip={ performanceProcess?.score && performanceProcess?.scale_max_index ? {
              score: getPercent(performanceProcess.score, true, 2),
              maxScore: getPercent(performanceProcess.scale_max_index, true, 2),
            } : null }
          />
        ) : "",
      },
      {
        content: getActionsIcons(
          t,
          history,
          rowData.employee.id,
          extraParam.processId,
          extraParam.hasCalibration,
          extraParam.handleOpenCalibrationModal,
          rowData,
          extraParam.hasCalibrationActivated,
          extraParam.handleOpenRestoreModal,
        ),
      },
    );
    return baseRows;
  });
  return rows;
};
