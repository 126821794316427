import { useQuery, useQueryClient, useMutation } from "react-query";
import {
  getNotificationsSettings, toggleNotificationSetting, getLaunchDate, launchNala,
} from "redux/actions/notificationsSettings/notificationsSettingsActions";
import { MESSAGE_TYPES, toast } from "components/Toast/functions";

const handleQueryError = (error, t) => {
  let errorMessage = t("common:common.api_responses.error.title");

  if (error.response && error.response.data && error.response.data.message) {
    errorMessage = error.response.data.message;
  } else if (error.message) {
    errorMessage = error.message;
  } else {
    errorMessage = error.toString();
  }

  toast(MESSAGE_TYPES.error, { message: errorMessage });
};

export const useNotificationsSettings = (t) => {
  const queryClient = useQueryClient();

  const {
    data: notificationsSettings, isLoading, isError, error,
  } = useQuery("notificationsSettings", getNotificationsSettings, {
    staleTime: Infinity,
    onError: (queryError) => handleQueryError(queryError, t),
  });

  return {
    notificationsSettings,
    isLoading,
    isError,
    queryClient,
    error,
  };
};

export const useToggleNotificationSetting = (t) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    (notificationName) => toggleNotificationSetting(notificationName),
    {
      onSuccess: () => {
        queryClient.invalidateQueries("notificationsSettings");
        toast(MESSAGE_TYPES.success, { message: t("common:common.api_responses.success.update") });
      },
      onError: (mutationError) => handleQueryError(mutationError, t),
    },
  );

  return mutation;
};

export const useLaunchDate = (t) => {
  const queryClient = useQueryClient();

  const {
    data: launchDate, isLoading, isError, error,
  } = useQuery("launchDate", getLaunchDate, {
    staleTime: Infinity,
    onError: (queryError) => handleQueryError(queryError, t),
  });

  return {
    launchDate,
    isLoading,
    isError,
    queryClient,
    error,
  };
};

export const useLaunchNala = (t) => {
  const queryClient = useQueryClient();

  const mutation = useMutation(
    launchNala,
    {
      onSuccess: () => {
        queryClient.invalidateQueries("launchDate");
        toast(MESSAGE_TYPES.success, { message: t("administrator:modules.notifications.toast.launched") });
      },
      onError: (mutationError) => handleQueryError(mutationError, t),
    },
  );

  return mutation;
};
