import styled from "styled-components";

export const StyleMultipleCheckbox = styled.div`
  margin-top: 20px;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  max-width: 100%;
  padding: 10px;
`;
