import { useState } from "react";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import Tooltip from "@mui/material/Tooltip";
import { getAvatar } from "common/utils";
import { orderByDesc } from "common/helpers";
import { useEvaluationScale } from "hooks/useEvaluationScale";
import GoalList from "../GoalList";
import {
  StyledContainer,
  StyledMemberSection,
  StyledAvatar,
  StyledTeamSection,
} from "../styles";

const TeamEvaluation = (props) => {
  const {
    t, membersWithGoals,
    isMobile, goalSection,
    handleToCoverOrHomeProcess,
  } = props;
  const [currentMemberIndex, setCurrentMemberIndex] = useState(0);
  const [members, setMembers] = useState(membersWithGoals);
  const currentMember = members[currentMemberIndex];

  const evaluationScaleId = goalSection?.evaluation_scale_id;

  const { evaluationScale, isLoading, isError } = useEvaluationScale(evaluationScaleId);

  let scaleIndices = null;

  if (evaluationScale && !isError) {
    scaleIndices = orderByDesc(evaluationScale.indices, "top");
  }

  const handleMemberClick = (index) => {
    setCurrentMemberIndex(index);
  };

  const updateGoalEvaluationsForMember = (memberId, updatedGoalEvaluations) => {
    setMembers((prevMembers) => prevMembers.map((member) => (member.id === memberId
      ? {
        ...member,
        evaluationResult: {
          ...member.evaluationResult,
          goal_evaluations: updatedGoalEvaluations,
        },
      }
      : member)));
  };

  return (
    <>
      <StyledContainer>
        <Grid container alignItems={ "center" } spacing={ 2 }>
          {/* Evaluated Member Section */}
          <Grid item>
            <StyledMemberSection>
              <Typography variant={ "h6" } fontWeight={ "bold" }>
                {t("surveys:form.fields.evaluated")}
              </Typography>
              <StyledAvatar
                alt={ currentMember?.full_name }
                src={ getAvatar(currentMember.evaluationResult?.evaluation_details?.evaluated) }
                active
              />
            </StyledMemberSection>
          </Grid>

          {/* Team Section */}
          {membersWithGoals.length > 1 && (
            <Grid item>
              <StyledTeamSection>
                <Typography variant={ "h6" }>
                  {t("surveys:form.fields.team")}
                </Typography>
                <Grid container spacing={ 1 } justifyContent={ "center" }>
                  {membersWithGoals.map((member, index) => (
                    <Grid item key={ member.id }>
                      <Tooltip title={ member.full_name } arrow>
                        <StyledAvatar
                          alt={ member.full_name }
                          src={ getAvatar(member.evaluationResult?.evaluation_details?.evaluated) }
                          active={ index === currentMemberIndex }
                          isCurrent={ index === currentMemberIndex }
                          onClick={ () => handleMemberClick(index) }
                        />
                      </Tooltip>
                    </Grid>
                  ))}
                </Grid>
              </StyledTeamSection>
            </Grid>
          )}
        </Grid>
      </StyledContainer>
      {!isLoading
      && (
        <GoalList
          currentMember={ currentMember }
          t={ t }
          isMobile={ isMobile }
          scaleIndices={ scaleIndices }
          handleToCoverOrHomeProcess={ handleToCoverOrHomeProcess }
          updateGoalEvaluations={ (updatedGoalEvaluations) => updateGoalEvaluationsForMember(
            currentMember.id,
            updatedGoalEvaluations,
          ) }
        />
      )}
    </>
  );
};

TeamEvaluation.propTypes = {
  t: PropTypes.func.isRequired,
  membersWithGoals: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      full_name: PropTypes.string.isRequired,
      evaluationResult: PropTypes.object,
    }),
  ),
  goalSection: PropTypes.object.isRequired,
  isMobile: PropTypes.bool.isRequired,
  handleToCoverOrHomeProcess: PropTypes.func.isRequired,
};

TeamEvaluation.defaultProps = {
  membersWithGoals: [],
};

export default TeamEvaluation;
