import Stack from "@mui/material/Stack";
import Switch from "@mui/material/Switch";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { StyledTitle, StyledSubtitle } from "styledComponents/View";

const InputToggle = (props) => {
  const { name, isChecked, onChange } = props;
  const { t } = useTranslation();
  return (
    <Stack direction={ "row" } sx={ { alignItems: "center", justifyContent: "space-between" } }>
      <Stack direction={ "column" } spacing={ 1 }>
        <StyledTitle size={ 20 }>
          { t(`administrator:modules.notifications.options.${name}.title`) }
        </StyledTitle>
        <StyledSubtitle fontWeight={ 400 } fontSize={ 18 }>
          { t(`administrator:modules.notifications.options.${name}.description`) }
        </StyledSubtitle>
      </Stack>
      <Switch checked={ isChecked } onChange={ onChange } />
    </Stack>
  );
};

InputToggle.propTypes = {
  name: PropTypes.string.isRequired,
  isChecked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
};

export default InputToggle;
