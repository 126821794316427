import React, { useState, useEffect, useCallback } from "react";
import {
  Grid, Typography, Box, Stepper, Step, RadioGroup, FormControlLabel,
  Radio, MenuItem, IconButton,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import {
  OBJECT_KEYS, DATE_PARTS, TYPES, VARIANT, ORIENTATION,
} from "common/constants";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import PropTypes from "prop-types";
import {
  EVALUATION_TYPE, ensureTemplateSuffix,
} from "views/SurveyTemplate/functions";
import { getListByQuery as getEvaluationScales, resetState } from "redux/actions/evaluationScaleActions";
import DateInputController from "components/DateInputController";
import { useTranslation } from "react-i18next";
import useStyles, {
  StyledTypography, StyledBox, StyledTextField, StyledHeader,
  StyledStepLabel, StyledContent, StyledSelect, StyledEvaluationScaleForm,
} from "./styles";
import EvaluationScaleEditForm from "../../../../../SurveyTemplate/components/EvaluationScaleEditForm";
import { getFormattedScalesList } from "../../../../../SurveyTemplate/functions";
import EvaluationScaleForm from "../../../../../SurveyTemplate/components/EvaluationScaleForm";
import GoalsStakeholders from "../GoalsStakeholders";

const Goals = ({
  t, evaluations, initialFormLanguage, user, handleGoalsChange, isDisabled, formValues,
}) => {
  const [goalWeight, setGoalWeight] = useState(formValues.goalsData.weighing);
  const [selectedScale, setSelectedScale] = useState(formValues.goalsData.evaluationScaleId);
  const [stakeholdersData, setStakeholdersData] =
  useState(formValues.goalsData.evaluationSectionDetailTemplatesAttributes);
  const [filteredEvaluations, setFilteredEvaluations] = useState([]);
  const stepLetters = ["A", "B", "C"];
  const [selectedItem, setSelectedItem] = useState(formValues.goalsData.evaluationScaleId ? "scale" : "keyIndicator");
  const [isEditFormVisible, setIsEditFormVisible] = useState(false);
  const [formLanguage, setFormLanguage] = useState(initialFormLanguage);
  const [formattedScalesList, setFormattedScalesList] = useState([]);
  const [isCustomFormVisible, setIsCustomFormVisible] = useState(false);
  const { i18n } = useTranslation();
  const [refreshScales, setRefreshScales] = useState(false);
  const [startDate, setStartDate] = useState(formValues.goalsData.startDate);
  const [endDate, setEndDate] = useState(formValues.goalsData.endDate);

  const dispatch = useDispatch();
  const classes = useStyles();

  const { control, watch } = useForm({
    defaultValues: {
      start_date: formValues.goalsData.startDate,
      end_date: formValues.goalsData.endDate,
    },
  });

  const watchedStartDate = watch("start_date");
  const watchedEndDate = watch("end_date");

  useEffect(() => {
    setStartDate(watchedStartDate);
    setEndDate(watchedEndDate);
  }, [watchedStartDate, watchedEndDate]);

  useEffect(() => {
    if (handleGoalsChange) {
      handleGoalsChange({
        startDate,
        endDate,
        goalWeight,
        evaluationScale: selectedScale,
        stakeholders: stakeholdersData,
        evaluationForm: selectedItem,
      });
    }
  }, [handleGoalsChange, goalWeight, selectedScale, stakeholdersData, selectedItem,
    startDate, endDate]);

  const handleStakeholdersChange = useCallback((updatedStakeholders) => {
    const stakeholdersArray = Array.isArray(updatedStakeholders)
      ? updatedStakeholders
      : Object.values(updatedStakeholders);
    setStakeholdersData(stakeholdersArray);
  });

  const handleItemChange = (event) => {
    const { value } = event.target;

    if (value === "keyIndicator") {
      setSelectedScale(null);
    }

    setSelectedItem(value);
  };

  const handleScaleChange = (event) => {
    setSelectedScale(event.target.value);
    if (event.target.value === "custom") {
      setIsCustomFormVisible(true);
      setIsEditFormVisible(false);
    } else {
      setIsCustomFormVisible(false);
      setIsEditFormVisible(false);
    }
    setSelectedItem("scale");
  };

  const { list: evaluationScales, isLoadingList: isLoadingEvaluationScales } = useSelector(
    ({ evaluationScaleReducer }) => evaluationScaleReducer,
  );

  const handleWeightChange = (event) => {
    const { value } = event.target;
    setGoalWeight(value / 100);
  };

  const onLanguageChange = (lang) => {
    setFormLanguage(lang);
  };

  const handleCancelSectionClick = () => {
    setIsEditFormVisible(false);
    setIsCustomFormVisible(false);
    if (selectedScale === "custom") {
      setSelectedScale(evaluationScales[0].id);
    }
  };

  const handleEditScale = (updatedScale) => {
    setSelectedScale(updatedScale.id);
    dispatch(resetState());
    setRefreshScales((prev) => !prev);
  };

  const handleEditEvaluationScale = () => {
    setIsEditFormVisible(true);
    setIsCustomFormVisible(false);
  };

  const handleCreateScale = (newScale) => {
    dispatch(resetState());
    setSelectedScale(newScale.id);
    setRefreshScales((prev) => !prev);
  };

  useEffect(() => {
    const query = {
      q: {
        hidden_not_eq_or_null: true,
        evaluation_scale_indices_value_eq: 1.2,
      },
    };
    dispatch(getEvaluationScales(query));
  }, [dispatch, refreshScales]);

  useEffect(() => {
    if (!isLoadingEvaluationScales && evaluationScales) {
      const list = getFormattedScalesList(evaluationScales, formLanguage);
      setFormattedScalesList(list);
    }
  }, [evaluationScales, isLoadingEvaluationScales, formLanguage, refreshScales]);

  useEffect(() => {
    if (evaluations) {
      const filtered = evaluations.filter((evaluation) => {
        const typeWithTemplate = ensureTemplateSuffix(evaluation.type);
        return typeWithTemplate === EVALUATION_TYPE.AUTOEVALUATION
        || typeWithTemplate === EVALUATION_TYPE.MANAGER;
      });
      setFilteredEvaluations(filtered);
    }
  }, [evaluations]);

  const steps = [
    {
      label: (
        <StyledHeader>
          <Typography variant={ VARIANT.h5 }>{t("planning:steps:goals:stakeholders:title")}</Typography>
          <Typography variant={ VARIANT.bodyOne }>{t("planning:steps:goals:stakeholders:subtitle")}</Typography>
        </StyledHeader>
      ),
      content: (
        <Box maxWidth={ "700px" } mb={ 5 } mt={ 3 }>
          <GoalsStakeholders
            t={ t }
            evaluations={ filteredEvaluations }
            formLanguage={ formLanguage }
            onStakeholdersChange={ handleStakeholdersChange }
            user={ user }
            formValues={ formValues }
            isDisabled={ isDisabled }
          />
        </Box>
      ),
    },
    {
      label: (
        <Grid>
          <Typography variant={ VARIANT.h5 }>
            {t("planning:steps:goals:dates:title")}
          </Typography>
          <Typography variant={ VARIANT.bodyOne } mt={ 1 }>
            {t("planning:steps:goals:dates:subtitle")}
          </Typography>
          <Typography variant={ VARIANT.bodyTwo } mt={ 1 }>
            {t("planning:steps:goals:dates:example")}
          </Typography>
        </Grid>
      ),
      content: (
        <Grid container mt={ 3 }>
          <Grid item xs={ 5 }>
            <DateInputController
              label={ t("surveys:form.fields.start_date") }
              name={ OBJECT_KEYS.start_date }
              openTo={ DATE_PARTS.day }
              value={ startDate }
              control={ control }
              views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
              type={ TYPES.text }
              customStyles={ classes.dateInputs }
              required
              disabled={ isDisabled }
            />
          </Grid>
          <Grid item xs={ 5 }>
            <DateInputController
              label={ t("surveys:form.fields.end_date") }
              name={ OBJECT_KEYS.end_date }
              openTo={ DATE_PARTS.day }
              value={ endDate }
              control={ control }
              views={ [DATE_PARTS.year, DATE_PARTS.month, DATE_PARTS.day] }
              type={ TYPES.text }
              customStyles={ classes.dateInputs }
              required
              disabled={ isDisabled }
            />
          </Grid>
        </Grid>
      ),
    },
    {
      label: (
        <Typography variant={ VARIANT.h5 }>
          {t("planning:steps:goals:scale:title")}
        </Typography>
      ),
      content: (
        <Box mt={ 3 } ml={ 5 }>
          <Grid container spacing={ 4 }>
            <RadioGroup
              row
              value={ selectedItem }
              onChange={ handleItemChange }
            >
              <Grid item xs={ 6 }>
                <FormControlLabel
                  value={ "scale" }
                  disabled={ isDisabled }
                  control={ <Radio /> }
                  label={ (
                    <Typography variant={ "h6" }>
                      {t("planning:steps:goals:scale:title1")}
                    </Typography>
                  ) }
                />
                <Box>
                  <StyledSelect
                    value={ selectedScale || ""}
                    onChange={ handleScaleChange }
                    displayEmpty
                    fullWidth
                    disabled={ isDisabled }
                  >
                    {formattedScalesList?.map((scale, index) => (
                      <MenuItem key={ index } value={ scale.value }>
                        <Typography>{scale.label}</Typography>
                      </MenuItem>
                    ))}
                  </StyledSelect>
                  {selectedScale && !isEditFormVisible && !isCustomFormVisible && !isDisabled && selectedScale !== "custom"
                  && (
                    <IconButton
                      onClick={ handleEditEvaluationScale }
                    >
                      <EditIcon />
                    </IconButton>
                  )}
                </Box>
              </Grid>
              <Grid item xs={ 6 }>
                <Box textAlign={ "center" }>
                  <FormControlLabel
                    value={ "keyIndicator" }
                    disabled={ isDisabled }
                    control={ <Radio /> }
                    label={ (
                      <Box>
                        <Typography variant={ "h6" }>
                          {t("planning:steps:goals:scale:title2")}
                        </Typography>
                      </Box>
                    ) }
                  />
                  <Box mt={ 2 }>
                    <Typography variant={ "h6" } color={ "primary" }>
                      {t("planning:steps:goals:scale:numbers")}
                    </Typography>
                    <Typography variant={ "body2" } color={ "textSecondary" }>
                      {t("planning:steps:goals:scale:numbers_text")}
                    </Typography>
                  </Box>
                </Box>
              </Grid>
            </RadioGroup>
          </Grid>
          {isEditFormVisible && selectedScale !== "custom" && (
            <Grid item xs={ 12 }>
              <StyledEvaluationScaleForm>
                <EvaluationScaleEditForm
                  onLanguageChange={ onLanguageChange }
                  t={ t }
                  formLanguage={ formLanguage }
                  handleCancelSectionClick={ handleCancelSectionClick }
                  handleEditScale={ handleEditScale }
                  scale={ evaluationScales.find((scale) => scale.id === selectedScale) }
                  dispatch={ dispatch }
                />
              </StyledEvaluationScaleForm>
            </Grid>
          )}
          {isCustomFormVisible && (
            <Grid item xs={ 12 }>
              <StyledEvaluationScaleForm>
                <EvaluationScaleForm
                  i18n={ i18n }
                  t={ t }
                  onLanguageChange={ onLanguageChange }
                  formLanguage={ formLanguage }
                  handleCancelSectionClick={ handleCancelSectionClick }
                  handleCreateScale={ handleCreateScale }
                  dispatch={ dispatch }
                />
              </StyledEvaluationScaleForm>
            </Grid>
          )}
        </Box>

      ),
    },
  ];

  return (
    <Box>
      <Box mb={ 5 }>
        <Grid container spacing={ 10 } direction={ "row" } alignItems={ "flex-start" }>
          <Grid item xs={ 6 }>
            <StyledBox>
              <StyledTypography variant={ VARIANT.h6 }>
                {t("planning:steps:goals:alert:title")}
              </StyledTypography>
              <ul>
                <li>
                  <StyledTypography>{t("planning:steps:goals:alert:l1")}</StyledTypography>
                </li>
                <li>
                  <StyledTypography>{t("planning:steps:goals:alert:l2")}</StyledTypography>
                </li>
                <li>
                  <StyledTypography>{t("planning:steps:goals:alert:l3")}</StyledTypography>
                </li>
              </ul>
            </StyledBox>
          </Grid>
          <Grid item xs={ 6 }>
            <Typography variant={ VARIANT.h6 }>
              {t("planning:steps:goals:weight:title")}
            </Typography>
            <StyledTextField
              inputProps={ { inputMode: "numeric", maxLength: 3, endAdornment: "%" } }
              label={ t("planning:steps:goals:weight:value") }
              value={ Math.round(goalWeight * 100) || 0 }
              onChange={ handleWeightChange }
              required
              error={ goalWeight > 1 || goalWeight < 0 }
              disabled={ isDisabled }
              onWheel={ (e) => e.target.blur() }
            />
            <Typography>
              {t("planning:steps:goals:weight:legend")}
            </Typography>
          </Grid>
        </Grid>
        <Stepper orientation={ ORIENTATION.vertical } connector={ null }>
          {steps.map((step, index) => (
            <Step key={ index } active>
              <Box mt={ 5 }>
                <StyledStepLabel icon={ stepLetters[index] }>{step.label}</StyledStepLabel>
                <StyledContent>
                  {step.content}
                </StyledContent>
              </Box>
            </Step>
          ))}
        </Stepper>
      </Box>
    </Box>
  );
};

Goals.propTypes = {
  handleGoalsChange: PropTypes.func.isRequired,
  t: PropTypes.func.isRequired,
  evaluations: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.oneOf(Object.values(EVALUATION_TYPE)),
      weighing: PropTypes.number,
    }),
  ),
  initialFormLanguage: PropTypes.string.isRequired,
  user: PropTypes.object.isRequired,
  isDisabled: PropTypes.bool,
  formValues: PropTypes.object.isRequired,
};

Goals.defaultProps = {
  evaluations: [],
  isDisabled: false,
};

export default Goals;
