import styled from "styled-components";
import { STATE_COLORS } from "theme/palette";
import makeStyles from "@mui/styles/makeStyles";
import Button from "components/Button";
import theme from "theme";

export const useStyles = makeStyles((localTheme) => ({
  customTable: {
    background: localTheme.palette.background.default,
  },
  totalCell: {
    fontWeight: 500,
    lineHeight: "30px",
  },
  goalsForm: {
    "& input, p, button, textarea": {
      fontSize: "13px",
    },
    "& input": {
      verticalALign: "top",
    },
    "& input, p, .MuiOutlinedInput-root, select": {
      background: localTheme.palette.white,
    },
  },
  hiddenInput: {
    display: "none",
  },
  fullWidth: {
    width: "100% !important",
  },
  addGoal: {
    borderRadius: 100,
    padding: "0px 10px",
    margin: localTheme.spacing(1),
    fontSize: "13px",
  },
  addGoalIcon: {
    width: "0.7em",
    marginRight: localTheme.spacing(0.5),
  },
  delete: {
    color: STATE_COLORS.ERROR,
  },
}));

export const StyledContainer = styled.div`
  table tr th, table tr td {
    font-size: 12px;
    line-height: 15px;
  }
  table tr th:first-child, table tr td:first-child {
    padding: 0 15px;
  }
  table tr th:not(:first-child), table tr td:not(:first-child) {
    padding: 5px;
  }
  .title {
    width: 150px;
  }
`;

export const StyledButton = styled(Button)`
  border-radius: 100px;
  padding: 0px 10px;
  margin: ${theme.spacing(1)};
  font-size: 13px;

  svg {
    width: 16px;
    height: 16px;
  }
`;

export const StyledDescription = styled.div`
  background: #E6EBF3;
  margin: 10px 0;
  padding: 10px 20px;
  border-radius: 4px;
  white-space: pre-line;
  p, h1, h2, h3, h4, h5, h6 {
    background: none;
    margin: 0;
  }
`;

export const StyledCollapseData = styled.div`
  padding: 10px 0px;
  .rdw-editor-wrapper {
    background: #fff;
  }
  h4 {
    margin: 10px 0;
  }
`;

export const StyledActionButton = styled.div`
  display: flex;
  justify-content: flex-end;
`;
