import { useEffect, useState, useCallback } from "react";
import { useHistory } from "react-router-dom";
import { useQuery } from "react-query";
import PropTypes from "prop-types";
import Grid from "@mui/material/Grid";
import VerticalStepperList from "components/VerticalStepperList";
import SkeletonLoader from "components/SkeletonLoader";
import SimpleAlert from "components/SimpleAlert";
import {
  VARIANT, PATHS, SKELETONS_NUMBER, NINEBOX,
} from "common/constants";
import { historyPush } from "common/utils";
import { isEmpty, isNotValid, isEqual } from "common/helpers";
import localRoutes from "views_refactor/Profile/functions/routes";
import { useNineBoxTypes } from "hooks/useNineBoxType";
import { getListByEmployee as getNineboxResultByEmployee } from "redux/actions/talentScore/talentScoreActions";
import HistoricalResult from "../HistoricalResult";
import { getDataNineBoxResult } from "../../functions";
import {
  ButtonStyled, Container, Title, TextButton,
} from "./styles";

const HistoricalEmployee = (props) => {
  const {
    data,
    t,
    resultScaleList,
    isLoadingScaleList,
  } = props;
  const history = useHistory();

  const { id: employeeId, talentScoreId } = data;
  const [historicalEmployee, setHistoricalEmployee] = useState([]);

  // TODO: evaluate general app and add it to the new architecture, don't use dispatch
  // React Query
  // When obtaining the history of an employee,
  // it is avoided to consult again since it caches the data
  const {
    data: historical,
    isLoading,
    error,
  } = useQuery(["historical", employeeId], () => getNineboxResultByEmployee(employeeId), {
    // considered time in which the state has no changes to avoid querying API
    // use Infinity when considered to be unchanged
    staleTime: Infinity,
  });
  // End React Query

  const getViewProfile = () => {
    historyPush(
      history,
      localRoutes.ninebox,
      `${PATHS.search.collaborators}${employeeId}`,
    );
  };

  const { nineBoxTypeList, isLoading: isLoadingType } = useNineBoxTypes(t);

  const getHistoricalEmployee = useCallback(async () => {
    const historicalWithoutCurrent = historical.filter((item) => !isEqual(item.id, talentScoreId));
    let historyByEmployee = [];
    if (!isEmpty(historicalWithoutCurrent)) {
      const results = await getDataNineBoxResult(
        historicalWithoutCurrent, resultScaleList, t, nineBoxTypeList,
      );
      historyByEmployee = await Promise.all(
        results.map(async (result) => {
          result.title = `${t("ninebox:quadrant")}: ${result?.quadrantData[NINEBOX.quadrantData.name]}`;
          const historicalResult = <HistoricalResult data={ result } t={ t } />;
          return {
            title: result.title,
            customRender: historicalResult,
          };
        }),
      );
    }
    setHistoricalEmployee(historyByEmployee);
  }, [historical, resultScaleList, t, talentScoreId]);

  useEffect(() => {
    if (!isLoading && !isLoadingScaleList && !isLoadingType) {
      getHistoricalEmployee();
    }
  }, [historical, isLoading, isLoadingScaleList, getHistoricalEmployee]);

  const historicalData = () => {
    if (error) {
      // statusMessage: t(`common:common.api_responses.error.${error.response.status}`)
      return (
        <SimpleAlert
          type={ "error" }
          title={ t("ninebox:errors.notFound") }
        />
      );
    }
    return <VerticalStepperList data={ historicalEmployee } />;
  };

  return (
    <Container>
      {data && (
        <>
          <Grid container>
            <HistoricalResult data={ data } { ...props } />
            <Grid item xs={ 12 }>
              {employeeId && (
                <ButtonStyled
                  variant={ VARIANT.outlined }
                  onClick={ () => getViewProfile() }
                >
                  <TextButton>{ t("collaborators:table.table-body.view_profile") }</TextButton>
                </ButtonStyled>
              )}
            </Grid>
            {!isEmpty(historicalEmployee)
            && (
              <Grid item xs={ 12 }>
                <Title variant={ VARIANT.h6 }>
                  {t("ninebox:historical")}
                </Title>
              </Grid>
            )}
          </Grid>
          {isLoading && isNotValid(historical) && historicalEmployee
            ? <SkeletonLoader numberOfSkeletons={ SKELETONS_NUMBER.FOUR } />
            : historicalData() }
        </>
      )}
    </Container>
  );
};

HistoricalEmployee.propTypes = {
  data: PropTypes.object.isRequired,
  t: PropTypes.func.isRequired,
  resultScaleList: PropTypes.array.isRequired,
  isLoadingScaleList: PropTypes.bool.isRequired,
};

export default HistoricalEmployee;
